import * as yup from 'yup'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../const'
import { getValidateText } from '../ValidateText'

const SpacePalletDetailSchema = yup.object().shape({
  params: yup.object().shape({
    size: yup
      .array()
      .of(
        yup
          .number()
          .integer(INTL_ERROR_MESSAGE.INTEGER)
          .typeError(INTL_ERROR_MESSAGE.REQUIRED)
          .min(1, getValidateText('number', 'min', 1))
          .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
          .required(INTL_ERROR_MESSAGE.REQUIRED)
      ),
    indentation: yup
      .array()
      .of(
        yup
          .number()
          .integer(INTL_ERROR_MESSAGE.INTEGER)
          .typeError(INTL_ERROR_MESSAGE.REQUIRED)
          .min(0, getValidateText('number', 'min', 0))
          .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
          .required(INTL_ERROR_MESSAGE.REQUIRED)
      )
  })
})

export default SpacePalletDetailSchema
