import * as yup from 'yup'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../const'
import { getValidateText } from '../ValidateText'

export default {
  length: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  height: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  width: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'width',
      INTL_ERROR_MESSAGE.WIDTH,
      (value, ctx) => !value || ctx.parent.length >= value
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  loading_length: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      INTL_ERROR_MESSAGE.LOADING_LENGTH,
      (value, ctx) => value === 0 || !value || value <= ctx.parent.length
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  loading_width: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      INTL_ERROR_MESSAGE.LOADING_WIDTH,
      (value, ctx) => value === 0 || !value || value <= ctx.parent.width
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  loading_height: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      INTL_ERROR_MESSAGE.LOADING_HEIGHT,
      (value, ctx) => value === 0 || !value || value <= ctx.parent.height
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  mass: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
    .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  carrying_capacity: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.CEILING_MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.CEILING_MIN_NUMBER))
    .max(ERROR_NUMBERS.CEILING_MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.CEILING_MAX_NUMBER))
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  external_UID: yup
    .string()
    .trim()
    .nullable()
    .max(ERROR_NUMBERS.MAX_STRING_EXTERNAL_UID, getValidateText('string', 'max', ERROR_NUMBERS.MAX_STRING_EXTERNAL_UID))
    .optional()
}
