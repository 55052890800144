import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'

const AuthSchema = yup.object().shape({
  username: yup
    .string()
    .email(INTL_ERROR_MESSAGE.EMAIL)
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  password:
    yup
      .string()
      .required(INTL_ERROR_MESSAGE.REQUIRED)
})

export default AuthSchema
