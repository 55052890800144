import React, { useRef, useEffect } from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'

import { youtubeBg } from '../../../images'
import { BUTTON_THEME } from '../../../const'

import InnerHtml from '../../../components/InnerHtml'
import Button from '../../Button'
import style from './style/index.module.scss'

/**
 *
 * @view
 * * Вью секции с линией градинта
 *
 * @param {Object} content   обьект с данными нашей вью (с бэка)
 * @param {string} id        id вьюхи
 * @param {string} title     Заголовок
 * @param {string} text      Подзаголовок
 */

const WatchUs = ({ handleOpenYouTubeModals, id, title, text, count, content }) => {
  const { video, theme } = content
  const styleWatchUs = theme && {
    '--title-color': theme.text_title_color,
    '--text-color': theme.text_color,
    '--font-custom': theme.font
  }

  const videoRef = useRef(null)

  useEffect(() => {
    videoRef && videoRef.current && videoRef.current.play()
  }, [])

  return (
    <section
      data-cy={`${id}-home-section`}
      data-count={count}
      id={id}
      className={style['watch-us']}
      style={styleWatchUs}
    >
      <div className={style['watch-us__wrap']}>
        <div className={style['watch-us__wrap_bg']} />
        <video
          className={style['watch-us__video']}
          loop
          muted
          playsInline
          ref={videoRef}
        >
          {video && <source src={video} type='video/mp4' />}
        </video>
      </div>
      <div className={style['watch-us__banner']}>
        <div className={style['watch-us__banner_left']}>
          <h2 className={style['watch-us__banner_title']}>{title}</h2>
          <InnerHtml className={style['watch-us__banner_text']} html={text} />
        </div>
        <Button
          onClick={handleOpenYouTubeModals}
          className={style['watch-us__btn']}
          theme={BUTTON_THEME.PLAY}
        >
          <GxIcon className={style['watch-us__btn_icon']} src={youtubeBg} />
        </Button>
      </div>
    </section>
  )
}

export default WatchUs
