import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'


class PaymentApiService extends Api {
  proofPayment = async (params) => {
    const res = await this.post('/payment/', params);
    return res.data;
  };
}


export default new PaymentApiService(runtimeConfig.API_URL)