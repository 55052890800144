import React from 'react'
import Layout from '.'
import HeaderLandingComponent from '../../components/HeaderLandingComponent'
import FooterLandingComponent from '../../components/FooterLandingComponent'

const Landing = ({ children, dataAttr = '' }) => (
  <Layout.Seo>
    <HeaderLandingComponent />
    <main data-cy={dataAttr}>{children}</main>
    <FooterLandingComponent />
  </Layout.Seo>
)

export default Landing
