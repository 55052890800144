import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'


class CargoSpaceApiService extends Api {
  cargoSpaceTariff = async () => {
    const res = await this.get('/cargo/cargo-space/check_tariff/');
    return res.data;
  };
  getSpecificCargoSpaces = async ({ page_size = 10, ...params }) => {
    const res = await this.get('/cargo/cargo-space/', {
      page_size,
      ...params,
    });
    return res.data;
  };
  deleteCargoSpacesList = async (id) =>{
    const res = await this.delete(`/cargo/cargo-space/list_cargo_space_delete/?${id}`);
    return res.data;
  }
  loadCargoSpaceExample = async () => {
    const res = await this.get('/cargo/cargo-space/sample/');
    return res.data.cargo_space;
  };
  createFileSpace = async ({ file }) => {
    const fd = new FormData();
    fd.append('file', file);
    const res = await this.post(
      '/cargo/cargo-space/create_from_file_upload/',
      fd
    );
    return res.data;
  };
}


export default new CargoSpaceApiService(runtimeConfig.API_URL)