import React from 'react'
import cn from 'classnames'
import styles from './styles/index.module.scss'

const FrequentProblemsImage = (props) => {
  const { number, image, className, theme } = props

  const normalNumber = number >= 10 ? number : `0${number}`
  return (
    <div className={cn({
      [styles.frequentProblems__image]: true,
      [styles[theme]]: !!theme,
      [className]: !!className
    })}
    >
      {number && <div className={styles.frequentProblems__image_sort}>{normalNumber}</div>}
      <img width='100' height='100' src={image} alt='frequent problem image' loading='lazy' />
    </div>
  )
}

export default FrequentProblemsImage
