/* eslint-disable camelcase */
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { ACTION_BUTTON_TYPE, BUTTON_THEME } from '../../../const'
import Button from '../../Button'

const ActionButton = ({ actionButton, handleOpenModal, className, callBack, ...restProps }) => {
  const { theme: buttonTheme } = actionButton

  const outsideUrl = actionButton?.outside_url || null
  const target = outsideUrl && '_blank'

  const history = useHistory()

  const style = buttonTheme && {
    '--background-color': buttonTheme.background_color,
    '--color': buttonTheme.text_color,
    '--border-color': buttonTheme.border_color,
    '--hover-color': buttonTheme.hover_color
  }

  const handleAction = useCallback(() => {
    handleOpenModal(actionButton?.role_type, actionButton?.form_type)
    callBack && callBack()
  }, [actionButton])

  const redirectToPath = useCallback(() => {
    actionButton?.url && history.push(actionButton?.url)
    callBack && callBack()
  }, [actionButton])

  const theme = buttonTheme?.frame ? BUTTON_THEME.FILL_ICON.CUSTOM_STROKE : BUTTON_THEME.FILL_ICON.CUSTOM_FILL

  return (actionButton?.button_type === ACTION_BUTTON_TYPE.BUTTON
    ? (
      <Button
        {...restProps}
        style={style}
        theme={theme}
        className={className}
        onClick={handleAction}
      >
        {actionButton?.text_title}
      </Button>
      )
    : (
      <Button
        {...restProps}
        style={style}
        className={className}
        onClick={redirectToPath}
        theme={theme}
        target={target}
        href={outsideUrl}
      >
        {actionButton?.text_title}
      </Button>
      )
  )
}

export default ActionButton
