import React from 'react'
import styles from './styles/index.module.scss'

const ModalTitle = ({ children }) => {
  return (
    <div data-cy='modal-title' className={styles.title}>
      {children}
    </div>
  )
}

export default ModalTitle
