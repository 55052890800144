import React, { useRef } from 'react'
import classNames from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Navigation } from 'swiper/core'

import { GxIcon } from '@garpix/garpix-web-components-react'
import { arrowIcon } from '../../images'
import SuitabilityCard from '../SuitabilityCard'

import styles from './style/index.module.scss'

SwiperCore.use([Navigation, Autoplay])

const SuitabilitySlider = ({ content }) => {
  const { text, text_subtitle: subtitle, text_title: title, target_audience_elements: elements } = content
  const haveHeader = Boolean(subtitle || text || title)
  const arrowsSlot = haveHeader ? 'container-start' : 'container-end'

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  return (
    <div className={styles.slider__wrapper}>
      <Swiper
        className={styles.slider__swiper}
        direction='horizontal'
        navigation={{
          nextEl: nextRef.current,
          prevEl: prevRef.current
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
        }}
        speed={700}
        slidesPerView={3}
        touchEventsTarget='container'
        spaceBetween={200}
        observer
        breakpoints={{
          300: {
            slidesPerView: 1
          },
          750: {
            slidesPerView: 2,
            spaceBetween: 200
          },
          1024: {
            spaceBetween: 150
          },
          1200: {
            slidesPerView: 3
          }
        }}
      >
        <div slot={arrowsSlot} className={styles.slider__buttons}>
          <button
            aria-label='Предыдущий слайд'
            ref={prevRef}
            className={classNames(
              styles.slider__button,
              styles['slider__button--prev']
            )}
          >
            <GxIcon className={styles['slider__button-icon']} src={arrowIcon} />
          </button>
          <button
            aria-label='Следующий слайд'
            ref={nextRef}
            className={classNames(
              styles.slider__button,
              styles['slider__button--next']
            )}
          >
            <GxIcon className={styles['slider__button-icon']} src={arrowIcon} />
          </button>
        </div>

        {elements.map((item) => {
          return (
            <SwiperSlide key={item.id} className={styles.slide}>
              <SuitabilityCard
                title={item.text_title}
                description={item.text}
                image={item.image}
                type='div'
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default SuitabilitySlider
