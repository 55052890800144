import React from 'react'
import usePage from '../../hooks/usePage'
import SeoTitle from '../SeoTitle'
/**
 * @view
 * * сео лейаут
 */
const Seo = ({ children }) => {
  const { page } = usePage()
  const { object, global } = page.init_state
  const { seo_title: seoTitle = '', title } = object
  const siteCommonTitle = global?.site_common_title

  return (
    <>
      <SeoTitle
        seoTitle={seoTitle}
        title={title}
        siteCommonTitle={siteCommonTitle}
      />
      <>{children}</>
    </>
  )
}

export default Seo
