import React from 'react'
import Pro from './Pro'
import Logo from './Logo'
import Remove from './Remove'
import Success from './Success'
import Warning from './Warning'
import Crown from './Crown'
import Move from './Move'
import File from './File'
import styles from './styles/index.module.scss'

const ModalIcon = ({ children }) => {
  return (
    <div className={styles.modal_icon}>
      {children}
    </div>
  )
}

export default Object.assign(ModalIcon, { Pro, Success, Remove, Warning, Logo, Crown, Move, File })
