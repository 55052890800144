import { useQuery } from 'react-query'
import { MAX_SIZE_NOTIFICATION_MENU } from '../../../../const'
import { notifyApiService } from '../../../../api/services'
import { MENU_NOTIFICATIONS_QK } from '../../const'

const useFetchNotifications = (enabled) => {
  return useQuery(
    MENU_NOTIFICATIONS_QK,
    () => notifyApiService.getNotify({ page_size: MAX_SIZE_NOTIFICATION_MENU }),
    {
      enabled: enabled,
      staleTime: 1500
    }
  )
}

export default useFetchNotifications
