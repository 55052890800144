export const MODALS_KEYS = {
  default: 'DEFAULT',
  reload: 'RELOAD_PAGE',
  tariffLimit: 'TARIFF_LIMIT',
  help: 'HELP',
  thanks: 'THANKS',
  warning: 'WARNING',
  webgl: 'WEB_GL',
  fieldError: 'FIELD_ERROR',
  listLimit: 'LIST_LIMIT',
  switchProject: 'SWITCH_PROJECT',
  cancelTariff: 'CANCEL_TARIFF',
  fileLoadSuccess: 'FILE_LOAD_SUCCESS',
  fileLoadError: 'FILE_LOAD_ERROR',
  tariffDuration: 'TARIFF_DURATION',
  noChangeCargoSpace: 'NO_CHANGE_CARGO_SPACE',
  historyWarning: 'HISTORY_WARNING',
  sendRequestError: 'SEND_REQUEST_ERROR',
  warningPalletSpace: 'WARNING_PALLET_SPACE',
  cargoSpaceTypeChange: 'CARGO_SPACE_TYPE_CHANGE',
  warningPalletCargo: 'WARNING_PALLET_CARGO',
  loadingOrderWarning: 'LOADING_ORDER_WARNING',
  cargoSuccessSave: 'CARGO_SUCCESS_SAVE',
  loudStepMove: 'LOUD_STEP_MOVE',
  updateAntiAlias: 'UPDATE_ANTIALIAS',
  quitAccount: 'QUIT_ACCOUNT',
  demoLimit: 'DEMO_LIMIT',
  successDeleteStaff: 'SUCCESS_DELETE_STAFF',
  successInvite: 'SUCCESS_INVITE',
  successChangeOwner: 'SUCCESS_CHANGE_OWNER',
  oldAppVersion: 'OLD_APP_VERSION',
  palletReduction: 'PALLET_REDUCTION'
}

export const MODAL_INTL_CONTENT = {
  [MODALS_KEYS.palletReduction]: {
    icon: 'warning',
    description: {
      id: 'modals.content.palletReduction.description',
      defaultMessage: 'Сумма высоты палеты и погрузочной высоты палеты X больше погрузочной высоты самого высокого ГП в списке. Погрузочная высота палеты уменьшена. До самой большо погрузочной высоты.'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.oldAppVersion]: {
    icon: 'warning',
    title: {
      id: 'modals.content.oldAppVersion.title',
      defaultMessage: 'Отсутствует запрашиваемая страница'
    },
    description: {
      id: 'modals.content.oldAppVersion.description',
      defaultMessage: 'Ваша версия приложения устарела, необходимо перезагрузить страницу'
    },
    accept: {
      id: 'reload',
      defaultMessage: 'Перезагрузить'
    }
  },
  [MODALS_KEYS.warning]: {
    icon: 'warning',
    title: {
      id: 'didntWork',
      defaultMessage: 'Не получилось!'
    },
    description: {
      id: 'modals.content.warning.description',
      defaultMessage: 'Что-то пошло не так...'
    },
    accept: {
      id: 'ok',
      defaultMessage: 'Ок'
    }
  },
  [MODALS_KEYS.warningPalletCargo]: {
    icon: 'warning',
    title: {
      id: 'warning',
      defaultMessage: 'Предупреждение'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    },
    description: {
      id: 'modals.content.warningPalletCargo.description',
      defaultMessage: 'Нельзя разместить грузы на палету, так как выбрано грузовое пространство - палета'
    }
  },
  [MODALS_KEYS.tariffLimit]: {
    title: {
      id: 'modals.content.tariffLimit.title',
      defaultMessage: 'Этот функционал доступен только в платном тарифе'
    },
    description: {
      id: 'modals.content.tariffLimit.description',
      defaultMessage: 'Функция, которую вы хотите использовать, недоступна в текущем тарифе. Выберите тариф в соответствии с периодом, на который хотите приобрести доступ к профессиональной версии продукта.'
    },
    close: {
      id: 'cancel',
      defaultMessage: 'Отмена'
    },
    accept: {
      id: 'tariffs',
      defaultMessage: 'Тарифы'
    }
  },
  [MODALS_KEYS.fieldError]: {
    icon: 'warning',
    title: {
      id: 'modals.content.fieldError.title',
      defaultMessage: 'Некорректные данные'
    },
    description: {
      id: 'modals.content.fieldError.description',
      defaultMessage: 'Проверьте корректность введенных данных'
    },
    accept: {
      id: 'ok',
      defaultMessage: 'Ок'
    }
  },
  [MODALS_KEYS.cargoSuccessSave]: {
    icon: 'success',
    title: {
      id: 'success',
      defaultMessage: 'Успешно'
    },
    description: {
      id: 'modals.content.cargoSuccessSave.description',
      defaultMessage: 'Груз успешно сохранен'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.historyWarning]: {
    icon: 'warning',
    title: {
      id: 'didntWork',
      defaultMessage: 'Не получилось!'
    },
    description: {
      id: 'modals.content.historyWarning',
      defaultMessage: 'Произошла ошибка при загрузке данных истории расчетов'
    },
    accept: {
      id: 'ok',
      defaultMessage: 'Ок'
    }
  },
  [MODALS_KEYS.warningPalletSpace]: {
    icon: 'warning',
    title: {
      id: 'warning',
      defaultMessage: 'Предупреждение'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    },
    description: {
      id: 'modals.content.warningPalletSpace',
      defaultMessage: 'Нельзя выбрать грузовое пространство - палета, так как грузы размещены на палету'
    }
  },
  [MODALS_KEYS.cargoSpaceTypeChange]: {
    title: {
      id: 'modals.content.cargoSpaceTypeChange.title',
      defaultMessage: 'Изменить тип грузового пространства?'
    },
    description: {
      id: 'modals.content.cargoSpaceTypeChange.description',
      defaultMessage: 'Изменение приведет к очистке заполненых полей'
    },
    close: {
      id: 'cancel',
      defaultMessage: 'Отмена'
    },
    accept: {
      id: 'change',
      defaultMessage: 'Изменить'
    }
  },
  [MODALS_KEYS.updateAntiAlias]: {
    title: {
      id: 'modals.content.updateAntiAlias.title',
      defaultMessage: 'Для изменения этого параметра требуется перезагрузить страницу!'
    },
    description: {
      id: 'modals.content.updateAntiAlias.description',
      defaultMessage: 'Несохраненые изменения не сохранятся.Вы точно хотите перезагрузить страницу?'
    },
    accept: {
      id: 'yes',
      defaultMessage: 'Да'
    },
    close: {
      id: 'cancel',
      defaultMessage: 'Отмена'
    }
  },
  [MODALS_KEYS.switchProject]: {
    icon: 'success',
    title: {
      id: 'modals.content.switchProject.title',
      defaultMessage: 'В данном проекте завершен расчет'
    },
    accept: {
      id: 'go',
      defaultMessage: 'Перейти'
    },
    close: {
      id: 'stay',
      defaultMessage: 'Остаться'
    }
  },
  [MODALS_KEYS.cancelTariff]: {
    title: {
      id: 'modals.content.cancelTariff.title',
      defaultMessage: 'Вы точно хотите остановить автопродление?'
    },
    description: {
      id: 'modals.content.cancelTariff.description',
      defaultMessage: 'После отписки, оплаченный тариф продолжает работу весь оплаченный период'
    },
    accept: {
      id: 'unsubscribe',
      defaultMessage: 'Отписаться'
    },
    close: {
      id: 'cancel',
      defaultMessage: 'Отмена'
    }
  },
  [MODALS_KEYS.help]: {
    icon: 'success',
    title: {
      id: 'modals.content.help.title',
      defaultMessage: 'Ваш запрос успешно отправлен!'
    },
    description: {
      id: 'modals.content.help.description',
      defaultMessage: 'Мы ответим вам в ближайшее время.'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.demoLimit]: {
    icon: 'pro',
    title: {
      id: 'modals.content.demoLimit.title',
      defaultMessage: 'В вашем тарифе отсутствует доступ к данному функционалу'
    },
    description: {
      id: 'modals.content.demoLimit.description',
      defaultMessage: 'Выберите другой тариф, чтобы получить больше возможностей для работы'
    },
    close: {
      id: 'cancel',
      defaultMessage: 'Отмена'
    },
    accept: {
      id: 'goToRates',
      defaultMessage: 'Перейти к тарифам'
    }
  },
  [MODALS_KEYS.noChangeCargoSpace]: {
    icon: 'warning',
    title: {
      id: 'warning',
      defaultMessage: 'Предупреждение'
    },
    description: {
      id: 'modals.content.noChangeCargoSpace.description',
      defaultMessage: 'Нельзя редактировать данное грузовое пространство. Для редактирования скопируйте его'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.successChangeOwner]: {
    icon: 'success',
    title: {
      id: 'ownerChangedSuccessfully',
      defaultMessage: 'Владелец успешно изменен'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.successDeleteStaff]: {
    icon: 'success',
    title: {
      id: 'employeeSuccessfullyRemoved',
      defaultMessage: 'Сотрудник успешно удален'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.loudStepMove]: {
    icon: 'warning',
    title: {
      id: 'modals.content.loudStepMove.title',
      defaultMessage: 'Ошибка! Статус проекта - Перерасчет'
    },
    description: {
      id: 'modals.content.loudStepMove.description',
      defaultMessage: 'Перерасчитайте проект чтобы воспользоаться "Шагом погрузки"'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.sendRequestError]: {
    icon: 'warning',
    title: {
      id: 'applicationNotSent',
      defaultMessage: 'Заявка не отправлена'
    },
    description: {
      id: 'tryAgain',
      defaultMessage: 'Попробуйте снова'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.successInvite]: {
    icon: 'success',
    title: {
      id: 'invitationSent',
      defaultMessage: 'Приглашение отправлено'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.thanks]: {
    icon: 'success',
    title: {
      id: 'modals.content.thanks.title',
      defaultMessage: 'Благодарим за обратную связь!'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.webgl]: {
    icon: 'warning',
    title: {
      id: 'error',
      defaultMessage: 'Ошибка'
    },
    description: {
      id: 'modals.content.webgl.description',
      defaultMessage: 'Браузер не поддерживает WebGL или данная функция отключена.'
    },
    accept: 'Вернуться к проектам'
  },
  [MODALS_KEYS.tariffDuration]: {
    title: {
      id: 'warning',
      defaultMessage: 'Предупреждение'
    },
    description: {
      id: 'modals.content.tariffDuration.description',
      defaultMessage: 'Заканчивается действие подписки, вы можете продлить доступ, перейдя в раздел тарифы'
    },
    close: {
      id: 'cancel',
      defaultMessage: 'Отмена'
    },
    accept: {
      id: 'tariffs',
      defaultMessage: 'Тарифы'
    }
  },
  [MODALS_KEYS.fileLoadError]: {
    icon: 'warning',
    title: {
      id: 'fileUploadError',
      defaultMessage: 'Ошибка загрузки файла'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.fileLoadSuccess]: {
    icon: 'success',
    title: {
      id: 'fileUploadedSuccessfully',
      defaultMessage: 'Файл успешно загружен'
    },
    accept: {
      id: 'continue',
      defaultMessage: 'Продолжить'
    }
  },
  [MODALS_KEYS.quitAccount]: {
    title: {
      id: 'modals.quitAccount.title',
      defaultMessage: 'Вы уверены, что хотите выйти?'
    },
    close: {
      id: 'cancel',
      defaultMessage: 'Отмена'
    },
    accept: {
      id: 'yes',
      defaultMessage: 'Да'
    }
  },

  [MODALS_KEYS.default]: {
    title: {
      defaultMessage: ''
    },
    description: {
      defaultMessage: ''
    },
    close: {
      defaultMessage: ''
    },
    accept: {
      defaultMessage: ''
    }
  }
}
