import { useStoreon } from 'storeon/react'
import { useEffect } from 'react'
import usePage from '../../../hooks/usePage'
import { useQuery } from 'react-query'
import { userApiService } from '../../../api/services'
import { useHistory } from 'react-router-dom'
import { PAGE_MODELS } from '../../../const'

const WithCurrentUser = Component => props => {
  const { page, baseSlug } = usePage()
  const { dispatch } = useStoreon()
  const history = useHistory()
  const allowedPageModels = [
    PAGE_MODELS.NEWS,
    PAGE_MODELS.NEWS_LIST,
    PAGE_MODELS.LANDING,
    PAGE_MODELS.AUTH,
    PAGE_MODELS.RECOVERY,
    PAGE_MODELS.RESTORE,
    PAGE_MODELS.REGISTRATION,
    PAGE_MODELS.FAQ,
    PAGE_MODELS.API,
    PAGE_MODELS['404'],
    PAGE_MODELS.INFO
  ]
  const isAllowed = allowedPageModels.includes(page.page_model)

  useQuery({
    queryKey: 'currentUser',
    queryFn: userApiService.getCurrentUser,
    initialData: page?.init_state?.global?.current_user,
    onSuccess: (data) => {
      dispatch('user/save', data)
    },
    onError: (error) => {
      // запрос возвращает 403 при отсутствии токена
      if (error.response?.status === 403 && !isAllowed) {
        history.push(baseSlug.pageLogin)
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: true,
    enabled: !isAllowed
  })

  useEffect(() => {
    if (page) {
      const user = page?.init_state?.global?.current_user
      user && dispatch('user/save', { ...user, status: !!user })
    }
  }, [page])

  return (
    <Component {...props} />
  )
}

export default WithCurrentUser
