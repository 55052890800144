import { useEffect } from 'react'
import { usePinchZoom } from 'react-use'
import { overwritingEventForPinchZoom } from '../utils'

export const useZoom = ({ scaleRef, zoomPhoto }) => {
  const { zoomingState, pinchState } = usePinchZoom(scaleRef)

  useEffect(() => {
    const element = scaleRef?.current
    if (element) {
      element.addEventListener('wheel', zoomPhoto, { passive: true })
    }
    return () => {
      element.removeEventListener('wheel', zoomPhoto)
    }
  }, [scaleRef])
  useEffect(() => {
    if (zoomingState) {
      overwritingEventForPinchZoom(zoomPhoto)(zoomingState)
    }
  }, [pinchState])
}
