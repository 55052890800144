import React, { useEffect } from 'react'
import usePage from '../../../hooks/usePage'
import { PERMITTED_JIVO_PAGE } from '../../../const'

const withJivo = (Component) => (props) => {
  const { page } = usePage()
  let timeOutStorage = 0

  const getJdivNodes = () => {
    const jdiv = document.querySelector('div[data-b24-crm-button-cont]')
    const jShadowBtn = document.querySelector('div[data-b24-crm-button-shadow]')
    return { jdiv, jShadowBtn }
  }
  const jivoOnHandler = () => {
    const script = document.querySelector('#jivoScript')
    if (!script) {
      appendScript()
    } else {
      const { jdiv, jShadowBtn } = getJdivNodes()

      if (jdiv) {
        jdiv.style.display = 'block'
      }
      if (jShadowBtn) {
        jdiv.style.display = 'block'
      }
    }
  }
  const jivoOffHandler = () => {
    const { jdiv, jShadowBtn } = getJdivNodes()
    if (jdiv) {
      jdiv.style.display = 'none'
    }
    if (jShadowBtn) {
      jdiv.style.display = 'none'
    }
  }

  const appendScript = () => {
    const JIVO_URL = 'https://cdn-ru.bitrix24.ru/b1636041/crm/site_button/loader_12_3fvyef.js'
    const script = document.createElement('script')
    script.id = 'jivoScript'
    script.src = JIVO_URL
    script.async = true
    document.body.appendChild(script)
    return script
  }

  const initJivo = () => {
    page?.page_model && PERMITTED_JIVO_PAGE.includes(page?.page_model)
      ? jivoOnHandler()
      : jivoOffHandler()
  }

  useEffect(() => {
    timeOutStorage = setTimeout(initJivo, 3000)
    return () => {
      clearTimeout(timeOutStorage)
    }
  }, [page])

  return <Component {...props} />
}

export default withJivo
