import React from 'react'
import { GxDropdown } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'
import classNames from 'classnames'

const Dropdown = React.forwardRef(({
  theme = 'dropdown_default',
  placement = 'bottom',
  hoist = null,
  children,
  skidding,
  distance,
  slot,
  id,
  open,
  className,
  ...restProps

}, ref) => {
  return (
    <GxDropdown
      open={open}
      id={id}
      className={classNames(style[theme], className)}
      placement={placement}
      hoist={hoist}
      skidding={skidding}
      distance={distance}
      slot={slot}
      ref={ref}
      {...restProps}
    >
      {children}
    </GxDropdown>
  )
})

export default Dropdown
