import ModalButtons from './ModalButtons'
import ModalTitle from './ModalTitle'
import ModalDescription from './ModalDescription'
import ModalIcon from './ModalIcon'

export default {
  Buttons: ModalButtons,
  Title: ModalTitle,
  Icon: ModalIcon,
  Description: ModalDescription
}
