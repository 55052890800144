/* eslint-disable camelcase */
import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'
/**
 * @view
 * * Вью Футера реализующий интерфейс блока с адрессами офисов -
 * * email, телефон и т.д
 *
 * @param {Array<Object>} addresses  адресса офисов
 * @param {string}        address_icon  массив ссылок на стр нижнего футера
 */
const LocationsInfo = ({ addresses, address_icon }) => {
  return (
    <div className={style['location-info']}>
      {addresses &&
        addresses.length > 0 &&
        addresses.map(({ id, city, address }) => (
          <div key={id} className={style['location-info__item']}>
            {city && (
              <div className={style['location-info__title']}>
                <GxIcon className={style['location-info__icon']} src={address_icon} />
                {city}
              </div>
            )}
            {address && <div className={style['location-info__desc']}>{address}</div>}
          </div>
        ))}
    </div>
  )
}

export default LocationsInfo
