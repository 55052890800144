import { notifyApiService } from '../../../../api/services'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation } from 'react-use'
import { QUERY } from '../../const'

const useReadAllNotifications = () => {
  const location = useLocation()
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (ids = []) => notifyApiService.setReadAllNotifies(ids, location.search),
    onSettled: () => queryClient.invalidateQueries([QUERY.NOTIFICATIONS])
  })
}

export default useReadAllNotifications
