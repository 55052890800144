import React from 'react'
import styles from './styles/index.module.scss'
import cn from 'classnames'

const TariffLabel = ({ children, className }) => {
  return (
    <div
      className={cn({
        [styles.tariff_label]: true,
        [className]: !!className
      })}
    >
      {children}
    </div>
  )
}

export default TariffLabel
