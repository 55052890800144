import { GxRadio } from '@garpix/garpix-web-components-react'
import React from 'react'
import style from './styles/index.module.scss'

const RadioButton = ({ onChange, projectVariant = 'radio_default', name, checked, disabled, children, ...props }) => {
  return (
    <GxRadio
      className={style[projectVariant]}
      name={name}
      onGx-change={onChange}
      checked={checked}
      disabled={disabled}
      {...props}
    >
      {children}
    </GxRadio>
  )
}

export default RadioButton
