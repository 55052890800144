import React from 'react'
import style from '../styles/show.module.scss'
import cn from 'classnames'

const Show = ({ children, className, delay, ...props }) => {
  return (
    <div
      style={{ '--delay': delay }}
      className={cn({
        [style.show]: true,
        [className]: !!className
      })}
      {...props}
    >
      {children}
    </div>
  )
}

export default Show
