import React from 'react'

import { GxIcon } from '@garpix/garpix-web-components-react'

import { downloadIcon, pdfFileIcon } from '../../../../images'

import styles from './styles/index.module.scss'
/**
 * @view
 * вюха картчки файла
 * @interface fileElements
 * @param {number} id
 * @param {string} title
 * @param {string} file - link
 * @param {string} size
 * @param {string} icon - link
 */
const FileCard = ({ file, title, size, icon }) => {
  return (
    <li className={styles['file-gallery__item']}>
      <a
        target='_blank'
        className={styles['file-gallery__download_link']}
        href={file}
        download
        rel='noreferrer'
      >
        <GxIcon src={downloadIcon} />
      </a>
      <img
        className={styles['file-gallery__icon']}
        src={icon || pdfFileIcon}
        alt='icon format'
        width='60'
        height='78'
        loading='lazy'
      />
      {Boolean(title) && (
        <p className={styles['file-gallery__item_title']}>{title}</p>
      )}
      {Boolean(size) && <p className={styles['file-gallery__item_size']}>{size}</p>}
    </li>
  )
}

export default FileCard
