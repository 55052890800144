import React from 'react'
import { GxMenu } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'

const Menu = ({ projectVariant = 'menu_default', children, ...restProps }) => {
  return (
    <GxMenu className={style[projectVariant]} {...restProps}>
      {children}
    </GxMenu>
  )
}

export default Menu
