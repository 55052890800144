/* eslint-disable camelcase */
import React from 'react'
import FrequentProblemsImage from './FrequentProblemsImage'
import { doneIcon, closeSmallIcon } from '../../images'
import styles from './styles/index.module.scss'
import FrequentProblemsItem from './FrequentProblemsItem'

const FrequentProblemsCard = ({ data }) => {
  const { image, number, text_title, text, problems, solutions } = data

  return (
    <div className={styles.frequentProblems__item}>
      <div className={styles.frequentProblems__heading}>
        <FrequentProblemsImage image={image} number={number} />
        {text_title && (
          <div className={styles.frequentProblems__title}>
            {text_title}
          </div>
        )}
      </div>
      <div className={styles.frequentProblems__content}>
        <div className={styles.frequentProblems__problems}>
          <p className={styles.frequentProblems__problems_title}>
            Проблема
          </p>
          {text && (
            <p className={styles.frequentProblems__problems_text}>
              {text}
            </p>
          )}
          {problems &&
            problems.length > 0 &&
            problems.map(({ id, text }) => (
              <FrequentProblemsItem
                key={id}
                icon={closeSmallIcon}
                styleForIcon={styles.frequentProblems__icon_b}
                text={text}
              />
            ))}
        </div>
        <div className={styles.frequentProblems__solutions}>
          <p className={styles.frequentProblems__solutions_title}>
            Решение GLS
          </p>
          {solutions &&
            solutions.length > 0 &&
            solutions.map(({ id, text }) => (
              <FrequentProblemsItem
                key={id}
                icon={doneIcon}
                styleForIcon={styles.frequentProblems__solutions_desc}
                text={text}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default FrequentProblemsCard
