import React from 'react'
import styles from './styles/index.module.scss'

const TableTariff = (props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = props
  return (
    <div className={styles.tariff_table}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
          // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
              // eslint-disable-next-line react/jsx-key
                <th
                  style={{
                    '--border-color': column.border_color
                  }}
                  {...column.getHeaderProps()}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
            // eslint-disable-next-line react/jsx-key
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                  // eslint-disable-next-line react/jsx-key
                    <td
                      style={{
                        '--border-color': cell.column.border_color
                      }}
                      {...cell.getCellProps()}
                    >
                      {
                      cell.render('Cell')
                    }
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TableTariff
