import React, { useCallback, useEffect, useRef } from 'react'
import usePage from '../../../hooks/usePage'
import ModalView from '../../../views/ModalView'
import { BUTTON_THEME } from '../../../const'
import useTranslate from '../../../hooks/useTranslate'

const TariffLimitModal = (props) => {
  const { title, description, accept, close, handleClose } = props
  const { baseSlug } = usePage()
  const ref = useRef(null)
  const onSubmitHandler = useCallback(() => {
    window.open(baseSlug.pageTariffs, '_blank')
    handleClose()
  }, [baseSlug])
  const { translate } = useTranslate()

  useEffect(() => { ref.current.setFocus() }, [])
  return (
    <>
      <ModalView.Icon>
        <ModalView.Icon.Logo />
      </ModalView.Icon>
      <ModalView.Title>
        {translate(title)}
      </ModalView.Title>
      <ModalView.Description description={translate(description)} />
      <ModalView.Buttons>
        <ModalView.Buttons.Button onClick={handleClose} theme={BUTTON_THEME.STROKE_ICON.SMALL}>
          {translate(close)}
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button
          onClick={onSubmitHandler}
          theme={BUTTON_THEME.FILL_ICON.SMALL}
          ref={ref}
        >
          {translate(accept)}
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </>
  )
}

export default TariffLimitModal
