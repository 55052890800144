import {Api} from '../../index.js'
import { runtimeConfig } from '../../../config'


class ContentApiService extends Api {

    getNewsList = async (params) => {
        const res = await this.get('/content/news/', params);
        return res.data;
    };

    getStatistics = async () => {
        const res = await this.get('/content/dashboard_gls/stats_gls/');
        return res.data;
    };

    sendFeedback = async (params) => {
        const res = await this.post('/content/feedback/', params);
        return res.data;
    };

    getHelpThemes = async () => {
        const res = await this.get('/content/theme_help/');
        return res.data;
    };

    createHelpRequest = async (payload) => {
        const res = await this.post('/content/help/', payload);
        return res.data;
    };

    getFAQ = async ({
        title__icontains,
        ordering = 'number',
        page_size = 5,
        ...props
    }) => {
        const res = await this.get('/content/faqinfo/', {
            page_size,
            q: title__icontains,
            ordering,
            ...props,
        });
        return res.data;
    };
}

export default new ContentApiService(runtimeConfig.API_URL)