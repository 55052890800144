import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import { CARDS } from '../../../const'
import LoadPerfomanceView from '../../../views/LandingView/LoadPerfomance'

const LoadPerfomance = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} isBlue id={CARDS.LOAD_PERFOMANCE.id} {...props}>
      <LoadPerfomanceView {...props} />
    </SectionHomePageWithButtons>
  )
}

export default LoadPerfomance
