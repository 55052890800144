import React from 'react'
import { rightIcon } from '../../../images'
import InnerHtml from '../../../components/InnerHtml'
import styles from './style/index.module.scss'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { Link } from 'react-router-dom'

const Clients = (props) => {
  const { cases } = props

  return (
    <div className={styles.clients}>
      {
        cases && cases.length > 0 && cases.map(({ id, image, text, absolute_url: absoluteUrl }) => {
          return (
            <div key={id} className={styles.clients_item}>
              {image && (
                <div className={styles.clients_image}>
                  <img width='100' height='100' src={image} alt='company logo' loading='lazy' />
                </div>
              )}
              {text && <InnerHtml className={styles.clients_description} html={text} />}
              {absoluteUrl && <Link to={absoluteUrl} className={styles.clients_link}>Подробнее <GxIcon src={rightIcon} /></Link>}
            </div>
          )
        })
      }
    </div>

  )
}

export default Clients
