import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { attentionBanner } from '../../images'
import { BUTTON_THEME, LINK_TARGET } from '../../const'
import Button from '../../views/Button'
import styles from './styles/index.module.scss'
/**
 * * Вьюха баннера с уведомлением о чем либо
 * @view
 * @param {string} href
 * @param {string} label
 * @param {string} title
 * @param {func} onClick
 */
const TariffBanner = ({ href, label, title, onClick }) => {
  const showLink = href && label
  const showBtn = onClick && label
  return (
    <div className={styles.banner}>
      <div className={styles.banner__content}>
        <div className={styles.content}>
          <GxIcon src={attentionBanner} className={styles.content__icon} />
          <h3 className={styles.content__text}>{title}</h3>
        </div>
        {showLink && (
          <Link
            className={styles.content__button}
            to={href}
            target={LINK_TARGET.BLANK}
            rel='noopener noreferrer'
          >
            {label}
          </Link>
        )}
        {showBtn && (
          <Button
            theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
            onClick={onClick}
            className={cn(styles.content__button, styles['content__button--fz'])}
          >
            {label}
          </Button>
        )}
      </div>
    </div>
  )
}

export default TariffBanner
