/* eslint-disable camelcase */
import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import { promocodes, historyIcon } from '../../../../images'
import ModalView from '../../../ModalView'
import style from './style/index.module.scss'
import { formattingDate } from '../../../../utils'
import { useIntl } from 'react-intl'

const PromocodeInfo = (props) => {
  const {
    title,
    is_active,
    description,
    start_at,
    finish_at,
    amount_money,
    last_registration_date,
    max_users_count,
    profit,
    registrations_count
  } = props
  const intl = useIntl()
  return (
    <>
      {title &&
        <ModalView.Title>
          <p className={style.info__subtitle}>
            {is_active
              ? intl.formatMessage({
                  id: 'active',
                  defaultMessage: 'Активен'
                })
              : intl.formatMessage({
                id: 'notActive',
                defaultMessage: 'Не активен'
              })}
          </p>
          <GxIcon src={promocodes} className={classNames({ [style.info__icon]: true, [style['info__icon-inactive']]: !is_active })} />
          {title}
        </ModalView.Title>}
      {description &&
        <div className={style['info__marketing-promotion']}>
          <p className={style['info__marketing-promotion_text']}>
            {description}
          </p>
        </div>}
      {start_at && finish_at &&
        <div className={style['info__promocode-finish']}>
          <p className={style['info__promocode-finish_text']}>
            <GxIcon src={historyIcon} className={classNames({ [style['info__promocode-finish_icon']]: true, [style['info__promocode-finish_icon-inactive']]: !is_active })} />
            {formattingDate(start_at)} - {formattingDate(finish_at)}
          </p>
        </div>}
      <div className={style.info__grid}>
        <div className={style.info__grid_item}>
          <p className={style['info__grid_item-label']}>{intl.formatMessage({
            id: 'availableUses',
            defaultMessage: 'Доступно использований'
          })}:
          </p>
          <p className={classNames(style['info__grid_item-value'], style['info__grid_item-value'])}>{max_users_count === -1
            ? intl.formatMessage({
                id: 'unlimited',
                defaultMessage: 'неограничено'
              })
            : max_users_count}
          </p>
        </div>
        <div className={style.info__grid_item}>
          <p className={style['info__grid_item-label']}>{intl.formatMessage({
            id: 'users',
            defaultMessage: 'Пользователей'
          })}:
          </p>
          <p className={classNames(style['info__grid_item-value'], style['info__grid_item-value'])}>{registrations_count}</p>
        </div>
        <div className={style.info__grid_item}>
          <p className={style['info__grid_item-label']}>{intl.formatMessage({
            id: 'lastRegistration',
            defaultMessage: 'Последняя регистрация'
          })}:
          </p>
          <p className={classNames(style['info__grid_item-value'], style['info__grid_item-value'])}>{last_registration_date
            ? formattingDate(last_registration_date)
            : intl.formatMessage({
              id: 'absent',
              defaultMessage: 'отсутствует'
            }).toLowerCase}
          </p>
        </div>
        <div className={style.info__grid_item}>
          <p className={style['info__grid_item-label']}>{intl.formatMessage({
            id: 'amountOfCustomerSpending',
            defaultMessage: 'Сумма трат клиентов'
          })}:
          </p>
          <p className={classNames(style['info__grid_item-value'], style['info__grid_item-value'])}>{amount_money} ₽</p>
        </div>
      </div>
      <div className={style.info__profit}>
        <p className={style.info__profit_text}>
          {intl.formatMessage({
            id: 'profit',
            defaultMessage: 'Прибыль'
          })}:
          <span className={classNames({ [style.info__profit_value]: true, [style.info__profit_text]: true, [style['info__profit_value-inactive']]: !is_active })}>
            {profit} ₽
          </span>
        </p>
      </div>
    </>
  )
}

export default PromocodeInfo
