import { useEffect } from 'react'

const ResizeBlock = () => {
  useEffect(() => {
    // First we get the viewport height and we multiple it by 1%
    // to get a value for a vh unit
    const vh = window.innerHeight * 0.01
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    const handlerResizable = () => {
      // We execute the same script as before
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    // We listen to the resize event
    window.addEventListener('resize', handlerResizable)
    return () => {
      window.removeEventListener('resize', handlerResizable)
    }
  }, [])
  return null
}

export default ResizeBlock
