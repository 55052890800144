import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'
import { notificationsSerializers } from '../../serializers'

class NotifyApiService extends Api {

  getNotify = async (params) => {
    const { data } = await this.get('/notify/', params);
    const serializedResults =  data.results.map(notificationsSerializers)
    data.results = serializedResults
    return data
  };

  setReadNotify = async (id) => {
    const res = await this.post(`/notify/${id}/read_notify/`);
    return res.data;
  };

  removeNotify = async (id) => {
    const res = await this.delete(`/notify/${id}/`);
    return res.data;
  };

  removeAllReadNotify = async () => {
    const res = await this.delete('/notify/delete_all_read/');
    return res.data;
  };

  setReadAllNotifies = async (ids, params = '') => {
    const res = await this.post(`/notify/read_all_notifies/${params}`, {
      ids,
    });
    return res.data;
  };
}

export default new NotifyApiService(runtimeConfig.API_URL)