import React from 'react'
import TableIcon from '../../views/TariffTable/TableIcon'
import TabelText from '../../views/TableText'

export const columnsTableSerialize = (headers) => {
  return headers.map((header, i) => ({
    ...header,
    Header: header.value,
    accessor: String(i) // не ладит с number
  }))
}
export const dataTableSerialize = (body) => {
  return body.map(item => {
    return item.reduce((acc, val, i) => {
      i = String(i)
      switch (val) {
        case true:
          acc[i] = <TableIcon.Success />
          break
        case false:
          acc[i] = <TableIcon.Error />
          break
        case -1:
          acc[i] = (
            <TabelText
              hasInfinityIcon
              msg={{
                id: 'unlimited',
                defaultMessage: 'Неограниченно'
              }}
            />
          )
          break
        default:
          acc[i] = val
      }
      return acc
    }, {})
  })
}
