import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import { CARDS } from '../../../const'
import FrequentProblemsView from '../../../views/LandingView/FrequentProblems'

const FrequentProblems = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} id={CARDS.FREQUENT_PROBLEMS.id} isBlue {...props}>
      <FrequentProblemsView {...props} />
    </SectionHomePageWithButtons>
  )
}

export default FrequentProblems
