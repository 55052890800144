/* eslint-disable camelcase */
import React from 'react'
import Tables from '../views/ListPageViews/Tables'
import WrapperTitle from './CargoSpaceComponents/WrapperTitle'
import OwnerTableField from '../views/OwnerTableField'
import DemoField from '../views/DemoTableField'
import UpdateTableField from '../views/UpdateTableField'
import AssessmentTableField from '../views/AssessmentTableField'
import { commonAcount } from '../images'
import DownloadReport from '../apps/appProjects/components/DownloadReport'

const commonUser = { thumbnail: commonAcount, first_name: 'Общий' }

const content = {
  title: (children, props) => <WrapperTitle {...props}>{children}</WrapperTitle>,
  download: (children, props) => <DownloadReport projectId={props.id} />,
  created_at: (date) => date,
  updated_at: (date, data) => (
    <UpdateTableField date={date} lastChangedUser={data.last_changed_user} />
  ),
  cargo_space_type: (children, props) => props.icon,
  cargo_type: (children, props) => props.icon,
  is_all_grades: () => <AssessmentTableField />,
  demo: (demo) => <DemoField demo={demo} />,
  project_owner: ({ thumbnail: src, ...props }, data) => (
    <OwnerTableField src={src} {...data} {...props} />
  )
}

const TableData = ({
  item,
  ordering,
  type,
  title,
  cargo_space_type,
  handleCheckID,
  checkID,
  icon,
  indexRow
}) => {
  if (!item.project_owner) item.project_owner = commonUser

  const data = {
    icon,
    type,
    title,
    cargo_space_type,
    user: item.user,
    handleCheckID,
    checkID,
    id: item.id,
    demo: item.demo,
    is_all_grades: item?.is_all_grades,
    owner: item.project_owner,
    last_changed_user: item.last_changed_user
  }

  const render = item[ordering]
    ? content[ordering]
        ? content[ordering](item[ordering], data)
        : item[ordering]
    : ''

  return (
    <Tables.TableData dataLabel={title} indexRow={indexRow}>
      {render}
    </Tables.TableData>
  )
}

export default TableData
