import React, { useEffect, useRef, useState } from 'react'
import { convertError } from '../../../utils'
import ModalView from '../../../views/ModalView'
import { BUTTON_THEME } from '../../../const'
import { useModalIntlContent, useModalStore } from '../../../hooks/Modals'
import { FormattedMessage } from 'react-intl'

const TITLE_SCHEME = {
  cargo: {
    id: 'modals.copyPageItem.cargoTitle',
    defaultMessage: 'Копировать груз'
  },
  cargo_space: {
    id: 'modals.copyPageItem.spaceTitle',
    defaultMessage: 'Копировать грузовое пространство'
  },
  project: {
    id: 'modals.copyPageItem.projectTitle',
    defaultMessage: 'Копировать проект'
  }
}

const CopyPageItemModal = (props) => {
  const {
    type,
    reload,
    copyHandler,
    itemID,
    itemTitle,
    handleClose,
    reset
  } = props
  const title = TITLE_SCHEME[type]
  const [isSubmitting, setSubmitting] = useState(false)
  const ref = useRef(null)
  const { openModal, contentTypes } = useModalStore()
  const content = useModalIntlContent(contentTypes.tariffLimit)

  const submitHandler = async () => {
    try {
      setSubmitting(true)
      await copyHandler({ id: itemID })
      handleClose()
      reset && reset()
      reload()
    } catch (e) {
      const error = convertError(e)
      openModal('tariffLimit', { ...content, description: error })
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    ref.current.setFocus()
  }, [])

  return (
    <div data-cy='copy-modal'>
      <ModalView.Title>
        <FormattedMessage {...title} />{' '}
        <span style={{ textDecoration: 'underline' }}>{itemTitle}</span>?
      </ModalView.Title>
      <ModalView.Buttons>
        <ModalView.Buttons.Button
          onClick={handleClose}
          theme={BUTTON_THEME.STROKE_ICON.SMALL}
        >
          <FormattedMessage
            id='cancel'
            defaultMessage='Отмена'
          />
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button
          data-cy='copy-modal-accept-btn'
          onClick={submitHandler}
          theme={BUTTON_THEME.FILL_ICON.SMALL}
          disabled={isSubmitting}
          ref={ref}
        >
          <FormattedMessage
            id='copy'
            defaultMessage='Копировать'
          />
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </div>
  )
}

export default CopyPageItemModal
