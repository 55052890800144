import React from 'react'
import { GxForm, GxIcon } from '@garpix/garpix-web-components-react'
import Textarea from '../../../Textarea'
import { BUTTON_THEME } from '../../../../const'
import style from './styles/index.module.scss'
import ModalView from '../../../ModalView'
import { FormattedMessage } from 'react-intl'
import useTranslate from '../../../../hooks/useTranslate'

const GradeView = ({ stars, value, formik, handleClose }) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    errors,
    values,
    touched
  } = formik

  const { translate } = useTranslate()

  return (
    <div className={style.grade}>
      <div className={style.grade__header}>
        <p className={style['grade__header_grade-text']}>
          <FormattedMessage
            id='modals.grade.thanksMessage'
            defaultMessage='Спасибо за вашу оценку!'
          />
        </p>
        <div className={style.grade__header_icons}>
          {stars.map(({ icon }, index) => (
            <GxIcon className={style['grade__header_icons-item']} src={icon} key={index} />
          ))}
        </div>
      </div>
      {value <= 3 && (
        <div className={style.grade__review}>
          <div className={style.grade__review_head}>
            <p className={style['grade__review_head-text']}>
              <FormattedMessage
                id='modals.grade.reviewMessage'
                defaultMessage='Расскажите о своем опыте, чтобы сделать приложение лучше!'
              />
            </p>
          </div>
          <GxForm onGx-submit={handleSubmit}>
            <div className={style.grade__review_message}>
              <Textarea
                name='comment'
                onGx-input={handleChange}
                onGx-blur={handleBlur}
                value={values.comment}
                label={translate(
                  {
                    id: 'modals.grade.commentLabel',
                    defaultMessage: 'Оставить отзыв'
                  }
                )}
                error={touched.comment && !!errors.comment}
                errorText={translate(errors.comment)}
              />
            </div>
            <ModalView.Buttons>
              <ModalView.Buttons.Button
                onClick={handleClose}
                theme={BUTTON_THEME.STROKE_ICON.SMALL}
                disabled={isSubmitting}
              >
                <FormattedMessage
                  id='cancel'
                  defaultMessage='Отмена'
                />
              </ModalView.Buttons.Button>
              <ModalView.Buttons.Button
                type='submit'
                theme={BUTTON_THEME.FILL_ICON.SMALL}
                disabled={isSubmitting}
              >
                <FormattedMessage
                  id='modals.grade.acceptButton'
                  defaultMessage='Отправить сообщение'
                />
              </ModalView.Buttons.Button>
            </ModalView.Buttons>
          </GxForm>
        </div>
      )}
    </div>
  )
}

export default GradeView
