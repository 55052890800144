/* eslint-disable camelcase */
import { useFormik } from 'formik'
import SubmitAppSchema from './../utils/ValidateSchemes/SubmitAppSchema'

const initialValues = {
  fullname: '',
  phone: '',
  phoneCountry: 'ru',
  email: '',
  message: ''
}

const useBackForm = (sendFeedback, form_type) => {
  const formikTools = useFormik({
    initialValues: initialValues,
    onSubmit: () => sendFeedback({ ...formikTools.values, form_type }),
    validationSchema: SubmitAppSchema
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue
  } = formikTools

  const handleChangePhone = (e) => {
    const phone = e.detail.value
    const phoneCountry = e.detail.country.countryCode
    setFieldValue('phone', phone)
    setFieldValue('phoneCountry', phoneCountry)
  }
  return {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isSubmitting,
    isValid,
    dirty,
    handleChangePhone
  }
}

export default useBackForm
