import * as yup from 'yup'
import { getValidateText } from '../ValidateText'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../const'

const TitleSchema = {
  title: yup
    .string()
    .trim()
    .max(ERROR_NUMBERS.MAX_STRING_NUMBER, getValidateText('title', 'max', ERROR_NUMBERS.MAX_STRING_NUMBER))
    .min(ERROR_NUMBERS.MIN_STRING_NUMBER, getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER))
    .required(INTL_ERROR_MESSAGE.REQUIRED)
}

export default TitleSchema
