/**
 * * сериалайзер для создания пользователя
 * @func userCreateSerializer
 * @param {obj} userCreateData - дата из формы регистрации
 */
export const userCreateSerializer = (userCreateData) => {
  const userSerData = {
    ...userCreateData,
    repeatPassword: undefined
  }

  return userSerData
}