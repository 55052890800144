import React from 'react'
import TableTariffView from '../../views/TariffTable'
import { useTable } from 'react-table'
import { columnsTableSerialize, dataTableSerialize } from '../../services/serializers'

const TableTariff = ({ advantages }) => {
  const { header, body } = advantages
  const data = React.useMemo(() => dataTableSerialize(body), [])

  const columns = React.useMemo(() => columnsTableSerialize(header), [])

  const tableInstance = useTable({ columns, data })
  return (
    <TableTariffView {...tableInstance} />
  )
}

export default TableTariff
