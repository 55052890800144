import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'
import { getPasswordValidateGaz } from './PasswordGazpromValidate'

const RecoveryEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email(INTL_ERROR_MESSAGE.EMAIL)
    .required(INTL_ERROR_MESSAGE.REQUIRED)
})

const RecoveryCodeSchema = yup.object().shape({
  code: yup.string().required(INTL_ERROR_MESSAGE.REQUIRED)
})

const RecoveryPasswordSchema = (passwordConfig) => yup.object().shape(getPasswordValidateGaz(passwordConfig))

export default {
  RecoveryEmailSchema,
  RecoveryCodeSchema,
  RecoveryPasswordSchema
}
