import {Api} from '../../index.js'
import { runtimeConfig } from '../../../config'


class VanApiService extends Api {

  getVan = async ({ id }) => {
    const res = await this.get(`/cargo/van/${id}/`);
    return res.data;
  };

  getVans = async (params) => {
    const res = await this.get('/cargo/van/', params);
    return res.data;
  };

  createVan = async (params) => {
    const res = await this.post('/cargo/van/', params);
    return res.data;
  };

  deleteVan = async ({ id }) => {
    const res = await this.delete(`/cargo/van/${id}/`);
    return res.data;
  };

  updateVan = async ({ id, ...params }) => {
    const res = await this.put(`/cargo/van/${id}/`, { ...params });
    return res.data;
  };

  renameVan = async ({ id, title }) => {
    const res = await this.patch(`/cargo/van/${id}/`, { title });
    return res.data;
  };

  copyVan = async ({ id }) => {
    const res = await this.post(`/cargo/van/${id}/copy/`);
    return res.data;
  };
}

export default new VanApiService(runtimeConfig.API_URL)