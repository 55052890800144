import {Api} from '../../index.js'
import { runtimeConfig } from '../../../config'


class AuthApiService extends Api {
    login = async (params) => {
        const res = await this.post('/auth/login/', params);
        const data = res.data;
        this.setAuthToken(data.access_token);
        this.setRefreshToken(data.refresh_token);
        return data;
    };

    logout = async () => {
        const res = await this.post('/auth/logout/');
        const data = res.data;
        this.removeAuthToken();
        this.removeRefreshToken();
        return data;
    };

    loginSocialUser = async (params) => {
        const res = await this.post('/social-auth/convert-token/', params);
        const data = res.data;
        const token = data.access_token;
        this.setAuthToken(token);
        return data;
    };
}

export default new AuthApiService(runtimeConfig.API_URL)