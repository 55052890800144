import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import PartnersProgramView from '../../../views/LandingView/PartnersProgram'

const PartnersProgram = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} {...props}>
      <PartnersProgramView theme={props.content?.theme} {...props} />
    </SectionHomePageWithButtons>
  )
}

export default PartnersProgram
