import Media from 'react-media'
import { BaseSelect } from '../../views/Select/BaseSelect'

const ModalSelectComponent = (props) => {
  const { value, setValue, isLoading, loadOptions, label = 'Новый владелец' } = props

  const setStyles = (small) => ({
    menuList: (base) => ({
      ...base,
      height: small ? '140px' : 'auto'
    }),
    input: (base) => ({
      ...base,
      paddingLeft: '33px'
    })
  })

  return (
    <Media queries={{ small: '(max-width: 568px)' }}>
      {(matches) => (
        <BaseSelect
          value={value}
          name='user'
          label={label}
          theme='select_default'
          additional={{ page: 1 }}
          isLoading={isLoading}
          loadOptions={loadOptions}
          onChange={setValue}
          isSearchable
          styles={setStyles(matches.small)}
          required
        />
      )}
    </Media>
  )
}

export default ModalSelectComponent
