import React from 'react'
import styles from './style/index.module.scss'
import InnerHtml from '../../../components/InnerHtml'
import classNames from 'classnames'

const ModalDescription = ({ description, className }) => {
  return (
    <InnerHtml
      className={classNames(styles.description, { [className]: !!className })}
      html={description}
    />
  )
}

export default ModalDescription
