import React from 'react'
import ModalView from '../../../ModalView'
import { FormattedMessage, useIntl } from 'react-intl'
import { BUTTON_THEME } from '../../../../const'

const UploadFile = ({ handleGLSFormatLoad, handleCompanyFormatLoad }) => {
  const intl = useIntl()
  const description = intl.formatMessage({
    id: 'supportedFileFormats',
    defaultMessage: 'Поддерживаемые форматы файлов'
  }) + ': xls, xlsx.'
  return (
    <div>
      <ModalView.Icon.File />
      <ModalView.Title>
        <FormattedMessage
          id='uploadFile'
          defaultMessage='Загрузить файл'
        />
      </ModalView.Title>
      <ModalView.Description description={description} />
      <ModalView.Buttons>
        <ModalView.Buttons.Button
          theme={BUTTON_THEME.FILL_ICON.SMALL}
          onClick={handleGLSFormatLoad}
        >
          <FormattedMessage
            id='formatGLS'
            defaultMessage='Формат GLS'
          />
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button
          theme={BUTTON_THEME.FILL_ICON.SMALL}
          onClick={handleCompanyFormatLoad}
        >
          <FormattedMessage
            id='formatCompany'
            defaultMessage='Формат Компании'
          />
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </div>
  )
}
export default UploadFile
