import React from 'react'
import styles from './styles/index.module.scss'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'

const FrequentProblemsItem = (props) => {
  const { icon, styleForIcon, styleForDesc, text } = props
  return (
    <div
      className={styles.frequentProblems__problems_item}
    >
      <GxIcon
        src={icon}
        className={classNames({
          [styles.frequentProblems__icon]: true,
          [styleForIcon]: !!styleForIcon
        })}
      />
      <div>
        {text && (
          <p className={classNames({
            [styles.frequentProblems__desc]: true,
            [styleForDesc]: !!styleForDesc
          })}
          >{text}
          </p>
        )}
      </div>
    </div>
  )
}

export default FrequentProblemsItem
