import React, { useCallback, useEffect, useMemo } from 'react'
import { useFormik } from 'formik'
import { useStoreon } from 'storeon/react'
import { GradeView } from '../../../views/BaseModal/ModalContent'
import { useModalIntlContent, useModalStore } from '../../../hooks/Modals'
import { selectedStar, starIcon } from '../../../images'
import { GradeModalSchema } from '../../../utils/ValidateSchemes'
import { MAX_GRADE_STARS } from '../../../const'
import { calculationApiService } from '../../../api/services'

const emptyIcons = [...Array(MAX_GRADE_STARS)].fill({}, 0)

const GradeModal = ({ value, handleClose, utm, id }) => {
  const { btnInfo: { formType } } = useStoreon('btnInfo')

  const { openModal, contentTypes } = useModalStore()
  const successModalContent = useModalIntlContent(contentTypes.thanks)
  const errorModalContent = useModalIntlContent(contentTypes.sendRequestError)

  const stars = useMemo(() => (emptyIcons.map((_, index) => (
    index + 1 <= value
      ? ({ icon: selectedStar })
      : ({ icon: starIcon })
  ))
  ), [value])

  useEffect(() => {
    if (value > 3) {
      const timer = setTimeout(() => handleClose(), 2000)
      return () => clearTimeout(timer)
    }
  }, [value])

  const sendGrade = useCallback(async ({ comment }) => {
    try {
      await calculationApiService.gradeCommentRequest({ id, comment, ...utm, form_type: formType })
      openModal('commonConfirm', successModalContent)
    } catch (e) {
      if (e?.response?.data?.partner[0]) {
        openModal('commonConfirm', { ...errorModalContent, description: e.response.data.partner[0] })
      } else {
        openModal('commonConfirm', errorModalContent)
      }
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      comment: ''
    },
    validationSchema: GradeModalSchema,
    onSubmit: sendGrade
  })

  return (
    <GradeView
      stars={stars}
      value={value}
      formik={formik}
      handleClose={handleClose}
    />
  )
}

export default GradeModal
