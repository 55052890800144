import React from 'react'
import Search from '../../components/Search'
import SelectOwner from '../../components/SelectOwner'
import Buttons from './Buttons'
import style from './styles/index.module.scss'

const SearchAndButtons = ({
  loadData,
  filterParams,
  type,
  selectOwner,
  handleModalSocket,
  reset,
  hasButtons = true
}) => {
  return (
    <div className={style.wrapper}>
      <div className={style.inputs_wrapper}>
        {selectOwner
          ? (
            <SelectOwner reset={reset} {...selectOwner} />
            )
          : null}
        <div className={style.search_wrapper}>
          <Search loadData={loadData} filterParams={filterParams} />
        </div>
      </div>
      <div className={style.buttons_wrapper}>
        {hasButtons && <Buttons type={type} handleModalSocket={handleModalSocket} />}
      </div>
    </div>
  )
}

export default SearchAndButtons
