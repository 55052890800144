import { useCallback, useRef } from 'react'

const useDebouncedCallback = (func, wait) => {
  const timeout = useRef()

  const debouncedFunction = useCallback(
    (...args) => {
      const later = () => {
        clearTimeout(timeout.current)
        func(...args)
      }

      clearTimeout(timeout.current)
      timeout.current = setTimeout(later, wait)
    },
    [func, wait]
  )

  debouncedFunction.cancel = () => {
    clearTimeout(timeout.current)
  }

  return debouncedFunction
}

export default useDebouncedCallback
