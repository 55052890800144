/* eslint-disable camelcase */
import React from 'react'
import WorkResultsCard from '../../WorkResultsCard'
import styles from './styles/index.module.scss'

const WorkResults = ({ content }) => {
  const { results_elements } = content

  return (
    <div className={styles.workResults}>
      <div className={styles.workResults_items}>
        {results_elements &&
          results_elements.length > 0 &&
          results_elements.map((el) => (
            <WorkResultsCard key={el.id} data={el} />
          ))}
      </div>
    </div>
  )
}

export default WorkResults
