import React from 'react'
import SectionHomePage from '../../../views/LandingView/Section'
import { CARDS } from '../../../const'
import Tariffs from '../../../apps/appTariffs/components/Tariffs'

const TariffsRemastered = (props) => {
  return (
    <SectionHomePage
      theme={props.content?.theme}
      id={CARDS.TARIFFS.id}
      {...props}
    >
      <Tariffs content={props.content} />
    </SectionHomePage>
  )
}

export default TariffsRemastered
