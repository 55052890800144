import React from 'react'
import * as Sentry from '@sentry/react'
import { useQuery } from 'react-query'
import pageRegistry from '../modules/pages/registry'
import LogoView from '../views/LogoView'
import { Redirect } from 'react-router-dom'
import ErrorBoundaryView from '../views/ErrorBoundaryView'
import { withCurrentUser, withExpirationTariff, withJivo, withSocket, withChangePassword, withMetrics } from '../apps/hoc'
import { compose } from '../utils'
import { pageApiService } from '../api/services'
import qs from 'query-string'
import UniversalErrorBoundary from '../apps/components/UniversalErrorBoundary'
import PrivacyPolicyModal from '../apps/components/views/PrivacyPolicyModal'
import UseCookiesAlert from '../apps/components/UseCookiesAlert'
import { NoSSR } from '../components/NoSSR'

const UniversalPage = ({ location }) => {
  const { pathname, search } = location
  const params = qs.parse(search)

  const { data, isFetching } = useQuery(['page', pathname], () => pageApiService.getPage(pathname, params), {
    refetchOnMount: false
  })

  if (isFetching) return <LogoView />

  /* TODO: зарефакторить на ссылку из бекенда */
  if (data.statusCode === 401) {
    return <Redirect to={{ pathname: data.page.init_state.global.page_login, state: { from: location } }} />
  }

  if (data.statusCode === 403) {
    return <Redirect to={{ pathname: data.page.init_state.global.page_profile, state: { from: location } }} />
  }

  if (data.statusCode === 500) {
    return <ErrorBoundaryView />
  }

  return (
    <Sentry.ErrorBoundary
      beforeCapture={scope => {
        scope.setTag('location', 'UniversalPage')
      }}
      fallback={() => {
        return <UniversalErrorBoundary />
      }}
    >
      <EnhancedPage {...data} />
    </Sentry.ErrorBoundary>
  )
}
const Page = ({ pageType, page }) => {
  const Page = pageRegistry.get(pageType)
  return (
    <>
      <Page {...page} />
      <PrivacyPolicyModal />
      <NoSSR>
        <UseCookiesAlert />
      </NoSSR>
    </>
  )
}
const EnhancedPage = compose(
  withSocket,
  withExpirationTariff,
  withJivo,
  withChangePassword,
  withCurrentUser,
  withMetrics
)(Page)

export default UniversalPage
