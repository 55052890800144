export const sortAdvantages = (advantages) => {
  const sortingAdvantages = {}
  Object.keys(advantages).forEach(key => {
    sortingAdvantages[key] = advantages[key].sort((a, b) => b.sort - a.sort)
  })
  return sortingAdvantages
}

export const reduceAdvantages = (advantages) => {
  return advantages.reduce((acc, el) => {
    if (acc[el.display]) {
      acc[el.display].push(el)
    } else {
      acc[el.display] = [el]
    }
    return acc
  }, {})
}

export const formInfinityVal = val => val === -1 ? 'Бесконечно' : val
