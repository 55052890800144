import React from 'react'
import style from './style/index.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper/core'

SwiperCore.use([Autoplay])

const LogoSlider = ({ content }) => {
  const { logo_slider_elements: logoSlides } = content
  return (
    <Swiper
      autoplay={{
        delay: 1,
        disableOnInteraction: false
      }}
      speed={9000}
      noSwiping
      noSwipingClass='swiper-slide'
      loop
      loopedSlides={6}
      className={style.slider}
      breakpoints={{
        280: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        450: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        720: {
          slidesPerView: 4,
          spaceBetween: 60
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 80
        }
      }}
    >
      {logoSlides.map(({ id, image }) => {
        return (
          <SwiperSlide className={style.slider__slide} key={id}>
            <img className={style.slider__img} src={image} alt='logo company' width='160' height='80' loading='lazy' />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default LogoSlider
