import React from 'react'
import TariffInfo from '../../views/TariffInfo'
import Divider from '../../../components/views/Divider'
import { CHANGE_TARIFF_MODAL_VARIANT, DIVIDER_THEME } from '../../../../const'
import usePage from '../../../../hooks/usePage'
import { useTariff } from '../../../hooks/useTariff'
import TariffPanel from '../../views/TariffPanel'
import { useModalStore } from '../../../../hooks/Modals'
import { useStoreon } from 'storeon/react'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'

const Tariffs = ({ content }) => {
  const history = useHistory()
  const { currentUser } = useStoreon('currentUser')
  const context = useTariff(content)
  const {
    activeTab,
    pressedTariff,
    setPressedTariff,
    proofPayment,
    tariffScale
  } = context
  const { baseSlug } = usePage()
  const { openModal } = useModalStore()

  const handlePickCard = tariff => {
    const search = qs.stringify({ tariffID: tariff.tariff_id })
    history.replace({ ...history.location, search })
    setPressedTariff(tariff)
  }
  const handleBuyTariff = tariff => {
    currentUser
      ? openModal('changeTariff', { variant: CHANGE_TARIFF_MODAL_VARIANT.LANDING, proofPayment, tariff, activeTab })
      : history.push({ pathname: baseSlug.pageLogin, state: { from: history.location } })
  }
  return (
    <>
      <TariffPanel
        {...context}
        handlePickCard={handlePickCard}
        handleBuyTariff={handleBuyTariff}
      />
      {pressedTariff && <Divider theme={DIVIDER_THEME.M20} />}
      {pressedTariff && (
        <TariffInfo
          tariff={pressedTariff}
          tariffScale={tariffScale}
          activeTab={activeTab}
          currentUser={currentUser}
          handleBuyTariff={handleBuyTariff}
        />
      )}
    </>
  )
}

export default Tariffs
