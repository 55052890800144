import React from 'react'
import { GxSpinner } from '@garpix/garpix-web-components-react'
import cn from 'classnames'
import style from './styles/index.module.scss'

const Loader = ({ theme }) => {
  return (
    <GxSpinner className={cn({
      [style.loader]: true,
      [style[`loader__${theme}`]]: !!theme
    })}
    />
  )
}

export default Loader
