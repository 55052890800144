import React from 'react'
import styles from './styles/index.module.scss'
import NewsCard from '../../../apps/components/views/NewsCard'
import Title from '../../Title'
import { TITLE_TYPE, TITLE_VARIANT } from '../../../const'

const News = ({ content }) => {
  const news = content?.news
    ? content.news.slice(0, 2)
    : []
  return (
    <>
      <div className={styles.news__header}>
        <div className={styles.news__title}>
          <Title type={TITLE_TYPE.H5} variant={TITLE_VARIANT.H5}>
            {content.text_title}
          </Title>
        </div>
        {content.news_list_page && <a href={content.news_list_page} className={styles.news__link}>Читать еще</a>}
      </div>
      <div className={styles.news__list}>
        {
          news.map(item => <NewsCard key={item.id} {...item} theme='card_landing' />)
        }
      </div>
    </>
  )
}

export default News
