import React from 'react'
import PropTypes from 'prop-types'
import style from './styles/index.module.scss'
import classNames from 'classnames'

const Title = ({ children, variant, type, className, ...restProps }) => {
  const CustomTag = type
  return <CustomTag className={classNames(style[variant], { [className]: !!className })} {...restProps}>{children}</CustomTag>
}

Title.defaultProps = {
  type: 'h1'
}

Title.propTypes = {
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  variant: PropTypes.string,
  children: PropTypes.any
}

export default Title
