import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import LogoSliderView from '../../../views/LandingView/LogoSlider'
import { CARDS } from '../../../const'

const LogoSlider = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} id={CARDS.LOGO_SLIDER.id} isBlue {...props}>
      <LogoSliderView {...props} />
    </SectionHomePageWithButtons>
  )
}

export default LogoSlider
