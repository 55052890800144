import React from 'react'
import classNames from 'classnames'
import { GxTab, GxTabGroup, GxTabPanel } from '@garpix/garpix-web-components-react'
import styles from './styles/index.module.scss'

const Tab = ({ children, theme = null, ...props }) => {
  return (
    <GxTab
      className={classNames({
        [styles.tariff_tabs__tab]: true,
        [styles[theme]]: !!theme
      })}
      slot='nav'
      {...props}
    >
      {children}
    </GxTab>
  )
}

const Panel = ({ children, theme = 'card_theme', ...props }) => {
  return (
    <GxTabPanel
      {...props}
      className={classNames({
        [styles.tariff_tabs__panel]: true,
        [styles[theme]]: !!theme
      })}
    >
      {children}
    </GxTabPanel>
  )
}

const Link = ({ children, ...props }) => {
  return (
    <a
      className={styles.tariff_tabs__link}
      slot='nav'
      {...props}
    >
      {children}
    </a>
  )
}

const TariffTabGroup = ({ children, className, theme, ...props }) => {
  return (
    <GxTabGroup className={classNames(styles.tariff_tabs__group, { [styles[theme]]: !!theme, [className]: !!className })} {...props}>
      {children}
    </GxTabGroup>
  )
}

export default Object.assign(TariffTabGroup, { Tab, Panel, Link })
