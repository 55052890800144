import React, { useCallback, useMemo } from 'react'
import usePage from '../../hooks/usePage'
import ProfileViews from '../../views/ProfileViews'
import { useModalIntlContent, useModalStore } from '../../hooks/Modals'

const ProfileNavigation = () => {
  const { openModal, contentTypes } = useModalStore()
  const quitAccountContent = useModalIntlContent(contentTypes.quitAccount)
  const { page } = usePage()
  const components = useMemo(() => page || [], [page])

  const handleLogout = useCallback(async () => {
    openModal('quitAccount', quitAccountContent)
  }, [])

  const links = components.init_state.global.menus.left_partner_profile_menu || components.init_state.global.menus.left_profile_menu

  return (
    <ProfileViews.ProfileNavigationView links={links} handleLogout={handleLogout} />
  )
}

export default ProfileNavigation
