/* eslint-disable camelcase */
import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import Button from '../../../Button'
import RadioButton from '../../../RadioButton'
import { plusSmallIcon } from '../../../../images'
import { BUTTON_THEME, BUTTON_VARIANT } from '../../../../const'
import style from '../styles/index.module.scss'
import { FormattedMessage } from 'react-intl'

const MoveCargo = ({
  cargoGroups,
  handleChange,
  handleCreate,
  handleMove,
  newGroupIndex,
  limit,
  handleClose,
  groupIndex
}) => {
  return (
    <>
      <div className={style.modal__title}>
        <FormattedMessage
          id='modals.moveCargo.title'
          defaultMessage='Выберите группу'
        />
      </div>
      <div className={style.move__radio_group}>
        {cargoGroups.map(({ group_id, title }, index) => {
          return (
            <div key={group_id} className={style.move__radio}>
              <RadioButton
                value={index}
                onChange={handleChange}
                name='group'
                disabled={index === groupIndex}
                checked={index === newGroupIndex}
              >
                {title}
              </RadioButton>
            </div>
          )
        })}
      </div>
      <div className={style.move__add}>
        {!limit
          ? (
            <Button
              onClick={handleCreate}
              variant={BUTTON_VARIANT.TEXT}
              theme={BUTTON_THEME.TEXT_WITH_ICON.SHORT}
            >
              <GxIcon
                src={plusSmallIcon}
                slot='icon-left'
                className={style.move__add_icon}
              />
              <FormattedMessage
                id='modals.moveCargo.addButton'
                defaultMessage='Создать группу'
              />
            </Button>
            )
          : null}
      </div>
      <div className={style.modal__buttons}>
        <div className={style.modal__button}>
          <Button onClick={handleClose} theme={BUTTON_THEME.STROKE_ICON.SMALL}>
            <FormattedMessage
              id='cancel'
              defaultMessage='Отмена'
            />
          </Button>
        </div>
        <div className={style.modal__button}>
          <Button
            onClick={handleMove}
            theme={BUTTON_THEME.FILL_ICON.SMALL}
            disabled={newGroupIndex === null}
          >
            <FormattedMessage
              id='move'
              defaultMessage='Переместить'
            />
          </Button>
        </div>
      </div>
    </>
  )
}

export default MoveCargo
