export const QUERY = {
  NOTIFICATIONS: 'notifications',
  UNREAD_NOTIFICATIONS: 'unreadNotifications',
  MENU_NOTIFICATIONS: 'menuNotifications',
  NOTIFICATIONS_PAGE: 'notificationsPage'
}
export const UNREAD_NOTIFICATIONS_QK = [QUERY.NOTIFICATIONS, QUERY.UNREAD_NOTIFICATIONS]
export const MENU_NOTIFICATIONS_QK = [QUERY.NOTIFICATIONS, QUERY.MENU_NOTIFICATIONS]
export const NOTIFICATIONS_PAGE_QK = [QUERY.NOTIFICATIONS, QUERY.NOTIFICATIONS_PAGE]
export const NOTIFICATIONS_TABS = {
  all: {
    id: 0,
    type: '',
    title: {
      id: 'all',
      defaultMessage: 'Все'
    }
  },
  calc: {
    id: 1,
    type: 'calc',
    title: {
      id: 'calculations',
      defaultMessage: 'Расчеты'
    }
  },
  report: {
    id: 2,
    type: 'report',
    title: {
      id: 'reports',
      defaultMessage: 'Отчеты'
    }
  },
  excel: {
    id: 3,
    type: 'excel',
    title: {
      id: 'createFromExcel',
      defaultMessage: 'Создание базы из Excel'
    }
  }
}
