import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useFollowElement from '../../../../hooks/useFollowElements'
import TariffCard from '../TariffCardView'
import styles from './styles/index.module.scss'

const TariffPanelSlider = ({
  tariffs,
  tariffKey,
  needSlide,
  setNeedSlide,
  baseTariff,
  pressedTariff,
  handleBuyTariff,
  handlePickCard,
  showButton,
  isProfile,
  periods
}) => {
  const [swiperRef, isVisible] = useFollowElement({ threshold: 0.7 })
  useEffect(() => {
    if (swiperRef && needSlide && isVisible) {
      swiperRef.current.swiper.slideTo(3, 3000)
      setNeedSlide(false)
    }
  }, [swiperRef, needSlide, isVisible])
  const period = periods.find(({ id }) => id === +tariffKey)
  return (
    <Swiper
      className={styles.slider}
      touchEventsTarget='container'
      slidesPerView={3}
      observeParents
      observer
      ref={swiperRef}
      breakpoints={{
        280: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        630: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        991: {
          spaceBetween: 25,
          allowTouchMove: true
        },
        1366: {
          allowTouchMove: false
        }
      }}
    >
      {tariffs.map((tariff) => {
        const isUserTariff = baseTariff === tariff.tariff_id
        return (
          <SwiperSlide className={styles.slider__slide} key={tariff.tariff_id}>
            <TariffCard
              isUserTariff={isUserTariff}
              key={tariff.tariff_id}
              active={pressedTariff?.tariff_id === tariff.tariff_id}
              handleBuyTariff={handleBuyTariff}
              tariff={tariff}
              amount={period.amount}
              timeUnit={period?.time_units}
              handlePickCard={handlePickCard}
              showButton={showButton}
              isProfile={isProfile}
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default TariffPanelSlider
