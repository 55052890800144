import React, { useMemo } from 'react'

import PasswordRule from './PasswordRule'

import usePage from '../../hooks/usePage'
import { getPasswordRules } from '../../apps/appRegistration/utils'

import styles from './styles/index.module.scss'

const PasswordRulesView = ({
  error
}) => {
  const { page } = usePage()
  const passwordConfig = page.init_state.global?.password_config
  const passwordRules = useMemo(
    () => getPasswordRules(passwordConfig),
    [passwordConfig]
  )

  if (!passwordConfig) {
    return null
  }

  return (
    <ul className={styles.list}>
      {passwordRules.map((el, i) => (
        <PasswordRule
          key={el.keyRule}
          {...el}
          errorKey={error}
          serialNumber={i + 1}
        />
      ))}
    </ul>
  )
}

export default PasswordRulesView
