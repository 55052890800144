import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import ModalView from '../../../ModalView'

import { zoomPhoto } from '../../../../utils'

import styles from '../styles/index.module.scss'

/**
 * @view
 * * Компонент для отображения фото ГП с его заполняемыми параметрами.

 * @prop {string} image         ссылка на фото ГП
 * @prop {Object} formikValues  Данные редактируемого ГП
 *
 */

const ShowCargoSpaceView = ({ image, formikValues }) => {
  const [isTouch, setIsTouch] = useState(false)
  const figureRef = useRef(null)
  const intl = useIntl()
  /**
   * * Обработчик тача на фото,
   * * при первом нажатии показываем приближенное фото
   * * при втором нажатии скрываем её
   * @function handleTouchImageWrap
   * @param {TouchEvent} e
   */
  const handleTouchImageWrap = (e) => {
    if (isTouch === false) {
      zoomPhoto(e)
    }

    setIsTouch((prev) => !prev)
  }

  useEffect(() => {
    if (figureRef.current) {
      figureRef.current.style.backgroundImage = `url('${image}')`
    }
  }, [figureRef])

  return (
    <div className={styles.cargo_space__wrap}>
      <ModalView.Title>
        {intl.formatMessage({
          id: 'axesLocation',
          defaultMessage: 'Расположение осей'
        })}
      </ModalView.Title>
      {formikValues?.axles && (
        <p>
          {intl.formatMessage({
            id: 'numberOfaxes',
            defaultMessage: 'Количество осей'
          })}
          : {formikValues?.axles.length}
        </p>
      )}
      <figure
        ref={figureRef}
        onTouchMove={zoomPhoto}
        onTouchStart={handleTouchImageWrap}
        data-touch={isTouch}
        className={styles.cargo_space__zoom}
      >
        <img src={image} alt='Cargo Illustration' />
      </figure>
      <p className={styles.cargo_space__text}>
        {intl.formatMessage({
          id: 'clickToZoom',
          defaultMessage: 'Нажмите, чтобы приблизить'
        })}
      </p>
    </div>
  )
}

export default ShowCargoSpaceView
