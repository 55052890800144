import { useCallback, useEffect, useRef } from 'react'
import { useInfiniteQuery } from 'react-query'
import { notifyApiService } from '../../../../api/services'
import { QUERY } from '../../const'
import useWindowHeight from '../../../../hooks/useWindowHeight'

const useInfiniteNotification = (params) => {
  const innerHeight = useWindowHeight()

  const ref = useRef(null)

  const query = useInfiniteQuery([QUERY.NOTIFICATIONS, QUERY.NOTIFICATIONS_PAGE, params.type_event, params.is_read], ({ pageParam = 1 }) => {
    return notifyApiService.getNotify({ ...params, page: pageParam })
  },
  {
    cacheTime: 0,

    getNextPageParam: (lastPage, allPages) => {
      const maxPages = Math.ceil(lastPage.count / params.page_size)
      const nextPage = allPages.length + 1
      return nextPage <= maxPages ? nextPage : undefined
    }
  }
  )
  const handleScroll = useCallback(async (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target
    if (!query.isFetching && scrollHeight - scrollTop <= clientHeight * 1.5) {
      query.hasNextPage && await query.fetchNextPage()
    }
  }, [query.hasNextPage, query.isFetching])

  useEffect(() => {
    const { scrollHeight } = (ref && ref.current) || {}
    if (query.hasNextPage && scrollHeight <= innerHeight) {
      query.fetchNextPage()
    }
  }, [query.hasNextPage, query.data, innerHeight])

  return { ...query, handleScroll, ref }
}

export default useInfiniteNotification
