import { useStoreon } from 'storeon/react'

import { useModalStore } from '../../../hooks/Modals'
import { MODAL_TYPES } from '../../../const'
import { useEffect } from 'react'

const withChangePassword = (Component) => (props) => {
  const { currentUser } = useStoreon('currentUser')
  const { openModal, setUnclosable } = useModalStore()

  useEffect(() => {
    if (currentUser?.is_needs_password_update && props.pageType) {
      openModal(MODAL_TYPES.CHANGE_PASSWORD)
      setUnclosable(true)
    }
  }, [currentUser?.is_needs_password_update, props.pageType])

  return <Component {...props} />
}

export default withChangePassword
