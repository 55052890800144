import * as yup from 'yup'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../const'
import { getValidateText } from '../ValidateText'

const NewProjectSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(
      ERROR_NUMBERS.MAX_PROJECT_TITLE_LENG,
      getValidateText('title', 'max', ERROR_NUMBERS.MAX_PROJECT_TITLE_LENG)
    )
    .min(
      ERROR_NUMBERS.MIN_STRING_NUMBER,
      getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED)
})

export default NewProjectSchema
