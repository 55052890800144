/* eslint-disable camelcase */
import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { doneIcon, possibilities1 } from '../../images'
import styles from './styles/index.module.scss'
import classNames from 'classnames'
import useFollowElement from '../../hooks/useFollowElements'

const PossibilitiesCard = ({ data }) => {
  const [possibilitiesRef, isVisible] = useFollowElement({ threshold: 0.2 })
  const { text_title, text, image, capabilities_points } = data

  return (
    <div className={styles.animate} ref={possibilitiesRef}>
      <div
        className={classNames(styles.possibilities, isVisible && styles.active)}
      >
        <div className={styles.possibilities__content}>
          <div className={styles.possibilities__content_top}>
            {text_title && (
              <h3 className={styles.possibilities__content_title}>{text_title}</h3>
            )}
            {text && (
              <div className={styles.possibilities__content_description}>{text}</div>
            )}
          </div>
          {capabilities_points && capabilities_points.length > 0 && (
            <div className={styles.possibilities__items}>
              {capabilities_points.map(({ id, text }) => (
                <div key={id} className={styles.possibilities__item}>
                  {text && (
                    <>
                      <GxIcon
                        src={doneIcon}
                        className={styles.possibilities__icon}
                      />
                      <span className={styles.possibilities__item_text}>{text}</span>
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={styles.possibilities__side}>
          <div className={styles.possibilities__side_wrap}>
            <img
              className={styles.possibilities__side_image}
              width='400'
              height='400'
              src={image || possibilities1}
              alt={text_title}
              loading='lazy'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PossibilitiesCard
