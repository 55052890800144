/* eslint-disable camelcase */
import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { doneIcon, workResultIcon } from '../../images'
import classNames from 'classnames'
import styles from './styles/index.module.scss'

const WorkResultsCard = ({ data }) => {
  const { results_points, text, text_title } = data
  return (
    <div className={styles.workResults}>
      {text_title && <h3 className={styles.workResults_header}>{text_title}</h3>}
      <div className={classNames(styles.workResults_content, results_points.length === 0 && styles['workResults_content-auto'])}>
        {text && (
          <div className={styles.workResults_description}>
            <GxIcon src={workResultIcon} className={styles['workResults_icon-desc']} />
            {text}
          </div>
        )}
        <div className={styles.workResults_items}>
          {results_points &&
            results_points.length > 0 &&
            results_points.map(({ id, text }) => (
              <div key={id} className={styles.workResults_item}>
                {text && (
                  <>
                    <GxIcon src={doneIcon} className={styles.workResults_icon} />
                    {text}
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default WorkResultsCard
