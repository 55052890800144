import { useCallback, useEffect } from 'react'
import { SOCKET_EVENTS } from '../../../const'
import { useStoreon } from 'storeon/react'
import usePage from '../../../hooks/usePage'
import { useQueryClient } from 'react-query'
import { useReadNotification } from '../hooks'
import { MENU_NOTIFICATIONS_QK, NOTIFICATIONS_PAGE_QK, UNREAD_NOTIFICATIONS_QK } from '../const'
import { useSocket } from '../../hooks'

export const withSocketEvents = (Component) => (props) => {
  const queryClient = useQueryClient()
  const { project } = useStoreon('project')
  const { id: currentProjectID } = project
  const { page } = usePage()
  const { socket } = useSocket()
  const readNotification = useReadNotification()
  const checkCurrentPage = useCallback((payload) => {
    const { event, message } = payload
    const { project_id: socketProjectID } = message
    switch (page.page_model) {
      case 'Project':
        return (
          [
            SOCKET_EVENTS.CHANGED_PROJECT,
            SOCKET_EVENTS.CALCULATION_SUCCESS,
            SOCKET_EVENTS.CALCULATION_START
          ].includes(event) &&
          Number(socketProjectID) === currentProjectID
        )
      case 'ProjectReportPage':
        return [
          SOCKET_EVENTS.REPORT_SUCCESS,
          SOCKET_EVENTS.PIVOT_REPORT_SUCCESS,
          SOCKET_EVENTS.FULL_REPORT_SUCCESS,
          SOCKET_EVENTS.REPORT_GENERATING,
          SOCKET_EVENTS.FULL_REPORT_GENERATING,
          SOCKET_EVENTS.PIVOT_REPORT_GENERATING
        ].includes(event)
      case 'CargoSpacesListPage':
        return [
          SOCKET_EVENTS.LOAD_CARGO_SPACE,
          SOCKET_EVENTS.LOAD_ERROR
        ].includes(event)
      case 'CargosListPage':
        return [
          SOCKET_EVENTS.LOAD_CARGOES,
          SOCKET_EVENTS.LOAD_ERROR
        ].includes(event)
      case 'ProjectsListPage':
        return [
          SOCKET_EVENTS.LOAD_PROJECT_EXCEL,
          SOCKET_EVENTS.LOAD_ERROR
        ].includes(event)
      default :
        return false
    }
  }, [page, currentProjectID])
  const memoizedMessageListener = useCallback((payload) => {
    const { id, event } = payload
    if (event !== SOCKET_EVENTS.STATISTICS) {
      queryClient.setQueryData(UNREAD_NOTIFICATIONS_QK, (prevData) => {
        return prevData && {
          ...prevData,
          count: prevData.count + 1
        }
      })
      queryClient.invalidateQueries(MENU_NOTIFICATIONS_QK)
      queryClient.invalidateQueries(NOTIFICATIONS_PAGE_QK)
      checkCurrentPage(payload) && readNotification.mutate(id)
    }
  }, [checkCurrentPage, readNotification])

  useEffect(() => {
    socket.subscribe('send_notify', memoizedMessageListener)
    return () => socket.unsubscribe('send_notify', memoizedMessageListener)
  }, [memoizedMessageListener])

  return <Component {...props} />
}
