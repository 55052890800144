import React from 'react'
import { decodeHTML } from '../../utils'

const InnerHtml = React.forwardRef(({
  component: Component = 'div',
  className,
  html,
  ...other
}, ref) => {
  return (
    <Component
      ref={ref}
      className={className}
      dangerouslySetInnerHTML={{ __html: decodeHTML(html || '') }}
      {...other}
    />
  )
})

export default InnerHtml
