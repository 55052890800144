import React from 'react'
import styles from './styles/index.module.scss'
import { useTable } from 'react-table'
import Title from '../../../../views/Title'
import { TITLE_TYPE, TITLE_VARIANT } from '../../../../const'
import classNames from 'classnames'
import { formatPrice } from '../../../../utils'
import useTranslate from '../../../../hooks/useTranslate'
import { TIME_FOR_TARIFF_SCALE } from '../../const'

const FormatedPrice = ({ price, active, time }) => {
  return (
    <>
      <span
        className={classNames(styles.tariff_scale__price, {
          [styles['tariff_scale__price--active']]: !!active
        })}
      >
        {price} ₽
      </span>{' '}
      за&nbsp;{time}
    </>
  )
}

const TariffScale = ({ tariffScale, activeTab, hasPromotionType = false }) => {
  const { formatMonth } = useTranslate()
  let maxPerPrice = 0
  const data = React.useMemo(() => {
    return tariffScale.map((item) => {
      const pricePerPeriod = item.price_per_period
      maxPerPrice = maxPerPrice < pricePerPeriod ? pricePerPeriod : maxPerPrice
      const hasReducedPrice = maxPerPrice > pricePerPeriod

      return {
        id: item.period_id,
        amount: item.amount,
        title: formatMonth(item.amount, item.time_units),
        price: formatPrice(Number(item.price)),
        price_per_period: (
          <FormatedPrice
            active={hasReducedPrice}
            price={formatPrice(Number(item.price_per_period), false)}
            time={TIME_FOR_TARIFF_SCALE[item.time_units]}
          />
        )
      }
    })
  }, [tariffScale])

  const columns = React.useMemo(
    () => [
      {
        accessor: 'title'
      },
      {
        accessor: 'price',
        className: styles.tariff_scale__full_price
      },
      {
        accessor: 'price_per_period',
        className: styles.tariff_scale__period_price
      }
    ],
    []
  )

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({
    columns,
    data
  })

  const tariffScaleTitle = hasPromotionType
    ? 'Тарифная сетка без учета акции'
    : 'Тарифная сетка'
  return (
    <div className={styles.tariff_scale}>
      <Title type={TITLE_TYPE.H6} variant={TITLE_VARIANT.H6}>
        {tariffScaleTitle}
      </Title>
      <table {...getTableProps()}>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...row.getRowProps()}
                className={classNames({
                  [styles['tariff_scale__row--active']]:
                    row.original.id === activeTab.id
                })}
              >
                {row.cells.map((cell) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td
                      {...cell.getCellProps([
                        {
                          className: cell.column.className
                        }
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TariffScale
