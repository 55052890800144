
import { serializedTariff } from '../api/serializers'
import usePage from './usePage'

const useSerializeCurrentTariff = () => {
  const { page } = usePage()
  const currentTariff = page.init_state.global.current_user?.current_tariff

  const serializedCurrentTariff = serializedTariff(currentTariff)

  return serializedCurrentTariff
}

export default useSerializeCurrentTariff
