/* eslint-disable camelcase */
import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import metrik from '../../../services/metrik'
import style from './styles/index.module.scss'
import { cleanPhone } from '../../../utils'

/**
 * @view
 * * Вью Футера реализующий интерфейс блока с контактами -
 * * email, телефон и т.д
 *
 * @param {string} contact_email
 * @param {string} contact_phone
 * @param {string} email_icon
 * @param {string} phone_icon
 * @param {string} phone_title
 */

const ContactUs = ({ contact_email, contact_phone, email_icon, phone_icon, phone_title }) => {
  return (
    <div>
      {contact_phone && (
        <div className={style['contact-info__item']}>
          <div>
            <GxIcon className={style['contact-info__icon']} src={phone_icon} />
          </div>
          <div>
            <a onClick={() => metrik.send('reachGoal', 'telephone')} href={`tel:${cleanPhone(contact_phone)}`} className={style['contact-info__phone']}>
              {contact_phone}
            </a>
            {phone_title && <p className={style['contact-info__desc']}>{phone_title}</p>}
          </div>
        </div>
      )}
      {contact_email && (
        <div className={style['contact-info__item']}>
          <div>
            <GxIcon className={style['contact-info__icon']} src={email_icon} />
          </div>
          <div>
            <a onClick={() => metrik.send('reachGoal', 'e_mail')} href='mailto:loadsystem@garpix.com' className={style['contact-info__phone']}>
              {contact_email}
            </a>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactUs
