import React from 'react'
import LoadPerfomaceView from '../../../views/LandingView/LoadPerfomanceNew'
import SectionHomePage from '../../../views/LandingView/Section'
import { CARDS } from '../../../const'

const LoadPerfomaceNew = (props) => {
  return (
    <SectionHomePage theme={props.content?.theme} id={CARDS.LOAD_PERFOMANCE_NEW.id} isBlue {...props}>
      <LoadPerfomaceView theme={props.content?.theme} {...props} />
    </SectionHomePage>
  )
}

export default LoadPerfomaceNew
