import React from 'react'
import { doneIcon } from '../../../images'
import FrequentProblemsImage from '../../FrequentProblemsCard/FrequentProblemsImage'
import FrequentProblemsItem from '../../FrequentProblemsCard/FrequentProblemsItem'
import { FREQUENT_PROBLEMS_IMAGE_THEME } from '../../../const'
import style from './style/index.module.scss'

const FrequentProblemsCardGazprom = (props) => {
  const { img, title, number, advantages } = props
  return (
    <li className={style.frequent__card}>
      <div className={style.frequent__card_wrap_img}>
        <FrequentProblemsImage
          theme={FREQUENT_PROBLEMS_IMAGE_THEME.GAZPROM}
          image={img}
          number={number}
        />
      </div>
      <h3 className={style.frequent__card_title}>{title}</h3>
      {advantages &&
        advantages.length !== 0 &&
        advantages.map(({ text, id }) => (
          <FrequentProblemsItem
            key={id}
            icon={doneIcon}
            text={text}
            styleForIcon={style.frequent__card_icon}
            styleForDesc={style.frequent__card_advantages}
          />
        ))}
    </li>
  )
}

export default FrequentProblemsCardGazprom
