import React, { useState } from 'react'
import { useStoreon } from 'storeon/react'
import { convertError } from '../../../utils'
import RemoveModal from '../../../views/BaseModal/ModalContent/Remove'
import { useModalIntlContent, useModalStore } from '../../../hooks/Modals'
import { useIntl } from 'react-intl'

const ELEMENT_TYPE_SCHEME = {
  project: {
    id: 'entities.project',
    defaultMessage: 'проект'
  },
  cargo: {
    id: 'cargo',
    defaultMessage: 'груз'
  },
  cargo_group: {
    id: 'entities.cargoGroup',
    defaultMessage: 'группу грузов'
  },
  cargo_space: {
    id: 'entities.space',
    defaultMessage: 'пространство'
  }
}

const RemovePageItemModal = (props) => {
  const { removeHandler, itemID, itemTitle, type, results, activePage, loadData, reload, handleClose, reset } = props
  const [isSubmitting, setSubmitting] = useState(false)
  const { dispatch } = useStoreon()
  const { openModal, contentTypes } = useModalStore()
  const content = useModalIntlContent(contentTypes.warning)
  const intl = useIntl()

  const page = results && results.length === 1 && activePage !== 1 ? activePage - 1 : activePage
  const updatePage = (...args) => results.length === 1 ? loadData(...args) : reload()

  const translatedType = intl.formatMessage(ELEMENT_TYPE_SCHEME[type]).toLowerCase()

  const onSubmitHandler = async () => {
    try {
      setSubmitting(true)
      await removeHandler({ id: itemID })
      updatePage(page)
      handleClose()
      reset && reset()
      dispatch('user/get', { handleAccess: () => {}, handleError: () => {} })
    } catch (e) {
      const error = convertError(e)
      openModal('commonConfirm', { ...content, description: { defaultMessage: error } })
    } finally {
      setSubmitting(false)
    }
  }
  return (
    <RemoveModal
      type={translatedType}
      name={itemTitle}
      handleAccept={onSubmitHandler}
      handleClose={handleClose}
      isSubmitting={isSubmitting}
    />
  )
}

export default RemovePageItemModal
