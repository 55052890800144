import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { isServer } from '../../utils'
/**
 * @view
 * * Вьюха для установки тега title
 */
const SeoTitle = ({
  siteCommonTitle = '',
  seoTitle = '',
  title
}) => {
  const commonTitle = (siteCommonTitle ?? '').trim()
  const currentTitle = `${seoTitle === '' ? title : seoTitle} ${commonTitle}`
  /**
   * @description
   * ! hac
   * * хелмет не работает если ты оборачиваешь сам комп
   */
  const WrapComp = !isServer() ? Helmet : Fragment

  return (
    <WrapComp>
      <title>{currentTitle ?? ''}</title>
    </WrapComp>
  )
}

export default SeoTitle
