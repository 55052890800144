import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'
import { userCompanySerializer, formDataSerializer, companyMPSerializer } from '../../serializers/index.js'


class CompanyApiService extends Api {

    createCompany = async ({params}) => {
        const fd = formDataSerializer(params)
        const res = await this.post('/company/', fd);
        return res.data;
    };
    getCompany = async (id) => {
        const res = await this.get(`/company/${id}/`)
        return userCompanySerializer(res.data)
    }
    editCompany = async ({id, params}) => {
        const fd = formDataSerializer(params)
        const res = await this.patch(`/company/${id}/`, fd);
        return res.data;
    };

    getUsers = async ({ companyID, page, q, page_size = 10, is_blocked}) => {
        const res = await this.get(`/company/${companyID}/user/`, {
            page,
            page_size,
            q,
            is_blocked
        });
        return res.data;
    };

    getUser = async ({ userID, companyID }) => {
        const res = await this.get(`/company/${companyID}/user/${userID}/`)
        return res.data
    }

    deleteUser = async ({ userID, companyID, params}) => {
        const res = await this.delete(`/company/${companyID}/user/${userID}/`, params)
        return res.data
    }

    blockUser = async ({ companyID, userID }) => {
        const res = await this.post(`/company/${companyID}/user/${userID}/block/`)
        return res.data
    }
    unblockUser = async ({ companyID, userID }) => {
        const res = await this.post(`/company/${companyID}/user/${userID}/unblock/`)
        return res.data
    }

    changeRole = async ({ companyID, userID, role }) => {
        const res = await this.patch(`/company/${companyID}/user/${userID}/change_role/`, { role: role.id })
        return res.data
    }


    invite = async ({ companyID, ...params }) => {
        const res = await this.post(`/company/${companyID}/invite/`, params)
        return res.data
    }

    getInvites = async ({ companyID, page, q, page_size = 10, status }) => {
        const res = await this.get(`/company/${companyID}/invites/`, {
            page,
            page_size,
            q,
            status
        });
        return res.data;
    }

    createAndInvite = async ({companyID , ...params}) => {
        const res = await this.post(`/company/${companyID}/create_and_invite/`, params)
        return res.data
    }

    changeOwner = async ({ companyID, newOwnerID }) => {
        const res = await this.post(`/company/${companyID}/change_owner/`, { new_owner: newOwnerID })
        return res.data
    }
    deleteCompany = async (idCompany) => {
        const res = await this.delete(`/company/${idCompany}/`);
        return res.data;
    };

    acceptInvite  = async (inviteID) => {
        const res = await this.post(`/company/invite/${inviteID}/accept/`)
        return res.data
    }
    declineInvite  = async (inviteID) => {
        const res = await this.post(`/company/invite/${inviteID}/decline/`)
        return res.data
    }

    downloadAdditionalParameters = async (companyID) => {
        const res = await this.get(`/company/${companyID}/download_additional_parameters/`)
        return res.data.file
    }

    loadAdditionalParameters = async (companyID, file) => {
        const fd = new FormData();
        fd.append('excel_file', file);
        const res = await this.post(`/company/${companyID}/import_additional_parameters/`, fd)
        return res.data
    }

    getMPtypes = async (companyID) => {
        const res = await this.get(`/company/${companyID}/mp_type/`)
        return companyMPSerializer(res.data)
    }
}

export default new CompanyApiService(runtimeConfig.API_URL)
