import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'

class MailingApiService extends Api {
  unsubscribe = async ({ unsub_token, user_id }) => {
    const res = await this.post(
      `/unsubscribe/?unsub_token=${unsub_token}&user_id=${user_id}`
    )
    return res.data
  }

  subscribe = async ({ confirm }) => {
    const res = await this.post('/subscribe/', { confirm })
    return res.data
  }
}

export default new MailingApiService(runtimeConfig.API_URL)
