/* eslint-disable camelcase */
import React from 'react'
import PossibilitiesCard from '../../PossibilitiesCard'
import styles from './styles/index.module.scss'

const Possibilities = ({ content }) => {
  const { capabilities_elements } = content
  return (
    <div className={styles.possibilities}>
      {capabilities_elements &&
        capabilities_elements.length > 0 &&
        capabilities_elements.map((card) => (
          <PossibilitiesCard key={card.id} data={card} />
        ))}
    </div>
  )
}

export default Possibilities
