import React, { useState } from 'react'
import usePage from '../../../../hooks/usePage'
import Loader from '../../../../views/Loader'
import NotificationsMenuView from '../../views/NotificationsMenuView'
import NotificationItemView from '../../views/NotificationItemView'
import EmptyNotificationView from '../../views/EmtyNotificationView'
import {
  useFetchNotifications,
  useFetchUnreadNotifications,
  useReadAllNotifications,
  useReadNotification
} from '../../hooks'
import { withSocketEvents } from '../../hoc'

const NotificationsMenuComponent = () => {
  const { baseSlug } = usePage()
  const [open, setOpen] = useState(false)

  const { data } = useFetchUnreadNotifications()
  const { data: dataNotifications, isFetching } = useFetchNotifications(open)
  const notifications = dataNotifications ? dataNotifications.results : []
  const readAllNotifications = useReadAllNotifications()
  const readNotification = useReadNotification()

  const handleShow = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <NotificationsMenuView
      count={data?.count}
      handleShow={handleShow}
      open={open}
      handleClose={handleClose}
      isFetching={isFetching}
      notifications={notifications}
      handleReadAll={readAllNotifications.mutate}
      isReadAllLoading={readAllNotifications.isLoading}
      url={baseSlug.pageNotifications}
    >
      {isFetching
        ? <Loader />
        : (
            Array.isArray(notifications) && notifications.length !== 0
              ? notifications.map(
                  item =>
                    <NotificationItemView key={item.id} {...item} handleRead={readNotification.mutate} />
                )
              : <EmptyNotificationView />
          )}
    </NotificationsMenuView>
  )
}
/* TODO: При необходимости обернуть апп, а не меню */
const EnchancedComponent = withSocketEvents(NotificationsMenuComponent)

export default EnchancedComponent
