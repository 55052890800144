import React from 'react'
import componentRegistry from '../../modules/landingComponents/registry'
import styles from './styles/index.module.scss'

const LandingView = ({ components = [], ...props }) => {
  return (
    <div className={styles.main}>
      {components.map((component, i) => {
        const Component = componentRegistry.get(component.component_model)
        if (!Component) return null
        return (
          <Component
            count={i}
            key={component.object.id}
            content={component.object}
            subtitle={component.object?.text_subtitle}
            title={component.object?.text_title}
            text={component.object?.text}
            {...props}
          />
        )
      })}
    </div>
  )
}

export default LandingView
