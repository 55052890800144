/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useStoreon } from 'storeon/react'
import ProfileViews from '../../views/ProfileViews'
import Loader from '../../views/Loader'
import { useModalIntlContent, useModalStore } from '../../hooks/Modals'
import useSerializeCurrentTariff from '../../hooks/useSerializeCurrentTariff'

const Profile = () => {
  const { dispatch } = useStoreon()
  const [success, setSuccess] = useState(false)
  const { openModal, contentTypes } = useModalStore()
  const errorModalContent = useModalIntlContent(contentTypes.warning)
  const serializedTariff = useSerializeCurrentTariff()
  const [currentTariff, setCurrentTariff] = useState(() => serializedTariff)

  useEffect(() => {
    dispatch('countries/get', {
      handleSuccess: () => setSuccess(true),
      handleError: () => openModal('commonConfirm', errorModalContent)
    })
  }, [])

  return success
    ? (
      <ProfileViews.ProfilePanel
        setCurrentTariff={setCurrentTariff}
        currentTariff={currentTariff}
      />
      )
    : (
      <Loader />
      )
}

export default Profile
