import React from 'react'
import ModalView from '../../../ModalView'
import { BUTTON_THEME } from '../../../../const'
import useTranslate from '../../../../hooks/useTranslate'

const IconScheme = {
  warning: () => <ModalView.Icon.Warning />,
  success: () => <ModalView.Icon.Success />,
  pro: () => <ModalView.Icon.Pro />
}

const CommonConfirm = ({ icon, title, description, accept, handleClose, close, handleAccept }) => {
  const Icon = IconScheme[icon] || IconScheme.warning
  const { translate } = useTranslate()
  return (
    <div data-cy='confirm-modal'>
      {icon && <ModalView.Icon><Icon /></ModalView.Icon>}
      {
        title && <ModalView.Title>{translate(title)}</ModalView.Title>
      }
      {
        description && <ModalView.Description description={translate(description)} />
      }
      <ModalView.Buttons>
        {
          close && (
            <ModalView.Buttons.Button data-cy='modal-close-btn' onClick={handleClose} theme={BUTTON_THEME.STROKE_ICON.SMALL}>
              {translate(close)}
            </ModalView.Buttons.Button>
          )
        }
        {
          accept && (
            <ModalView.Buttons.Button data-cy='modal-accept-btn' onClick={handleAccept || handleClose}>
              {translate(accept)}
            </ModalView.Buttons.Button>
          )
        }
      </ModalView.Buttons>
    </div>
  )
}

export default CommonConfirm
