import React from 'react'
import style from './style/index.module.scss'
import FrequentProblemsCardGazprom from './FrequentProblemsCardGazprom'

const FrequentProblemsGazprom = ({ content }) => {
  const { solutions_and_problems_gpb_elements: cardData } = content
  return (
    <ul className={style.frequent}>
      {cardData?.length !== 0 &&
        cardData.map(
          ({
            id,
            image,
            text_title: title,
            solutions_and_problems_gpb_points: advantages,
            number
          }) => (
            <FrequentProblemsCardGazprom
              key={id}
              img={image}
              title={title}
              number={number}
              advantages={advantages}
            />
          )
        )}
    </ul>
  )
}

export default FrequentProblemsGazprom
