/* eslint-disable camelcase */
import { useCallback } from 'react'
import { useStoreon } from 'storeon/react'
import useProjectStatus from '@/hooks/useProjectStatus'
import { useUpdateCalc, useUpdateProjectSettings } from '@/apps/appProject/hooks'

const useUserSort = () => {
  const { dispatch, project } = useStoreon('project')
  const { isForRecalculationStatus, isIrrelevant, isSuccessStatus, isErrorStatus } =
  useProjectStatus()

  const updateSettingsProject = useUpdateCalc()
  const debounceSend = useUpdateProjectSettings(updateSettingsProject.mutateAsync)

  const handleChangeUserSort = useCallback(
    (e, callback) => {
      const checked = e.target.checked
      if (isForRecalculationStatus || isIrrelevant) {
        return
      }
      if (isSuccessStatus || isErrorStatus) {
        dispatch('project/calc/status/save', null)
      }
      if (checked === true) {
        callback?.()
      }

      dispatch('project/change/userSort', checked)

      debounceSend({ userSort: checked })
    },
    [
      isForRecalculationStatus,
      isIrrelevant,
      isSuccessStatus,
      isErrorStatus,
      dispatch
    ]
  )

  return {
    handleChangeUserSort,
    isActiveUserSort: project.userSort
  }
}

export default useUserSort
