/* eslint-disable camelcase */
import React from 'react'
import HowsCard from '../../HowsCard'
import styles from './styles/index.module.scss'

const HowsItem = ({ content }) => {
  const {
    how_work_elements,
    bottom_left_border,
    bottom_right_border,
    top_left_border,
    top_right_border
  } = content

  const style = {
    '--top-left': top_left_border && `${top_left_border}px`,
    '--top-right': top_right_border && `${top_right_border}px`,
    '--bottom-left': bottom_left_border && `${bottom_left_border}px`,
    '--bottom-right': bottom_right_border && `${bottom_right_border}px`
  }
  return (
    <div className={styles.howsitem} style={style}>
      {how_work_elements &&
        how_work_elements.length > 0 &&
        how_work_elements.map((el) => <HowsCard key={el.id} data={el} />)}
    </div>
  )
}

export default HowsItem
