import React from 'react'

import { useIntl } from 'react-intl'
import cn from 'classnames'

import style from './styles/index.module.scss'
/**
 * * Вьюха с ошибкой
 * @view
 * @param theme
 * @param theme
 * @param title
 */
const ErrorBoundaryView = ({
  theme,
  description,
  title = {
    id: 'technicalWorks',
    defaultMessage: 'Технические работы'
  }
}) => {
  const intl = useIntl()
  return (
    <div className={cn(style.boundary, {
      [style[`boundary__${theme}`]]: Boolean(theme)
    })}
    >
      <h1>{intl.formatMessage(title)}</h1>
      {Boolean(description) && <p>{description}</p>}
    </div>
  )
}

export default ErrorBoundaryView
