import { useEffect, useRef, useState } from 'react'

const useFollowElement = (options) => {
  const elementRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  /* TODO: не использовать window в ренедере */
  const observer = typeof window !== 'undefined' &&
      new window.IntersectionObserver((entries, observer) => {
        const [entry] = entries
        setIsVisible(entry.isIntersecting)

        if (entry.isIntersecting) {
          observer.unobserve(elementRef.current)
        }
      }, options)

  useEffect(() => {
    if (elementRef.current) {
      observer.observe(elementRef.current)
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current)
      }
    }
  }, [elementRef, observer])

  return [elementRef, isVisible]
}

export default useFollowElement
