/* eslint-disable camelcase */
import React, { memo, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import ProfileViews from '../../views/ProfileViews'
import { HelpSchema } from '../../utils/ValidateSchemes'
import usePage from '../../hooks/usePage'
import { useModalIntlContent, useModalStore } from '../../hooks/Modals'
import { contentApiService } from '../../api/services'

const Help = ({ results, fetcherActions, ...props }) => {
  const [helpThemes, setHelpThemes] = useState([])
  const { baseSlug } = usePage()
  const { openModal, contentTypes } = useModalStore()
  const successModalContent = useModalIntlContent(contentTypes.help)
  const warningModalContent = useModalIntlContent(contentTypes.warning)

  const formikTools = useFormik({
    initialValues: {
      theme: '',
      message: ''
    },
    onSubmit: async (values, actions) => {
      try {
        const { theme, message } = values
        await contentApiService.createHelpRequest({ theme: Number(theme), message })
        openModal('commonConfirm', successModalContent)
        actions.resetForm()
      } catch (e) {
        openModal('commonConfirm', warningModalContent)
      }
    },
    validationSchema: HelpSchema
  })

  useEffect(() => {
    contentApiService
      .getHelpThemes()
      .then((data) => setHelpThemes(data.results))
      .catch()
  }, [])

  return (
    <ProfileViews.HelpView
      formikTools={formikTools}
      themes={helpThemes}
      baseSlug={baseSlug}
      questions={results}
      fetcherActions={fetcherActions}
      {...props}
    />
  )
}

export default memo(Help)
