import React, { memo } from 'react'
import Search from '../../components/Search'
import AccordionView from '../AccordionView'
import Title from '../Title'
import ListPageViews from '../ListPageViews'
import {
  TITLE_VARIANT,
  ACCORDION_TYPE,
  QUESTION_EMPTY_THEME
} from '../../const'
import QuestionsEmpty from '../QuestionsEmpty'
import classNames from 'classnames'
import style from './styles/index.module.scss'
import { useIntl } from 'react-intl'

const HelpQuestions = ({ questions, fetcherActions }) => {
  const { showMore, filterParams, loadData, isNext, status } =
    fetcherActions

  const isDisabled = !isNext
  const intl = useIntl()
  const hasQuestions = Array.isArray(questions) && questions.length !== 0

  return (
    <div className={style.help_left}>
      <div className={style.help_left__header}>
        <Title
          className={style.help_title}
          type='h1'
          variant={TITLE_VARIANT.LIST_PAGES}
        >
          {
            intl.formatMessage({
              id: 'help',
              defaultMessage: 'Помощь'
            })
          }
        </Title>
        <Search loadData={loadData} filterParams={filterParams} />
      </div>
      {questions && questions.length !== 0
        ? (
          <>
            <div className={style.help_accordions}>
              {questions.map((el) => (
                <AccordionView
                  key={el.id}
                  question={el}
                  theme={ACCORDION_TYPE.HELP_PAGE}
                />
              ))}
              {!isDisabled && status !== 'loading' && (
                <div className={style.help_left__wrap}>
                  <button
                    onClick={showMore}
                    className={style.help_left__btn}
                    data-cy='btn-showmore'
                  >
                    {
                      intl.formatMessage({
                        id: 'showMore',
                        defaultMessage: 'Показать еще'
                      })
                    }
                  </button>
                </div>
              )}
              {status === 'loading' && (
                <ListPageViews.PageLoader
                  className={classNames(
                    style.help__loader,
                    questions.length !== 0 && style['help__loader-relative']
                  )}
                />
              )}
            </div>
          </>
          )
        : (
            status === 'loaded' && (
              <QuestionsEmpty hasQuestions={hasQuestions} theme={QUESTION_EMPTY_THEME.HELP} />
            )
          )}
    </div>
  )
}
export default memo(HelpQuestions)
