/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import ProfileViews from '../../views/ProfileViews'
import Loader from '../../views/Loader'
import { useModalIntlContent, useModalStore } from '../../hooks/Modals'
import { dateSerializers } from '../../api/serializers'
import { paymentApiService, TariffApiService } from '../../api/services'

const Tariffs = (props) => {
  const { state } = useLocation()
  const { dispatch } = useStoreon()

  const { openModal, contentTypes } = useModalStore()
  const errorModalContent = useModalIntlContent(contentTypes.warning)

  const [pressTariff, setPressTariff] = useState(
    () => state?.tariff || null
  )
  const [currentTariff, setCurrentTariff] = useState(props?.init_state?.profile_info?.current_tariff)
  const [success, setSuccess] = useState(false)
  const serializedCurrentTariff = useMemo(() => {
    const { start_at, finish_at } = currentTariff
    const { progressValue, progressMaxValue, restDay, restHour } =
      dateSerializers(start_at, finish_at)
    return {
      ...currentTariff,
      progressValue,
      progressMaxValue,
      restDay,
      restHour
    }
  }, [currentTariff])

  const tariffs = useMemo(() => {
    const freeTariffs = props?.init_state?.free_tariffs
    const paidTariffs = props?.init_state?.tariffs
    return [...freeTariffs, ...paidTariffs]
  }, [])

  useEffect(() => {
    dispatch('countries/get', {
      handleSuccess: () => setSuccess(true),
      handleError: () => openModal('commonConfirm', errorModalContent)
    })
  }, [])

  const handleBuyTariff = useCallback(async () => {
    const { id } = pressTariff
    try {
      const res = await TariffApiService.buyTariff({ tariff: id })
      const { total_amount, currency, form } = await paymentApiService.proofPayment({
        user_tariff_id: res.id,
        recurring: pressTariff.auto_payment
      })
      let newForm
      if (form.indexOf('Initiating recurring pay') !== -1) {
        newForm = form.replace(
          'Initiating recurring pay',
          'Перейти к оплате'
        )
      } else {
        newForm = form.replace('Pay', 'Перейти к оплате')
      }
      const content = {
        html: newForm,
        total_amount,
        currency,
        pressTariff,
        currentTariff
      }
      openModal('confirmBuyTariff', content)
    } catch {
      openModal('commonConfirm', errorModalContent)
    }
  }, [pressTariff])

  if (!success) return <Loader />
  if (!tariffs) return null

  return (
    <ProfileViews.Tariffs
      tariffs={tariffs}
      currentTariff={serializedCurrentTariff}
      pressTariff={pressTariff}
      onPressTariff={setPressTariff}
      buyTariff={handleBuyTariff}
      setCurrentTariff={setCurrentTariff}
    />
  )
}

export default Tariffs
