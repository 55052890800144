/* eslint-disable camelcase */
import { useEffect, useMemo } from 'react'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import { useStoreon } from 'storeon/react'

export const useBitrixUtm = () => {
  const { search } = useLocation()
  const { dispatch, bitrixUTM } = useStoreon('bitrixUTM')
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content
  } = useMemo(() => qs.parse(search), [search])

  useEffect(() => {
    if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
      dispatch('bitrixUTM/set', { utm_source, utm_medium, utm_campaign, utm_term, utm_content })
    }
  }, [utm_source, utm_medium, utm_campaign, utm_term, utm_content])
  return bitrixUTM
}

export default useBitrixUtm
