import React from 'react'
import styles from './styles/index.module.scss'
import Button from '../../Button'
import classNames from 'classnames'

const ModalButton = React.forwardRef(({ children, className, ...props }, ref) => {
  return (
    <Button
      {...props} ref={ref} className={classNames({
        [styles.modal_buttons__button]: true,
        [className]: Boolean(className)
      })}
    >
      {children}
    </Button>
  )
})

const Wrapper = ({ children, className }) => {
  return (
    <div className={classNames({
      [styles.modal_buttons]: true,
      [className]: Boolean(className)
    })}
    >
      {children}
    </div>
  )
}

export default Object.assign(Wrapper, { Button: ModalButton })
