import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import { CARDS } from '../../../const'
import WorkResultsView from '../../../views/LandingView/WorkResults'

const WorkResults = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} id={CARDS.WORK_RESULTS.id} {...props}>
      <WorkResultsView {...props} />
    </SectionHomePageWithButtons>
  )
}

export default WorkResults
