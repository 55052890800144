import React, { useMemo } from 'react'

import { useStoreon } from 'storeon/react'
import usePage from '../../hooks/usePage'
import { useModalIntlContent, useModalStore } from '../../hooks/Modals'
import Header from '../../views/Header'

/**
 * Комопнент Шапки отображаюший табы списочых страниц и меню
 * @component
 *
 * @param {boolean} showOnMobile Флаг на отображение навбара
 * @return {JSX.Element|null}
 */

const HeaderComponent = ({ showOnMobile }) => {
  const { currentUser } = useStoreon('currentUser')
  const { baseSlug, page } = usePage()
  const components = useMemo(() => page || [], [page])

  const { openModal, contentTypes } = useModalStore()
  const quitAccountContent = useModalIntlContent(contentTypes.quitAccount)

  const menus = components?.init_state?.global?.menus

  /**
   * Вызывает модалку на подтверждение логаута
   *
   * @function handleLogout
   */
  const handleLogout = () => {
    openModal('quitAccount', quitAccountContent)
  }

  const changeProfileMenu = () => {
    const roleUser = currentUser?.role_type
    const partnerConfirm = currentUser?.partner_confirm
    const partnerProfileMenu = menus?.partner_profile_menu
    const userProfileMenu = menus?.profile_menu

    if (roleUser === 'partner' && partnerConfirm === 'confirmed') {
      return partnerProfileMenu
    } else {
      return userProfileMenu
    }
  }

  const profileMenuLinks = changeProfileMenu()

  if (!currentUser) return null

  return (
    <Header
      activePage={page?.page_model}
      logout={handleLogout}
      logoLink={baseSlug.pageProjectsList}
      profileMenuLinks={profileMenuLinks}
      mainMenuLinks={menus?.main_menu}
      showOnMobile={showOnMobile}
    />
  )
}

export default HeaderComponent
