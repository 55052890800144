import React from 'react'
import { GxForm } from '@garpix/garpix-web-components-react'
import { Link } from 'react-router-dom'
import { defineMessage, FormattedMessage, useIntl } from 'react-intl'
import Input from '../../../Input'
import InputPhone from '../../../InputPhone'
import Textarea from '../../../Textarea'
import Button from '../../../Button'
import {
  BUTTON_THEME,
  LINK_TARGET,
  TITLE_TYPE,
  TITLE_VARIANT
} from '../../../../const'
import Title from '../../../Title'
import metrik from '../../../../services/metrik'
import useTranslate from '../../../../hooks/useTranslate'
import styles from '../styles/index.module.scss'

const titleLabel = defineMessage({
  id: 'modals.sendRequest.title',
  defaultMessage: 'Оставить заявку'
})

const SendRequestView = (props) => {
  const intl = useIntl()
  const {
    errorRecaptcha,
    formik,
    title = intl.formatMessage(titleLabel),
    baseSlug,
    handleChangePhone
  } = props
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    isSubmitting,
    isValid
  } = formik
  const phoneEntered = !!values.phone

  const handleSendMetrik = () => {
    metrik.send('reachGoal', 'ostavit_zayavku')
  }

  const { translate } = useTranslate()

  return (
    <div className={styles.submit_request__container}>
      <Title type={TITLE_TYPE.H2} variant={TITLE_VARIANT.LARGE_MODAL}>
        {title}
      </Title>
      <GxForm onGx-submit={handleSubmit}>
        <div className={styles.submit_request__input}>
          <Input
            name='fullname'
            value={values.fullname || ''}
            label={translate({
              id: 'firstName',
              defineMessage: 'Имя'
            })}
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            error={touched.fullname && !!errors.fullname}
            errorText={translate(errors.fullname)}
          />
        </div>
        <div className={styles.submit_request__input}>
          <InputPhone
            country={values.phoneCountry}
            name='phone'
            label={translate({
              id: 'phone',
              defineMessage: 'Телефон'
            })}
            value={values.phone || ''}
            onGx-blur={handleBlur}
            onGx-change={handleChangePhone}
            error={touched.phone && !!errors.phone}
            errorText={translate(errors.phone)}
            required
          />
        </div>
        <div className={styles.submit_request__input}>
          <Input
            type='email'
            name='email'
            label={translate({
              id: 'email',
              defineMessage: 'Электронная почта'
            })}
            value={values.email || ''}
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            error={touched.email && !!errors.email}
            errorText={translate(errors.email)}
          />
        </div>
        <div className={styles.submit_request__input}>
          <Textarea
            name='message'
            label={translate({
              id: 'message',
              defineMessage: 'Сообщение'
            })}
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            error={touched.message && !!errors.message}
            errorText={translate(errors.message)}
          />
        </div>
        <div className={styles.submit_request__btn}>
          {errorRecaptcha && (
            <p className={styles['text--error']}>{errorRecaptcha}</p>
          )}
          <Button
            type='submit'
            theme={BUTTON_THEME.FILL_ICON.LANDING}
            disabled={isSubmitting || !(isValid && phoneEntered)}
            onClick={handleSendMetrik}
          >
            <FormattedMessage
              id='modals.sendRequest.acceptButton'
              defaultMessage='Отправить сообщение'
            />
          </Button>
        </div>
        <div className={styles.submit_request__note}>
          <FormattedMessage
            id='modals.sendRequest.privacyPolicy'
            defaultMessage='Нажимая на кнопку «Отправить», я соглашаюсь с {br} <a>политикой конфиденциальности сайта</a>'
            values={{
              br: <br />,
              a: chunks => (
                <Link
                  target={LINK_TARGET.BLANK}
                  rel='noopener noreferrer'
                  to={baseSlug.pagePrivacyPolicy}
                >
                  {chunks}
                </Link>
              )
            }}
          />
        </div>
      </GxForm>
    </div>
  )
}

export default SendRequestView
