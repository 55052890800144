import React from 'react'
import Input from '../Input'
import { replaceSymbols } from '../../utils'

const InputWithoutSymbols = React.forwardRef((props, ref) => {
  const handleChange = (e) => {
    e.target.value = replaceSymbols(e.target.value)
    return props['onGx-input'] ? props['onGx-input'](e) : e
  }
  return <Input {...props} onGx-input={handleChange} ref={ref} />
})

export default InputWithoutSymbols
