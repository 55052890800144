import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config.js'

class KeycloackApiService extends Api {
  setKeycloackCode = async (code) => {
    const request = await this
      .post('/keyclaok/get_keycloak_token/', { code })
    this.setAuthToken(request.data.access_token)
    this.setRefreshToken(request.data.refresh_token)    
    return request
  }
}

export default new KeycloackApiService(runtimeConfig.API_URL)
