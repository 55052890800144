import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'
import { getValidateText } from '../ValidateText'
import CargoSchema from './CargoSchema'

const CargoesSchema = yup.array().of(
  CargoSchema.shape({
    count: yup
      .number()
      .integer(INTL_ERROR_MESSAGE.INTEGER)
      .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
      .min(1, getValidateText('number', 'min', 1))
      .required(INTL_ERROR_MESSAGE.REQUIRED)
  })
)

const CargoProjectSchema = yup.object().shape({
  groups: yup.array().of(
    yup.object().shape({
      cargoes: CargoesSchema
    })
  )
})

export default CargoProjectSchema
