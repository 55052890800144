import * as yup from 'yup'
import indentationSchema from '../indentationSchema'
import cargoSpaceSchema from '../cargoSpaceSchema'
import titleSchema from '../TitleSchema'

const ContainerSchema = yup.object().shape({
  ...titleSchema,
  ...indentationSchema,
  ...cargoSpaceSchema
})

export default ContainerSchema
