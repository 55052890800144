import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import PageMenuDropdown from '../../../components/PageMenuDropdown'
import TableData from '../../../components/TableData'
import style from '../styles/index.module.scss'

const DesktopTableCard = ({
  item,
  handleClick,
  handleContextMenu,
  type,
  results,
  indexRow,
  ...props
}) => {
  const {
    icons,
    reload,
    loadData,
    path,
    headers,
    actions,
    activePage,
    handleCheckID,
    checkID,
    handleResetCheck,
    company
  } = props

  const actionsCargoSpace = actions[item.cargo_space_type] || actions
  const iconType = item.cargo_space_type ? item.cargo_space_type : item.cargo_type

  return (
    <tr
      id={item.id}
      data-cy={`${type}-update-link`}
      className={style.table_row}
      onClick={handleClick}
      onContextMenu={handleContextMenu}
    >
      {headers.map(({ ordering, title }, index) => {
        return (
          <TableData
            key={index}
            company={company}
            ordering={ordering}
            cargo_space_type={item.cargo_space_type}
            item={item}
            path={path}
            type={type}
            title={title}
            handleCheckID={handleCheckID}
            checkID={checkID}
            indexRow={indexRow}
            icon={
              <GxIcon src={icons[iconType]} className={style.cargo_space__icon} />
            }
          />
        )
      })}
      <td className={style.table_data__item} data-type='dropdown'>
        <PageMenuDropdown
          reload={reload}
          loadData={loadData}
          type={type}
          actions={actionsCargoSpace}
          elementId={item.id}
          element={item}
          results={results}
          activePage={activePage}
          reset={handleResetCheck}
        />
      </td>
    </tr>
  )
}
export default DesktopTableCard
