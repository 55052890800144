import React from 'react'
import GradientLineView from '../../../views/LandingView/GradientLineView'

/**
 * @component
 * * Компонент в котором мы добовляем/реализуем логику для вью
 *
 * @param {Object} props Данные, нужные для текущей комп
 */

const GradientLine = (props) => <GradientLineView {...props} />
export default GradientLine
