import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Pagination } from 'swiper/core'
import { GxIcon } from '@garpix/garpix-web-components-react'

import Button from '../../../../views/Button'

import { leftIcon, rightIcon } from '../../../../images'
import { BUTTON_THEME } from '../../../../const'

import styles from './styles/index.module.scss'

SwiperCore.use([Navigation, Pagination])

const ImageGallery = ({ imageList, text, title }) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  return (
    <div className={styles['image-gallery']}>
      {Boolean(title) && <h3 className={styles['image-gallery__title']}>{title}</h3>}
      {Boolean(text) && <p className={styles['image-gallery__text']}>{text}</p>}
      <Swiper
        className={styles['image-gallery__slider']}
        slidesPerView={1}
        spaceBetween={20}
        loop
        touchEventsTarget='wrapper'
        pagination={{
          type: 'fraction'
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
        }}
        breakpoints={{
          750: {
            slidesPerView: 2
          }
        }}
      >
        {imageList?.length > 0 &&
          imageList.map(({ id, image }) => (
            <SwiperSlide className={styles['image-gallery__slide']} key={id}>
              <img
                src={image}
                alt='gallery'
                width='600'
                height='350'
                loading='lazy'
              />
            </SwiperSlide>
          ))}
        <div className={styles['image-gallery__navigate']} slot='container-end'>
          <Button theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT} ref={prevRef}>
            <GxIcon src={leftIcon} />
          </Button>
          <Button theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT} ref={nextRef}>
            <GxIcon src={rightIcon} />
          </Button>
        </div>
      </Swiper>
    </div>
  )
}

export default ImageGallery
