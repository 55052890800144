import { useEffect, useState } from 'react'
import { debounce } from '../utils'

const useWindowHeight = () => {
  const [height, setHeight] = useState(window.innerHeight)
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      setHeight(window.innerHeight)
    }, 300)
    window.addEventListener('resize', debouncedResizeHandler)
    return () => window.removeEventListener('resize', debouncedResizeHandler)
  }, [])
  return height
}

export default useWindowHeight
