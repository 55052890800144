import { useMutation, useQueryClient } from 'react-query'
import { notifyApiService } from '../../../../api/services'
import { MENU_NOTIFICATIONS_QK, NOTIFICATIONS_PAGE_QK, UNREAD_NOTIFICATIONS_QK } from '../../const'

const useReadNotification = () => {
  const queryClient = useQueryClient()
  const readNotification = useMutation({
    mutationFn: notifyApiService.setReadNotify,
    onMutate: async (id) => {
      const prevNotifications = queryClient.getQueryData(MENU_NOTIFICATIONS_QK)
      const prevCount = queryClient.getQueryData(UNREAD_NOTIFICATIONS_QK)
      const prevNotificationsPage = queryClient.getQueriesData(NOTIFICATIONS_PAGE_QK)

      prevNotifications && queryClient.setQueryData(MENU_NOTIFICATIONS_QK, (prevNotifications) => {
        return {
          ...prevNotifications,
          results: prevNotifications.results.map((el) => (el.id === id ? { ...el, isRead: true } : el))
        }
      })
      queryClient.setQueriesData(NOTIFICATIONS_PAGE_QK, prevNotifications => {
        return {
          ...prevNotifications,
          pages: prevNotifications.pages.map((page) => ({
            ...page,
            results: page.results.map((el) => (el.id === id ? { ...el, isRead: true } : el))
          }))
        }
      })
      prevCount && queryClient.setQueryData(UNREAD_NOTIFICATIONS_QK, (prevData) => {
        return {
          ...prevData,
          count: prevData.count - 1
        }
      })
      return { prevNotifications, prevCount, prevNotificationsPage }
    },
    // eslint-disable-next-line node/handle-callback-err
    onError: (err, data, context) => {
      queryClient.setQueryData(MENU_NOTIFICATIONS_QK, context.prevNotifications)
      queryClient.setQueryData(UNREAD_NOTIFICATIONS_QK, context.prevCount)
      queryClient.setQueriesData(...context.prevNotificationsPage[0])
    }
  })
  return readNotification
}

export default useReadNotification
