import React from 'react'
import styles from './styles/index.module.scss'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { crown } from '../../../../images'

const BaneCurrentTariff = () => {
  return (
    <div className={styles.bane_tariff__root}>
      <div className={styles.bane_tariff__head}>
        <GxIcon src={crown} />
        Ваш тариф:
      </div>
      <div className={styles.bane_tariff__title}>
        Не активен
      </div>
      <p className={styles.bane_tariff__description}>
        Для активации аккаунта обратитесь к администратору или владельцу компании
      </p>
    </div>
  )
}

export default BaneCurrentTariff
