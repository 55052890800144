import React from 'react'
import classNames from 'classnames'
import style from '../styles/index.module.scss'

const TableData = ({
  children,
  className = '',
  dataLabel,
  where = '',
  indexRow = 0
}) => {
  return (
    <td
      data-label={dataLabel}
      className={classNames(
        style.table_data__item,
        !!where && style[`table_data__item-${where}`],
        className
      )}
      style={{ animationDelay: `${(0.03 * indexRow).toFixed(2)}s` }}
    >
      {children}
    </td>
  )
}

export default TableData
