import React from 'react'
import style from './styles/index.module.scss'
import { GxPhoneInput } from '@garpix/garpix-web-components-react'

const InputPhone = ({ country, errorText, error, theme = 'input__input-phone', ...props }) => {
  return (
    <>
      <GxPhoneInput
        country={country || 'ru'}
        className={style[theme]}
        error={error}
        {...props}
      >
        <div className={style.error_text} slot='help-text'>
          {errorText}
        </div>
      </GxPhoneInput>
    </>
  )
}

export default InputPhone
