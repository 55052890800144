import React from 'react'
import ModalView from '../../../ModalView'
import styles from '../styles/index.module.scss'
import { BUTTON_THEME } from '../../../../const'
import ModalSelectComponent from '../../../../components/ModalSelectComponent'
import { FormattedMessage, useIntl } from 'react-intl'

const ChangeCompanyOwner = (props) => {
  const {
    handleAccept,
    handleClose,
    selectProps
  } = props
  const intl = useIntl()
  return (
    <>
      <ModalView.Icon.Move />
      <ModalView.Title>
        <FormattedMessage
          id='modals.changeCompanyOwner.title'
          defaultMessage='Сменить владельца компании'
        />
      </ModalView.Title>
      <ModalSelectComponent
        {...selectProps}
        label={intl.formatMessage({
          id: 'modals.changeCompanyOwner.selectLabel',
          defaultMessage: 'Новый владелец'
        })}
      />
      <div className={styles.delete_staff__warning}>
        <FormattedMessage
          id='modals.changeCompanyOwner.warningMessage'
          defaultMessage='После смены владельца вы станете администратором компании'
        />
      </div>
      <ModalView.Buttons>
        <ModalView.Buttons.Button onClick={handleClose} theme={BUTTON_THEME.STROKE_ICON.SMALL}>
          <FormattedMessage
            id='cancel'
            defaultMessage='Отмена'
          />
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button
          onClick={handleAccept}
        >
          <FormattedMessage
            id='modals.changeCompanyOwner.buttonAccept'
            defaultMessage='Сменить'
          />
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </>
  )
}

export default ChangeCompanyOwner
