import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { userProfile, camera } from '../../images'

import style from './style/index.module.scss'

const PhotoChange = ({
  handelOpenChangePhoto,
  defPhoto = userProfile,
  photo = null
}) => {
  return (
    <div onClick={handelOpenChangePhoto} className={style.change__img}>
      <img
        src={photo || defPhoto}
        className={style.change__img_user}
        alt='user icon'
        loading='lazy'
        height='200'
        width='200'
      />
      <div className={style.change__blur} />
      <GxIcon
        className={style.change__camera}
        src={camera}
      />
    </div>
  )
}

export default PhotoChange
