import { useMutation, useQueryClient } from 'react-query'
import { companyApiService } from '../../../../api/services'
import useModalStore from '../../../../hooks/Modals/useModalStore'
import { useModalIntlContent } from '../../../../hooks/Modals'
import { convertError } from '../../../../utils'
import { COMPANY_QUERY } from '../../const'
import { useLocation } from 'react-router-dom'

const useDeleteUser = () => {
  const queryClient = useQueryClient()
  const { contentTypes, openModal } = useModalStore()
  const { pathname } = useLocation()
  const modalSuccessContent = useModalIntlContent(contentTypes.successDeleteStaff)
  const modalWarningContent = useModalIntlContent(contentTypes.warning)
  const mutation = useMutation({
    mutationFn: companyApiService.deleteUser,
    onError: (e) => {
      const error = convertError(e)
      openModal('commonConfirm', { ...modalWarningContent, description: error })
    },
    onSuccess: () => {
      queryClient.setQueryData(['page', pathname], prev => {
        const usersCount = prev.page.init_state.users_count
        return {
          ...prev,
          page: {
            ...prev.page,
            init_state: {
              ...prev.page.init_state,
              users_count: usersCount - 1
            }
          }
        }
      })
      openModal('commonConfirm', modalSuccessContent)
    },
    onSettled: () => queryClient.invalidateQueries([COMPANY_QUERY.STAFF_LIST])
  })
  return mutation
}

export default useDeleteUser
