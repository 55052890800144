import React from 'react'
import ModalView from '../../../ModalView'
import styles from '../styles/index.module.scss'
import { useIntl } from 'react-intl'

const UserInCompany = ({ email }) => {
  const intl = useIntl()
  return (
    <>
      <ModalView.Icon.Warning />
      <ModalView.Title>
        {
          intl.formatMessage({
            id: 'modals.userInCompany.title',
            defaultMessage: 'Пользователь с почтой <underline>{email}</underline> уже принадлежит компании.'
          }, {
            underline: chunks => <span className={styles['text--underline']}>{chunks}</span>,
            email
          })
        }
      </ModalView.Title>
    </>
  )
}

export default UserInCompany
