/* eslint-disable camelcase */
import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import { doneIcon, okIcon } from '../../images'
import Button from '../Button'
import { BUTTON_THEME, TYPE_PROMOTION } from '../../const'
import style from './styles/index.module.scss'
import { formattingDate, formattingPrice } from '../../utils'

const Tariff = ({ tariff, pressId, onClick, active }) => {
  const { title, price, advantages, marketing, promocode_info } = tariff
  return (
    <Button
      data-cy={tariff?.is_free ? 'free-tariff-button' : 'tariff-button'}
      onClick={() => onClick(tariff)}
      theme={BUTTON_THEME.TARIFFS_ITEM}
      active={pressId === tariff.id}
      className={style.tariffs__item}
    >
      {promocode_info?.type_promotion &&
        <div className={style.tariffs__promotion}>
          <div className={style.tariffs__promotion_title}>
            <GxIcon src={okIcon} className={style.tariffs__promotion_icon} />
            <p className={style.tariffs__promotion_label}>Промокод</p>
          </div>
          {promocode_info?.promocode_finish_at &&
            <div className={style.tariffs__promotion_date}>
              <p className={style['tariffs__promotion_label-date']}> {formattingDate(promocode_info.promocode_finish_at)}</p>
            </div>}
        </div>}
      {title && <div className={classNames({ [style.tariffs__title]: true, [style['tariffs__title-active']]: title && active })}>{title}</div>}
      {promocode_info?.marketing_promotion && promocode_info?.type_promotion === TYPE_PROMOTION.PERIOD &&
        <div className={style['tariffs__marketing-promotion']}>
          <p className={classNames({ [style['tariffs__marketing-promotion_label']]: true, [style['tariffs__marketing-promotion_label-active']]: active })}>{promocode_info.marketing_promotion}</p>
        </div>}
      {
        price &&
          <div className={style.tariffs__price_block}>
            <p className={classNames({ [style.tariffs__price]: true, [style['tariffs__price-line']]: tariff?.promocode_info?.price_promotion, [style['tariffs__price-line-active']]: tariff?.promocode_info?.price_promotion && active })}>{formattingPrice(price) === '0' ? 'Бесплатно' : `${formattingPrice(price)} ₽`}</p>
          </div>
      }
      {
        promocode_info?.price_promotion && promocode_info?.type_promotion === TYPE_PROMOTION.PERCENT &&
          <div className={style['tariffs__price-promotion']}>
            <p className={classNames({ [style['tariffs__price-promotion_label']]: true, [style['tariffs__price-promotion_label-active']]: active })}>{formattingPrice(promocode_info.price_promotion)} ₽</p>
          </div>
      }
      {marketing ? <p className={style.tariffs__marketing}>{marketing}</p> : null}
      <div slot='icon-right'>
        {advantages && advantages.length > 0 && (
          <ul className={style.tariffs__item_desc_ul}>
            {advantages.map((advantage, index) => (
              <li key={index} className={style.tariffs__item_desc_li}>
                <div className={style.tariffs__item_desc_icon_wrap}>
                  <GxIcon active={pressId === tariff.id} src={doneIcon} className={style.tariffs__item_desc_icon} />
                </div>
                {advantage.description}
              </li>
            ))}
          </ul>
        )}
      </div>
    </Button>
  )
}

export default Tariff
