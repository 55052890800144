import qs from 'query-string'
import { getQueryString } from '../../../../utils'
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'

const useChangeTab = () => {
  const history = useHistory()
  const handleChangeTab = useCallback(
    (activeTab) => {
      const params = qs.parse(history.location.search)
      if (activeTab.type) {
        params.type_event = activeTab.type
      } else {
        delete params.type_event
      }
      const search = getQueryString(params)
      history.replace({ search })
    },
    [history.location.search]
  )
  return handleChangeTab
}

export default useChangeTab
