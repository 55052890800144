/* eslint-disable camelcase */
import React from 'react'
import ToPreservedHtml from '../ToPreservedHtml/ToPreservedHtml'
import style from './styles/index.module.scss'
import ActionButton from '../LandingView/ActionButton'
import { BUTTON_THEME } from '../../const'

const BannerView = ({ content, handleOpenModal, count }) => {
  const { text, action_button, action_button2 } = content
  return (
    <div data-count={count} className={style.banner__root}>
      <ToPreservedHtml html={text} />
      {(action_button || action_button2) && (
        <div className={style.banner__btns}>
          {action_button && (
            <ActionButton
              actionButton={action_button}
              theme={BUTTON_THEME.FILL_ICON.ORANGE}
              handleOpenModal={handleOpenModal}
              className={style.banner__btn}
            />
          )}
          {action_button2 && (
            <ActionButton
              actionButton={action_button2}
              theme={BUTTON_THEME.FILL_ICON.ORANGE}
              handleOpenModal={handleOpenModal}
              className={style.banner__btn}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default BannerView
