import { useQuery } from 'react-query'
import { notifyApiService } from '../../../../api/services'
import { UNREAD_NOTIFICATIONS_QK } from '../../const'

const useFetchUnreadNotifications = () => {
  return useQuery(
    UNREAD_NOTIFICATIONS_QK,
    () => notifyApiService.getNotify({ is_read: false })
  )
}

export default useFetchUnreadNotifications
