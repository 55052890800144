import React from 'react'
import Download from '../Download'
import Load from '../../components/Load'
import AddComponent from '../../components/AddComponent'
import style from './styles/index.module.scss'
import classNames from 'classnames'
import {
  cargoSpaceApiService,
  projectApiService,
  cargoApiService
} from '../../api/services'
import { useIntl } from 'react-intl'

const paramsType = {
  project: {
    text: {
      id: 'createProject',
      defaultMessage: 'Создать проект'
    },
    upload: projectApiService.loadProjectExample
  },
  cargo_space: {
    text: {
      id: 'newSpace',
      defaultMessage: 'Новое пространство'
    },
    upload: cargoSpaceApiService.loadCargoSpaceExample
  },
  cargo: {
    text: {
      id: 'addCargo',
      defaultMessage: 'Добавить груз'
    },
    upload: cargoApiService.loadCargoExample
  }
}

const Buttons = ({ type, handleModalSocket }) => {
  const intl = useIntl()
  return (
    <>
      <div className={style.button_wrapper}>
        <Download handleUpload={paramsType[type].upload} />
      </div>
      <div className={style.button_wrapper}>
        <Load
          type={type}
          handleModalSocket={handleModalSocket}
        />
      </div>
      <div
        className={classNames(style.button_wrapper, style.button_fixed)}
      >
        <AddComponent
          addButtonText={intl.formatMessage(paramsType[type].text)}
          type={type}
          style={style.button_add}
        />
      </div>
    </>
  )
}

export default Buttons
