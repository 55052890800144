import React from 'react'
import dayjs from 'dayjs'
import { emptyIcon } from '../../../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from './styles/index.module.scss'

const NewsCard = (props) => {
  const {
    absolute_url: absoluteUrl,
    created_at: createdAt,
    image_preview: imagePreview,
    subtitle,
    title,
    theme = 'card_blog'
  } = props
  const date = dayjs(createdAt).format('DD.MM.YYYY')
  return (
    <a href={absoluteUrl} className={styles[`${theme}__root`]}>
      <div className={styles[`${theme}__img`]}>
        {
          imagePreview
            ? <img src={imagePreview} alt='news image' loading='lazy' />
            : <div className={styles[`${theme}__img_empty`]}><GxIcon src={emptyIcon} alt='empty icon' /></div>
        }
      </div>
      <div className={styles[`${theme}__info`]}>
        <div className={styles[`${theme}__info_title`]}>
          {title}
        </div>
        <p className={styles[`${theme}__info_description`]}>{subtitle && subtitle}</p>
        <div className={styles[`${theme}__info_footer`]}>
          <div className={styles[`${theme}__info_date`]}>{date}</div>
        </div>
      </div>
    </a>
  )
}

export default NewsCard
