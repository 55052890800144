import React, { useEffect } from 'react'

import usePage from '../../../hooks/usePage'

import { ALLOWED_PAGE_MODELS, APP_ENVIRONMENT } from '../../../const'
import { runtimeConfig } from '../../../config'

const withRoisat = (Component) => (props) => {
  const { page } = usePage()
  const isProd = runtimeConfig.RAZZLE_APP_ENVIRONMENT === APP_ENVIRONMENT.PRODUCTION
  const canInitScript = ALLOWED_PAGE_MODELS.includes(page.page_model) && isProd
  const idRoisatScript = 'roisatScript'

  const roisat = `
  (function(w, d, s, h, id) {
    w.roistatProjectId = id; w.roistatHost = h;
    var p = d.location.protocol == "https:" ? "https://" : "http://";
    var u = /^.*roistat_visit=[^;]+(.*)?$/.test(d.cookie) ? "/dist/module.js" : "/api/site/1.0/"+id+"/init?referrer="+encodeURIComponent(d.location.href);
    var js = d.createElement(s); js.charset="UTF-8"; js.async = 1; js.src = p+h+u; var js2 = d.getElementsByTagName(s)[0]; js2.parentNode.insertBefore(js, js2);
})(window, document, 'script', 'cloud.roistat.com', 'bea531cbb96370a04b1553643afea14a');
`

  const initScript = () => {
    const roisatScript = document.querySelector(`#${idRoisatScript}`)
    if (roisatScript !== null) return null

    const scriptTag = document.createElement('script')
    scriptTag.setAttribute('id', idRoisatScript)
    scriptTag.text = roisat
    document.body.appendChild(scriptTag)
    return scriptTag
  }

  useEffect(() => {
    if (canInitScript) {
      initScript()
    }
  }, [])
  return <Component {...props} />
}

export default withRoisat
