import React from 'react'
import style from './styles/index.module.scss'
import classNames from 'classnames'
import { Link as RouterLink } from 'react-router-dom'

const Link = React.forwardRef(({
  children,
  theme = 'fill_icon--small',
  className,
  disabled = false,
  ...props
}, ref) => {
  return (
    <RouterLink
      ref={ref}
      className={classNames({
        [style[theme]]: true,
        [style.disabled]: disabled,
        [className]: !!className
      })}
      {...props}
    >
      {children}
    </RouterLink>
  )
})

export default Link
