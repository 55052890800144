import { useStoreon } from 'storeon/react'
import { MODALS_KEYS } from './modalTypes'

const useModalStore = () => {
  const { dispatch, showModal, modalProps, isUnclosable } = useStoreon('showModal', 'modalProps', 'isUnclosable')
  const isOpen = showModal !== null

  const openModal = (modal, modalProps = {}) => {
    dispatch('modalType/toggle', { modal, modalProps })
  }

  const setUnclosable = (isUnclosable = false) => {
    dispatch('modalType/setUnclosable', { isUnclosable })
  }

  const closeModal = () => {
    if ('handleClose' in modalProps) {
      modalProps.handleClose()
    }
    dispatch('modalType/close')
  }

  return {
    isUnclosable,
    setUnclosable,
    closeModal,
    openModal,
    isOpen,
    showModal,
    modalProps,
    contentTypes: MODALS_KEYS
  }
}

export default useModalStore
