const projectNotificationModule = store => {
    store.on('@init', () => ({ projectNotification: { isStretch: false, type: null, props: null } }))
    store.on('projectNotification/stretch/set', ({ projectNotification }, payload) => {
        return {
            projectNotification: { ...projectNotification, isStretch: payload }
        }
    })
    store.on('projectNotification/type/set', ({ projectNotification }, payload) => {
        return {
            projectNotification: { ...projectNotification, type: payload }
        }
    })
    store.on('projectNotification/props/set', ({ projectNotification }, payload) => {
        return {
            projectNotification: { ...projectNotification, props: payload }
        }
    })
    store.on('projectNotification/close', () => ({
        projectNotification: {
            isStretch: false,
            type: null,
            props: null
        }
    }))
}

export default projectNotificationModule