import React from 'react'
import style from './style/index.module.scss'

/**
 *
 * @view
 * * Вью секции с линией градинта
 *
 * @param {Object} content               обьект с данными нашей вью (с бэка)
 * @param {string} content.line_color_1  свойство цвета (с лева)
 * @param {string} content.line_color_2  свойство цвета (с права)
 */

const GradientLineView = ({ content }) => {
  const theme = {
    '--color-1': content?.line_color_1,
    '--color-2': content?.line_color_2
  }

  return (
    <section style={theme}>
      <div className={style.gradient_line} />
    </section>
  )
}

export default GradientLineView
