import React from 'react'
import Layout from '.'
import HeaderComponent from '../../components/HeaderComponent'
import styles from './styles/index.module.scss'
import TariffBannerComponent from '../../components/TariffBannerComponent'

const Default = ({ children, header = {}, noBanner, dataAttr = '' }) => {
  return (
    <Layout.Seo>
      <HeaderComponent header={header} />
      <main data-cy={dataAttr} className={styles['main--default']}>
        <TariffBannerComponent isShow={!noBanner} />
        {children}
      </main>
    </Layout.Seo>
  )
}
export default Default
