import React from 'react'
import { NavLink } from 'react-router-dom'
import Link from '../Link'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { LINK_THEME } from '../../const'
import usePage from '../../hooks/usePage'
import { exitIcon, leftIcon } from '../../images'
import styles from './styles/index.module.scss'

const ProfileNavigationView = ({ links, handleLogout }) => {
  const { baseSlug } = usePage()
  return (
    <nav className={styles.nav}>
      <ul className={styles.nav_list}>
        <li className={styles.nav_out}>
          <Link theme={LINK_THEME.PROJECT} to={baseSlug.pageProjectsList}>
            <GxIcon src={leftIcon} />
          </Link>
        </li>
        {links &&
          links.length > 0 &&
          links.map(({ icon, title, url, id }) => (
            <li key={id} className={styles.nav_item}>
              <NavLink
                className={(isActive) => isActive ? styles.selected : styles.nav_link}
                to={url}
                data-label={title}
              >
                {icon && (
                  <GxIcon src={icon} className={styles.nav_icon} />
                )}
                {title && (
                  <span className={styles.nav_text}>{title}</span>
                )}
              </NavLink>
            </li>
          ))}
        <li className={styles.nav_logout}>
          <button
            className={styles['nav_logout-button']}
            onClick={handleLogout}
          >
            <GxIcon src={exitIcon} className={styles.nav_icon} />
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default ProfileNavigationView
