import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import { CARDS } from '../../../const'
import ClientsView from '../../../views/LandingView/Clients'

const Clients = (props) => {
  return (
    <SectionHomePageWithButtons id={CARDS.CLIENTS.id} {...props}>
      <ClientsView {...props.content} />
    </SectionHomePageWithButtons>
  )
}

export default Clients
