import { useMemo } from 'react'
import { CALCULATION_STATUS } from '../const'
import { useStoreon } from 'storeon/react'

const useProjectStatus = () => {
  const { project } = useStoreon('project')
  const { calculation_status: calculationStatus } = project
  const isQueueStatus = useMemo(() => calculationStatus === CALCULATION_STATUS.IN_QUEUE, [calculationStatus])
  const isSuccessStatus = useMemo(() => calculationStatus === CALCULATION_STATUS.SUCCESS, [calculationStatus])
  const isDesignedStatus = useMemo(() => calculationStatus === CALCULATION_STATUS.DESIGNED, [calculationStatus])
  const isForRecalculationStatus = useMemo(() => calculationStatus === CALCULATION_STATUS.FOR_RECALCULATION, [calculationStatus])
  const isRecalculationStatus = useMemo(() => calculationStatus === CALCULATION_STATUS.RECALCULATION, [calculationStatus])
  const isCalculationStatus = useMemo(() => calculationStatus === CALCULATION_STATUS.CALCULATING, [calculationStatus])
  const isQueueRecalculation = useMemo(() => calculationStatus === CALCULATION_STATUS.QUEUE_RECALCULATION, [calculationStatus])
  const isIrrelevant = useMemo(() => calculationStatus === CALCULATION_STATUS.IRRELEVANT, [calculationStatus])
  const isNullStatus = useMemo(() => calculationStatus === null, [calculationStatus])
  const isErrorStatus = useMemo(() => calculationStatus === CALCULATION_STATUS.ERROR, [calculationStatus])
  return {
    isQueueStatus,
    isSuccessStatus,
    isDesignedStatus,
    isForRecalculationStatus,
    isRecalculationStatus,
    isCalculationStatus,
    isQueueRecalculation,
    isIrrelevant,
    isNullStatus,
    isErrorStatus
  }
}

export default useProjectStatus
