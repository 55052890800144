import React from 'react'
import { GxButton, GxSpinner } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'

const Button = React.forwardRef(
  (
    {
      children,
      type = 'button',
      theme = 'fill_icon--small',
      className = '',
      isSpinner = false,
      spinnerClass = 'spinner__default',
      href = null,
      target = null,
      ...props
    },
    ref
  ) => {
    return (
      <GxButton
        {...props}
        type={type}
        className={`${style[theme]} ${className}`}
        ref={ref}
        href={href}
        target={target}
      >
        {isSpinner
          ? <GxSpinner className={style[spinnerClass]} />
          : children}
      </GxButton>
    )
  }
)

export default Button
