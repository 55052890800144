import React from 'react'
import SelectViews from '../SelectViews'

const CountrySelect = ({ countries, country, onChange }) => {
  return (
    <SelectViews.Select
      placeholder='Не указано'
      label='Страна'
      name='country'
      value={country.country_name}
      onGx-change={onChange}
    >
      {countries.map((country) => (
        <SelectViews.Item key={country.id} value={country.country_name}>
          {country.country_name}
        </SelectViews.Item>
      ))}
    </SelectViews.Select>
  )
}

export default CountrySelect
