import React from 'react'
import style from './styles/index.module.scss'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { smileIcon } from '../../../../images'
import { useIntl } from 'react-intl'

const noMessages = {
  id: 'youHaveNoUnreadMessages',
  defaultMessages: 'У вас нет непрочитанных сообщений!'
}

const noNotifications = {
  id: 'youHaveNoNotifications',
  defaultMessages: 'У вас пока нет уведомлений!'
}

const EmptyNotificationView = React.memo(({ isShowUnread }) => {
  const intl = useIntl()
  return (
    <div className={style.notification_empty}>
      <div className={style.notification_empty__wrapper}>
        <div className={style.notification_empty__icon}>
          <GxIcon src={smileIcon} />
        </div>
        {
          isShowUnread
            ? intl.formatMessage(noMessages)
            : intl.formatMessage(noNotifications)
        }
      </div>
    </div>
  )
})

export default EmptyNotificationView
