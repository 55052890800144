import React, { useState } from 'react'
import { DeleteStaff } from '../../../views/BaseModal/ModalContent'
import useDeleteUser from '../../../apps/appCompany/hooks/useDeleteUser'
import { useStoreon } from 'storeon/react'
import { avatar24 } from '../../../images'
import { useMutation } from 'react-query'
import { companyApiService } from '../../../api/services'

const DeleteStaffModal = ({ user, handleClose }) => {
  const { currentUser } = useStoreon('currentUser')
  const [moveSpaces, setMoveSpaces] = useState(false)
  const [moveCargoes, setMoveCargoes] = useState(false)
  const [value, setValue] = useState({
    id: currentUser?.company?.user_company_id,
    label: currentUser?.email,
    img: currentUser?.thumbnail || avatar24
  })
  const usersMutation = useMutation({
    mutationFn: companyApiService.getUsers
  })
  const mutation = useDeleteUser()

  const loadOptions = async (q, prevOptions, { page }) => {
    const params = {
      page,
      companyID: currentUser?.company?.id,
      q,
      page_size: 10,
      is_blocked: false
    }
    const { results, next } = await usersMutation.mutateAsync(params)
    const options = results
      .filter(item => item.id !== user.id)
      .map((item) => ({
        id: item.id,
        label: item.user.email,
        img: item.user.thumbnail || avatar24
      }))
    return {
      options,
      hasMore: !!next,
      additional: {
        page: page + 1
      }
    }
  }
  const handleDelete = () => {
    mutation.mutate({
      userID: user.id,
      companyID: user.company,
      params: {
        is_transfer_projects: moveSpaces,
        is_transfer_cargos: moveCargoes,
        new_user: (moveSpaces || moveCargoes) ? value.id : null
      }
    })
  }

  const selectProps = {
    isLoading: usersMutation.isLoading,
    loadOptions,
    value,
    setValue
  }

  const context = {
    moveSpaces,
    moveCargoes,
    setMoveSpaces,
    setMoveCargoes,
    selectProps,
    handleDelete,
    user: user.user,
    handleClose
  }
  return (
    <DeleteStaff {...context} />
  )
}

export default DeleteStaffModal
