import React, { useState } from 'react'
import ProfileComponent from './../../components/ProfileComponent'
import UserCompany from './UserCompany'
import { useStoreon } from 'storeon/react'
import style from './styles/profile.module.scss'
import { COMPANY_ROLE_TYPE } from '../../apps/appCompany/const'
import BaneCurrentTariff from '../../apps/components/views/BaneCurrentTariff'

const ProfilePanel = ({ currentTariff, setCurrentTariff }) => {
  const [showProfileInfo, setShowProfileInfo] = useState(false)
  const { currentUser } = useStoreon('currentUser')
  const isFree = currentUser?.current_tariff?.is_free
  const roleType = currentUser?.company?.role?.role_type
  const showBaneCard = roleType === null ||
      ((roleType === COMPANY_ROLE_TYPE.admin || roleType === COMPANY_ROLE_TYPE.user) && isFree)

  const handelChangeShowBlocks = () => {
    setShowProfileInfo((prev) => !prev)
  }

  return (
    <div className={style.profile}>
      <div data-showprofile={showProfileInfo} className={style.profile__left}>
        <div className={style.profile__left_wrap}>
          <ProfileComponent.UserType handelChangeShowBlocks={handelChangeShowBlocks} />
          {currentUser?.company && <UserCompany userCompany={currentUser.company} isFree={isFree} />}
          {
                showBaneCard
                  ? <BaneCurrentTariff />
                  : <ProfileComponent.UserCurrentTariff
                      currentTariff={currentTariff}
                      setCurrentTariff={setCurrentTariff}
                    />
            }
        </div>
      </div>
      <ProfileComponent.ProfileInfo
        handelChangeShowBlocks={handelChangeShowBlocks}
        showProfileInfo={showProfileInfo}
      />
    </div>
  )
}

export default ProfilePanel
