import React from 'react'
import { GxMenuItem } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'
import classNames from 'classnames'

const Item = ({
  projectVariant = 'item_default',
  dataUnread = false,
  className,
  children,
  ...props
}) => {
  return (
    <GxMenuItem
      className={classNames(style[projectVariant], className)}
      data-unread={dataUnread}
      {...props}
    >
      {children}
    </GxMenuItem>
  )
}

export default Item
