import intl from '../Intl'
export const getValidateText = (value, property, num) => {
  const text = {
    title: {
      min: (num) => intl.formatMessage({
        id: 'schemes.messages.minTitle',
        defaultMessage: 'Длина названия не может быть меньше {num, plural, one {{num} символа} few {{num} символов} many {{num} символов} other {{num} символов}}'
      }, { num }),
      max: (num) => intl.formatMessage({
        id: 'schemes.messages.maxTitle',
        defaultMessage: 'Длина названия не может быть больше {num, plural, one {{num} символа} few {{num} символов} many {{num} символов} other {{num} символов}}'
      }, { num })
    },
    string: {
      min: (num) => intl.formatMessage({
        id: 'schemes.messages.minString',
        defaultMessage: 'Убедитесь, что это значение содержит не менее {num} символов.'
      }, { num }),
      max: (num) => intl.formatMessage({
        id: 'schemes.messages.maxString',
        defaultMessage: 'Убедитесь, что это значение содержит не более {num} символов.'
      }, { num })
    },
    number: {
      min: (num) => intl.formatMessage({
        id: 'schemes.messages.minNumber',
        defaultMessage: 'Значение должно быть больше или равно {num}'
      }, { num }),
      max: (num) => intl.formatMessage({
        id: 'schemes.messages.maxNumber',
        defaultMessage: 'Значение должно быть меньше или равно {num}'
      }, { num })
    },
    phone: {
      min: (num) => intl.formatMessage({
        id: 'schemes.messages.minPhone',
        defaultMessage: 'Убедитесь, что номер телефона содержит не менее {num} цифр.'
      }, { num }),
      max: (num) => intl.formatMessage({
        id: 'schemes.messages.maxPhone',
        defaultMessage: 'Убедитесь, что номер телефона содержит не более {num} цифр.'
      }, { num })
    },
    password: {
      min: (num) => intl.formatMessage({
        id: 'registration.minLength',
        defaultMessage: 'Пароль должен содержать минимум {num, plural, one {{num} символ} few {{num} символа} many {{num} символов} other {{num} символов}}'
      },
      { num }),
      minChar: (num) => intl.formatMessage({
        id: 'registration.minChar',
        defaultMessage: 'Пароль должен содержать минимум {num, plural, one {{num} букву} few {{num} буквы} many {{num} букв} other {{num} букв}}'
      }, { num }),
      minUppercase: (num) => intl.formatMessage({
        id: 'registration.minUppercase',
        defaultMessage: 'Пароль должен содержать минимум {num, plural, one {{num} прописную букву} few {{num} прописные буквы} many {{num} прописных букв} other {{num} прописных букв}}'
      }, { num }),
      minSpecialSymbols: (num) => intl.formatMessage({
        id: 'registration.minSpecialSymbols',
        defaultMessage: 'Пароль должен содержать минимум {num, plural, one {{num} спец. символ} few {{num} спец. символа} many {{num} спец. символов} other {{num} спец. символов}}'
      }, { num }),
      minDigits: (num) => intl.formatMessage({
        id: 'registration.minDigits',
        defaultMessage: 'Пароль должен содержать минимум {num, plural, one {{num} цифру} few {{num} цифры} many {{num} цифр} other {{num} цифр}}'
      }, { num })
    }
  }
  return text[value][property](num)
}
