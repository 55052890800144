import React, { useState } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import useTranslate from '../../hooks/useTranslate'
import { ANIMATION_TIME } from '../../const'
import { DropdownIndicator, LoadingIndicator } from './SelectLoaders'
import customStyles, { animationSelectMenuStyles } from './customStyles'

import style from './select.module.scss'

export const BaseSelect = ({
  isLoading = true,
  errorStyle = 'error_text',
  errorText = '',
  required,
  value,
  error,
  label = '',
  theme = 'select_default',
  additional,
  isCreatable,
  loadOptions,
  placeholder,
  isSearchable = false,
  styles = {},
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isStartCloseAnimation, setIsStartCloseAnimation] = useState(false)
  const { translate, intl } = useTranslate()
  /**
   * @func openMenuHandler
   * * метод для открытия селекта
   */
  const openMenuHandler = () => {
    setIsOpen(true)
  }
  /**
   * @func closeMenuHandler
   * * метод для закрытия селекта
   * ! с анимацией
   */
  const closeMenuHandler = () => {
    setIsStartCloseAnimation(true)
    setTimeout(() => {
      setIsOpen(false)
      setIsStartCloseAnimation(false)
    }, ANIMATION_TIME.ACCORDION)
  }

  return (
    <>
      {label && (
        <label required={required} className={style[`${theme}_label`]}>
          {label}
        </label>
      )}
      <AsyncPaginate
        onMenuOpen={openMenuHandler}
        onMenuClose={closeMenuHandler}
        menuIsOpen={isOpen}
        isCloseAnimation={isStartCloseAnimation}
        debounceTimeout={500}
        placeholder={placeholder}
        className={style[theme]}
        styles={
          error
            ? {
                ...customStyles,
                ...styles,
                ...animationSelectMenuStyles,
                control: (base) => ({ ...base, border: '1px solid red', minHeight: '30px' })
              }
            : {
                ...customStyles,
                ...styles,
                ...animationSelectMenuStyles
              }
        }
        components={{ DropdownIndicator }}
        additional={additional}
        loadOptions={loadOptions}
        isLoading={isLoading}
        isSearchable={isSearchable}
        isCreatable={isCreatable}
        value={value?.label ? value : null}
        loadingMessage={LoadingIndicator}
        noOptionsMessage={() => intl.formatMessage({
          id: 'noResults',
          defaultMessage: 'Нет результатов'
        })}
        {...props}
      />
      {error && (
        <div className={style[errorStyle]} slot='help-text'>
          {translate(errorText)}
        </div>
      )}
    </>
  )
}
