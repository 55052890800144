/* eslint-disable camelcase */
import React from 'react'
import SuitabilityCard from '../../SuitabilityCard'
import styles from './styles/index.module.scss'

const SuitabilityList = ({ content }) => {
  const { target_audience_elements } = content

  return (
    <div className={styles.suitability}>
      {target_audience_elements && target_audience_elements.length > 0 && (
        <ul className={styles.suitability_list}>
          {target_audience_elements.map((el) => (
            <SuitabilityCard
              key={el.id}
              title={el.text_title}
              description={el.text}
              image={el.image}
              type='li'
            />
          ))}
        </ul>
      )}
    </div>
  )
}

export default SuitabilityList
