import React from 'react'
import { BUTTON_THEME } from './../../../../const'
import ModalView from '../../../ModalView'
import { GxForm, GxIcon, GxSpinner } from '@garpix/garpix-web-components-react'
import Input from '../../../Input'
import { closeSmallIcon, doneIcon } from '../../../../images'
import styles from '../styles/index.module.scss'
import SelectView from '../../../SelectViews'
import classNames from 'classnames'
import Tooltip from '../../../Tooltip'
import { FormattedMessage } from 'react-intl'
import useTranslate from '../../../../hooks/useTranslate'

const AddStaff = (props) => {
  const {
    isExist,
    isEmailLoading,
    roles,
    handleClose,
    formik
  } = props

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    values,
    errors,
    isSubmitting,
    isValid,
    dirty
  } = formik
  const { translate, intl } = useTranslate()
  const showSpinner = isSubmitting || isEmailLoading
  const disabledButton = showSpinner || (!isValid && dirty)

  return (
    <GxForm className={styles.add_staff} onGx-submit={handleSubmit}>
      <ModalView.Title>
        <FormattedMessage
          id='addEmployee'
          defaultMessage='Добавить сотрудника'
        />
      </ModalView.Title>
      <div className={styles.add_staff__field}>
        <Input
          name='email'
          label={intl.formatMessage({
            id: 'modals.addStaff.emailLabel',
            defaultMessage: 'Почта сотрудника'
          })}
          value={values.email}
          error={touched.email && !!errors.email}
          errorText={translate(errors.email)}
          onGx-input={handleChange}
          onGx-blur={handleBlur}
          required
        >
          {
            isEmailLoading && (
              <GxSpinner
                slot='suffix'
                className={classNames(styles.add_staff__icon, styles['add_staff__icon--fetching'])}
              />
            )
          }
          {
            !isEmailLoading && isExist && (
              <Tooltip
                slot='suffix'
                content={intl.formatMessage({
                  id: 'userRegistered',
                  defaultMessage: 'Пользователь зарегистрирован'
                })}
                hoist
              >
                <GxIcon
                  src={doneIcon}
                  className={classNames(styles.add_staff__icon, styles['add_staff__icon--success'])}
                />
              </Tooltip>
            )
          }
          {
            !isEmailLoading && isExist === false && (
              <Tooltip
                slot='suffix'
                content={intl.formatMessage({
                  id: 'userNotRegistered',
                  defaultMessage: 'Пользователь не зарегистрирован'
                })}
                hoist
              >
                <GxIcon
                  src={closeSmallIcon}
                  className={classNames(styles.add_staff__icon, styles['add_staff__icon--error'])}
                />
              </Tooltip>
            )
          }
        </Input>
      </div>
      <div className={styles.add_staff__field}>
        <SelectView.Select
          label={intl.formatMessage({
            id: 'modals.addStaff.roleLabel',
            defaultMessage: 'Роль'
          })}
          name='role'
          value={values.role}
          onGx-change={handleChange}
          required
        >
          {
            roles.map(role => {
              return (
                <SelectView.Item key={role.id} value={role.id}>
                  {role.title}
                </SelectView.Item>
              )
            })
          }
        </SelectView.Select>
      </div>
      {
            isExist === false && (
              <>
                <div className={styles.add_staff__field}>
                  <Input
                    name='name'
                    label={intl.formatMessage({
                      id: 'modals.addStaff.nameLabel',
                      defaultMessage: 'Имя сотрудника'
                    })}
                    value={values.name}
                    error={touched.name && !!errors.name}
                    errorText={translate(errors.name)}
                    onGx-input={handleChange}
                    onGx-blur={handleBlur}
                    required
                  />
                </div>
                <div className={styles.add_staff__field}>
                  <Input
                    name='surname'
                    label={intl.formatMessage({
                      id: 'modals.addStaff.surnameLabel',
                      defaultMessage: 'Фамилия сотрудника'
                    })}
                    value={values.surname}
                    error={touched.surname && !!errors.surname}
                    errorText={translate(errors.surname)}
                    onGx-input={handleChange}
                    onGx-blur={handleBlur}
                    required
                  />
                </div>
              </>
            )
        }
      <ModalView.Buttons>
        <ModalView.Buttons.Button theme={BUTTON_THEME.STROKE_ICON.SMALL} onClick={handleClose}>
          <FormattedMessage
            id='cancel'
            defaultMessage='Отмена'
          />
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button
          data-cy='project-add-submit-button'
          type='submit'
          theme={BUTTON_THEME.FILL_ICON.SMALL}
          isSpinner={showSpinner}
          disabled={disabledButton}
        >
          <FormattedMessage
            id='modals.addStaff.acceptButton'
            defaultMessage='Отправить приглашение'
          />
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </GxForm>
  )
}

export default AddStaff
