/* eslint-disable camelcase */
import React from 'react'
import ToPreservedHtml from '../../ToPreservedHtml/ToPreservedHtml'
import PartnersProgramCard from './PartnersProgramCard'
import style from './style/index.module.scss'

const PartnersProgram = ({ content }) => {
  const { partners_program_elements, text, image } = content
  return (
    <div className={style['partner-program']}>
      <div className={style['partner-program__card']}>
        <div className={style['partner-program__text-content']}>
          {partners_program_elements && partners_program_elements.length > 0 &&
            partners_program_elements.map((item) => (
              <PartnersProgramCard item={item} key={item?.id} />
            ))}
        </div>
        {image &&
          <div className={style['partner-program__card_image-block']}>
            <img src={image} alt='Партнерство' className={style['partner-program__card_image']} loading='lazy' width={400} height={400} />
          </div>}
      </div>
      {text && <ToPreservedHtml html={text} className={style['partner-program__content']} />}
    </div>
  )
}

export default PartnersProgram
