import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import FAQView from '../../../views/LandingView/FAQView'
import { CARDS } from '../../../const'

const FAQComponet = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} id={CARDS.FAQ.id} {...props}>
      <FAQView {...props.content} />
    </SectionHomePageWithButtons>
  )
}

export default FAQComponet
