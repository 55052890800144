import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import Button from '../../../Button'
import { warningIcon } from '../../../../images'
import style from '../styles/index.module.scss'

const Confirm = ({
  handleClose,
  handleAccept,
  title,
  description,
  close,
  accept
}) => {
  return (
    <div className={style['modal-confirm']}>
      <GxIcon
        src={warningIcon}
        className={style['modal-confirm__icon']}
      />
      <h2 className={style['modal-confirm__title']}>
        {title}
      </h2>
      <p className={style['modal-confirm__description']}>
        {description}
      </p>
      <div
        className={style['modal-confirm__wrapper-button']}
      >
        <Button
          onClick={handleClose}
          theme='stroke_icon--small'
        >
          {close}
        </Button>
        <Button
          onClick={handleAccept}
          theme='fill_icon--small'
        >
          {accept}
        </Button>
      </div>
    </div>
  )
}

export default Confirm
