import React from 'react'
import styles from './styles/index.module.scss'

const PartnersCard = ({ data }) => {
  const { image, text } = data
  return (
    <div className={styles.partners_item}>
      {image && (
        <div className={styles.partners_image}>
          <img width='100' height='100' src={image} alt={text} loading='lazy' />
        </div>
      )}
      {text && <div className={styles.partners_description}>{text}</div>}
    </div>
  )
}

export default PartnersCard
