import React, { useMemo, useState } from 'react'
import usePage from '../../../hooks/usePage'
import { setCookie, getCookie } from '../../../utils'

import { ANIMATION_TIME, PAGE_MODELS } from '../../../const'
import { COOKIES_CONSENT_CLOSED } from '../../const'

import UseCookiesAlertView from '../views/UseCookiesAlertView'
/**
 * @component
 * * компонент диалогового окна с куками.
 * * пока просто заглушка
 */
const UseCookiesAlert = () => {
  const [isOpen, setIsOpen] = useState(true)
  const showAlert = useMemo(() => !getCookie(COOKIES_CONSENT_CLOSED), [])

  const { page } = usePage()
  const isReportVisualPage = page.page_model === PAGE_MODELS.VISUAL

  const handleAccess = () => {
    setIsOpen(false)
    setTimeout(() => {
      setCookie(COOKIES_CONSENT_CLOSED, true, 365)
    }, ANIMATION_TIME.ACCORDION)
  }

  if (isReportVisualPage || !showAlert) {
    return null
  }

  return <UseCookiesAlertView handleAccess={handleAccess} isOpen={isOpen} />
}

export default UseCookiesAlert
