import React from 'react'
import style from './style/index.module.scss'
import cn from 'classnames'
import useTranslate from '../../../../hooks/useTranslate'

const TabelText = ({ hasInfinityIcon = null, msg = '' }) => {
  const { translate } = useTranslate()
  return (
    <div className={cn({
      [style.table_text]: true,
      [style.table_text__icon_inf]: hasInfinityIcon
    })}
    >
      {translate(msg)}
    </div>
  )
}

export default TabelText
