import React, { useEffect } from 'react'
import { useModalStore } from '../../../hooks/Modals'
import { SOCKET_EVENTS } from '../../../const'
import { useSocket } from '../../hooks'

const withExpirationTariff = Component => props => {
  const { openModal } = useModalStore()
  const { socket } = useSocket()
  useEffect(() => {
    if (socket) {
      const listener = () => {
        return openModal('expirationTariff')
      }
      socket.subscribe(SOCKET_EVENTS.EXPIRATION_TARIFF, listener)
      return () => socket.unsubscribe(SOCKET_EVENTS.EXPIRATION_TARIFF, listener)
    }
  }, [socket])
  return <Component {...props} />
}

export default withExpirationTariff
