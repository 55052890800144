import * as yup from 'yup'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../../const'
import { getValidateText } from '../../ValidateText'
import indentationSchema from '../indentationSchema'
import cargoSpaceSchema from '../cargoSpaceSchema'
import titleSchema from '../TitleSchema'

const VanSchema = yup.object().shape({
  ...titleSchema,
  ...indentationSchema,
  ...cargoSpaceSchema,
  full_length: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .test(
      'min',
      INTL_ERROR_MESSAGE.ERROR_FULL_LENGTH,
      function (value) {
        return !value || value >= this.parent.length
      }
    )
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  axles: yup.array().of(
    yup.object().shape({
      distance: yup
        .number()
        .integer(INTL_ERROR_MESSAGE.INTEGER)
        .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
        .min(ERROR_NUMBERS.VAN.MIN_DISTANCE_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.VAN.MIN_DISTANCE_NUMBER))
        .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
        .test(
          'axles_distance',
          INTL_ERROR_MESSAGE.AXLES_DISTANCE,
          (value, ctx) => {
            const fullLength = ctx.from[1].value.full_length
            const distance = ctx.from[1].value.axles.reduce((acc, axl) => acc + axl.distance, 0) || 0
            return distance < fullLength
          }
        )
        .required(INTL_ERROR_MESSAGE.REQUIRED),
      default_load: yup
        .number()
        .integer(INTL_ERROR_MESSAGE.INTEGER)
        .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
        .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
        .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
        .test(
          'axles_load',
          INTL_ERROR_MESSAGE.AXLES_DEFAULT_LOAD,
          (value, ctx) => {
            const mass = +ctx.from[1].value.mass
            const load = ctx.from[1].value.axles.reduce((acc, axl) => acc + axl.default_load, 0) || 0
            return load === mass
          }
        )
        .required(INTL_ERROR_MESSAGE.REQUIRED),
      max_load: yup
        .number()
        .integer(INTL_ERROR_MESSAGE.INTEGER)
        .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
        .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
        .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
        .test(
          'max_load',
          INTL_ERROR_MESSAGE.MAX_LOAD,
          (value, ctx) => value > ctx.parent.default_load
        )
        .required(INTL_ERROR_MESSAGE.REQUIRED)
    })
  )

})

export default VanSchema
