/* eslint-disable camelcase */
import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { crown, dollar, promocodes, historyIcon } from '../../../../images'
import { Link } from 'react-router-dom'
import { LINK_TARGET, TYPE_PROMOTION } from '../../../../const'
import { decOfMonth } from '../../../../utils'
import ModalView from '../../../ModalView'
import styles from '../styles/index.module.scss'
import classNames from 'classnames'
import PaymentFormBtn from '../../../../apps/components/views/PaymenFormBtn'
import Checkbox from '../../../Checkbox'
import TariffLabel from '../../../../apps/appTariffs/views/TariffLabelView'
import useTranslate from '../../../../hooks/useTranslate'

const LandingChangeTariff = React.forwardRef((props, ref) => {
  const {
    currency,
    offerLink,
    html,
    tariff,
    handleAccept,
    isLoading,
    isSuccess,
    checkbox,
    checked,
    handleCheck,
    description,
    activeTab
  } = props
  const {
    promocode_info,
    price,
    base_type: baseType,
    promotion_price: promotionPrice
  } = tariff
  const { title, icon } = baseType
  const {
    promocode_title,
    marketing_promotion,
    price_promotion,
    type_promotion
  } = promocode_info || {}
  const { intl, formatMonth } = useTranslate()
  const amount = activeTab?.amount
  const timeUnits = activeTab.time_units
  const textLabel = `${price} через ${amount} ${decOfMonth(amount)}`
  return (
    <>
      <ModalView.Title>{
        intl.formatMessage({
          id: 'modals.landingChangeTariff.title',
          defaultMessage: 'Вы хотите приобрести тариф?'
        })
      }
      </ModalView.Title>
      <div className={styles.confirm_buy__description}>
        <div className={styles.confirm_buy__description_tariff}>
          <div className={styles.confirm_buy__tariff_title}>
            <GxIcon
              slot='icon-left'
              src={icon || crown}
              className={styles.confirm_buy__icons}
            />
            {intl.formatMessage({
              id: 'tariff',
              defaultMessage: 'Тариф'
            })}:
            <span className={styles.confirm_buy__tariff_value}>
              {title}
            </span>
            {type_promotion === TYPE_PROMOTION.PERIOD && (
              <span className={styles.confirm_buy__blue}>
                {' '}
                {marketing_promotion}
              </span>
            )}
          </div>
          <div className={styles.confirm_buy__tariff_title}>
            <GxIcon
              slot='icon-left'
              src={historyIcon}
              className={styles.confirm_buy__icons}
            />
            {intl.formatMessage({
              id: 'period',
              defaultMessage: 'Период'
            })}:{' '}
            <span className={styles.confirm_buy__tariff_value}>{formatMonth(amount, timeUnits)}</span>
          </div>
          <div className={styles.confirm_buy__tariff_title}>
            <GxIcon
              slot='icon-left'
              src={dollar}
              className={styles.confirm_buy__icons}
            />
            {intl.formatMessage({
              id: 'price',
              defaultMessage: 'Стоимость'
            })}:
            {price_promotion && type_promotion === TYPE_PROMOTION.PERCENT && (
              <span
                className={classNames({
                  [styles.confirm_buy__line_through]: !!price_promotion
                })}
              >
                {`${price} ${currency}`}
              </span>
            )}
            <span
              className={classNames(styles.confirm_buy__tariff_value, {
                [styles.confirm_buy__blue]: !!price_promotion
              })}
            >
              {`${promotionPrice || price} ${currency}`}
            </span>
            {promotionPrice &&
              <TariffLabel className={styles.confirm_buy__tariff_label}>
                {textLabel}
              </TariffLabel>}
          </div>
          {promocode_title && (
            <div className={styles.confirm_buy__tariff_title}>
              <GxIcon
                slot='icon-left'
                src={promocodes}
                className={styles.confirm_buy__icons}
              />
              {intl.formatMessage({
                id: 'promoCode',
                defaultMessage: 'Промокод'
              })}:
              <span className={styles.confirm_buy__tariff_value}>
                {promocode_title}
              </span>
            </div>
          )}
        </div>
        <div className={styles.confirm_buy__description_tariff}>
          <Link
            className={styles.confirm_buy__link}
            to={offerLink}
            target={LINK_TARGET.BLANK}
          >
            {
              intl.formatMessage({
                id: 'contractOffer',
                defaultMessage: 'Договор оферты'
              })
            }
          </Link>
        </div>
        {
            checkbox && (
              <div className={styles.confirm_buy__checkbox}>
                <Checkbox checked={checked} onGx-change={handleCheck}>
                  {
                    intl.formatMessage({
                      id: 'activateTariffRightNow',
                      defaultMessage: 'Активировать тариф прямо сейчас'
                    })
                  }
                </Checkbox>
              </div>
            )
        }
        <ModalView.Description
          description={description}
          className={classNames(
            styles.confirm_buy__checkbox_description,
            { [styles['confirm_buy__checkbox_description--red']]: !!checkbox }
          )}
        />
      </div>
      <ModalView.Buttons>
        {
          !isSuccess
            ? (
              <ModalView.Buttons.Button
                onClick={handleAccept}
                isSpinner={isLoading}
                disabled={isLoading}
              >
                {
                  intl.formatMessage({
                    id: 'continue',
                    defaultMessage: 'Продолжить'
                  })
                }
              </ModalView.Buttons.Button>
              )
            : (
              <PaymentFormBtn
                html={html}
                ref={ref}
              >
                {
                  intl.formatMessage({
                    id: 'goToThePayment',
                    defaultMessage: 'Перейти к оплате'
                  })
                }
              </PaymentFormBtn>
              )
        }
      </ModalView.Buttons>
    </>
  )
})

export default LandingChangeTariff
