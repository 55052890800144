import { useMemo } from 'react'
import { slugSerializers } from '../api/serializers'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router-dom'

const usePage = () => {
  const queryClient = useQueryClient()
  const { pathname } = useLocation()
  const { page } = queryClient.getQueryData(['page', pathname])
  const baseSlug = useMemo(() => {
    const globalProps = page?.init_state?.global
    if (globalProps) {
      return slugSerializers(globalProps)
    }
  }, [page])

  return { page, baseSlug }
}

export default usePage
