import React from 'react'
import { GxMenuItem } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'

const Item = ({ projectVariant = 'default', value = '', children, ...props }) => {
  return (
    <GxMenuItem data-cy='gx-menu-item' className={style[`item_${projectVariant}`]} value={value} {...props}>
      {children}
    </GxMenuItem>
  )
}

export default Item
