import React, { useState } from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import InnerHtml from '../../components/InnerHtml'
import classNames from 'classnames'
import { closeCircle } from '../../images'
import styles from './styles/index.module.scss'

const AccordionView = ({ question, theme = null }) => {
  const [openDetail, setOpenDetail] = useState(false)
  const handleToggleOpen = () => {
    setOpenDetail(!openDetail)
  }

  const { answer, title } = question

  return (
    <div
      data-cy='accradion-wrap' className={classNames({
        [styles.accordion]: true,
        [styles[`accordion-${theme}`]]: theme
      })}
    >
      <button
        type='button'
        className={classNames({
          [styles.accordion__title]: true,
          [styles[`accordion__title-${theme}`]]: theme,
          [styles['accordion__title--opened']]: openDetail
        })}
        onClick={handleToggleOpen}
      >
        <GxIcon
          src={closeCircle}
          className={classNames({
            [styles.accordion__icon]: true,
            [styles[`accordion__icon-${theme}`]]: theme,
            [styles['accordion__icon--opened']]: openDetail
          })}
        />
        {title}
      </button>
      {answer && (
        <InnerHtml
          html={answer}
          className={classNames({
            [styles.accordion__text]: true,
            [styles[`accordion__text-${theme}`]]: theme,
            [styles['accordion__text--opened']]: openDetail
          })}
        />
      )}
    </div>
  )
}

export default AccordionView
