import { useStoreon } from 'storeon/react'

const useLimit = () => {
  const { currentUser } = useStoreon('currentUser')
  const {
    current_tariff: currentTariff,
    count_project: countProject,
    count_cargo_space: countCargoSpace
  } = currentUser || {}
  const isFreeTariffLimit = currentTariff?.is_free
  const isTrial = currentTariff?.is_trial
  const isCountProjectLimit = currentTariff?.project_count !== -1 && countProject >= currentTariff?.project_count
  const isCountSpaceLimit = currentTariff?.cargo_space_count !== -1 && countCargoSpace >= currentTariff?.cargo_space_count
  const isAvailableSpace = (space) => {
    const spaces = currentTariff?.cargo_space_list || []
    return spaces.some(el => el === space)
  }
  const isAvailableDemoEditing = currentTariff?.demo_editing || false

  return {
    isTrial,
    isFreeTariffLimit,
    isCountProjectLimit,
    isCountSpaceLimit,
    isAvailableSpace,
    isAvailableDemoEditing
  }
}

export default useLimit
