import React, { useCallback } from 'react'
import ModalView from '../../../ModalView'
import TariffInfoView from '../../../TariffInfoView'
import useSerializeCurrentTariff from '../../../../hooks/useSerializeCurrentTariff'
import styles from './styles/index.module.scss'
import usePage from '../../../../hooks/usePage'
import { useIntl } from 'react-intl'

const ExpirationTariff = ({ handleClose }) => {
  const currentTariff = useSerializeCurrentTariff()
  const { baseSlug } = usePage()
  const handleAccept = useCallback(() => {
    window.open(baseSlug.pageTariffs, '_blank')
    handleClose()
  }, [baseSlug])
  const intl = useIntl()
  return (
    <>
      <ModalView.Title>
        {
          intl.formatMessage({
            id: 'tariffExpires',
            defaultMessage: 'Истекает срок действия тарифа'
          })
        }
      </ModalView.Title>
      <div className={styles.expiration_tariff__root}>
        <TariffInfoView.Title showIcon={!currentTariff.is_free} title={currentTariff.title} />
        <TariffInfoView.Progressbar currentTariff={currentTariff} />
      </div>
      <ModalView.Buttons>
        <ModalView.Buttons.Button theme='stroke_icon--small' onClick={handleClose}>
          {
            intl.formatMessage({
              id: 'cancel',
              defaultMessage: 'Отмена'
            })
          }
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button theme='fill_icon--small' onClick={handleAccept}>
          {
            intl.formatMessage({
              id: 'extend',
              defaultMessage: 'Продлить'
            })
          }
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </>
  )
}

export default ExpirationTariff
