import React, { memo } from 'react'
import Help from '../Help'
import style from './styles/index.module.scss'

const HelpView = ({ questions, fetcherActions, ...props }) => (
  <div className={style.help}>
    <Help.HelpQuestions questions={questions} fetcherActions={fetcherActions} />
    <Help.HelpSendQuestion {...props} />
  </div>
)

export default memo(HelpView)
