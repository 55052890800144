import React, { useMemo } from 'react'
import ImageGallery from '../../views/ImageGallery'
import { imageGallerySerializer } from '../../utils'

const ImageGalleryComponent = (props) => {
  const componentValueSerialized =
    useMemo(() => imageGallerySerializer(props), [])

  return <ImageGallery {...componentValueSerialized} />
}

export default ImageGalleryComponent
