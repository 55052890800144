import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'

class TariffApiService extends Api {
  getTariffs = async () => {
    const res = await this.get('/tariff/');
    return res.data;
  };

  getFreeTariff = async () => {
    const res = await this.get('/tariff/free_tariff/');
    return res.data;
  };

  cancelTariff = async () => {
    const res = await this.post('/tariff/user-tariff/cancel_payment/');
    return res.data;
  };

  buyTariff = async (params) => {
    const res = await this.post('/tariff/user-tariff/', params);
    return res.data;
  };
  /**
   * @param is_use_statistic boolean - default true (on backend)
   * @description 
   * если используем не на стр статистики - используй фильтра 
   */
  getActivePeriod = async (isUseStatistic = true) => {
    const res = await this.get(`/tariff/active_period/?is_use_statistic=${isUseStatistic}`)
    return res.data
  }
}


export default new TariffApiService(runtimeConfig.API_URL)