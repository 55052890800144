import React from 'react'
import { doneIcon } from '../../../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from './styles/index.module.scss'
import classNames from 'classnames'
import Tooltip from '../../../../views/Tooltip'

const UserTariffCircle = ({ modal, content = 'Вы уже приобрели этот тариф' }) => {
  return (
    <Tooltip hoist placement='left' content={content}>
      <div className={classNames(styles.tariff_card__active, {
        [styles['tariff_card__active--modal']]: modal
      })}
      >
        <GxIcon src={doneIcon} className={styles.tariff_card__active_img} />
      </div>
    </Tooltip>
  )
}

export default UserTariffCircle
