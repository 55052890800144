import React from 'react'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'

import { garpixLogo } from '../../../images'

import style from '../styles/index.module.scss'
/**
 * @view
 * * Вью нижней части футера на лендинге
 *
 * @param {Array<Object>} menus           массив ссылок на стр нижнего футера
 * @param {string}        garpixUrl
 */
const FooterSecondPart = ({
  menus,
  garpixUrl
}) => {
  return (
    <div className={style['second-part']}>
      <div className={classNames(style.container, style['container-flex'])}>
        <div className={style.menu} data-cy='footer-menus'>
          {menus &&
            menus.length > 0 &&
            menus.map(
              ({
                url,
                id,
                title,
                model_name: modelName,
                target_blank: targetBlank
              }) => (
                <a
                  key={id}
                  data-cy={`${modelName}-footer-link`}
                  className={style.menu__link}
                  href={url}
                  target={targetBlank ? '_blank' : '_self'}
                  rel='noreferrer'
                >
                  {title}
                </a>
              )
            )}
        </div>
        <a
          href={garpixUrl}
          target='_blank'
          rel='noreferrer'
          className={style.logo_link}
        >
          <GxIcon src={garpixLogo} alt='Logo' />
        </a>
      </div>
      <div className={style.footer_recaptcha}>
        This site is protected by reCAPTCHA and the Google
        <a href='https://policies.google.com/privacy'>Privacy Policy</a> and
        <a href='https://policies.google.com/terms'>Terms of Service</a> apply.
      </div>
    </div>
  )
}

export default FooterSecondPart
