import React, { useEffect, useRef } from 'react'
import { BUTTON_THEME } from './../../../../const'
import ModalView from '../../../ModalView'
import { GxForm } from '@garpix/garpix-web-components-react'
import InputWithoutSymbols from '../../../InputWithoutSymbols'
import style from './style/index.module.scss'
import useTranslate from '../../../../hooks/useTranslate'

const Create = ({ title, inputLabel, buttonLabel, closeModal, formik }) => {
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    isSubmitting,
    isValid,
    dirty
  } = formik

  const ref = useRef(null)
  const { translate, intl } = useTranslate()

  useEffect(() => {
    setTimeout(() => ref.current.setFocus(), 450)
  }, [])

  return (
    <GxForm onGx-submit={handleSubmit}>
      <ModalView.Title>
        {title}
      </ModalView.Title>
      <InputWithoutSymbols
        data-cy='project-add-field'
        ref={ref}
        name='title'
        value={values.title}
        onGx-input={handleChange}
        label={inputLabel}
        error={!!errors.title}
        errorText={translate(errors.title)}
      />
      <ModalView.Buttons className={style.create__btns}>
        <ModalView.Buttons.Button className={style.create__btn_cancel} theme={BUTTON_THEME.STROKE_ICON.SMALL} onClick={closeModal}>
          {
            intl.formatMessage({
              id: 'cancel',
              defaultMessage: 'Отмена'
            })
          }
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button
          data-cy='project-add-submit-button'
          type='submit'
          theme={BUTTON_THEME.FILL_ICON.SMALL}
          disabled={isSubmitting || !(isValid && dirty)}
          className={style.create__btn_create}
        >
          {
            buttonLabel
          }
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </GxForm>
  )
}

export default React.memo(Create)
