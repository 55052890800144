import React from 'react'
import { GxForm, GxIcon } from '@garpix/garpix-web-components-react'
import Input from '../Input'
import Button from '../Button'
import style from './styles/index.module.scss'
import Title from '../Title'
import { leftIcon } from '../../images'
import Switch from '../../apps/components/views/Switch'
import { BUTTON_THEME, BUTTON_VARIANT, INPUT_REGEXP, INTL_TITLE, TITLE_VARIANT } from '../../const'
import InputPhone from '../InputPhone'
import useTranslate from '../../hooks/useTranslate'

const inputsGeneral = [
  {
    id: 0,
    label: {
      id: 'firstName',
      defaultMessage: 'Имя'
    },
    name: 'first_name',
    required: true,
    disabled: false,
    regexp: INPUT_REGEXP.PROFILE_NAME
  },
  {
    id: 1,
    label: {
      id: 'lastName',
      defaultMessage: 'Фамилия'
    },
    name: 'last_name',
    required: true,
    disabled: false,
    regexp: INPUT_REGEXP.PROFILE_NAME
  },
  {
    id: 2,
    label: {
      id: 'phone',
      defaultMessage: 'Телефон'
    },
    name: 'phone',
    required: true,
    disabled: false
  },
  {
    id: 3,
    label: {
      id: 'email',
      defaultMessage: 'Email'
    },
    name: 'email',
    required: true,
    disabled: true
  }
]

const ProfileInfo = ({
  tools,
  handelChangeShowBlocks,
  showProfileInfo,
  handleChangePhone,
  handleOpenPasswordForm,
  hasSubscribe,
  subscribeToMailing
}) => {
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    values,
    touched,
    errors,
    isSubmitting
  } = tools
  const { translate, intl } = useTranslate()
  return (
    <GxForm data-showprofile={showProfileInfo} onGx-submit={handleSubmit} className={style.profile_form}>
      <div className={style.profile_form__block}>
        <div className={style.profile_form__wrap}>
          <Button
            caret
            className={style.profile_form__back}
            onClick={handelChangeShowBlocks}
            variant={BUTTON_VARIANT.TEXT}
            theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
          >
            <GxIcon src={leftIcon} />
          </Button>
          <Title className={style.profile_form__title} variant={TITLE_VARIANT.LIST_PAGES}>{intl.formatMessage({
            id: 'profile',
            defaultMessage: 'Профиль'
          })}
          </Title>
        </div>
        <h2 className={style.profile_form__subtitle}>{intl.formatMessage({
          id: 'general',
          defaultMessage: 'Общее'
        })}
        </h2>
        <div className={style.profile_form__grid}>
          {inputsGeneral.map(({ name, label, id, disabled, required, regexp }) => (
            <div key={id} className={style.profile_form__input}>
              {name === 'phone'
                ? (
                  <InputPhone
                    name={name}
                    label={intl.formatMessage(label)}
                    value={values[name]}
                    onGx-blur={handleBlur}
                    onGx-change={handleChangePhone}
                    error={errors[name]}
                    errorText={translate(errors[name])}
                    required
                  />
                  )
                : (
                  <Input
                    name={name}
                    value={values[name]}
                    label={intl.formatMessage(label)}
                    placeholder={intl.formatMessage({
                      id: 'notIndicated',
                      defaultMessage: 'Не указано'
                    })}
                    onGx-input={handleChange}
                    onGx-blur={handleBlur}
                    disabled={disabled}
                    error={touched[name] && errors[name]}
                    errorText={translate(errors[name])}
                    required={required}
                    regexp={regexp}
                  />
                  )}
            </div>
          ))}
          <Switch
            checked={hasSubscribe}
            onGx-change={subscribeToMailing}
          >
            {
              intl.formatMessage({
                id: 'receiveNewsletter',
                defaultMessage: 'Получать рассылку от сервиса'
              })
            }
          </Switch>
        </div>
        {/* <div className={style.profile_form__block}> */}
        {/*  <p className={style.profile_form__title}>Реквизиты</p> */}
        {/*  <div className={style.profile_form__grid}> */}
        {/*    <div className={style.profile_form__input}> */}
        {/*      <CountrySelect */}
        {/*        name='country' */}
        {/*        label='Страна' */}
        {/*        onChange={handleSelectCountry} */}
        {/*        country={values.country} */}
        {/*      /> */}
        {/*    </div> */}
        {/*    <div className={style.profile_form__input}> */}
        {/*      <Input */}
        {/*        name='address' */}
        {/*        value={values.address} */}
        {/*        placeholder='Не указано' */}
        {/*        label='Адрес' */}
        {/*        onGx-input={handleChange} */}
        {/*        onGx-blur={handleBlur} */}
        {/*        error={touched.address && errors.address} */}
        {/*        errorText={errors.address} */}
        {/*      /> */}
        {/*    </div> */}
        {/*    <div className={style.profile_form__input}> */}
        {/*      <InputNumbers */}
        {/*        name='tax_identify' */}
        {/*        label='ИНН' */}
        {/*        value={values.tax_identify} */}
        {/*        placeholder='Не указано' */}
        {/*        onGx-input={handleChange} */}
        {/*        onGx-blur={handleBlur} */}
        {/*        error={touched.tax_identify && errors.tax_identify} */}
        {/*        errorText={errors.tax_identify} */}
        {/*        decimalSeparator='' */}
        {/*      /> */}
        {/*    </div> */}
        {/*    <div className={style.profile_form__input}> */}
        {/*      <InputNumbers */}
        {/*        name='zip_code' */}
        {/*        label='Индекс' */}
        {/*        value={values.zip_code} */}
        {/*        placeholder='Не указано' */}
        {/*        onGx-input={handleChange} */}
        {/*        onGx-blur={handleBlur} */}
        {/*        error={touched.zip_code && errors.zip_code} */}
        {/*        errorText={errors.zip_code} */}
        {/*        decimalSeparator='' */}
        {/*      /> */}
        {/*    </div> */}
        {/*  </div> */}
        {/* </div> */}
        <div>
          <p className={style.profile_form__security}>{intl.formatMessage({
            id: 'security',
            defaultMessage: 'Безопасность'
          })}
          </p>
          <Button
            data-cy='change-password'
            variant={BUTTON_VARIANT.TEXT}
            theme={BUTTON_THEME.TEXT_WITH_ICON.SHORT}
            onClick={handleOpenPasswordForm}
          >
            {
              intl.formatMessage({
                id: 'changePassword',
                defaultMessage: 'Изменение пароля'
              })
            }
          </Button>
        </div>
      </div>
      <div className={style.profile_form__buttons}>
        <Button
          data-cy='profile-submit-btn'
          type='submit'
          className={style.profile_form__buttons_save}
          disabled={isSubmitting || !isValid}
        >
          {
            intl.formatMessage(INTL_TITLE.SAVE)
          }
        </Button>
      </div>
    </GxForm>
  )
}

export default ProfileInfo
