import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import { useStoreon } from 'storeon/react'
import PromoCash from '../../../views/BaseModal/ModalContent/PromoCash'
import usePage from './../../../hooks/usePage'
import { useModalIntlContent, useModalStore } from '../../../hooks/Modals'
import { PromoCashModalSchema } from '../../../utils/ValidateSchemes'
import { FORM_TYPE } from './../../../const'
import { partnerApiService } from '../../../api/services'
import { useIntl } from 'react-intl'

const title = {
  promo_code: {
    id: 'modals.promoCash.promoCodeTitle',
    defaultMessage: 'Запросить промокод'
  },
  withdrawal_request: {
    id: 'modals.promoCash.withdrawalTitle',
    defaultMessage: 'Вывод средств'
  }
}

const PromoCashModal = ({ handleAccept, utm, handleClose, action }) => {
  const intl = useIntl()
  const {
    btnInfo: { formType }
  } = useStoreon('btnInfo')

  const { page } = usePage()
  const components = useMemo(() => page || [], [page])

  const partnerBalance = components.init_state.partner_balance

  const { openModal, contentTypes } = useModalStore()
  const successModalContent = useModalIntlContent(contentTypes.help)
  const errorModalContent = useModalIntlContent(contentTypes.sendRequestError)

  const sendFeedback = useCallback(async ({ message }) => {
    try {
      if (formType === FORM_TYPE.promo_code) {
        await partnerApiService.promocodeRequest({ message })
      }
      if (formType === FORM_TYPE.withdrawal_request) {
        await partnerApiService.cashoutRequest({ message })
        action && action()
      }
      openModal('commonConfirm', successModalContent)
    } catch (e) {
      if (e?.response?.data?.partner[0]) {
        openModal('commonConfirm', {
          ...errorModalContent,
          description: intl.formatMessage({ defaultMessage: e.response.data.partner[0] })
        })
      } else {
        openModal('commonConfirm', errorModalContent)
      }
    }
  }, [])

  const formik = useFormik({
    initialValues: {
      message: ''
    },
    validationSchema: PromoCashModalSchema,
    onSubmit: handleAccept || sendFeedback
  })

  return (
    <PromoCash
      handleClose={handleClose}
      title={intl.formatMessage(title[formType])}
      formType={formType}
      formik={formik}
      partnerBalance={partnerBalance}
    />
  )
}

export default PromoCashModal
