import { Locator } from '../locator'
import Banner from '../../components/Landing/Banner'
import Welcome from '../../components/Landing/Welcome'
import Possibilities from '../../components/Landing/Possibilities'
import LoadPerfomance from '../../components/Landing/LoadPerfomance'
import LoadPerfomaceNew from '../../components/Landing/LoadPerfomanceNew'
import FrequentProblems from '../../components/Landing/FrequentProblems'
import WorkResults from '../../components/Landing/WorkResults'
import HowWork from '../../components/Landing/HowWork'
import WatchUs from '../../components/Landing/WatchUs'
import News from '../../components/Landing/News'
import CallOrder from '../../components/Landing/CallOrder'
import PartnersProgram from '../../components/Landing/PartnersProgram'
import SuitabilitySlider from '../../components/Landing/SuitabilitySlider'
import Suitability from '../../components/Landing/Suitability'
import Partners from '../../components/Landing/Partners'
import FAQComponet from '../../components/Landing/FAQComponent'
import TariffAdvantage from '../../components/Landing/TariffAdvantage'
import TariffsRemastered from '../../components/Landing/TariffsRemastered'
import WorkResultsSlider from '../../components/Landing/WorkResultsSlider'
import Clients from '../../components/Landing/Clients'
import LogoGrid from '../../components/Landing/LogoGrid'
import LogoSlider from '../../components/Landing/LogoSlider'
import FrequentProblemsGazprom from '../../components/Landing/FrequentProblemsGazprom'
import GradientLine from '../../components/Landing/GradientLine'

import FileGallery from '../../apps/appNews/views/FileGallery'
import ImageGalleryComponent from '../../apps/appNews/components/ImageGalleryComponent'

/**
 * @module
 * * модуль для нахождения и отображения конкретных компонент лендинга
 * * в зависимости от наличия их на стр (см. какие комп присылает бэк)
 *
 */

const componentRegistry = new Locator()

componentRegistry.register('InfoBannerComponent', Banner)
componentRegistry.register('TopBannerComponent', Welcome)
componentRegistry.register('CapabilitiesComponent', Possibilities) // was window undefined
componentRegistry.register('LoadingEfficiencyComponent', LoadPerfomance) // was window undefined
componentRegistry.register('ProblemsAndSolutionsComponent', FrequentProblems)
componentRegistry.register('ResultsComponent', WorkResults)
componentRegistry.register('HowWorkComponent', HowWork)
componentRegistry.register('TariffsComponent', TariffsRemastered)
componentRegistry.register('NewsComponent', News)
componentRegistry.register('DividingLineComponent', GradientLine)

componentRegistry.register('ProblemsAndSolutionsGPBComponent', FrequentProblemsGazprom)

componentRegistry.register('PartnersProgramComponent', PartnersProgram)
componentRegistry.register('LoadingEfficiencyComponentNew', LoadPerfomaceNew)
componentRegistry.register('WatchUsOnYoutubeComponent', WatchUs)
componentRegistry.register('CallOrderComponent', CallOrder)
componentRegistry.register('FAQComponent', FAQComponet)
componentRegistry.register('AdvantageComponent', TariffAdvantage)
componentRegistry.register('CaseComponent', Clients)
componentRegistry.register('LogoNetComponent', LogoGrid)

// swiper
componentRegistry.register('WorkResultComponent', WorkResultsSlider)
componentRegistry.register('TargetAudienceComponent', Suitability)
componentRegistry.register('LogoSliderComponent', LogoSlider)
componentRegistry.register('TargetAudienceSliderComponent', SuitabilitySlider)
componentRegistry.register('PartnersComponent', Partners)

// news
componentRegistry.register('NewsFileGalleryComponent', FileGallery)
componentRegistry.register('NewsImageGalleryComponent', ImageGalleryComponent)

export default componentRegistry
