/* eslint-disable camelcase */
import React from 'react'

import ProfileViews from '../../views/ProfileViews'

import { useModalIntlContent, useModalStore } from '../../hooks/Modals'
import usePage from '../../hooks/usePage'

import { serializedTariff } from '../../api/serializers'
import { TariffApiService, userApiService } from '../../api/services'

const UserCurrentTariff = ({ currentTariff, setCurrentTariff, isProfile }) => {
  const { page } = usePage()
  const isShowTariffPanel = page.init_state.global?.is_show_tariff
  const { openModal, contentTypes, closeModal } = useModalStore()
  const cancelTariffModalContent = useModalIntlContent(contentTypes.cancelTariff)

  const cancelTariff = async () => {
    await TariffApiService.cancelTariff()
    const { current_tariff } = await userApiService.getCurrentUser()
    setCurrentTariff(serializedTariff(current_tariff))
    closeModal()
  }

  const handleCancelModal = () => {
    openModal('commonConfirm', {
      ...cancelTariffModalContent,
      handleAccept: cancelTariff
    })
  }

  if (!currentTariff || !isShowTariffPanel) return null

  return (
    <ProfileViews.UserCurrentTariff
      currentTariff={currentTariff}
      cancelModal={handleCancelModal}
      isProfile={isProfile}
    />
  )
}
export default UserCurrentTariff
