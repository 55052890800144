import React from 'react'

import FileCard from './FileCard'

import styles from './styles/index.module.scss'

/**
 * @param {string} textTitle
 * @param {string} label
 * @param {Array} fileElements
 * @returns ReactElement
 */
const FileGallery = ({
  text_title: textTitle,
  label,
  file_elements: fileElements = [],
  ...props
}) => {
  return (
    <div className={styles['file-gallery']}>
      {textTitle && <h3 className={styles['file-gallery__title']}>{textTitle}</h3>}
      <ul className={styles['file-gallery__list']}>
        {fileElements.length > 0 &&
          fileElements.map((el) => (
            <FileCard
              key={el.id}
              {...el}
            />
          ))}
      </ul>
    </div>
  )
}

export default FileGallery
