import { GxIcon } from '@garpix/garpix-web-components-react'
import React from 'react'
import { downloadIcon } from '../../images'
import styles from './styles/download.module.scss'
import Button from '../Button'
import { BUTTON_THEME } from './../../const'
import { useIntl } from 'react-intl'
import FileSaver from 'file-saver'

const Download = ({ handleUpload }) => {
  const handleOpenExample = async () => {
    const url = await handleUpload()
    const name = url.split('/').pop()
    FileSaver.saveAs(url, name)
  }
  const intl = useIntl()

  return (
    <>
      <Button
        download
        onClick={handleOpenExample}
        theme={BUTTON_THEME.STROKE_ICON.SMALL}
        className={styles.download_btn}
      >
        <GxIcon
          src={downloadIcon}
          slot='icon-left'
          className={styles.download_btn__icon}
        />
        {intl.formatMessage({
          id: 'downloadExample',
          defaultMessage: 'Скачать пример'
        })}
      </Button>
    </>
  )
}

export default Download
