/* eslint-disable camelcase */
import React from 'react'
import SuitabilitySlider from '../../SuitabilitySlider'
import styles from './styles/index.module.scss'

const SuitabilitySlides = ({ content }) => {
  return (
    <div className={styles.suitability}>
      {content?.target_audience_elements && content?.target_audience_elements.length > 0 && (
        <SuitabilitySlider content={content} />
      )}
    </div>
  )
}

export default SuitabilitySlides
