import * as yup from 'yup'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../const'
import { getValidateText } from '../ValidateText'

const CargoSchema = yup
  .object()
  .shape({
    title: yup
      .string()
      .trim()
      .max(150, getValidateText('title', 'max', 150))
      .min(
        ERROR_NUMBERS.MIN_STRING_NUMBER,
        getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER)
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED),
    article: yup.string().trim().nullable(),
    length: yup.number().when('type', {
      is: 'box',
      then: (shema) =>
        shema
          .integer(INTL_ERROR_MESSAGE.INTEGER)
          .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
          .min(
            ERROR_NUMBERS.CARGO_MIN_NUMBER,
            getValidateText('number', 'min', ERROR_NUMBERS.CARGO_MIN_NUMBER)
          )
          .max(
            ERROR_NUMBERS.MAX_NUMBER,
            getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)
          )
          .required(INTL_ERROR_MESSAGE.REQUIRED),
      otherwise: (shema) => shema.optional()
    }),
    width: yup.number().when('type', {
      is: 'box',
      then: (shema) =>
        shema
          .integer(INTL_ERROR_MESSAGE.INTEGER)
          .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
          .min(
            ERROR_NUMBERS.CARGO_MIN_NUMBER,
            getValidateText('number', 'min', ERROR_NUMBERS.CARGO_MIN_NUMBER)
          )
          .max(
            ERROR_NUMBERS.MAX_NUMBER,
            getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)
          )
          .required(INTL_ERROR_MESSAGE.REQUIRED),
      otherwise: (shema) => shema.optional()
    }),
    height: yup
      .number()
      .integer(INTL_ERROR_MESSAGE.INTEGER)
      .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
      .min(
        ERROR_NUMBERS.CARGO_MIN_NUMBER,
        getValidateText('number', 'min', ERROR_NUMBERS.CARGO_MIN_NUMBER)
      )
      .max(
        ERROR_NUMBERS.MAX_NUMBER,
        getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED),
    mass: yup
      .number()
      .typeError(INTL_ERROR_MESSAGE.NUMBER_TYPE)
      .min(0.001, getValidateText('number', 'min', 0.001))
      .max(
        ERROR_NUMBERS.MAX_WEIGHT_NUMBER,
        getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER)
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED),
    stacking_limit: yup
      .number()
      .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
      .min(0, getValidateText('number', 'min', 0))
      .max(
        ERROR_NUMBERS.MAX_WEIGHT_NUMBER,
        getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER)
      ),
    type: yup.string().required(INTL_ERROR_MESSAGE.REQUIRED)
  })
  .when((values, schema) => {
    if ('mp_rules' in values && values.mp_rules) {
      return schema.shape({
        mp_rules: yup.object().shape({
          type: yup.string().required(INTL_ERROR_MESSAGE.REQUIRED),
          layer: yup
            .number()
            .min(1, getValidateText('number', 'min', 1))
            .integer(INTL_ERROR_MESSAGE.INTEGER)
            .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
            .required(INTL_ERROR_MESSAGE.REQUIRED)
            .max(
              ERROR_NUMBERS.MAX_LAYER_NUMBER,
              getValidateText('number', 'max', ERROR_NUMBERS.MAX_LAYER_NUMBER)
            ),
          tiering: yup
            .number()
            .integer(INTL_ERROR_MESSAGE.INTEGER)
            .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
            .min(0, getValidateText('number', 'min', 0))
            .required(INTL_ERROR_MESSAGE.REQUIRED)
            .max(
              ERROR_NUMBERS.MAX_TIERING_NUMBER,
              getValidateText('number', 'max', ERROR_NUMBERS.MAX_TIERING_NUMBER)
            ),
          RTU_priority: yup
            .number()
            .integer(INTL_ERROR_MESSAGE.INTEGER)
            .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
            .min(-1, getValidateText('number', 'min', -1))
            .max(
              ERROR_NUMBERS.MAX_RTU_NUMBER,
              getValidateText('number', 'max', ERROR_NUMBERS.MAX_TIERING_NUMBER)
            )
        })
      })
    }
  })
export default CargoSchema
