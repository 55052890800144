import React, { createRef, useEffect, useState } from 'react'
import { LandingChangeTariff, ProfileChangeTariff } from '../../../views/BaseModal/ModalContent'
import { useMutation } from 'react-query'
import usePage from '../../../hooks/usePage'
import { CHANGE_TARIFF_MODAL_TYPE, CHANGE_TARIFF_MODAL_VARIANT } from '../../../const'
import { useModalStore } from '../../../hooks/Modals'
import { defineMessage, useIntl } from 'react-intl'

const CHECKED_DESCRIPTION = defineMessage({
  id: 'modals.changeTariff.nowDescription',
  defaultMessage: 'Новый тариф начнет действовать сразу после покупки, оставшиеся дни старого тарифа сгорят.'
})
const UNCHECKED_DESCRIPTION = defineMessage({
  id: 'modals.changeTariff.queueDescription',
  defaultMessage: 'Новый тариф будет поставлен в очередь и начнёт своё действие в день, когда закончится текущий тариф.'
})

const ChangeTariffModal = (props) => {
  const { variant, tariff, proofPayment, activeTab } = props
  const { modal = CHANGE_TARIFF_MODAL_TYPE.DOWNGRADE } = tariff
  const isUpgrade = modal === CHANGE_TARIFF_MODAL_TYPE.UPGRADE
  const intl = useIntl()
  const { baseSlug } = usePage()
  const { closeModal } = useModalStore()
  const isLanding = variant === CHANGE_TARIFF_MODAL_VARIANT.LANDING
  const [checked, setChecked] = useState(isUpgrade)
  const formRef = createRef()
  const isHold = isUpgrade ? !checked : true
  const description = checked ? intl.formatMessage(CHECKED_DESCRIPTION) : intl.formatMessage(UNCHECKED_DESCRIPTION)
  const offerLink = baseSlug.pageContractOffer
  const mutation = useMutation({
    mutationFn: proofPayment
  })
  const handleCheck = (e) => setChecked(e.target.checked)
  const handleAccept = () => mutation.mutate({ tariff, isHold })

  useEffect(() => {
    if (mutation.isSuccess) {
      const $form = formRef.current.querySelector('form')
      if ($form) {
        $form.submit()
        closeModal()
      }
    }
  }, [mutation.isSuccess, formRef])

  const context = {
    checked,
    handleCheck,
    description,
    activeTab,
    handleAccept,
    checkbox: isUpgrade,
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    html: mutation?.data?.form,
    ref: formRef
  }

  return isLanding
    ? (
      <LandingChangeTariff
        {...context}
        currency={mutation?.data?.currency || 'RUB'}
        offerLink={offerLink}
        tariff={tariff}
      />
      )
    : (
      <ProfileChangeTariff {...context} />
      )
}

export default ChangeTariffModal
