import React from 'react'
import WorkResultsSliderView from '../../../views/LandingView/WorkResultsSlider'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import { CARDS } from '../../../const'

const WorkResultsSlider = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} id={CARDS.WORK_RESULTS_SLIDER.id} {...props}>
      <WorkResultsSliderView {...props.content} />
    </SectionHomePageWithButtons>
  )
}

export default WorkResultsSlider
