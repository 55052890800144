import React from 'react'
import DropdownViews from '../../../../views/DropdownViews'
import style from './styles/index.module.scss'
import Button from '../../../../views/Button'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { downloadIcon } from '../../../../images'
import InnerHtml from '../../../../components/InnerHtml'
import classNames from 'classnames'
import { BUTTON_VARIANT, EXCEL_EVENTS, BUTTON_THEME } from '../../../../const'
import { useIntl } from 'react-intl'
import { formatRelativeDate, getMoscowTime } from '../../../../utils'
import useLocale from '../../../../hooks/useLocale'

const NotificationItemView = React.memo((props) => {
  const {
    id,
    createdAt,
    link,
    isRead,
    message,
    handleRead,
    error = false,
    isPage = false,
    event
  } = props
  const intl = useIntl()
  const { locale } = useLocale()
  const time = formatRelativeDate(getMoscowTime(), createdAt, locale)
  return (
    <DropdownViews.Item
      onClick={() => !isRead && handleRead(id)}
      projectVariant='item_notification'
      className={
        classNames(
          style.notification_item,
          { [style['notification_item--error']]: error && !isRead, [style['notification_item--unread']]: !isRead }
        )
      }
    >
      <div className={classNames({ [style.notification_item__container]: isPage })}>
        <div className={style.notification_item__info}>
          <InnerHtml className={style.notification_item__description} html={message} />
          <div className={style.notification_item__time}>
            {time}
          </div>
        </div>
        <div className={classNames(style.notification_item__button_wrapper, { [style['notification_item__button_wrapper--page']]: isPage })}>
          {
              link && (
                EXCEL_EVENTS.includes(event)
                  ? (
                    <Button variant={BUTTON_VARIANT.TEXT} theme={BUTTON_THEME.TEXT_WITH_ICON.DEFAULT} className={classNames({ [style['notification_item__button--page']]: isPage })} href={link} target='_blank'>
                      {
                        intl.formatMessage({
                          id: 'go',
                          defaultMessage: 'Перейти'
                        })
                      }
                    </Button>
                    )
                  : (
                    <Button variant={BUTTON_VARIANT.TEXT} theme={BUTTON_THEME.TEXT_WITH_ICON.DEFAULT} className={classNames({ [style['notification_item__button--page']]: isPage })} href={link} target='_blank'>
                      <GxIcon
                        slot='icon-left'
                        src={downloadIcon}
                        className={style.notification_item__icon}
                      />
                      {
                        intl.formatMessage({
                          id: 'downloadFile',
                          defaultMessage: 'Скачать файл'
                        })
                      }
                    </Button>
                    )
              )
          }
          <div className={style.notification_item__tooltip}>
            {
              intl.formatMessage({
                id: 'markAsRead',
                defaultMessage: 'Отметить прочитанным'
              })
            }
          </div>
        </div>
      </div>
    </DropdownViews.Item>
  )
})

export default NotificationItemView
