import { useEffect, useState } from 'react'
import { hyphenate } from 'hyphen/ru'

const useHyphenate = (initialValue) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    (async () => {
      if (initialValue) {
        const result = await hyphenate(initialValue)
        setValue(result)
      }
    })()
  }, [initialValue])
  return value
}

export default useHyphenate
