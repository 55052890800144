const singleTooltip = (store) => {
  const DEFAULT_DATA = {
    singleTooltip: { isMounted: false, id: null, closeTrigger: null },
  }
  store.on('@init', () => DEFAULT_DATA)
  store.on('singleTooltip/mount', (store, { isMounted, id, closeTrigger }) => ({
    singleTooltip: { isMounted, id, closeTrigger },
  }))
  store.on('singleTooltip/unmount', (store) => DEFAULT_DATA)
}

export default singleTooltip
