import React from 'react'
import { useFormik } from 'formik'
import { RenameSchema } from '../../../utils/ValidateSchemes'
import { convertError } from '../../../utils'
import { Create } from '../../../views/BaseModal/ModalContent/'
import { defineMessage } from 'react-intl'
import useTranslate from '../../../hooks/useTranslate'

const ITEM_TITLE_SCHEME = {
  cargo: {
    id: 'modals.renamePageItem.cargo',
    defaultMessage: 'Переименовать груз?'
  },
  cargo_space: {
    id: 'modals.renamePageItem.space',
    defaultMessage: 'Переименовать грузовое пространство?'
  },
  project: {
    id: 'renameProject',
    defaultMessage: 'Переименовать проект?'
  },
  group: {
    id: 'modals.renamePageItem.group',
    defaultMessage: 'Переименовать группу?'
  }
}

const inputLabel = defineMessage({
  id: 'modals.renamePageItem.inputLabel',
  defaultMessage: 'Новое название'
})
const buttonLabel = defineMessage({
  id: 'rename',
  defaultMessage: 'Переименовать'
})

const RenamePageItemModal = (props) => {
  const {
    type,
    renameHandler,
    itemID,
    itemTitle,
    handleClose,
    reload,
    reset
  } = props
  const { translate, intl } = useTranslate()
  const title = intl.formatMessage(ITEM_TITLE_SCHEME[type])

  const formik = useFormik({
    initialValues: {
      title: itemTitle,
      type
    },
    validationSchema: RenameSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        const data = { id: itemID, title: values.title }
        await renameHandler(data)
        handleClose()
        reset && reset()
        reload()
      } catch (e) {
        const error = translate(convertError(e))
        setFieldError('title', error)
      }
    }
  })

  return (
    <Create
      formik={formik}
      title={title}
      inputLabel={intl.formatMessage(inputLabel)}
      buttonLabel={intl.formatMessage(buttonLabel)}
      closeModal={handleClose}
    />
  )
}

export default RenamePageItemModal
