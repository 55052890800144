import React from 'react'
import style from './style/index.module.scss'
import { BUTTON_THEME } from '../../../const'
import InnerHtml from '../../../components/InnerHtml'
import ActionButton from '../ActionButton'

const LoadPerfomaceNew = ({ handleOpenModal, ...props }) => {
  const { main_text: text, topic, image, action_button: actionButton } = props.content

  return (
    <div className={style.loadPerformance}>
      {image &&
        <div className={style.loadPerformance__top}>
          <img
            width='1250'
            height='500'
            className={style.loadPerformance__img}
            src={image}
            alt='load_perfomance'
            loading='lazy'
          />
        </div>}
      <div className={style.loadPerformance__bottom}>
        <div className={style.loadPerformance__bottom_left}>
          {topic && <InnerHtml className={style.loadPerformance__bottom_title} html={topic} />}
          {text && <InnerHtml className={style.loadPerformance__bottom_text} html={text} />}
        </div>
        {actionButton &&
          <ActionButton
            theme={BUTTON_THEME.FILL_ICON.LANDING}
            className={style.loadPerformance__bottom_btn}
            handleOpenModal={handleOpenModal}
            actionButton={actionButton}
          />}
      </div>
    </div>
  )
}

export default LoadPerfomaceNew
