import { useIntl } from 'react-intl'
/**
 * @info
 * ? backend units: key for formatted intl
 * */
export const TIME_UNITS = {
  days: 'day',
  months: 'month',
  years: 'year'
}

const useTranslate = () => {
  const intl = useIntl()

  const translate = msg => {
    try {
      return intl.formatMessage(msg)
    } catch {
      return String(msg)
    }
  }

  const formatMonth = (num, unit) => {
    return intl.formatNumber(num, {
      style: 'unit',
      unit: TIME_UNITS[unit] || TIME_UNITS.months,
      unitDisplay: 'long'
    })
  }

  const formatInfinity = val => {
    return val === -1
      ? intl.formatMessage({
          id: 'unlimited',
          defaultMessage: 'Неограниченно'
        })
      : val
  }

  return { translate, formatMonth, formatInfinity, intl }
}

export default useTranslate
