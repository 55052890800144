import { GxIcon } from '@garpix/garpix-web-components-react'
import React from 'react'
import { fileIcon } from '../../images'
import styles from './styles/input_file.module.scss'
import Button from '../Button'
import { BUTTON_THEME } from '../../const'
import { useIntl } from 'react-intl'

const FileInput = ({
  handleLoad,
  limit,
  theme = BUTTON_THEME.STROKE_ICON.SMALL,
  isLimited,
  inputKey,
  text,
  accept = ''
}) => {
  const intl = useIntl()
  const label = text || intl.formatMessage({
    id: 'uploadFile',
    defaultMessage: 'Загрузить файл'
  })
  return (
    <Button className={styles.input_file} theme={theme} onClick={isLimited}>
      <label htmlFor='file' className={styles.input_file__label}>
        <input key={inputKey} onChange={handleLoad} type={limit ? 'text' : 'file'} name='file' id='file' accept={accept} className={styles.input_file__input} />
      </label>
      <GxIcon src={fileIcon} alt='download' className={styles.input_file__icon} slot='icon-left' />
      {label}
    </Button>
  )
}

export default FileInput
