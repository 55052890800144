import { CARDS } from '../../../const'
import React from 'react'
import SectionHomePage from '../../../views/LandingView/Section'
import TableTariff from '../../../apps/appTariffs/components/TariffTable'

const TariffAdvantage = (props) => {
  return (
    <SectionHomePage
      theme={props.content?.theme}
      id={CARDS.TARIFF_ADVANTAGE.id}
      {...props}
    >
      <TableTariff advantages={props.content.advantages} />
    </SectionHomePage>
  )
}

export default TariffAdvantage
