import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import Button from '@/views/Button'
import Checkbox from '@/views/Checkbox'
import { deleteIcon, sortDownIcon, sortUpIcon } from '@/images'
import { BUTTON_THEME, BUTTON_VARIANT } from '@/const'

import style from '../styles/index.module.scss'

const Header = ({
  handleDeleteCargo,
  handleClickHeader,
  handleSubmit,
  values,
  setFieldValue,
  headers,
  handleCheckAll,
  checkID,
  allID
}) => (
  <thead className={style.table_head}>
    <tr>
      {headers.map((header, index) => {
        return (
          <th
            key={header.id}
            className={classNames(style.table_head__item, {
              [style['table_head__item-delete']]: checkID?.length > 0
            })}
            style={{ width: header.width, ...header.style }}
          >
            {index === 0 && (
              <div className={style.table_head__checkbox}>
                <Checkbox
                  checked={checkID?.length > 0 && checkID?.length === allID?.length}
                  onGx-change={handleCheckAll}
                  disabled={allID?.length === 0}
                />
                {checkID?.length > 0 && (
                  <Button
                    theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
                    circle
                    className={style.table_head__btn}
                    onClick={handleDeleteCargo}
                  >
                    <GxIcon src={deleteIcon} className={style.table_head__icon} />
                  </Button>
                )}
              </div>
            )}
            <Button
              onClick={() =>
                handleClickHeader(
                  header.ordering,
                  setFieldValue,
                  handleSubmit,
                  values
                )}
              variant={BUTTON_VARIANT.TEXT}
              theme={
                header.sort ? BUTTON_THEME.TABLE.SORT : BUTTON_THEME.TABLE.NO_SORT
              }
              data-up={values.ordering === header.ordering}
              data-down={values.ordering === `-${header.ordering}`}
            >
              {header.title}
              {header.sort
                ? (
                  <div slot='icon-right' className={style.table_sort_icons}>
                    <GxIcon src={sortUpIcon} className={style.table_sort__icon_up} />
                    <GxIcon
                      src={sortDownIcon}
                      className={style.table_sort__icon_down}
                    />
                  </div>
                  )
                : null}
            </Button>
          </th>
        )
      })}
      <th className={style.table_head__item} />
    </tr>
  </thead>
)

export default Header
