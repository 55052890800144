import React from 'react'
import { HashLink as HashRouterLink } from 'react-router-hash-link'

const HashLink = ({
  children,
  id = '#',
  link,
  handleClick,
  ...props
}) => {
  const url = `${link}${id}`
  return (
    <HashRouterLink
      onClick={handleClick}
      to={url}
      {...props}
    >
      {children}
    </HashRouterLink>
  )
}

export default HashLink
