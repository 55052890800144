const bitrixUTMModule = (store) => {
    store.on('@init', () => ({
        bitrixUTM: {
            utm_source: '',
            utm_medium: '',
            utm_campaign: '',
            utm_term: '',
            utm_content: ''
        }
    }))
    store.on('bitrixUTM/set', ({ bitrixUTM }, payload) => ({ bitrixUTM: { ...payload } }))
}

export default bitrixUTMModule