import React from 'react'
import { ChangeCompanyOwner } from '../../../views/BaseModal/ModalContent'
import useSelectUser from '../../../apps/appCompany/hooks/useSelectUser'
import useChangeOwner from '../../../apps/appCompany/hooks/useChangeOwner'
import { useStoreon } from 'storeon/react'

const ChangeCompanyOwnerModal = ({ handleClose }) => {
  const selectProps = useSelectUser()
  const { currentUser } = useStoreon('currentUser')

  const mutation = useChangeOwner()

  const handleAccept = () => {
    mutation.mutate({
      newOwnerID: selectProps.value.id,
      companyID: currentUser.company.id
    })
  }

  const context = {
    selectProps,
    handleAccept,
    handleClose
  }
  return (
    <ChangeCompanyOwner {...context} />
  )
}

export default ChangeCompanyOwnerModal
