import { useCallback } from 'react'
import qs from 'query-string'
import { getQueryString } from '../../../../utils'
import { useHistory } from 'react-router-dom'

const useShowUnreadNotifications = () => {
  const history = useHistory()
  const handleShowUnread = useCallback(
    (e) => {
      const checked = e.target.checked
      const params = qs.parse(history.location.search)
      if (checked) {
        params.is_read = false
      } else {
        delete params.is_read
      }
      const search = getQueryString(params)
      history.replace({ search })
    },
    [history.location.search]
  )
  return handleShowUnread
}

export default useShowUnreadNotifications
