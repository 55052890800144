import { baseApiService } from "../../../api/services";

/* TODO: возможно удалить */
const countriesModule = (store) => {
    store.on('@init', () => ({ countries: [] }))
    store.on('countries/save', ({ countries }, { new_countries }) => {
        return {
            countries: new_countries
        }
    })

    store.on('countries/get', async ({ countries }, { handleSuccess, handleError }) => {
        try {
            const data = await baseApiService.getCountry()

            if (data) {
                store.dispatch('countries/save', { new_countries: data })
                handleSuccess && handleSuccess()
            }
        } catch (e) {
            handleError && handleError(e.message)
        }
    })
}

export default countriesModule