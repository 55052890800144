class YandexMetrik {
  constructor () {
    this.id = 86465851
  }

  send (type, value) {
    window.ym && window.ym(this.id, type, value)
  }
}

const metrik = new YandexMetrik()
export default metrik
