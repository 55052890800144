import BaseApi from '@garpix/base-api';
import Cookies from 'universal-cookie';

import { runtimeConfig } from '../config';
import { setCookie, isServer } from '../utils';
import { LOCALE } from '../const'

const cookies = new Cookies()
export class Api extends BaseApi {
  AUTH_TOKEN_KEY = null
  REFRESH_TOKEN_KEY = null

  constructor(url) {
    super(url)
    this.AUTH_TOKEN_KEY = 'auth_token_key'
    this.REFRESH_TOKEN_KEY = 'refresh'
  }

  getLanguage = () => runtimeConfig.RAZZLE_APP_LOCALE || LOCALE.RU;

  getAuthToken = () => cookies.get(this.AUTH_TOKEN_KEY)

  setAuthToken = (token) => setCookie(this.AUTH_TOKEN_KEY, token, 150)

  removeAuthToken = () => cookies.remove(this.AUTH_TOKEN_KEY)

  setRefreshToken = (token) => cookies.set(this.REFRESH_TOKEN_KEY, token)

  getRefreshToken = () => cookies.get(this.REFRESH_TOKEN_KEY)

  removeRefreshToken = () => cookies.remove(this.REFRESH_TOKEN_KEY)

  axiosOverride = (axios) => {
    axios.defaults.headers.common['Accept-Language'] = this.getLanguage()
    this._setRequestInterseptor(axios)
    this._setResponseInterseptor(axios)

    return axios
  }

  _setRequestInterseptor = (axiosInstance) => {
    axiosInstance.interceptors.request.use((config) => {
      if (!isServer()) {
        const token = this.getAuthToken()
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
        if ('Authorization' in config.headers && !token) {
          delete config.headers.Authorization
        }
      }
      return config
    })
  }

  _setResponseInterseptor = (axiosInstance) => {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      async (error) => {
        if (isServer()) return Promise.reject(error)

        const originalConfig = error.config
        const response = error.response
        const isSendRefresh =
          (response.status === 401 || response.status === 403) && 
          Boolean(this.getRefreshToken())

        try {
          if (isSendRefresh && !originalConfig._retry) {
            originalConfig._retry = true
            await this.refresh()
            const accesToken = this.getAuthToken()
            axiosInstance.defaults.headers.common.Authorization = `Bearer ${accesToken}`
            return axiosInstance(originalConfig)
          }
        } catch (error) {
          return Promise.reject(error)
        }
        return Promise.reject(error)
      }
    )
  }

  refresh = async () => {
    try {
      const refresh_token = this.getRefreshToken()
      const res = await this.post('/auth/refresh/', { refresh_token })
      const data = res.data
      this.setAuthToken(data.access_token)
      return res.data
    } catch {
      this.removeAuthToken()
      this.removeRefreshToken()
    }
  }

  getLanding = async (params) => {
    const res = await this.get('/landing/')
    return res.data
  }

  getCountry = async () => {
    const res = await this.get('/country/')
    return res.data
  }
}

const API_URL = runtimeConfig.API_URL

const api = new Api(`${String(API_URL)}`)
export default api
