/* eslint-disable camelcase */
import React, { memo } from 'react'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'
import { FetcherList } from '@garpix/fetcher'
import Help from './Help'
import { contentApiService } from '../../api/services'

const HelpContainer = ({ type, ...props }) => {
  const { search } = useLocation()
  const { title__icontains } = qs.parse(search)

  return (
    <FetcherList isHistoryPush={false} api={contentApiService.getFAQ}>
      {({
        results = [],
        status,
        loadData,
        filterParams,
        reload,
        activePage,
        showMore,
        isNext
      }) => {
        const fetcherActions = {
          showMore,
          reload,
          loadData,
          filterParams,
          isNext,
          status,
          activePage
        }
        return (
          <Help
            title__icontains={title__icontains}
            fetcherActions={fetcherActions}
            results={results}
            {...props}
          />
        )
      }}
    </FetcherList>
  )
}

export default memo(HelpContainer)
