import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'

import useHyphenate from '../../hooks/useHyphenate'

import { crown, infinity } from '../../images'

import styles from './styles/index.module.scss'

const configProgress = {
  rotation: 0,
  strokeLinecap: 'butt',
  textSize: '16px',
  pathTransitionDuration: 0.5,
  pathColor: 'var(--gx-color-blue)',
  textColor: 'var(--gx-color-primary)',
  trailColor: 'var(--gx-color-input-hover)',
  backgroundColor: '#3e98c7'
}

const expirationConfigProgress = {
  ...configProgress,
  pathColor: '#fb4e4e'
}

const Title = ({ title, showIcon }) => {
  const hyphenTitle = useHyphenate(title)
  const intl = useIntl()
  return (
    <div>
      <div className={styles.tariff_info__title}>
        {showIcon && <GxIcon slot='icon-left' src={crown} className={styles.tariff_info__icons} />}
        {intl.formatMessage({
          id: 'youTariff',
          defaultMessage: 'Ваш тариф'
        })}:
      </div>
      <div className={styles.tariff_info__name}>{hyphenTitle}</div>
    </div>
  )
}

const Progressbar = ({ currentTariff }) => {
  const isDangerExpiration = currentTariff.is_change_color
  const intl = useIntl()
  return (
    <div className={styles.tariff_info__progress}>
      <CircularProgressbar
        styles={buildStyles(isDangerExpiration ? expirationConfigProgress : configProgress)}
        value={currentTariff?.is_free ? 0 : currentTariff?.progressValue}
        maxValue={currentTariff?.is_free ? 10 : currentTariff?.progressMaxValue}
        strokeWidth={5}
      />
      {currentTariff?.is_free
        ? (
          <span className={styles.tariff_info__progress_text}>
            <GxIcon src={infinity} />
          </span>
          )
        : currentTariff?.restDay >= 1
          ? (
            <div className={styles.tariff_info__progress_text}>
              <div className={classNames(styles.tariff_info__progress_count, { [styles['tariff_info__progress_count--danger']]: isDangerExpiration })}>
                {currentTariff?.restDay}
              </div>
              {
                intl.formatNumberToParts(currentTariff?.restDay, {
                  style: 'unit',
                  unit: 'day',
                  unitDisplay: 'long'
                })[2].value
              }
            </div>
            )
          : (
            <div className={styles.tariff_info__progress_text}>
              <div className={classNames(styles.tariff_info__progress_count, { [styles['tariff_info__progress_count--danger']]: isDangerExpiration })}>
                {currentTariff?.restHour}
              </div>
              {
                intl.formatNumberToParts(currentTariff?.restHour, {
                  style: 'unit',
                  unit: 'hour',
                  unitDisplay: 'long'
                })[2].value
              }
            </div>
            )}
    </div>
  )
}

export default { Title, Progressbar }
