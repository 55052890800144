import { useCallback, useState } from 'react'
import { useIntl } from 'react-intl'
import { runtimeConfig } from '../config'

const useRecaptcha = () => {
  const [errorRecaptcha, setErrorRecaptcha] = useState('')
  const intl = useIntl()
  const errorMessage = intl.formatMessage({
    id: 'tryAgainLater',
    defaultMessage: 'Повторите позже'
  })

  const grecaptchaReady = useCallback(async (errorText = errorMessage, action = 'feedback') => {
    try {
      setErrorRecaptcha('')
      const token = await window.grecaptcha.execute(runtimeConfig.RAZZLE_RECAPTCHA_KEY, { action })
      return token
    } catch {
      setErrorRecaptcha(errorText)
    }
  }, [])

  return { grecaptchaReady, errorRecaptcha }
}

export default useRecaptcha
