import React from 'react'
import DOMPurify from 'isomorphic-dompurify'

const ToPreservedHtml = ({ html, className }) => {
  const clean = DOMPurify.sanitize(html)
  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: clean }} />
  )
}

export default ToPreservedHtml
