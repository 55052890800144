import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'
import { getValidateText } from '../ValidateText'

function checkOnContent (errMes) {
  return this.test('checkOnContent', errMes, function (value) {
    const { path, createError } = this
    if (!isNaN(Number(value))) {
      return createError({ path, message: errMes })
    }
    return true
  })
}

function matchPasses (errMes) {
  return this.test('matchPasses', errMes, function (value) {
    const { path, createError, parent } = this
    if (parent.password !== value) {
      return createError({ path, message: errMes })
    }
    return true
  })
}

yup.addMethod(yup.string, 'checkOnContent', checkOnContent)
yup.addMethod(yup.string, 'matchPasses', matchPasses)

const RegisterEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email(INTL_ERROR_MESSAGE.EMAIL)
    .required(INTL_ERROR_MESSAGE.REQUIRED)
})

const RegisterCodeSchema = yup.object().shape({
  code: yup.string().required(INTL_ERROR_MESSAGE.REQUIRED)
})

const RegistrFormSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(/^[а-яА-ЯёЁa-zA-Z\-\s]+$/, { message: INTL_ERROR_MESSAGE.INVALID_NAME })
    .trim()
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  last_name: yup
    .string()
    .matches(/^[а-яА-ЯёЁa-zA-Z\-\s]+$/, {
      message: INTL_ERROR_MESSAGE.INVALID_FULLNAME
    })
    .trim()
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  phone: yup
    .string()
    .min(11, INTL_ERROR_MESSAGE.REQUIRED)
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  password: yup
    .string()
    .trim()
    .required(INTL_ERROR_MESSAGE.REQUIRED)
    .min(8, INTL_ERROR_MESSAGE.SHORT_PASSWORD)
    .checkOnContent(INTL_ERROR_MESSAGE.ONLY_NUMBER_PASSWORD),
  repassword: yup
    .string()
    .trim()
    .matchPasses(INTL_ERROR_MESSAGE.MATCH_PASS)
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  agreement: yup.boolean().oneOf([true], ''),
  promo_code: yup
    .string()
    .trim()
    .min(4, getValidateText('string', 'min', 4))
    .max(32, getValidateText('string', 'max', 32))
})

export default { RegisterEmailSchema, RegisterCodeSchema, RegistrFormSchema }
