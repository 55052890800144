import style from '../styles/accordion.module.scss'

/**
 *
 * @view
 * * вью для реализации анимации аккордиона (откр/закр)
 * @param {boolean}   isOpen
 * @param {ReactNode} children
 */

const Accordion = ({ isOpen, children }) => {
  return (
    <div className={style.accordion} data-open={isOpen}>{children}</div>
  )
}

export default Accordion
