/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import { useFormik } from 'formik'
import { NewProjectSchema } from '../../../utils/ValidateSchemes'
import { convertError } from '../../../utils'
import { Create } from '../../../views/BaseModal/ModalContent'
import { projectApiService } from '../../../api/services'
import useTranslate from '../../../hooks/useTranslate'

const title = {
  id: 'createProject',
  defaultMessage: 'Создать проект'
}
const inputLabel = {
  id: 'modals.createProject.inputLabel',
  defaultMessage: 'Название'
}
const acceptButton = {
  id: 'create',
  defaultMessage: 'Создать'
}

const CreateProjectModal = ({ handleClose }) => {
  const { dispatch } = useStoreon()
  const history = useHistory()
  const [submitError, setSubmitError] = useState(null)
  const { translate, intl } = useTranslate()

  const formik = useFormik({
    initialValues: { title: '' },
    validationSchema: NewProjectSchema,
    onSubmit: async (values) => {
      try {
        const { absolute_url } = await projectApiService.createProject(values)
        dispatch('user/get', { handleAccess: () => {}, handleError: () => {} })
        handleClose()
        history.push(absolute_url)
      } catch (e) {
        const error = translate(convertError(e))
        setSubmitError(error)
      }
    }
  })

  useEffect(() => {
    submitError && setSubmitError(null)
  }, [formik.values.title, submitError])

  return (
    <Create
      formik={formik}
      title={intl.formatMessage(title)}
      inputLabel={intl.formatMessage(inputLabel)}
      buttonLabel={intl.formatMessage(acceptButton)}
      closeModal={handleClose}
      submitError={submitError}
    />
  )
}

export default CreateProjectModal
