import * as yup from 'yup'
import { getPasswordValidateGaz } from './PasswordGazpromValidate'
import { INTL_ERROR_MESSAGE } from '../../const'

function checkOnContent (errMes) {
  return this.test('checkOnContent', errMes, function (value) {
    const { path, createError } = this
    if (!isNaN(Number(value))) {
      return createError({ path, message: errMes })
    }
    return true
  })
}

function matchPasses (errMes) {
  return this.test('matchPasses', errMes, function (value) {
    const { path, createError, parent } = this
    if (parent.new_password !== value) {
      return createError({ path, message: errMes })
    }
    return true
  })
}

yup.addMethod(yup.string, 'checkOnContent', checkOnContent)
yup.addMethod(yup.string, 'matchPasses', matchPasses)

const PasswordSchema = (passwordConfig) => yup.object().shape({
  old_password: yup.string().trim().required(INTL_ERROR_MESSAGE.REQUIRED),
  ...getPasswordValidateGaz(passwordConfig, {
    password: 'new_password',
    repeatPassword: 'password2'
  })
})

export default PasswordSchema
