import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { GxIcon } from '@garpix/garpix-web-components-react'

import style from '../style/index.module.scss'

const NavPanel = ({ mainMenuLinks, activePage, isProjectPage, showOnMobile, className }) => {
  return (
    <nav
      data-page={activePage}
      className={classNames({
        [style['header_nav--project']]: isProjectPage,
        [style['header_nav--hidden']]: !showOnMobile,
        [className]: !!className
      })}
    >
      <div className={style['header_nav-wrap']}>
        {mainMenuLinks &&
          mainMenuLinks.map((link) => {
            return (
              <Link
                data-cy={`${link.model_name}-tab-link`}
                key={link.id}
                to={link.url}
                className={classNames({
                  [style.header_nav__link]: true,
                  [style['header_nav__link--active']]:
                    activePage === link?.model_name
                })}
              >
                <GxIcon src={link.icon} className={style.header_nav__link_icon} />
                {link.title}
              </Link>
            )
          })}
      </div>
    </nav>
  )
}

export default NavPanel
