import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import GroupDropdown from '../views/ListPageViews/GroupDropdown'
import { ENTITY_TYPES } from '../const'
import { useModalStore } from '../hooks/Modals'

const PageMenuDropdown = ({
  element,
  type,
  elementId,
  reload,
  loadData,
  actions,
  results,
  activePage,
  reset
}) => {
  const { openModal } = useModalStore()
  const checkOnVisibleRename = useMemo(() => type !== ENTITY_TYPES.CARGO_SPACE ? true : +element.user, [type, element.user])

  const elementTitle = useRef(element)

  useEffect(() => {
    elementTitle.current = element
  }, [element])

  const remove = useCallback((e) => {
    e.stopPropagation()
    const modalProps = {
      removeHandler: actions.remove,
      itemID: elementId,
      itemTitle: element?.title,
      type,
      results,
      activePage,
      loadData,
      reload,
      reset
    }
    openModal('removePageItem', modalProps)
  }, [])

  const copy = useCallback((e) => {
    e.stopPropagation()
    const modalProps = {
      copyHandler: actions.copy,
      itemID: elementId,
      itemTitle: element?.title,
      type,
      reload,
      reset
    }
    openModal('copyPageItem', modalProps)
  }, [])

  const rename = useCallback((e) => {
    e.stopPropagation()
    const modalProps = {
      renameHandler: actions.rename,
      itemTitle: elementTitle.current?.title,
      itemID: elementId,
      type,
      reload,
      reset
    }
    openModal('renamePageItem', modalProps)
  }, [])

  const action = {
    copy,
    remove,
    rename
  }
  return (
    <GroupDropdown visibleRename={checkOnVisibleRename} action={action} />
  )
}

export default PageMenuDropdown
