import React from 'react'
import { GxForm, GxIcon } from '@garpix/garpix-web-components-react'
import Textarea from '../../../Textarea'
import Button from '../../../Button'
import ModalView from '../../../ModalView'
import { BUTTON_THEME, FORM_TYPE } from '../../../../const'
import { dollar } from '../../../../images'
import styles from '../styles/index.module.scss'
import { defineMessage } from 'react-intl'
import useTranslate from '../../../../hooks/useTranslate'
import style from '../../../../apps/appPromocodes/views/styles/promocodes.module.scss'

const withdrawalMessageLabel = defineMessage({
  id: 'modals.promoCash.withdrawalMessageLabel',
  defaultMessage: 'Комментарий'
})

const messageLabel = defineMessage({
  id: 'modals.promoCash.messageLabel',
  defaultMessage: 'Укажите описание промокода'
})
const PromoCash = ({
  formik,
  title,
  formType,
  handleClose,
  partnerBalance
}) => {
  const {
    handleSubmit,
    handleChange,
    isSubmitting,
    errors,
    values,
    touched
  } = formik
  const { translate, intl } = useTranslate()
  return (
    <>
      {title && (
        <ModalView.Title>
          {title}
        </ModalView.Title>
      )}
      {formType === FORM_TYPE.withdrawal_request && (
        <>
          <div className={styles['submit_request__action_price-block']}>
            <GxIcon
              src={dollar}
              className={styles['submit_request__action_price-icon']}
            />
            <p className={styles['submit_request__action_price-text']}>
              {intl.formatMessage({
                id: 'balance',
                defaultMessage: 'Баланс'
              })}: <span className={style['promocode__action_price-subtext']}>{partnerBalance} ₽</span>
            </p>
          </div>
          <div className={styles.submit_request__description}>
            <p className={styles['submit_request__description-text']}>
              {
                intl.formatMessage({
                  id: 'modals.promoCash.description',
                  defaultMessage: 'С вашего баланса будет списана вся сумма.'
                })
              }
            </p>
          </div>
        </>
      )}
      <GxForm onGx-submit={handleSubmit}>
        <div className={styles.submit_request__input}>
          <Textarea
            name='message'
            onGx-input={handleChange}
            required
            value={values.message}
            label={formType === FORM_TYPE.withdrawal_request ? translate(withdrawalMessageLabel) : translate(messageLabel)}
            error={touched.message && !!errors.message}
            errorText={translate(errors.message)}
          />
        </div>
        <div className={styles['submit_request__action-buttons']}>
          <Button
            onClick={handleClose}
            theme={BUTTON_THEME.FILL_ICON.WHITE}
            disabled={isSubmitting}
            className={styles['submit_request__action-button']}
          >
            {
              intl.formatMessage({
                id: 'cancel',
                defaultMessage: 'Отмена'
              })
            }
          </Button>
          <Button
            type='submit'
            theme={BUTTON_THEME.FILL_ICON.SMALL}
            disabled={isSubmitting}
            className={styles['submit_request__action-button']}
          >
            {
              intl.formatMessage({
                id: 'modals.promoCash.acceptButton',
                defaultMessage: 'Отправить сообщение'
              })
            }
          </Button>
        </div>
      </GxForm>
    </>
  )
}

export default PromoCash
