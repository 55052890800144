import React, { useEffect, useMemo, useState } from 'react'
import { AddStaff } from '../../../views/BaseModal/ModalContent'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from 'react-query'
import { companyApiService, userApiService } from '../../../api/services'
import { convertError, debounce, hasSomeObjKeys, isBoolean } from '../../../utils'
import { useStoreon } from 'storeon/react'
import { useModalIntlContent, useModalStore } from '../../../hooks/Modals'
import { AddStaffModalSchema, EmailSchema } from '../../../utils/ValidateSchemes'
import { COMPANY_QUERY } from '../../../apps/appCompany/const'

const noExistUserSerializer = user => {
  return {
    email: user.email,
    last_name: user.surname,
    first_name: user.name,
    role: Number(user.role)
  }
}

const AddStaffModal = ({ handleClose, roles }) => {
  const { currentUser } = useStoreon('currentUser')
  const queryClient = useQueryClient()
  const { openModal, contentTypes } = useModalStore()
  const successContentModal = useModalIntlContent(contentTypes.successInvite)
  const errorContentModal = useModalIntlContent(contentTypes.warning)
  const [isExist, setExist] = useState(null)

  const inviteMutation = useMutation({
    mutationFn: params => isExist ? companyApiService.invite(params) : companyApiService.createAndInvite(params),
    onSuccess: () => openModal('commonConfirm', successContentModal),
    onError: (error) => {
      const user = error?.response?.data?.user
      if (isExist && user) {
        openModal('userInCompany', { email: formik.values.email })
      } else {
        const err = convertError(error)
        openModal('commonConfirm', { ...errorContentModal, description: err })
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([COMPANY_QUERY.COMPANY_TAB_COUNT])
      queryClient.invalidateQueries([COMPANY_QUERY.STAFF_LIST])
      queryClient.invalidateQueries([COMPANY_QUERY.INVITATION_LIST])
    }
  })

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      surname: '',
      role: roles[0].id
    },
    onSubmit: (values) => {
      const serializedUser = isExist
        ? { role: Number(values.role), user: emailMutation.data.user_id }
        : noExistUserSerializer(values)
      inviteMutation.mutate({ ...serializedUser, companyID: currentUser?.company?.id })
    },
    validationSchema: isExist === false ? AddStaffModalSchema : EmailSchema,
    enableReinitialize: true
  })

  const emailMutation = useMutation({
    mutationFn: userApiService.checkUserExist,
    onSuccess: (data) => {
      setExist(data.exist)
    },
    onError: (err) => {
      const errors = err.response?.data
      if (errors && hasSomeObjKeys(errors, formik.values)) {
        formik.setErrors(errors)
        isBoolean(isExist) && setExist(null)
      }
    }
  })

  const debounceHandler = useMemo(() => debounce(emailMutation.mutate, 300), [])

  useEffect(() => {
    if (!formik.errors.email && formik.values.email) {
      debounceHandler(formik.values.email)
    }
  }, [formik.errors.email, formik.values.email])

  const context = {
    isEmailLoading: emailMutation.isLoading,
    formik,
    roles,
    handleClose,
    isExist
  }
  return (
    <AddStaff {...context} />
  )
}

export default AddStaffModal
