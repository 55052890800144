/* eslint-disable camelcase */
import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

import { TITLE_VARIANT, TITLE_TYPE } from '../../../const'

import ContactUs from '../ContactUs'
import LocationsInfo from '../LocationsInfo'
import Title from '../../Title'

import style from '../styles/index.module.scss'

/**
 * @view
 * * Вью Футера реализующий интерфейс блока с контактой инфой
 *
 * @param {Object} footerComponent   Обьект содержащий данные о комп футера (бэк)
 * @param {string} garpixUrl
 */

const ContactsInfo = ({ footerComponent, actionButton }) => {
  const intl = useIntl()
  const socialMedia = footerComponent?.object?.social_media

  return (
    <div
      className={classNames(style['first-part__left-col'], {
        [style['first-part__left-col_flex']]: !actionButton
      })}
    >
      <div className={style['first-part__top']}>
        <Title type={TITLE_TYPE.H2} variant={TITLE_VARIANT.FOOTER_H2}>
          {intl.formatMessage({
            id: 'contacts',
            defaultMessage: 'Контакты'
          })}
        </Title>
        <div className={style['first-part__contact-info']}>
          {footerComponent?.object && <ContactUs {...footerComponent.object} />}
          {
            socialMedia?.length > 0 && (
              <div className={style['first-part__social_links']}>
                {socialMedia.map(({ id, icon, link, name }) => (
                  <a
                    key={id}
                    href={link}
                    target='_blank' rel='noreferrer'
                  >
                    <img src={icon} width='50' height='50' alt={name} />
                  </a>
                ))}
              </div>
            )
          }
        </div>
      </div>
      <div className={style['first-part__bottom']}>
        {footerComponent?.object && <LocationsInfo {...footerComponent.object} />}
        <div className={style['legal-info']}>
          {footerComponent?.object?.extra_info.length > 0 &&
            footerComponent?.object?.extra_info.map(({ id, text }) => (
              <div className={style['legal-info__item']} key={id}>
                {text}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default ContactsInfo
