import React from 'react'
import { closeIcon } from '../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from './styles/index.module.scss'
import classNames from 'classnames'

const CloseButtonView = ({ closeModal, className }) => {
  return (
    <span className={classNames(styles.slote, { [className]: !!className })} slot='close-button'>
      <GxIcon
        className={styles.slote__icon}
        onClick={closeModal}
        src={closeIcon}
      />
    </span>
  )
}
export default CloseButtonView
