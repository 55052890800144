import React, { useMemo } from 'react'
import TariffAdvantageIcons from '../TariffAdvantageIconsView'
import TariffScale from '../TariffScale'
import TariffDetails from '../TariffDetails'
import styles from './styles/index.module.scss'
import Title from '../../../../views/Title'
import { TITLE_TYPE, TITLE_VARIANT } from '../../../../const'
import InnerHtml from '../../../../components/InnerHtml'
import { reduceAdvantages, sortAdvantages } from '../../helpers'
import { PROMOTION_TYPE } from '../../helpers/const'

const TariffInfo = ({ tariff, activeTab, currentUser, handleBuyTariff, tariffScale }) => {
  const {
    description,
    promotion_type: promotionType
  } = tariff

  const hasPromotionType = promotionType?.toLowerCase() === PROMOTION_TYPE.PRICE_PROMOTION

  const advantages = useMemo(() => reduceAdvantages(tariff.advantages), [tariff.advantages])
  const sortingAdvantages = useMemo(() => sortAdvantages(advantages), [advantages])
  return (
    <div className={styles.tariff_info__root}>
      <div className={styles.tariff_info__wrapper}>
        <div className={styles.tariff_info__wrapper_top}>
          {
          description && (
            <>
              <Title type={TITLE_TYPE.H6} variant={TITLE_VARIANT.H6}>
                Описание тарифа
              </Title>
              <InnerHtml html={description} className={styles.tariff_info__description} />
            </>
          )
        }
          <TariffAdvantageIcons advantages={sortingAdvantages.icon} />
        </div>
        {tariffScale && <TariffScale tariffScale={tariffScale} hasPromotionType={hasPromotionType} activeTab={activeTab} />}
      </div>
      <TariffDetails
        tariff={tariff}
        activeTab={activeTab}
        hasPromotionType={hasPromotionType}
        currentUser={currentUser}
        handleBuyTariff={handleBuyTariff}
        advantages={sortingAdvantages.value}
      />
    </div>
  )
}

export default TariffInfo
