import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'

const EmailSchema = yup.object().shape({
  email: yup
    .string()
    .required(INTL_ERROR_MESSAGE.REQUIRED)
    .email(INTL_ERROR_MESSAGE.EMAIL)
})

export default EmailSchema
