import classNames from 'classnames'
import React from 'react'
import InnerHtml from '../../../components/InnerHtml'
import { CARDS } from '../../../const'
import styles from './styles/index.module.scss'

const SectionHomePage = ({
  children,
  count,
  subtitle,
  title,
  id,
  theme,
  text,
  isNews,
  isBlue
}) => {
  const styleSection = theme && {
    '--background-color': theme.background_color,
    '--subtitle-color': theme.text_subtitle_color,
    '--title-color': theme.text_title_color,
    '--text-color': theme.text_color,
    '--accent-color-1': theme.accent_color_1,
    '--accent-color-2': theme.accent_color_2,
    '--title-color-card': theme.accent_text_card_color,
    '--background-card-color': theme.background_card_color,
    '--text-card-color': theme.text_card_color,
    '--font-custom': theme.font
  }
  const haveHeaderText = Boolean(title || subtitle || text)
  const suitabilityNotPadding = CARDS.SUITABILITY.id === id && !haveHeaderText

  return (
    <section
      data-cy={`${id}-home-section`}
      data-count={count}
      className={classNames(styles.section, {
        [styles.section_news]: isNews,
        [styles.section_blue]: !theme && isBlue,
        [styles.section_suitability]: suitabilityNotPadding
      })}
      id={id}
      style={styleSection}
    >
      <div className={styles.container}>
        {subtitle && (
          <div
            className={classNames(
              styles['section-subheading']
            )}
          >
            {subtitle}
          </div>
        )}
        {title && (
          <div
            className={classNames(styles['section-heading'], {
              [styles['section-heading_blue']]: !theme && isBlue
            })}
          >
            <h2 className={classNames(styles['section-heading_title'],
              theme?.font && styles['section-heading_title--weight'])}
            >
              {title}
            </h2>
          </div>
        )}
        {text && (
          <InnerHtml
            html={text}
            className={classNames(styles['section-heading__subTitle'],
              {
                [styles['section-heading__subTitle-prime']]: !isBlue && !theme
              })}
          />
        )}
        {children}
      </div>
    </section>
  )
}

export default SectionHomePage
