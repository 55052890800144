import {Api} from '../../index.js'
import { runtimeConfig } from '../../../config'


class ContainerApiService extends Api {
    getContainer = async ({ id }) => {
        const res = await this.get(`/cargo/container/${id}/`);
        return res.data;
    };

    getContainers = async (params) => {
        const res = await this.get('/cargo/container/', params);
        return res.data;
    };

    createContainer = async (params) => {
        const res = await this.post('/cargo/container/', params);
        return res.data;
    };

    deleteContainer = async ({ id }) => {
        const res = await this.delete(`/cargo/container/${id}/`);
        return res.data;
    };

    renameContainer = async ({ id, title }) => {
        const res = await this.patch(`/cargo/container/${id}/`, { title });
        return res.data;
    };

    updateContainer = async ({ id, ...params }) => {
        const res = await this.put(`/cargo/container/${id}/`, { ...params });
        return res.data;
    };

    copyContainer = async ({ id }) => {
        const res = await this.post(`/cargo/container/${id}/copy/`);
        return res.data;
    };
}

export default new ContainerApiService(runtimeConfig.API_URL)