import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import { CARDS } from '../../../const'
import PossibilitiesView from '../../../views/LandingView/Possibilities'

const Possibilities = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} isBlue id={CARDS.POSSIBILITIES.id} {...props}>
      <PossibilitiesView {...props} />
    </SectionHomePageWithButtons>
  )
}

export default Possibilities
