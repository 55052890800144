import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { closeDetails, save } from '../../../../images'
import classNames from 'classnames'
import styles from './styles/index.module.scss'

const Error = () => <GxIcon src={closeDetails} className={classNames(styles.tariff_table__icon, styles['tariff_table__icon--red'])} />
const Success = () => <GxIcon src={save} className={classNames(styles.tariff_table__icon, styles['tariff_table__icon--blue'])} />
export default {
  Error,
  Success
}
