import ProfilePanel from './ProfilePanel'
import Tariffs from './Tariffs'
import ProfileInfo from './ProfileInfo'
import CountrySelect from './CountrySelect'
import UserCurrentTariff from './UserCurrentTariff'
import HelpView from './HelpView'
import Settings from './Settings'
import Tariff from './Tariff'
import UserType from './UserType'
import ProfileNavigationView from './ProfileNavigationView'

export default {
  ProfileNavigationView,
  ProfilePanel,
  Tariffs,
  ProfileInfo,
  CountrySelect,
  UserCurrentTariff,
  HelpView,
  Settings,
  Tariff,
  UserType
}
