import React from 'react'
import { useIntl } from 'react-intl'
import { GxIcon } from '@garpix/garpix-web-components-react'

import { workBack, companyLogo } from '../../images'

import style from './styles/usercompany.module.scss'

const UserCompany = ({ userCompany, isFree }) => {
  const { full_title: companyTitle, role, logo } = userCompany
  const intl = useIntl()

  return (
    <div className={style.user_company}>
      <div className={style.user_company__top}>
        <p className={style.user_company__img}>
          <GxIcon src={workBack} />
          {intl.formatMessage({
            id: 'company',
            defaultMessage: 'Компания'
          })}:
        </p>
        {isFree && !role.role_type &&
          <p className={style.user_company__status}>не активно</p>}
      </div>
      <div className={style.user_company__bottom}>
        <img src={logo || companyLogo} alt='company icon' height='50' width='50' loading='lazy' />
        <div className={style.user_company__info}>
          {companyTitle && <h3 className={style.user_company__info_title}>{companyTitle}</h3>}
          {role && <p className={style.user_company__info_role}>{role?.title}</p>}
        </div>
      </div>
    </div>
  )
}

export default UserCompany
