import React from 'react'
import classNames from 'classnames'
import styles from './style/index.module.scss'

const CounterView = ({ count, className, ...props }) => {
  return (
    <div
      className={
        classNames(
          styles.counter,
          { [styles['counter--medium']]: count > 9 },
          { [styles['counter--large']]: count > 99 },
          { [className]: !!className }
        )
      }
      {...props}
    >
      {count > 99 ? '99+' : count}
    </div>
  )
}

export default CounterView
