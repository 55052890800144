import React, { useMemo } from 'react'
import HashLink from '../../views/HashLink/index'
import { useLocation } from 'react-router-dom'

const DirectLink = ({ children, link, ...props }) => {
  const location = useLocation()
  const pathname = useMemo(() => link || location.pathname, [location, link])

  return (
    <HashLink link={pathname} {...props}>
      {children}
    </HashLink>
  )
}

export default DirectLink
