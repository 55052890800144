import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'
import { getValidateText } from '../ValidateText'

const SubmitAppSchema = yup.object().shape({
  phone: yup
    .string()
    .trim()
    .min(11, getValidateText('phone', 'min', 11))
    .max(14, getValidateText('phone', 'max', 14))
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  email: yup
    .string()
    .trim()
    .email(INTL_ERROR_MESSAGE.EMAIL),
  message: yup
    .string()
    .trim()
    .max(500, INTL_ERROR_MESSAGE.MAX_LENGTH_MESSAGE)
})

export default SubmitAppSchema
