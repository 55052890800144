import React from 'react'
import style from './style/index.module.scss'
import { useStoreon } from 'storeon/react'
import { avatar24 } from '../../images'

const UserIcon = () => {
  const { currentUser } = useStoreon('currentUser')
  return (
    <div data-invite={currentUser?.show_marker} className={style.user__wrap}>
      <img
        src={currentUser.thumbnail || avatar24}
        className={style.user__wrap_img}
        alt='user photo'
        loading='lazy'
        height='30'
        width='30'
      />
    </div>
  )
}

export default UserIcon
