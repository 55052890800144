import React from 'react'
import { useStoreon } from 'storeon/react'
import { avatar24 } from '../../images'
import style from './styles/index.module.scss'

const Owner = ({
  src = '',
  first_name: firstName = '',
  last_name: lastName = '',
  company = false,
  type
}) => {
  const { currentUser } = useStoreon('currentUser')

  return (
    <div className={style.owner_wrap}>
      <div className={style.owner_image_wrap}>
        <img
          height='40px'
          width='40px'
          className={style.owner_image}
          src={src || avatar24}
        />
      </div>
      {currentUser.company
        ? (
          <span className={style.owner_title}>{`${firstName} ${lastName}`}</span>
          )
        : null}
    </div>
  )
}

export default Owner
