import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import LogoGridView from '../../../views/LandingView/LogoGrid'
import { CARDS } from '../../../const'

const LogoGrid = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} {...props} id={CARDS.LOGO_GRID.id}>
      <LogoGridView {...props} />
    </SectionHomePageWithButtons>
  )
}

export default LogoGrid
