import React from 'react'
import styles from './styles/index.module.scss'
import InnerHtml from '../../../../components/InnerHtml'
import Button from '../../../../views/Button'

const PaymentFormBtn = React.forwardRef((props, ref) => {
  const { children, html, ...restProps } = props
  return (
    <Button {...restProps} ref={ref}>
      {
            html && (
              <InnerHtml
                html={html}
                className={styles.payment_btn}
              />
            )
        }
      {children}
    </Button>
  )
})

export default PaymentFormBtn
