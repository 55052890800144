import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'


class PalletApiService extends Api {

  getPallets = async (params) => {
    const res = await this.get('/cargo/pallet/', params);
    return res.data;
  };

  getPallet = async ({ id }) => {
    const res = await this.get(`/cargo/pallet/${id}/`);
    return res.data;
  };

  updatePallet = async ({ id, ...params }) => {
    const res = await this.put(`/cargo/pallet/${id}/`, { ...params });
    return res.data;
  };

  renamePallet = async ({ id, title }) => {
    const res = await this.patch(`/cargo/pallet/${id}/`, { title });
    return res.data;
  };

  createPallet = async (params) => {
    const res = await this.post('/cargo/pallet/', params);
    return res.data;
  };

  deletePallet = async ({ id }) => {
    const res = await this.delete(`/cargo/pallet/${id}/`);
    return res.data;
  };

  copyPallet = async ({ id }) => {
    const res = await this.post(`/cargo/pallet/${id}/copy/`);
    return res.data;
  };
}


export default new PalletApiService(runtimeConfig.API_URL)