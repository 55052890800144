import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import Tooltip from '../Tooltip'
import { editIcon } from '../../images'
import style from './styles/index.module.scss'

const UpdateTableField = ({ date, lastChangedUser }) => {
  return (
    <>
      {lastChangedUser
        ? (
          <Tooltip placement='top' content={lastChangedUser?.last_name}>
            <div className={style.wrap}>
              <GxIcon className={style.icon} src={editIcon} />
              {date}
            </div>
          </Tooltip>
          )
        : (
          <div className={style.wrap}>
            <GxIcon className={style.icon} src={editIcon} />
            {date}
          </div>
          )}
    </>
  )
}

export default UpdateTableField
