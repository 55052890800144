import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'
import { notFoundSerializer, errorSerializer } from '../../serializers'


class PageApiService extends Api {
  getPage = async (path, queryParams = {}, axiosParams = {}) => {
    try {
      const res = await this.get(`/page${path}`, queryParams, axiosParams);
      const page = res.data;
      return {
        pageType: page.page_model,
        page,
        statusCode: 200,
      };
    } catch (error) {
      const status = error?.response?.status;
      switch (status) {
        case 404:
          return {
            pageType: '404',
            statusCode: 404,
            page: notFoundSerializer(error.response.data),
          };
        case 403:
          return {
            pageType: '404',
            statusCode: 403,
            page: notFoundSerializer(error.response.data),
          };
        case 401:
          return {
            pageType: '404',
            page: notFoundSerializer(error.response.data),
            statusCode: 401,
          };
        default:
          return {
            pageType: '500',
            page: errorSerializer(),
            statusCode: 500,
          };
      }
    }
  };
}


export default new PageApiService(runtimeConfig.API_URL)