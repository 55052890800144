import React from 'react'
import style from './styles/index.module.scss'
import DropdownViews from '../../../../views/DropdownViews'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { notificationsIcon } from '../../../../images'
import classNames from 'classnames'
import { BUTTON_THEME, BUTTON_VARIANT } from '../../../../const'
import Button from '../../../../views/Button'
import { useIntl } from 'react-intl'
import CounterView from '../../../components/views/Counter'

const NotificationsMenuView = ({ children, ...props }) => {
  const {
    count,
    handleShow,
    handleClose,
    open,
    isFetching,
    notifications,
    handleReadAll,
    isReadAllLoading,
    url
  } = props
  const intl = useIntl()
  return (
    <div className={style.notification}>
      <DropdownViews.Dropdown
        className={style.notification__dropdown}
        closeOnSelect={false}
        open={open}
        onGx-show={handleShow}
        onGx-hide={handleClose}
        hoist
        placement='bottom-end'
        theme='dropdown_notification'
      >
        <Button slot='trigger' caret theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}>
          <div className={classNames(style.notification__icon, { [style['notification__icon--active']]: open })}>
            <GxIcon src={notificationsIcon} />
          </div>
          {
            count > 0
              ? <CounterView count={count} className={style.notification__counter} />
              : null
          }
        </Button>
        <DropdownViews.Menu
          className={
            classNames(
              style.notification__menu,
              {
                [style['notification__menu--empty']]:
                isFetching || (Array.isArray(notifications) && notifications.length === 0)
              }
            )
          }
        >
          <DropdownViews.Item projectVariant='item_notification_title' className={style.notification__menu_header}>
            <div className={style.notification__menu_title}>
              {
                intl.formatMessage({
                  id: 'notifications',
                  defaultMessage: 'Уведомления'
                })
              }
            </div>
            <div>
              <Button
                disabled={count === 0 || isReadAllLoading}
                variant={BUTTON_VARIANT.TEXT}
                theme={BUTTON_THEME.TEXT_BLACK}
                onClick={() => handleReadAll()}
              >
                {
                  intl.formatMessage({
                    id: 'markAllAsRead',
                    defaultMessage: 'Отметить все как прочитанные'
                  })
                }
              </Button>
            </div>
          </DropdownViews.Item>
          {children}
          {
            !isFetching &&
            Array.isArray(notifications) &&
            notifications.length !== 0 && (
              <a href={url} className={style.notification__btn_link}>
                {
                  intl.formatMessage({
                    id: 'showAll',
                    defaultMessage: 'Показать все'
                  })
                }
              </a>
            )
          }
        </DropdownViews.Menu>
      </DropdownViews.Dropdown>
    </div>
  )
}

export default React.memo(NotificationsMenuView)
