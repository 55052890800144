/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import { HomeMenu } from '../../../views/BaseModal/ModalContent/'
import usePage from '../../../hooks/usePage'
import { useModalStore } from '../../../hooks/Modals'

const HeaderMenuModal = () => {
  const { closeModal } = useModalStore()
  const { baseSlug, page } = usePage()
  const headerContent = useMemo(() => page.init_state.components.find(
    ({ component_model }) => component_model === 'HeaderComponent') || [], [page])

  return (
    <HomeMenu
      closeModal={closeModal}
      content={headerContent?.object}
      baseSlug={baseSlug}
      page={page}
    />
  )
}

export default HeaderMenuModal
