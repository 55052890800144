import React from 'react'
import Header from '@/components/Table/Header'
import Body from './Body'

import { useUserTariffPermissions } from '@/hooks/useUserTariffPermissions'
import useTranslate from '@/hooks/useTranslate'

import {
  containerIcon,
  palletIcon,
  truckIcon,
  vanIcon,
  boxListType,
  cyliderType,
  tubeType,
  tireType
} from '@/images'

import style from '../styles/index.module.scss'

const HEADER_TYPES = {
  CARGO: 'cargo',
  CS: 'cargo_space',
  PROJECT: 'project'
}

const headers = {
  [HEADER_TYPES.CARGO]: [
    {
      id: 1,
      title: { id: 'title', defaultMessage: 'Название' },
      ordering: 'title',
      sort: true,
      width: '100%'
    },
    {
      id: 2,
      title: { id: 'type', defaultMessage: 'тип' },
      ordering: 'cargo_type',
      style: { width: '75px' },
      sort: false
    },
    {
      id: 3,
      title: { id: 'created', defaultMessage: 'Создано' },
      ordering: 'created_at',
      sort: true
    },
    {
      id: 4,
      title: { id: 'changed', defaultMessage: 'Изменено' },
      ordering: 'updated_at',
      sort: true
    },
    {
      id: 5,
      title: { id: 'owner', defaultMessage: 'Владелец' },
      ordering: 'project_owner',
      style: {
        width: '100%'
      },
      sort: true
    },
    { id: 6, title: '', ordering: 'demo', sort: false, width: '60px' }
  ],
  [HEADER_TYPES.CS]: [
    {
      id: 1,
      title: { id: 'title', defaultMessage: 'Название' },
      ordering: 'title',
      sort: true,
      width: '100%'
    },
    {
      id: 2,
      title: { id: 'type', defaultMessage: 'Тип' },
      ordering: 'cargo_space_type',
      sort: true
    },
    {
      id: 3,
      title: { id: 'created', defaultMessage: 'Создано' },
      ordering: 'created_at',
      sort: true
    },
    {
      id: 4,
      title: { id: 'changed', defaultMessage: 'Изменено' },
      ordering: 'updated_at',
      sort: true
    },
    {
      id: 5,
      title: { id: 'owner', defaultMessage: 'Владелец' },
      ordering: 'project_owner',
      sort: false
    },
    { id: 6, title: '', ordering: 'demo', sort: false, width: '60px' }
  ],
  [HEADER_TYPES.PROJECT]: [
    {
      id: 1,
      title: { id: 'title', defaultMessage: 'Название' },
      ordering: 'title',
      sort: true,
      width: '100%'
    },
    {
      id: 2,
      title: { id: 'download', defaultMessage: 'Скачать' },
      ordering: 'download',
      sort: false
    },
    {
      id: 3,
      title: { id: 'created', defaultMessage: 'Создано' },
      ordering: 'created_at',
      sort: true
    },
    {
      id: 4,
      title: { id: 'changed', defaultMessage: 'Изменено' },
      ordering: 'updated_at',
      sort: true
    },
    {
      id: 5,
      title: { id: 'owner', defaultMessage: 'Владелец' },
      ordering: 'project_owner',
      sort: false
    },
    {
      id: 6,
      title: { id: 'rated', defaultMessage: 'Оценено' },
      ordering: 'is_all_grades',
      sort: false
    },
    { id: 7, title: '', ordering: 'demo', sort: false, width: '60px' }
  ]
}

const icons = {
  truck: truckIcon,
  van: vanIcon,
  container: containerIcon,
  pallet: palletIcon,
  box: boxListType,
  cylinder: cyliderType,
  tube: tubeType,
  tire: tireType
}

const Table = ({ type, company, hasRatingColumn, ...props }) => {
  const { translate } = useTranslate()
  const { isShowDownloadingReports } = useUserTariffPermissions()

  let header = headers[type].map((header) => ({
    ...header,
    title: translate(header.title)
  }))

  if (type === HEADER_TYPES.PROJECT) {
    if (!company) {
      header = header.filter((el) => el.ordering !== 'project_owner')
    }
    if (!hasRatingColumn) {
      header = header.filter((el) => el.ordering !== 'is_all_grades')
    }
    if (!isShowDownloadingReports) {
      header = header.filter((el) => el.ordering !== 'download')
    }
  }

  return (
    <div className={style.table_wrap}>
      <table className={style.table}>
        <Header headers={header} type={type} {...props} />
        <Body
          type={type}
          icons={icons}
          headers={header}
          company={company}
          {...props}
        />
      </table>
    </div>
  )
}

export default Table
