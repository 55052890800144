import React from 'react'
import styles from '../styles/index.module.scss'
import { newLogoPro } from '../../../../images'

const ModalIconPro = () => {
  return (
    <div className={styles.icon_pro}>
      <img src={newLogoPro} />
    </div>
  )
}

export default ModalIconPro
