import React from 'react'
import { crown } from '../../../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from '../styles/index.module.scss'

const ModalIconCrown = () => {
  return (
    <div className={styles.icon_crown}>
      <GxIcon src={crown} />
    </div>
  )
}

export default ModalIconCrown
