import { GxTooltip } from '@garpix/garpix-web-components-react'
import React from 'react'
import style from './styles/index.module.scss'

const Tooltip = ({
  children,
  projectVariant = 'tooltip_default',
  placement = 'right',
  content = '',
  ...props
}) => {
  const hide = (e) => {
    e.stopPropagation()
  }
  return (
    <GxTooltip
      className={style[projectVariant]}
      placement={placement}
      content={content}
      onGx-hide={hide}
      {...props}
    >
      {children}
    </GxTooltip>
  )
}

export default Tooltip
