import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import { CARDS } from '../../../const'
import PartnersView from '../../../views/LandingView/Partners'

const Partners = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} id={CARDS.PARTNERS.id} {...props}>
      <PartnersView theme={props.content?.theme} {...props} />
    </SectionHomePageWithButtons>
  )
}

export default Partners
