import * as yup from 'yup'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../../const'
import { getValidateText } from '../../ValidateText'
import cargoSpaceSchema from '../cargoSpaceSchema'
import titleSchema from '../TitleSchema'

const PalletSchema = yup.object().shape({
  ...titleSchema,
  ...cargoSpaceSchema,
  loading_length: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      INTL_ERROR_MESSAGE.LOADING_LENGTH_UP,
      (value, ctx) => value === 0 || !value || value >= ctx.parent.length
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  loading_width: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      INTL_ERROR_MESSAGE.LOADING_WIDTH_UP,
      (value, ctx) => value === 0 || !value || value >= ctx.parent.width
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  loading_height: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  margin_length: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.CEILING_MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.CEILING_MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)),
  margin_width: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.CEILING_MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.CEILING_MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)),
  indent_length: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.CEILING_MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.CEILING_MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)),
  indent_length_end: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.CEILING_MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.CEILING_MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)),
  indent_width: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.CEILING_MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.CEILING_MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER)),
  indent_width_right: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.CEILING_MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.CEILING_MIN_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
})

export default PalletSchema
