import { useStoreon } from 'storeon/react'

export const useCompanyUtils = () => {
  const { currentUser } = useStoreon('currentUser')

  const checkIsEnterprise = (enterpriseType) => {
    return currentUser?.company?.options?.enterprise === enterpriseType
  }

  return {
    checkIsEnterprise,
    company: currentUser?.company ?? {}
  }
}
