import React from 'react'
import { fileIcon } from '../../../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from '../styles/index.module.scss'

const ModalIconFile = () => {
  return (
    <div className={styles.icon_file}>
      <GxIcon src={fileIcon} />
    </div>
  )
}

export default ModalIconFile
