import React from 'react'
import ProfileInfo from '../../components/ProfileComponent/ProfileInfo'
import UserCurrentTariff from '../../components/ProfileComponent/UserCurrentTariff'
import style from './styles/index.module.scss'

const Settings = ({ currentTariff, setCurrentTariff }) => {
  return (
    <div className={style.settings}>
      <UserCurrentTariff
        currentTariff={currentTariff}
        setCurrentTariff={setCurrentTariff}
      />
      <ProfileInfo />
    </div>
  )
}

export default Settings
