class Locator {
  constructor () {
    this.locator = new Map()
  }

  register (key, payload) {
    this.locator.set(key, payload)
  }

  get (key) {
    return this.locator.get(key)
  }

  getAll () {
    return [...this.locator.values()]
  }
}

export default Locator
