import { useEffect, useMemo, useState } from 'react'
import { paymentApiService, TariffApiService } from '../../api/services'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import { convertError } from '../../utils'
import { useModalIntlContent, useModalStore } from '../../hooks/Modals'

const findPressedTariff = ({ id, tariffs, pressedTariff }) => {
  const findTariff = tariffs[id].find(tariff => {
    if (!pressedTariff) {
      return tariff?.base_type?.basic
    }
    return tariff?.base_type?.title === pressedTariff?.base_type?.title
  })
  return findTariff || tariffs[id][0]
}

const findTariffScale = (tariffsWeb, pressedTariff) => {
  const tariffScale = []
  for (const item of tariffsWeb) {
    if (item.base_type === pressedTariff?.base_type.id) {
      tariffScale.push(item.tariff_scale)
    }
  }
  return tariffScale.sort((a, b) => a.price - b.price)
}

export const useTariff = (content) => {
  const { contentTypes, openModal } = useModalStore()
  const warningModalContent = useModalIntlContent(contentTypes.warning)
  const history = useHistory()
  const { tariffID } = qs.parse(history.location.search)
  const { tariffs, tariffs_web: tariffsWeb, periods } = content
  const {
    parsedTab,
    parsedTariff
  } = useMemo(() => {
    let parsedTab
    let parsedTariff
    if (tariffID) {
      for (let i = 0; i < periods.length; i++) {
        const tariff = tariffs[periods[i].id].find(tariff => tariff.tariff_id === Number(tariffID))
        if (tariff) {
          parsedTab = periods[i]
          parsedTariff = tariff
          break
        }
      }
    }
    return {
      parsedTab,
      parsedTariff
    }
  }, [])
  const [activeTab, setActiveTab] = useState(() => parsedTab || periods[0])
  const initPressedTariff = parsedTariff || findPressedTariff({ id: activeTab.id, tariffs, pressedTariff: null })
  const [pressedTariff, setPressedTariff] = useState(() => initPressedTariff)
  const tariffScale = findTariffScale(tariffsWeb, pressedTariff)
  const tableLinkOptions = useMemo(() => ({ 'data-pos': 'default', href: content.link }), [content.link])

  const proofPayment = async ({ tariff, isHold = false }) => {
    try {
      const res = await TariffApiService.buyTariff({ tariff: tariff.tariff_id, is_hold: isHold })
      const paymentRes = await paymentApiService.proofPayment({
        user_tariff_id: res.id,
        recurring: !!tariff?.auto_payment // undefined
      })
      const form = paymentRes.form
      return { ...paymentRes, form }
    } catch (e) {
      const error = convertError(e)
      openModal('commonConfirm', { ...warningModalContent, description: error })
    }
  }

  useEffect(() => {
    const baseTariff = findPressedTariff({ id: activeTab.id, tariffs, pressedTariff })
    const search = qs.stringify({ tariffID: baseTariff.tariff_id })
    history.replace({ ...history.location, search })
    setPressedTariff(baseTariff)
  }, [activeTab])
  return {
    tariffs,
    tariffScale,
    periods,
    activeTab,
    setActiveTab,
    proofPayment,
    tableLinkOptions,
    pressedTariff,
    setPressedTariff,
    tariffID
  }
}
