import React, { useCallback } from 'react'
import { useStoreon } from 'storeon/react'
import { useFormik } from 'formik'
import SubmitAppSchema from './../../../utils/ValidateSchemes/SubmitAppSchema'
import SendRequestView from '../../../views/BaseModal/ModalContent/SendRequest'
import usePage from '../../../hooks/usePage'
import { useModalIntlContent, useModalStore } from '../../../hooks/Modals'
import useRecaptcha from './../../../hooks/useRecaptcha'
import useBitrixUtm from '../../../hooks/useBitrixUTM'
import { contentApiService } from '../../../api/services'

const SendRequestModal = (props) => {
  const { btnInfo, currentUser } = useStoreon('btnInfo', 'currentUser')

  const { first_name: fullname, phone, email } = currentUser || {}
  const { title, handleAccept, action } = props
  const utm = useBitrixUtm()
  const { openModal, contentTypes } = useModalStore()
  const successModalContent = useModalIntlContent(contentTypes.help)
  const errorModalContent = useModalIntlContent(contentTypes.sendRequestError)

  const { grecaptchaReady, errorRecaptcha } = useRecaptcha()

  const sendFeedback = useCallback(async (data) => {
    try {
      const recaptcha = await grecaptchaReady()
      await contentApiService.sendFeedback({
        ...data,
        ...utm,
        form_type: btnInfo.formType,
        recaptcha
      })
      openModal('commonConfirm', successModalContent)
      action && action()
    } catch {
      openModal('commonConfirm', errorModalContent)
    }
  }, [])
  const { baseSlug } = usePage()
  const formik = useFormik({
    initialValues: { fullname, phone, email },
    onSubmit: handleAccept || sendFeedback,
    validationSchema: SubmitAppSchema
  })

  const handleChangePhone = (e) => {
    const phone = e.detail.value
    const phoneCountry = e.detail.country.countryCode
    formik.setFieldValue('phone', phone)
    formik.setFieldValue('phoneCountry', phoneCountry)
  }
  return (
    <SendRequestView
      title={title}
      formik={formik}
      handleChangePhone={handleChangePhone}
      baseSlug={baseSlug}
      errorRecaptcha={errorRecaptcha}
    />
  )
}

export default SendRequestModal
