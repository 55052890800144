import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'


class PartnerApiService extends Api {
  getPromocodes = async ({
    page,
    is_active,
    ordering,
    page_size = 10,
  }) => {
    const res = await this.get('/partner/promocode/', {
      page,
      is_active,
      ordering,
      page_size,
    });
    return res.data;
  };

  promocodeRequest = async ({  message = '' }) => {
    const res = await this.post(`/partner/promocode_request/`, {
      message,
    });
    return res.data;
  };

  cashoutRequest = async ({ message = '' }) => {
    const res = await this.post(`/partner/withdrawal_request/`, {
      message,
    });
    return res.data;
  };

  getPromocodeInfo = async (id) => {
    const res = await this.get(`/partner/promocode/${id}/`);
    return res.data;
  };
}


export default new PartnerApiService(runtimeConfig.API_URL)