import * as yup from 'yup'
import { getPasswordValidateGaz } from './PasswordGazpromValidate'

const ChangePasswordSchema = (passwordConfig) =>
  yup.object().shape(getPasswordValidateGaz(passwordConfig, {
    password: 'new_password',
    repeatPassword: 'new_password_2'
  }))

export default ChangePasswordSchema
