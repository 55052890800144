import { COLOR_SCENE } from "../../../const";

const settingsSceneModule = (store) => {
    const default_options = {
        enable_steps_color: false,
        enabled_dnd_sys: false,
        enabled_phx_sys: false,
        enabled_dndс_sys: false,
        enabled_context: false,
        enabled_aliasing: false,
        enabled_friction: true,
        enabled_mirrow_floor: true,
        color_ground: COLOR_SCENE.GROUND,
        color_sky: COLOR_SCENE.SKY
    }
    store.on('@init', () => ({
        settings_scene: default_options
    }))
    store.on('settings_scene/change', ({ settings_scene, ...props }, { settings_scene: new_settings_scene, ...data }) => {
        return {
            settings_scene: {
                ...settings_scene,
                ...new_settings_scene
            }
        }
    })
}

export default settingsSceneModule