import { TariffApiService } from "../../../api/services";


/* TODO: возможно на удаление */
const tariffsModule = (store) => {
    store.on('@init', () => ({ tariffs: [] }))
    store.on('tariffs/save', ({ tariffs }, { new_tariffs }) => {
        return {
            tariffs: [...tariffs, ...new_tariffs]
        }
    })

    store.on('tariffs/get', async ({ tariffs }) => {
        try {
            const freeTariffs = await TariffApiService.getFreeTariff()
            const { results } = await TariffApiService.getTariffs()
            const data = [freeTariffs, ...results]
            if (data) {
                store.dispatch('tariffs/save', { new_tariffs: data })
            }
        } catch (e) {
            store.dispatch('tariffs/save', { new_tariffs: [] })
        }
    })
}

export default tariffsModule