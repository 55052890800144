import React from 'react'
import { BUTTON_THEME } from '../../../../const'
import ModalView from '../../../ModalView'
import { FormattedMessage } from 'react-intl'

const QuitAccountView = ({ title, close, accept, handleClose, handleAccept }) => (
  <>
    <ModalView.Title>
      <FormattedMessage {...title} />
    </ModalView.Title>
    <ModalView.Buttons>
      <ModalView.Buttons.Button onClick={handleClose} theme={BUTTON_THEME.STROKE_ICON.SMALL}>
        <FormattedMessage {...close} />
      </ModalView.Buttons.Button>
      <ModalView.Buttons.Button onClick={handleAccept || handleClose}>
        <FormattedMessage {...accept} />
      </ModalView.Buttons.Button>
    </ModalView.Buttons>
  </>
)

export default QuitAccountView
