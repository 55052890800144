import React from 'react'
import SuitabilityView from '../../../views/LandingView/Suitability'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import { CARDS } from '../../../const'

const Suitability = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} isBlue id={CARDS.SUITABILITY.id} {...props}>
      <SuitabilityView.SuitabilitySlides {...props} />
    </SectionHomePageWithButtons>
  )
}

export default Suitability
