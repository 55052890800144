import React from 'react'
import { moveIcon } from '../../../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from '../styles/index.module.scss'

const ModalIconWarning = () => {
  return (
    <div className={styles.icon_move}>
      <GxIcon src={moveIcon} />
    </div>
  )
}

export default ModalIconWarning
