import React from 'react'
import styles from './styles/index.module.scss'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { doneIcon } from '../../../../images'

const TariffTextAdvantages = ({ advantages }) => {
  return (
    <div className={styles.advantages}>
      {advantages && advantages.length > 0 && (
        <ul className={styles.advantages__list}>
          {advantages.map((el) => {
            return (
              <li key={el.id} className={styles.advantages__item}>
                <GxIcon
                  slot='icon-left'
                  src={doneIcon}
                  className={styles.advantages__icon}
                />
                <p>{el.description}</p>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default TariffTextAdvantages
