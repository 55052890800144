/* eslint-disable camelcase */
import React from 'react'
import { GxForm } from '@garpix/garpix-web-components-react'
import Input from '../../../views/Input'
import Textarea from '../../../views/Textarea'
import InputPhone from './../../InputPhone'
import useBackForm from './../../../hooks/useBackForm'
import style from './styles/index.module.scss'
import { BUTTON_THEME } from '../../../const'
import useTranslate from '../../../hooks/useTranslate'
import ActionButton from '../../LandingView/ActionButton'

const ContactForm = ({ errorRecaptcha, sendFeedback, msgStatus, baseSlug, sendBtn }) => {
  const { form_type, text_title } = sendBtn
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    handleChangePhone,
    isSubmitting
  } = useBackForm(sendFeedback, form_type)
  const { translate, intl } = useTranslate()

  return (
    <div id='contacts' className={style['contact-form']}>
      <GxForm onGx-submit={handleSubmit}>
        <h3 className={style['contact-form__title']}>{intl.formatMessage({
          id: 'serviceOffers',
          defaultMessage: 'Предложения по работе сервиса'
        })}
        </h3>
        <div className={style['contact-form__item']}>
          <Input
            name='fullname'
            theme='input_footer_form'
            label={intl.formatMessage({
              id: 'firstName',
              defaultMessage: 'Имя'
            })}
            value={values.fullname}
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            error={touched.fullname && !!errors.fullname}
            errorText={translate(errors.fullname)}
          />
        </div>
        <div className={style['contact-form__item']}>
          <InputPhone
            country={values.phoneCountry}
            theme='input__footer-form'
            name='phone'
            disableDropdown
            label={intl.formatMessage({
              id: 'phone',
              defaultMessage: 'Телефон'
            })}
            value={values.phone}
            onGx-blur={handleBlur}
            onGx-change={handleChangePhone}
            error={touched.phone && errors.phone}
            errorText={translate(errors.phone)}
            required
          />
        </div>
        <div className={style['contact-form__item']}>
          <Input
            name='email'
            theme='input_footer_form'
            label={intl.formatMessage({
              id: 'email',
              defaultMessage: 'Электронная почта'
            })}
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            error={touched.email && !!errors.email}
            errorText={translate(errors.email)}
          />
        </div>
        <div className={style['contact-form__item']}>
          <Textarea
            name='message'
            theme='textarea_footer_form'
            label={intl.formatMessage({
              id: 'message',
              defaultMessage: 'Сообщение'
            })}
            rows='2'
            onGx-input={handleChange}
            onGx-blur={handleBlur}
            error={touched.message && !!errors.message}
            errorText={translate(errors.message)}
          />
        </div>
        {sendBtn &&
          <div className={style['contact-form__btn']}>
            {errorRecaptcha && (
              <p className={style['contact-form__btn_error']}>{errorRecaptcha}</p>
            )}
            <ActionButton
              actionButton={sendBtn}
              disabled={isSubmitting}
              type='submit'
              handleOpenModal={() => {}}
              className={style['contact-form__action-btn']}
              theme={BUTTON_THEME.FILL_ICON.LANDING}
            >
              {text_title}
            </ActionButton>
          </div>}
        {msgStatus && (
          <p className={style['contact-form__msg-status']}>{msgStatus}</p>
        )}
        <div className={style['contact-form__agree-text']}>
          {
            intl.formatMessage({
              id: 'modals.sendRequest.privacyPolicy',
              defaultMessage: 'Нажимая на кнопку «Отправить», я соглашаюсь с {br} <a>политикой конфиденциальности сайта</a>'
            },
            {
              br: <br />,
              a: chunks => (
                <a
                  href={baseSlug.pagePrivacyPolicy} target='_blank' className={style['contact-form__agree-link']}
                  rel='noreferrer'
                >{chunks}
                </a>
              )
            })
          }
        </div>
      </GxForm>
    </div>
  )
}

export default ContactForm
