import * as yup from 'yup'
import { INTL_ERROR_MESSAGE, ERROR_NUMBERS } from '../../const'
import { getValidateText } from '../ValidateText'

const indentationSchema = {
  indentation_length: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(0, getValidateText('number', 'min', 0))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      {
        id: 'schemes.indentation.length',
        defaultMessage: 'Значение суммы полей "по длине" должно быть меньше или равно разницы "длины" и "погрузочной длины"'
      },
      (value, ctx) => {
        return value === 0 || !value || value <= ((ctx.parent.length - ctx.parent.loading_length) - (ctx.parent.indentation_length_end || 0))
      }
    ),
  indentation_length_end: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(0, getValidateText('number', 'min', 0))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      {
        id: 'schemes.indentation.length',
        defaultMessage: 'Значение суммы полей "по длине" должно быть меньше или равно разницы "длины" и "погрузочной длины"'
      },
      (value, ctx) => value === 0 || !value || value <= ((ctx.parent.length - ctx.parent.loading_length) - (ctx.parent.indentation_length || 0))
    ),
  indentation_width: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(0, getValidateText('number', 'min', 0))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      {
        id: 'schemes.indentation.width',
        defaultMessage: 'Значение суммы полей "по ширине" должно быть меньше или равно разницы "ширины" и "погрузочной ширины"'
      },
      (value, ctx) => value === 0 || !value || value <= (ctx.parent.width - ctx.parent.loading_width) - (ctx.parent.indentation_width_right || 0)
    ),
  indentation_width_right: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(0, getValidateText('number', 'min', 0))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      {
        id: 'schemes.indentation.width',
        defaultMessage: 'Значение суммы полей "по ширине" должно быть меньше или равно разницы "ширины" и "погрузочной ширины"'
      },
      (value, ctx) => value === 0 || !value || value <= (ctx.parent.width - ctx.parent.loading_width) - (ctx.parent.indentation_width || 0)
    ),
  indentation_height_top: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(0, getValidateText('number', 'min', 0))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      {
        id: 'schemes.indentation.height',
        defaultMessage: 'Значение суммы полей "по высоте" должно быть меньше или равно разницы "высоты" и "погрузочной высоты"'
      },
      (value, ctx) => value === 0 || !value || value <= (ctx.parent.height - ctx.parent.loading_height) - (ctx.parent.indentation_height || 0)
    ),
  indentation_height: yup.number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(0, getValidateText('number', 'min', 0))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      {
        id: 'schemes.indentation.height',
        defaultMessage: 'Значение суммы полей "по высоте" должно быть меньше или равно разницы "высоты" и "погрузочной высоты"'
      },
      (value, ctx) => value === 0 || !value || value <= (ctx.parent.height - ctx.parent.loading_height) - (ctx.parent.indentation_height_top || 0)
    )
}

export default indentationSchema
