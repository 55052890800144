import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'
import { EmailSchema } from './index'

const AddStaffModalSchema = EmailSchema.shape({
  name:
        yup
          .string()
          .trim()
          .required(INTL_ERROR_MESSAGE.REQUIRED)
          .matches(/^[а-яА-ЯёЁa-zA-Z\\-]+$/, {
            message: INTL_ERROR_MESSAGE.INVALID_NAME
          }),
  surname:
        yup
          .string()
          .trim()
          .matches(/^[а-яА-ЯёЁa-zA-Z\\-]+$/, {
            message: INTL_ERROR_MESSAGE.INVALID_FULLNAME
          })
          .required(INTL_ERROR_MESSAGE.REQUIRED)
})

export default AddStaffModalSchema
