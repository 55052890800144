import React from 'react'
import styles from './styles/index.module.scss'
import { GxIcon } from '@garpix/garpix-web-components-react'
import Tooltip from '../../../../views/Tooltip'

const AdvantageIconsList = ({ advantages }) => {
  return (
    <div className={styles.advantages_icons}>
      {advantages && advantages.length > 0 && (
        <ul className={styles.advantages_icons__list}>
          {advantages.map((el) => {
            return el.title && el.icon
              ? (
                <li key={el.id}>
                  <Tooltip hoist content={el.title}>
                    <GxIcon src={el.icon} className={styles.advantages_icons__icon} />
                  </Tooltip>
                </li>
                )
              : null
          })}
        </ul>
      )}
    </div>
  )
}

export default AdvantageIconsList
