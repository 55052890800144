import React from 'react'
import styles from './styles/search.module.scss'
import Input from '../Input'
import { closeSmallIcon, searchIcon } from '../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { useIntl } from 'react-intl'

const SearchView = React.memo(({
  value = '',
  handleSearch,
  enableClearButton = true,
  maxLength = 100
}) => {
  const intl = useIntl()
  return (
    <Input
      theme='input_search'
      placeholder={intl.formatMessage({
        id: 'search',
        defaultMessage: 'Поиск'
      })}
      value={value}
      name='title__icontains'
      data-cy='input_search'
      maxLength={maxLength}
      onGx-input={handleSearch}
      clearable={enableClearButton}
    >
      <GxIcon
        src={searchIcon}
        slot='prefix'
        className={styles.search__icon}
      />
      Поиск
      <GxIcon
        src={closeSmallIcon}
        slot='clear-icon'
        className={styles.search__closeIcon}
      />
    </Input>
  )
})

export default SearchView
