import React from 'react'
import { useHistory } from 'react-router-dom'
import { useStoreon } from 'storeon/react'
import { useModalIntlContent, useModalStore } from '../../../hooks/Modals'
import usePage from '../../../hooks/usePage'
import { QuitAccountView } from '../../../views/BaseModal/ModalContent'
import { authApiService } from '../../../api/services'

const QuitAccountModal = (props) => {
  const { dispatch } = useStoreon('currentUser')
  const { baseSlug } = usePage()

  const history = useHistory()

  const { openModal, contentTypes } = useModalStore()
  const warningContent = useModalIntlContent(contentTypes.warning)

  const handleAccept = async () => {
    try {
      await authApiService.logout()
      history.push(baseSlug.pageHome)
      dispatch('user/clean')
      props.handleClose()
    } catch {
      openModal('commonConfirm', warningContent)
    }
  }
  return (
    <QuitAccountView {...props} handleAccept={handleAccept} />
  )
}

export default QuitAccountModal
