import React from 'react'

import { GxIcon } from '@garpix/garpix-web-components-react'

import Button from '../../../../views/Button'

import useTranslate from '../../../../hooks/useTranslate'

import { clockWiseIcon, reportFilesIcon } from '../../../../images'
import { BUTTON_THEME } from '../../../../const'

import styles from './styles/index.module.scss'
/**
 * @view
 * @param {obj} downloadAllReportsProps
 * @param {obj} downloadLastReportProps
 */
const DownloadReportView = ({
  downloadAllReportsProps,
  downloadLastReportProps
}) => {
  const { translate } = useTranslate()
  return (
    <div className={styles.wrap}>
      <Button
        {...downloadLastReportProps}
        theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
        className={styles.wrap__btn}
      >
        <GxIcon slot='icon-left' src={clockWiseIcon} />
        {translate({
          id: 'latestReport',
          defaultMessage: 'Последние отчеты'
        })}
      </Button>
      <Button
        {...downloadAllReportsProps}
        theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
        className={styles.wrap__btn}
      >
        <GxIcon slot='icon-left' src={reportFilesIcon} />
        {translate({
          id: 'allReports',
          defaultMessage: 'Все отчеты'
        })}
      </Button>
    </div>
  )
}

export default DownloadReportView
