import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'

const HelpSchema = yup.object().shape({
  theme: yup
    .string()
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  message:
        yup
          .string()
          .trim()
          .required(INTL_ERROR_MESSAGE.REQUIRED)
          .max(500, INTL_ERROR_MESSAGE.MAX_LENGTH_MESSAGE)
})

export default HelpSchema
