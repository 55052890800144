/* eslint-disable camelcase */
import React from 'react'
import Checkbox from '../Checkbox'
import style from './styles/index.module.scss'

const WrapperTitleView = ({
  children,
  icon,
  content,
  checkID,
  handleCheckID,
  id,
  disableCheckbox
}) => {
  const handleChange = () => handleCheckID(id)
  const stopPropagation = (e) => e.stopPropagation()
  return (
    <div className={style.title__wrapper} data-cy='project-title'>
      <div className={style.title__wrapper_checkbox}>
        <Checkbox
          theme='checkbox_medium'
          checked={checkID?.includes(id)}
          onGx-change={handleChange}
          onClick={stopPropagation}
          onContextMenu={stopPropagation}
          disabled={disableCheckbox}
        />
      </div>
      {children}
    </div>
  )
}

export default WrapperTitleView
