/* eslint-disable camelcase */
import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useStoreon } from 'storeon/react'
import { contentApiService } from '../../api/services'
import useBitrixUtm from '../../hooks/useBitrixUTM'
import { useModalIntlContent, useModalStore } from '../../hooks/Modals'
import useRecaptcha from '../../hooks/useRecaptcha'
import usePage from '../../hooks/usePage'
import metrik from '../../services/metrik'
import { FORM_TYPE, PAGE_MODELS } from '../../const'
import FooterLanding from '../../views/FooterLanding'

const FooterLandingComponent = () => {
  const { dispatch } = useStoreon()
  const bitrixUTM = useBitrixUtm()
  const { page, baseSlug } = usePage()
  const { openModal, contentTypes } = useModalStore()
  const { grecaptchaReady, errorRecaptcha } = useRecaptcha()
  const [msgStatus, setMsgStatus] = useState('')
  const intl = useIntl()

  //* ссылка на garpix портал
  const garpixUrl = page.init_state.global.garpix_url || 'https://garpix.com/'
  //* флаг для поднятия футера
  const raiseFooter = PAGE_MODELS.MAILING === page.page_model

  const successModalContent = useModalIntlContent(contentTypes.help)
  const errorModalContent = useModalIntlContent(contentTypes.sendRequestError)

  //* все ссылки для нижнего меню
  const menus = useMemo(
    () => page.init_state.global.menus.landing_footer_menu || [],
    [page]
  )
  //* все компоненты на стр
  const components = useMemo(() => page.init_state.components || [],
    [page])

  const form_title = {
    partner: global.partner_model_text,
    request: global.request_model_text,
    question: global.question_model_text
  }

  /**
   *  * метод для отправки вопроса от юзера
   * @param {Object} data .
   * @param {string} data.email
   * @param {string} data.form_type
   * @param {string} data.fullname
   * @param {string} data.message
   * @param {string} data.phone
   * @param {string} data.phoneCountry
   */

  const sendFeedback = useCallback(async (data) => {
    try {
      const recaptcha = await grecaptchaReady()
      await contentApiService.sendFeedback({
        ...data,
        ...bitrixUTM,
        recaptcha
      })
      metrik.send('reachGoal', 'ostavit_zayavku')
      setMsgStatus(intl.formatMessage({
        id: 'messageSentSuccessfully',
        defaultMessage: 'Сообщение успешно отправлено'
      }))
      openModal('commonConfirm', successModalContent)
    } catch {
      setMsgStatus(intl.formatMessage({
        id: 'serverError',
        defaultMessage: 'Ошибка сервера'
      }))
      openModal('commonConfirm', errorModalContent)
    }
  }, [grecaptchaReady, bitrixUTM])

  /**
   *  * метод для открытия модалки
   * @param {string} role роль юзера.
   * @param {string} form тип отправляемой формы (см в FORM_TYPE)
   */

  const handleOpenModal = (role, form) => {
    if (form in FORM_TYPE) {
      dispatch('btnInfo/roleType/set', role)
      dispatch('btnInfo/formType/set', form)
      openModal('sendRequest', { title: form_title[form], theme: 'large' })
    } else {
      openModal('homeVideo', { theme: 'large' })
    }
  }

  return (
    <FooterLanding
      sendFeedback={sendFeedback}
      msgStatus={msgStatus}
      handleOpenModal={handleOpenModal}
      menus={menus}
      baseSlug={baseSlug}
      raiseFooter={raiseFooter}
      components={components}
      errorRecaptcha={errorRecaptcha}
      garpixUrl={garpixUrl}
    />
  )
}

export default FooterLandingComponent
