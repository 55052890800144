/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import HeaderLanding from '../../views/HeaderLanding'
import { useLocation } from 'react-router-dom'
import { useModalStore } from '../../hooks/Modals'
import usePage from '../../hooks/usePage'

const HeaderLandingComponent = () => {
  const [scrolled, setScrolled] = useState(false)

  const { baseSlug, page } = usePage()
  const headerContent = useMemo(() => page.init_state.components.find(
    ({ component_model }) => component_model === 'HeaderComponent'), [page])
  const theme = headerContent?.object?.theme
  const headerCustomStyle = theme && {
    '--background': theme.background_color,
    '--border-color': theme.accent_color_1,
    '--link-color': theme.text_title_color,
    '--burger-btn-color': theme.accent_color_2
  }

  const { openModal } = useModalStore()
  const { hash } = useLocation()

  useEffect(() => {
    if (!hash) {
      window.scroll(0, 0)
    } else {
      const $el = document.querySelector(hash)
      setTimeout(() => {
        $el && $el.scrollIntoView({ block: 'start' })
      }, 100)
    }
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 1) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const openModalVideo = useCallback(() => {
    openModal('headerMenu', { theme: 'header_menu', colorTheme: headerCustomStyle })
  }, [])

  if (!headerContent) {
    return
  }

  return (
    <HeaderLanding
      baseSlug={baseSlug}
      content={headerContent?.object}
      scrolled={scrolled}
      openModalVideo={openModalVideo}
      headerCustomStyle={headerCustomStyle}
      page={page}
    />
  )
}

export default HeaderLandingComponent
