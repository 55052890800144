/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import { useStoreon } from 'storeon/react'
import { useModalStore } from '../../hooks/Modals'
import { userApiService } from '../../api/services'
import useBitrixUtm from '../../hooks/useBitrixUTM'
import usePage from '../../hooks/usePage'
import ProfileViews from '../../views/ProfileViews'

const UserType = ({ handelChangeShowBlocks }) => {
  const { dispatch, currentUser } = useStoreon('currentUser')
  const utm = useBitrixUtm()
  const { openModal } = useModalStore()
  const { page } = usePage()
  const components = useMemo(() => page || [], [page])

  const modal_title = components.init_state.global.partner_model_text

  const changeProfileInfo = () => {
    dispatch('user/get', {})
  }

  const openModalSumbitApp = (formType) => {
    openModal('sendRequest', { utm, title: modal_title, action: changeProfileInfo, theme: 'large' })
    dispatch('btnInfo/formType/set', formType)
  }

  const handelSendPhoto = async ({ file }) => {
    await userApiService.setUserPhoto({ file })
    changeProfileInfo()
  }

  const handelOpenChangePhoto = () => {
    openModal('changeUserAvatar', { handelSendPhoto })
  }

  return (
    <ProfileViews.UserType
      components={components.init_state.components}
      profileInfo={currentUser}
      handelChangeShowBlocks={handelChangeShowBlocks}
      handelOpenChangePhoto={handelOpenChangePhoto}
      handleOpenModal={openModalSumbitApp}
    />
  )
}

export default UserType
