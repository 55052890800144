import { useMemo } from 'react'

import { useFormikContext } from 'formik'

import { AXLES_BTN_ERROR_KEYS } from '../../../../const'
/**
 * * Хук возвращает флаг - есть ли в ГП ошибки, которые вам по каким либо причинам нужны
 * @hook
 * @param {number} spaceIndex  индекс наблюдемого пространства
 * @param {string[]} arrErrorKeys  массив полей ГП которые проверяются в формике
 * @returns
 */
const useSpaceError = (spaceIndex, arrErrorKeys = AXLES_BTN_ERROR_KEYS) => {
  const { errors } = useFormikContext()

  const isError = useMemo(() => {
    const currentSpaceError = errors?.spaces?.at(spaceIndex)
    if (!currentSpaceError) return false
    const errArray = Object.keys(currentSpaceError)
    return Boolean(
      errArray?.find((errorKey) =>
        arrErrorKeys.some((el) => errorKey === el)
      )
    )
  }, [errors])

  return isError
}

export default useSpaceError
