import React, { useState } from 'react'

import classNames from 'classnames'
import { runtimeConfig } from '@/config'
import {
  BUTTON_THEME,
  BUTTON_VARIANT,
  ENTITY_TYPES,
  USE_MEDIA_CONST
} from '@/const'
import { downIcon, exitIcon, logo } from '@/images'
import { useMedia } from 'react-use'

import { Link } from 'react-router-dom'
import NotificationsMenuComponent from '@/apps/appNotifications/components/NotificationsMenuComponent'
import LocaleMenu from '@/apps/components/views/LocaleMenu'
import Button from '@/views/Button'
import { GxIcon } from '@garpix/garpix-web-components-react'
import DropdownViews from '@/views/DropdownViews'
import UserIcon from '@/views/UserIcon'
import usePage from '@/hooks/usePage'
import useTranslate from '@/hooks/useTranslate'
import NavPanel from '@/views/Header/components/NavPanel.js'
import { isProduction } from '@/utils'

import style from './style/index.module.scss'

/**
 * Шапка App
 *
 * @param {Array<Object>} mainMenuLinks Список ссылок списочных страниц
 * @param {Array<Object>} profileMenuLinks Список ссылок меню (навбар/табы)
 * @param {string} activePage Активная страница
 * @param {string} logoLink Ссылка для Лого
 * @param {boolean} showOnMobile Флаг на отображение навбара
 * @param {function(): void} logout Метод для логаута
 * @return {JSX.Element}
 */
const Header = ({
  mainMenuLinks,
  profileMenuLinks,
  activePage,
  logout,
  logoLink,
  showOnMobile = true
}) => {
  const { intl } = useTranslate()
  const isProjectPage =
    ENTITY_TYPES.PROJECT.toLowerCase() === activePage.toLowerCase()

  const { page } = usePage()
  const {
    global: { image_logo: imageLogo }
  } = page.init_state

  /**
   * Состояние открытия меню
   */
  const [open, setOpen] = useState(null)

  const handleCloseMenu = () => setOpen(null)

  /**
   * Метод для логаута + закрытие меню
   */
  const handleLogout = () => {
    logout()
    handleCloseMenu()
  }
  const isMobile = useMedia(USE_MEDIA_CONST.MOBILE)

  return (
    <>
      {isMobile
        ? (
          <NavPanel
            mainMenuLinks={mainMenuLinks}
            activePage={activePage}
            isProjectPage={isProjectPage}
            showOnMobile={showOnMobile}
            className={style.header_mobile}
          />
          )
        : null}

      <header className={style.header}>
        <Link to={logoLink} className={style.header__logo_wrap}>
          <img
            className={style.header__logo_image}
            src={imageLogo || logo}
            width='40px'
            height='40px'
            alt='Logo'
          />
        </Link>

        {!isMobile
          ? (
            <NavPanel
              mainMenuLinks={mainMenuLinks}
              activePage={activePage}
              isProjectPage={isProjectPage}
              showOnMobile={showOnMobile}
              className={style.header_nav}
            />
            )
          : null}

        <div className={style.header_right}>
          {!isProduction(runtimeConfig.RAZZLE_APP_ENVIRONMENT) && (
            <div className={style.header_language}>
              <LocaleMenu />
            </div>
          )}
          <NotificationsMenuComponent />
          <div className={style.header_profile}>
            <DropdownViews.Dropdown
              open={open}
              onGx-show={() => setOpen(true)}
              onGx-hide={handleCloseMenu}
              hoist
              placement='bottom-end'
              className={style.header__details}
            >
              <Button
                slot='trigger'
                caret
                variant={BUTTON_VARIANT.TEXT}
                theme={BUTTON_THEME.PROFILE_DROPDOWN}
              >
                <UserIcon />
                <GxIcon
                  slot='icon-right'
                  src={downIcon}
                  className={style.header_language__icon}
                />
              </Button>
              <DropdownViews.Menu projectVariant='header_profile'>
                {profileMenuLinks &&
                  profileMenuLinks.map((item) => {
                    return (
                      <DropdownViews.Item
                        onClick={handleCloseMenu}
                        className={style.header_profile__item}
                        key={item.id}
                      >
                        <Link
                          data-cy={`${item.model_name}-menu-link`}
                          to={item.url}
                          className={style.header_profile__link}
                        >
                          <GxIcon
                            src={item.icon}
                            className={style.header_profile__icon}
                            slot='prefix'
                          />
                          {item.title}
                        </Link>
                      </DropdownViews.Item>
                    )
                  })}
                <DropdownViews.Divider />
                <DropdownViews.Item
                  onClick={handleLogout}
                  className={style.header_profile__logout}
                >
                  <GxIcon
                    src={exitIcon}
                    className={classNames(
                      style.header_profile__icon,
                      style['header_profile__icon--logout']
                    )}
                    slot='prefix'
                  />
                  {intl.formatMessage({
                    id: 'signOut',
                    defaultMessage: 'Выйти'
                  })}
                </DropdownViews.Item>
              </DropdownViews.Menu>
            </DropdownViews.Dropdown>
          </div>
        </div>
      </header>
    </>
  )
}
export default React.memo(Header)
