import { Api } from '../../index.js'
import { runtimeConfig } from '../../../config'


class CargoApiService extends Api {

  cargoTariff = async () => {
    const res = await this.get('/cargo/cargo/check_tariff/');
    return res.data;
  };

  loadCargoExample = async () => {
    const res = await this.get('/cargo/cargo/sample/');
    return res.data.cargo;
  };

  createCargo = async (params) => {
    const res = await this.post('/cargo/cargo/', params);
    return res.data;
  };

  getCargos = async ({ page_size = 10, ...params }) => {
    const res = await this.get('/cargo/cargo/', {
      page_size,
      ...params,
    });
    return res.data;
  };

  deleteCargo = async ({ id }) => {
    const res = await this.delete(`/cargo/cargo/${id}/`);
    return res.data;
  };

  deleteCargoesList = async (id) => {
    const res = await this.delete(`/cargo/cargo/list_cargo_delete/?${id}`);
    return res.data;
  };

  renameCargo = async ({ id, title }) => {
    const res = await this.patch(`/cargo/cargo/${id}/`, { title });
    return res.data;
  };

  copyCargo = async ({ id }) => {
    const res = await this.post(`/cargo/cargo/${id}/copy/`);
    return res.data;
  };

  getCargo = async ({ id }) => {
    const res = await this.get(`/cargo/cargo/${id}/`);
    return res.data;
  };

  updateCargo = async ({ id, ...params }) => {
    const res = await this.patch(`/cargo/cargo/${id}/`, params);
    return res.data;
  };

  createCargosFromFile = async ({ file }) => {
    const fd = new FormData();
    fd.append('file', file);
    const res = await this.post('/cargo/cargo/create_from_file_upload/', fd);
    return res.data;
  };

  getCargoesBox = async ({ page_size = 10, ...params }) => {
    const res = await this.get('/cargo/box/', {
      page_size,
      ...params
    });
    return res.data;
  }

  getCargoesCylinder = async ({ page_size = 10, ...params }) => {
    const res = await this.get('/cargo/cylinder/', {
      page_size,
      ...params
    });
    return res.data;
  }

  getCargoesTire = async ({ page_size = 10, ...params }) => {
    const res = await this.get('/cargo/tire/', {
      page_size,
      ...params
    });
    return res.data;
  }

  getCargoesTube = async ({ page_size = 10, ...params }) => {
    const res = await this.get('/cargo/tube/', {
      page_size,
      ...params
    });
    return res.data;
  }
}

export default new CargoApiService(runtimeConfig.API_URL)
