import React from 'react'
import SectionHomePageWithButtons from '../../../views/LandingView/Section/SectionHomePageWithButtons'
import { CARDS } from '../../../const'
import HowsItem from '../../../views/LandingView/HowsItem'

const HowWork = (props) => {
  return (
    <SectionHomePageWithButtons theme={props.content?.theme} id={CARDS.HOWS_ITEMS.id} {...props}>
      <HowsItem theme={props.content?.theme} {...props} />
    </SectionHomePageWithButtons>
  )
}

export default HowWork
