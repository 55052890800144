import React from 'react'
import SectionHomePage from '../../../views/LandingView/Section'
import { CARDS } from '../../../const'
import NewsView from '../../../views/LandingView/News'
import usePage from '../../../hooks/usePage'

const News = (props) => {
  const { baseSlug } = usePage()
  return (
    <SectionHomePage id={CARDS.NEWS.id} isNews {...props}>
      <NewsView baseSlug={baseSlug} {...props} />
    </SectionHomePage>
  )
}

export default News
