import React, { useCallback, useRef } from 'react'
import SearchView from '../views/SearchView/SearchView'
import { useFormik } from 'formik'

const Search = ({ loadData, filterParams }) => {
  const ref = useRef(null)
  const formik = useFormik({
    initialValues: {
      ...filterParams,
      title__icontains: filterParams?.title__icontains
    },
    onSubmit: (values) => loadData(1, { ...filterParams, title__icontains: values.title__icontains, page: 1 })
  })
  const handleSearch = useCallback(
    (e) => {
      const searchStr = e.target.value || ''
      if (ref.current) {
        clearTimeout(ref.current)
      }
      formik.setFieldValue('title__icontains', searchStr)
      const delayId = setTimeout(formik.handleSubmit, 500)
      ref.current = delayId
    },
    [ref]
  )

  const handleReset = useCallback((e) => {
    formik.handleReset()
    handleSearch(e)
  }, [])

  return (
    <SearchView
      handleReset={handleReset}
      value={formik.values.title__icontains}
      handleSearch={handleSearch}
    />
  )
}

export default React.memo(Search)
