import React from 'react'
import ReactDOM from 'react-dom'
import { GxModal } from '@garpix/garpix-web-components-react'
import CloseButtonView from '../../CloseButtonView'
import style from './styles/index.module.scss'

const BaseModal = ({
  handleClose,
  children,
  theme = 'default',
  dataAttr,
  modalProps,
  colorTheme = null,
  ...props
}) => {
  return ReactDOM.createPortal(
    <GxModal
      {...props}
      data-modal={dataAttr}
      className={style[theme]}
      style={colorTheme}
    >
      <CloseButtonView closeModal={handleClose} className={style[`${theme}__close`]} />
      {children}
    </GxModal>,
    document.body
  )
}

export default BaseModal
