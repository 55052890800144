import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'

const PromoCashModalSchema = yup.object().shape({
  message:
    yup
      .string()
      .max(500, INTL_ERROR_MESSAGE.MAX_LENGTH_MESSAGE)
      .required(INTL_ERROR_MESSAGE.REQUIRED)
})

export default PromoCashModalSchema
