import React from 'react'
import ModalView from '../../../ModalView'
import Checkbox from '../../../Checkbox'
import styles from '../styles/index.module.scss'
import classNames from 'classnames'
import PaymentFormBtn from '../../../../apps/components/views/PaymenFormBtn'
import { useIntl } from 'react-intl'

const ProfileChangeTariff = React.forwardRef((props, ref) => {
  const {
    checked = true,
    checkbox,
    description,
    isLoading,
    isSuccess,
    html,
    handleCheck,
    handleAccept
  } = props
  const intl = useIntl()
  return (
    <>
      <ModalView.Icon>
        <ModalView.Icon.Crown />
      </ModalView.Icon>
      <ModalView.Title>
        {
          intl.formatMessage({
            id: 'buyingTariff',
            defaultMessage: 'Покупка тарифа'
          })
        }
      </ModalView.Title>
      {
                checkbox && (
                  <div className={styles.change_tariff__checkbox}>
                    <Checkbox checked={checked} onGx-change={handleCheck}>
                      {
                        intl.formatMessage({
                          id: 'activateTariffRightNow',
                          defaultMessage: 'Активировать тариф прямо сейчас'
                        })
                      }
                    </Checkbox>
                  </div>
                )
            }
      <ModalView.Description
        description={description}
        className={classNames({ [styles.change_tariff__description]: !!checkbox })}
      />
      <ModalView.Buttons>
        {
              !isSuccess
                ? (
                  <ModalView.Buttons.Button
                    onClick={handleAccept}
                    isSpinner={isLoading}
                    disabled={isLoading}
                  >
                    {
                      intl.formatMessage({
                        id: 'continue',
                        defaultMessage: 'Продолжить'
                      })
                    }
                  </ModalView.Buttons.Button>
                  )
                : (
                  <PaymentFormBtn
                    html={html}
                    ref={ref}
                  >
                    {
                      intl.formatMessage({
                        id: 'goToThePayment',
                        defaultMessage: 'Перейти к оплате'
                      })
                    }
                  </PaymentFormBtn>
                  )
          }
      </ModalView.Buttons>
    </>
  )
})

export default ProfileChangeTariff
