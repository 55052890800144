/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, EffectFade, Autoplay } from 'swiper'
import InnerHtml from '../../../components/InnerHtml'

import style from './style/index.module.scss'

SwiperCore.use([Pagination, Autoplay, EffectFade])

const WorkResultsSlider = (props) => {
  const {
    work_result_elements: slide,
    extra_text: extraText,
    bottom_left_border,
    bottom_right_border,
    top_left_border,
    top_right_border
  } = props

  const bullets = useMemo(
    () => slide.map(({ element_name: bullet }) => bullet),
    [slide]
  )

  const styles = {
    '--top-left': top_left_border && `${top_left_border}px`,
    '--top-right': top_right_border && `${top_right_border}px`,
    '--bottom-left': bottom_left_border && `${bottom_left_border}px`,
    '--bottom-right': bottom_right_border && `${bottom_right_border}px`,
    '--col': bullets.length
  }

  return (
    <>
      <Swiper
        autoplay={{
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }}
        loop
        effect='fade'
        fadeEffect={{
          crossEffect: true
        }}
        className={style.slider}
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className}">${bullets[index]}</span>`
          }
        }}
        style={styles}
      >
        {slide.map(({ image, text_title: title, text, id }) => (
          <SwiperSlide key={id}>
            <div className={style.work}>
              <img
                className={style.work__img}
                src={image}
                loading='lazy'
                width={370}
                height={300}
                alt='work-result'
              />
              <div className={style.work__wrap}>
                <h3 className={style.work__title}>{title}</h3>
                <p className={style.work__text}>{text}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {extraText && <InnerHtml className={style.text} html={extraText} />}
    </>
  )
}

export default WorkResultsSlider
