/* eslint-disable camelcase */
import React, { memo, useMemo } from 'react'
import WrapperTitleView from '../../views/WrapperTitleView'
import { demoIcon, userIcon, usersIcon } from '../../images'
import { CONTENT_HINT, ENTITY_TYPES } from '../../const'

const WrapperTitle = ({
  cargo_space_type,
  children,
  demo,
  type,
  owner,
  handleCheckID,
  checkID,
  id
}) => {
  const dataTitle = useMemo(() => {
    return ({
      icon: demo ? demoIcon : (owner?.id || type === ENTITY_TYPES.PROJECT) ? userIcon : usersIcon,
      content: demo
        ? CONTENT_HINT.DEMO
        : (owner?.id || ENTITY_TYPES.PROJECT === type)
            ? CONTENT_HINT.USER
            : CONTENT_HINT.COMMON,
      cargo_space_type
    })
  }, [type])

  const disableCheckbox = useMemo(() => {
    if (type === ENTITY_TYPES.CARGO || type === ENTITY_TYPES.CARGO_SPACE) {
      return !owner?.id
    }
    return false
  }, [owner, type])

  return (
    <WrapperTitleView {...dataTitle} type={type} handleCheckID={handleCheckID} checkID={checkID} id={id} disableCheckbox={disableCheckbox}>
      {children}
    </WrapperTitleView>
  )
}

export default memo(WrapperTitle)
