import React, { useEffect } from 'react'

import usePage from '../../../hooks/usePage'
import { useModalStore, useModalIntlContent } from '../../../hooks/Modals'

import ErrorBoundaryView from '../../../views/ErrorBoundaryView'

const UniversalErrorBoundary = () => {
  const { page } = usePage()
  const { openModal, closeModal, contentTypes } = useModalStore()
  const warningModalContent = useModalIntlContent(contentTypes.oldAppVersion)
  const currentVersion = page.init_state.global.version_tag
  const userVersion = localStorage.getItem('version_tag')

  useEffect(() => {
    if (currentVersion !== userVersion) {
      localStorage.setItem('version_tag', currentVersion)
      openModal('commonConfirm', {
        ...warningModalContent,
        handleAccept: () => {
          document.location.reload()
          closeModal()
        }
      })
    }
  }, [])
  return (
    <ErrorBoundaryView />
  )
}

export default UniversalErrorBoundary
