import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import ResizeBlock from './components/ResizeBlock'
import App from './App'
import serviceWorker from './services/initServiceWorker'
import * as Sentry from '@sentry/react'
import { StoreContext } from 'storeon/react'
import { storeonParams } from './store'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { WebToken } from './WebToken'
import {
  applyPolyfills,
  defineCustomElements
} from '@garpix/garpix-web-components/loader'
import '@garpix/garpix-web-components/dist/garpix-web-components/garpix-web-components.css'

import './styles/index.scss'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/effect-fade/effect-fade.min.css'
import 'swiper/components/pagination/pagination.min.css'

import 'react-popper-tooltip/dist/styles.css'
import { HelmetProvider } from 'react-helmet-async'
import { createStoreon } from 'storeon'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://4709c4dfd5304660a7c428ec19b3b010@sentry.garpix.com/8'
  })
}
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } }
})
const dehydrateState = window.__REACT_QUERY_STATE__
const store = createStoreon(storeonParams)

const LoaderFontCanvas = ({ children }) => {
  useEffect(() => {
    // eslint-disable-next-line
    new FontFace('CanvasFont', 'url(/fonts/Montserrat-Bold.ttf)')
      .load()
      .then(function (font) {
        document.fonts.add(font)
      })
  }, [])
  return children
}

const BaseApp = () => {
  return (
    <Sentry.ErrorBoundary fallback='An error has occurred'>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydrateState}>
            <StoreContext.Provider value={store}>
              <BrowserRouter>
                <WebToken>
                  <LoaderFontCanvas>
                    <App />
                  </LoaderFontCanvas>
                  <ResizeBlock />
                </WebToken>
              </BrowserRouter>
            </StoreContext.Provider>
          </Hydrate>
        </QueryClientProvider>
      </HelmetProvider>
    </Sentry.ErrorBoundary>
  )
}

ReactDOM.hydrateRoot(document.getElementById('root'), <BaseApp />)

applyPolyfills().then(() => {
  defineCustomElements()
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker()

if (module.hot) {
  module.hot.accept()
}
