import React, { useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { COLOR_USER_AVATAR } from '../../../../const'
import style from '../styles/index.module.scss'
import { useZoom } from '../../../../hooks/useZoom'

const Avatar = React.forwardRef(({ avatarSettings, zoomPhoto }, ref) => {
  const scaleRef = useRef()
  useZoom({ scaleRef, zoomPhoto })

  return (
    <div
      ref={scaleRef}
    >
      <div className={style.user_avatar__editor}>
        <AvatarEditor
          ref={ref}
          height={350}
          width={350}
          className={style.user_avatar__editor_canvas}
          borderRadius={(380 / 2) * 380}
          crossOrigin='anonymous'
          backgroundColor={COLOR_USER_AVATAR.BC_COLOR_IMAGE}
          border={50}
          disableBoundaryChecks={false}
          color={COLOR_USER_AVATAR.COLOR_CIRCLE_STROKES}
          scale={avatarSettings.scale}
          image={avatarSettings.image}
        />
      </div>
    </div>
  )
})

export default Avatar
