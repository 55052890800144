import React from 'react'
import Layout from '../views/Layout'
import HomeComponent from '../components/HomeComponent'

const Home = (props) => (
  <Layout.Landing dataAttr='home-page'>
    <HomeComponent {...props} />
  </Layout.Landing>
)

export default Home
