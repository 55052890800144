import React from 'react'
import { warningIcon } from '../../../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from '../styles/index.module.scss'

const ModalIconWarning = () => {
  return (
    <div className={styles.icon_warning}>
      <GxIcon src={warningIcon} />
    </div>
  )
}

export default ModalIconWarning
