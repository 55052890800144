import React from 'react'
import Layout from '.'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { Link } from 'react-router-dom'
import { logo } from '../../images'
import usePage from '../../hooks/usePage'
import style from './styles/index.module.scss'

const NotFoundPage = ({ children }) => {
  const { baseSlug } = usePage()
  return (
    <Layout.Seo>
      <header className={style.not_found}>
        <Link to={baseSlug.pageHome} replace>
          <GxIcon src={logo} />
        </Link>
      </header>
      <main>{children(true)}</main>
    </Layout.Seo>
  )
}

export default NotFoundPage
