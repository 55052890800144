import { useStoreon } from 'storeon/react'
/**
 * @hook
 * * Хук для получения каких либо пермишинов из тарифа юзера
 * @returns
 */
export const useUserTariffPermissions = () => {
  const { currentUser } = useStoreon('currentUser')

  return {
    isShowDownloadingReports: currentUser?.current_tariff?.is_show_downloading_reports ?? false
  }
}
