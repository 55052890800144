import React from 'react'
import style from './style/index.module.scss'
import ActionButton from '../ActionButton'
import { CARDS, BUTTON_THEME } from '../../../const'

const CallOrder = ({ handleOpenModal, count, ...props }) => {
  const {
    action_button: actionButton,
    text_title: title,
    text_subtitle: subtitle,
    image,
    theme
  } = props.content

  const styleCallOrder = theme && {
    '--background-color': theme.background_color,
    '--subtitle-color': theme.text_subtitle_color,
    '--title-color': theme.text_title_color
  }

  return (
    <section data-count={count} id={CARDS.CALL_ORDER.id} className={style['call-order']} style={styleCallOrder}>
      <div className={style.container}>
        {image &&
          <img
            src={image}
            alt='callorder'
            className={style['call-order__img']}
            width={520}
            height={355}
          />}
        <div className={style['call-order__banner']}>
          {title && <h2 className={style['call-order__title']}>{title}</h2>}
          {subtitle && <p className={style['call-order__desc']}>{subtitle}</p>}
          {actionButton && (
            <ActionButton
              actionButton={actionButton}
              theme={BUTTON_THEME.FILL_ICON.LANDING}
              className={style['call-order__btn']}
              handleOpenModal={handleOpenModal}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default CallOrder
