import React from 'react'
import AccordionView from '../../AccordionView'

const FAQView = ({ questions }) => {
  return (
    <div>
      {questions && questions.length > 0
        ? questions.map(({ id, ...question }) => (
          <AccordionView key={id} question={question} />
          ))
        : null}
    </div>
  )
}

export default FAQView
