/* eslint-disable camelcase */
import React from 'react'
import Title from '../Title'
import { TITLE_TYPE, TITLE_VARIANT } from '../../const'
import styles from './styles/index.module.scss'

const HowsCard = ({ data }) => {
  const { image, number, text, text_title } = data
  return (
    <div className={styles.howscard}>
      <div className={styles.howscard_header}>
        {image && (
          <div className={styles.howscard_wrap}>
            <img className={styles.howscard_image} src={image} width='120' height='120' alt={text_title} loading='lazy' />
          </div>
        )}
        {number && <div className={styles.howscard_step}>{`шаг ${number}`}</div>}
      </div>
      <div>
        <div className={styles.howscard_title}>
          {text_title && (
            <Title type={TITLE_TYPE.H6} variant={TITLE_VARIANT.HOWS_CARD}>
              {text_title}
            </Title>
          )}
        </div>
        {text && <div className={styles.howscard_description}>{text}</div>}
      </div>
    </div>
  )
}

export default HowsCard
