import React, { useEffect } from 'react'

import CommonConfirm from '../CommonConfirm'
/**
 * * компонент над кастомной модалкой которая перезапрашивает
 * * список чего либо (проекты, пространства, грузы)
 * @componenet
 * @param {fn} refetchList
 * @param {obj} content
 */
const UploadExel = ({ refetchList, content }) => {
  useEffect(() => {
    refetchList()
  }, [])

  return <CommonConfirm {...content} />
}

export default UploadExel
