import React from 'react'
import Layout from '.'

const Auth = ({ children, dataAttr = '' }) => {
  return (
    <Layout.Seo>
      <main data-cy={dataAttr}>{children}</main>
    </Layout.Seo>
  )
}

export default Auth
