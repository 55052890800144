/* eslint-disable camelcase */
import React from 'react'
import { useStoreon } from 'storeon/react'
import LandingView from '../../views/LandingView'
import { useModalStore } from '../../hooks/Modals'
import { FORM_TYPE } from '../../const'
import usePage from '../../hooks/usePage'

const HomeComponent = () => {
  const { dispatch } = useStoreon()
  const { openModal } = useModalStore()
  const { page } = usePage()

  const form_title = {
    partner: page.init_state.global.partner_model_text,
    request: page.init_state.global.request_model_text,
    question: page.init_state.global.question_model_text
  }

  const handleOpenModal = (role, form) => {
    if (form in FORM_TYPE) {
      openModal('sendRequest', { title: form_title[form], theme: 'large' })
      dispatch('btnInfo/roleType/set', role)
      dispatch('btnInfo/formType/set', form)
    }
  }

  return (
    <LandingView
      components={page.init_state.components}
      handleOpenModal={handleOpenModal}
    />
  )
}

export default HomeComponent
