import { GxIcon } from '@garpix/garpix-web-components-react'
import React from 'react'
import { okIcon } from '../../../images'
import cn from 'classnames'
import style from './style/index.module.scss'

const PartnersProgramCard = ({ item }) => (

  <div className={style['partner-program__group-content']}>
    {item.text_title &&
      <h6 className={cn({
        [style['partner-program__group-content_title']]: true,
        [style['partner-program__group-content_title--empty']]: item.partners_program_points.length === 0
      })}
      >
        {item.text_title}
      </h6>}
    {item.partners_program_points && item.partners_program_points.length > 0 &&
      <ul className={style['partner-program__group-content_lists']}>
        {item.partners_program_points.length > 0 && item.partners_program_points.map(({ id, text }) => (
          <li className={style['partner-program__group-content_list']} key={id}>
            <GxIcon src={okIcon} className={style['partner-program__group-content_icon']} />
            {text}
          </li>
        ))}
      </ul>}
  </div>
)

export default PartnersProgramCard
