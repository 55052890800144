import React from 'react'
import { save } from '../../../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from '../styles/index.module.scss'

const ModalIconSuccess = () => {
  return (
    <div className={styles.icon_success}>
      <GxIcon src={save} />
    </div>
  )
}

export default ModalIconSuccess
