class SocketBase {
  socket
  listeners
  api
  qp
  connectionURI

  notify = (data) => {
    const { event, type } = data
    const eventSubscribers = this.listeners.get(event)
    const typeSubscribers = this.listeners.get(type)
    if (eventSubscribers) {
      for ( let subscriber of eventSubscribers.values()){
        subscriber(data)
      }
    }
    if(typeSubscribers){
      for ( let subscriber of typeSubscribers.values()){
        subscriber(data)
      }
    }
  }

  activate = () => {
    this.socket.onopen = (e) => {
      if (this.listeners.has('open')) {
        this.listeners.get('open')(e)
      }
    }
    this.socket.onmessage = (e) => {
      const data = JSON.parse(e.data)
      const { type, id } = data
      this.notify(data)
      if (type === 'send_notify') {
        this.socket.send(JSON.stringify({ type: 'confirm', id }))
      }
    }
    this.socket.onclose = (event) => {
      if (this.listeners.has('disconnect')) {
        this.listeners.get('disconnect')(event)
      }
      setTimeout(this.activate, 3000)
    }
    this.socket.onerror = (error) => {
      if (this.listeners.has('error')) {
        this.listeners.get('error')(error)
      }
      console.log('Ошибка подключения сокетов')
    }
  }


  close = () => {
    this.socket.close()
    return this.socket
  }

  subscribe = (event, callback) => {
    const listener = this.listeners.get(event)
    if (listener) {
      listener.set(callback, callback)
    } else {
      const map = new Map()
      map.set(callback, callback)
      this.listeners.set(event, map)
    }
  }

  unsubscribe = (event, callback)  =>{
    const listener = this.listeners.get(event)
    listener && listener.delete(callback)
  }

  init = (api, qp) => {
    this.api = api
    this.qp = qp
    this.connectionURI = `${api}${qp}`
    this.socket = new WebSocket(this.connectionURI)
    this.listeners = new Map()
    this.activate()
    return this.socket
  }
}

export default SocketBase