import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { demoIcon } from '../../images'
import style from './styles/index.module.scss'

const DemoField = ({
  demo = false
}) => {
  return (
    <div className={style.demo_wrap}>
      {demo ? <GxIcon className={style.demo_image} src={demoIcon} /> : null}
    </div>
  )
}

export default DemoField
