import React from 'react'
import { deleteIcon } from '../../../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from '../styles/index.module.scss'

const ModalIconRemove = () => {
  return (
    <div className={styles.icon_remove}>
      <GxIcon src={deleteIcon} />
    </div>
  )
}

export default ModalIconRemove
