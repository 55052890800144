/* eslint-disable camelcase */
import React from 'react'
import { loadPerformance } from '../../../images'
import styles from './styles/index.module.scss'
import AnimateNumber from './AnimateNumber'
import useFollowElement from '../../../hooks/useFollowElements'

const loadingPercentage = [80, 85, 95]

const LoadPerfomance = ({ content }) => {
  const [elementRef, isVisible] = useFollowElement()

  const { image } = content
  return (
    <div className={styles.loadPerformance}>
      <div className={styles.loadPerformance_wrap} ref={elementRef}>
        {loadingPercentage.map((el, i) => {
          return (
            <AnimateNumber key={i} number={el} isVisible={isVisible} className={styles.loadPerformance_percentage} />
          )
        })}
        <img
          width='1250'
          height='500'
          className={styles.loadPerformance__img}
          src={image || loadPerformance}
          alt='load_perfomance'
          loading='lazy'
        />
      </div>
    </div>
  )
}

export default LoadPerfomance
