import React from 'react'
import AddComponent from './../../../components/AddComponent'
import Animation from '../../Animation'
import style from '../styles/index.module.scss'

const Empty = ({ content, picture, type, buttonText }) => {
  return (
    <Animation.Show className={style.empty}>
      <img src={picture} className={style.empty__picture} />
      <p className={style.empty__content}>{content}</p>
      <div className={style.empty__wrap}>
        <AddComponent
          addButtonText={buttonText}
          type={type}
        />
      </div>
    </Animation.Show>
  )
}

export default Empty
