import React from 'react'
import { IntlProvider } from 'react-intl'
import Russian from '../../../../public/messages/ru'
import English from '../../../../public/messages/en'
import api from '../../../api'
import { LOCALE } from '../../../const'

const messages = {
  [LOCALE.RU]: Russian,
  [LOCALE.EN]: English
}

const Locale = ({ children }) => {
  const locale = api.getLanguage()

  return (
    <IntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultLocale={LOCALE.RU}
    >
      {children}
    </IntlProvider>
  )
}

export default Locale
