import React from 'react'
import styles from './styles/index.module.scss'
import cn from 'classnames'
import DirectLink from '../../components/DirectLink'

const HeaderLandingLink = ({
  className = null,
  targetBlank,
  url,
  title,
  baseSlug,
  theme = null,
  handleClick
}) => {
  return url.startsWith('#')
    ? (
      <DirectLink
        className={cn({
          [styles[theme]]: theme,
          [className]: className
        })}
        id={url}
        handleClick={handleClick}
        link={baseSlug.pageHome}
      >
        {title}
      </DirectLink>
      )
    : (
      <a
        className={cn({
          [styles[theme]]: theme,
          [className]: className
        })}
        href={url}
        target={targetBlank ? '_blank' : '_self'}
        rel='noreferrer'
      >
        {title}
      </a>
      )
}

export default HeaderLandingLink
