import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import Button from '../Button'
import DirectLink from './../../components/DirectLink'
import DropdownViews from '../DropdownViews'
import ActionButton from '../LandingView/ActionButton'
import { headerMenu, downIcon } from '../../images'
import styles from './styles/index.module.scss'
import { BUTTON_THEME, BUTTON_VARIANT, HEADER_LANDING_LINK_THEME } from '../../const'
import HeaderLandingLink from './HeaderLandingLink'

const HeaderLanding = ({
  scrolled,
  openModalVideo,
  baseSlug,
  page,
  content,
  headerCustomStyle
}) => {
  const { menu, image: logo, action_button: actionButton, action_button2: actionButton2 } = content

  return (
    <header
      className={classNames(styles.header, {
        [styles.fixed]: scrolled
      })}
      style={headerCustomStyle}
    >
      <div className={styles.container}>
        <div className={styles['header-logo']}>
          <DirectLink link={baseSlug.pageHome}>
            <img
              className={classNames(styles['header-logo_image'], {
                [styles['header-logo_image_fixed']]: scrolled
              })}
              src={logo}
              alt='Logo'
            />
          </DirectLink>
        </div>
        {page?.page_model && (
          <div className={styles['header-menu']}>
            {menu && menu.length > 0 && menu.map(
              ({ url, id, title, target_blank: targetBlank, children }) => {
                return (
                  <React.Fragment key={id}>
                    {children && children.length > 0
                      ? (
                        <DropdownViews.Dropdown
                          distance='10'
                          className={styles['header-menu__dropdown']}
                          placement='bottom-start'
                        >
                          <Button
                            slot='trigger'
                            variant={BUTTON_VARIANT.TEXT}
                            className={styles['header-menu__dropdown-btn']}
                            theme={BUTTON_THEME.PROFILE_DROPDOWN}
                          >
                            {title}
                            <GxIcon className={styles['header-menu__dropdown-img']} src={downIcon} />
                          </Button>
                          <DropdownViews.Menu>
                            {children.map(
                              ({ url, id, title, target_blank: targetBlank }) => (
                                <DropdownViews.Item
                                  key={id}
                                  className={styles['header-menu__dropdown-item']}
                                >
                                  <HeaderLandingLink
                                    className={styles['header-menu__dropdown-link']}
                                    title={title}
                                    theme={HEADER_LANDING_LINK_THEME.default}
                                    url={url}
                                    targetBlank={targetBlank}
                                    baseSlug={baseSlug}
                                  />
                                </DropdownViews.Item>
                              )
                            )}
                          </DropdownViews.Menu>
                        </DropdownViews.Dropdown>
                        )
                      : (
                        <HeaderLandingLink
                          title={title}
                          url={url}
                          targetBlank={targetBlank}
                          baseSlug={baseSlug}
                          theme={HEADER_LANDING_LINK_THEME.default}
                        />
                        )}
                  </React.Fragment>
                )
              }
            )}
          </div>
        )}
        <div className={styles['header-menu__icons']}>

          <div className={styles['header-btns']}>
            {/* <a download className={styles['header-download']} href='pdf/exemple.pdf'>
              <GxIcon src={downloadIcon} /> // TODO: переделать в комп
              Примеры отчетов
            </a> */}
            {
              actionButton && (
                <ActionButton
                  actionButton={actionButton}
                  theme={BUTTON_THEME.FILL_ICON.ORANGE}
                  data-cy='header-link-login'
                  className={classNames({
                    [styles['header-login__btn']]: true,
                    [styles['header-login__text_fixed']]: scrolled
                  })}
                />
              )
            }
            {
              actionButton2 && (
                <ActionButton
                  actionButton={actionButton2}
                  data-cy='header-link-registration'
                  theme={BUTTON_THEME.FILL_ICON.LANDING}
                  className={classNames({
                    [styles['header-login__btn']]: true,
                    [styles['header-login__text_fixed']]: scrolled
                  })}
                />
              )
            }
          </div>
          <div className={styles['header__burger-menu']}>
            <Button
              data-cy='header-burger-menu'
              slot='trigger'
              caret
              variant={BUTTON_VARIANT.TEXT}
              theme={BUTTON_THEME.PROFILE_DROPDOWN}
              onClick={openModalVideo}
            >
              <GxIcon
                src={headerMenu}
                className={styles['header__burger-menu_icon']}
              />
            </Button>
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderLanding
