import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import { useStoreon } from 'storeon/react'

import TariffBanner from '../../views/TariffBanner'

import usePage from '../../hooks/usePage'
import useModalStore from '../../hooks/Modals/useModalStore'

import { MODAL_TYPES } from '../../const'

const TariffBannerComponent = ({ isShow = false }) => {
  const { baseSlug, page } = usePage()
  const intl = useIntl()
  const { currentUser } = useStoreon('currentUser')
  const { openModal } = useModalStore()
  const isPasswordValidityPassed = currentUser?.is_password_validity_passed
  const { is_free: isFree, is_trial: isTrial } = currentUser?.current_tariff || {}
  const isShowTrial = page.init_state.global?.is_show_trial
  const tariffExpiration = currentUser?.current_tariff?.is_change_color

  const showBannerWithTariffInfo = isShowTrial && (tariffExpiration || isFree || isTrial)
  const showBanner = (showBannerWithTariffInfo || isPasswordValidityPassed) && isShow

  /**
   * * Метод для получения контента для банера по какому либо условию
   * @function getBannerProps
   */
  const getBannerProps = () => {
    if (isPasswordValidityPassed) {
      return {
        title: intl.formatMessage({
          id: 'yourPasswordAboutToExpire',
          defaultMessage:
            'Срок действия пароля скоро истечёт. Пожалуйста, обновите пароль.'
        }),
        label: intl.formatMessage({
          id: 'refreshPassword',
          defaultMessage: 'Сменить пароль'
        }),
        onClick: () => {
          openModal(MODAL_TYPES.CHANGE_PASSWORD)
        }
      }
    } else if (isFree) {
      return {
        title: intl.formatMessage({
          id: 'yourTariffHasExpired',
          defaultMessage: 'У Вас закончилось действие тарифа'
        }),
        label: intl.formatMessage({
          id: 'choosePlan',
          defaultMessage: 'Выбрать тариф'
        }),
        href: baseSlug.pageTariffs
      }
    } else if (isTrial) {
      return {
        title: intl.formatMessage({
          id: 'youHaveATrialPlan',
          defaultMessage: 'У Вас пробный тариф'
        }),
        label: intl.formatMessage({
          id: 'choosePlan',
          defaultMessage: 'Выбрать тариф'
        }),
        href: baseSlug.pageTariffs
      }
    } else if (tariffExpiration) {
      return {
        title: intl.formatMessage({
          id: 'yourPlanIsAboutToExpire',
          defaultMessage: 'Срок действия вашего тарифа подходит к концу'
        }),
        label: intl.formatMessage({
          id: 'extendTariff',
          defaultMessage: 'Продлить тариф'
        }),
        href: baseSlug.pageTariffs
      }
    }
  }

  const tariffBannerProps = getBannerProps()

  if (!showBanner) {
    return null
  }

  return <TariffBanner {...tariffBannerProps} />
}

export default memo(TariffBannerComponent)
