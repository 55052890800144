/* eslint-disable camelcase */
import React from 'react'
import FrequentProblemsCard from '../../FrequentProblemsCard'
import styles from './styles/index.module.scss'

const FrequentProblems = ({ content }) => {
  const { problems_and_solutions_elements, theme } = content
  return (
    <div className={styles.frequentProblems}>
      <div className={styles.frequentProblems__items}>
        {problems_and_solutions_elements &&
          problems_and_solutions_elements.length > 0 &&
          problems_and_solutions_elements.map((el) => (<FrequentProblemsCard key={el.id} data={el} theme={theme} />))}
      </div>
    </div>
  )
}

export default FrequentProblems
