import React from 'react'
import Button from '../Button'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from './styles/add_component.module.scss'
import { plusIcon } from '../../images'
import { BUTTON_THEME } from '../../const'

const AddComponentView = ({ handleAdd, addButtonText, type }) => {
  return (
    <>
      <Button className={styles.button__add} theme={BUTTON_THEME.FILL_ICON.SMALL} onClick={handleAdd} data-cy={`${type}-add-button`}>
        <GxIcon
          src={plusIcon}
          slot='icon-left'
          className={styles.button__icon}
        />
        {addButtonText}
      </Button>
    </>
  )
}

export default AddComponentView
