import React from 'react'
import { GxSwitch } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'

import styles from './styles/index.module.scss'

/**
 *
 * @view
 * * вью для отрисовки свича
 * @param {string}              className
 * @param {ReactNode}           children
 * @param {HTMLGxSwitchElement} props
 */

const Switch = ({ children, className = '', ...props }) => {
  return (
    <GxSwitch
      className={classNames(styles.switch, className)}
      {...props}
    >
      {children}
    </GxSwitch>
  )
}

export default Switch
