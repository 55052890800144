import React from 'react'
import DropdownViews from '../DropdownViews'
import Button from '../Button'
import { GxIcon } from '@garpix/garpix-web-components-react'
import {
  copyIcon,
  deleteIcon,
  editIcon,
  moreIcon
} from '../../images'
import style from './styles/index.module.scss'
import classNames from 'classnames'
import { BUTTON_THEME, BUTTON_VARIANT } from '../../const'
import { useIntl } from 'react-intl'

const GroupDropdown = ({ action, visibleRename }) => {
  const { copy, remove, rename } = action
  const intl = useIntl()
  const handleClick = e => e.stopPropagation()

  return (
    <DropdownViews.Dropdown placement='bottom-end' hoist>
      <Button
        onClick={handleClick}
        onContextMenu={handleClick}
        data-cy='dropdown-btn'
        slot='trigger'
        caret
        variant={BUTTON_VARIANT.TEXT}
        theme={BUTTON_THEME.TRANSPARENT_WITH_ICON.DEFAULT}
        className={style.table_data__dropdown_btn}
      >
        <GxIcon src={moreIcon} className={style.table_data__more_icon} />
      </Button>
      <DropdownViews.Menu data-cy='dropdown-menu'>
        {visibleRename
          ? (
            <DropdownViews.Item onClick={rename} data-cy='dropdown-item-rename'>
              <GxIcon
                src={editIcon}
                className={style.table_data__dropdown_icon}
                slot='prefix'
              />
              {
                intl.formatMessage({
                  id: 'rename',
                  defaultMessage: 'Переименовать'
                })
              }
            </DropdownViews.Item>
            )
          : null}
        <DropdownViews.Item onClick={copy} data-cy='dropdown-item-copy'>
          <GxIcon
            src={copyIcon}
            className={style.table_data__dropdown_icon}
            slot='prefix'
          />
          {
            intl.formatMessage({
              id: 'copy',
              defaultMessage: 'Копировать'
            })
          }
        </DropdownViews.Item>
        {/* <DropdownViews.Item onClick={share}>
          <GxIcon
            src={shareIcon}
            className={style.table_data__dropdown_icon}
            slot="prefix"
          />
          Поделиться
        </DropdownViews.Item> */}
        {visibleRename
          ? (
            <DropdownViews.Item onClick={remove} data-cy='dropdown-item-remove'>
              <GxIcon
                src={deleteIcon}
                className={classNames(style.table_data__dropdown_icon, style['table_data__dropdown_icon--danger'])}
                slot='prefix'
              />
              {
                intl.formatMessage({
                  id: 'delete',
                  defaultMessage: 'Удалить'
                })
              }
            </DropdownViews.Item>
            )
          : null}
      </DropdownViews.Menu>
    </DropdownViews.Dropdown>
  )
}

export default GroupDropdown
