import React from 'react'
import { useMutation } from 'react-query'
import FileSaver from 'file-saver'

import { useModalStore, useModalIntlContent } from '@/hooks/Modals'
import useTranslate from '@/hooks/useTranslate'

import { projectApiService } from '@/api/services'
import { MODAL_TYPES } from '@/const'
import { stopProp, convertError } from '@/utils'

import DownloadReportView from '../views/DownloadReportView'
/**
 * @component
 * @param {number} projectId - id проекта для скачивания
 */
const DownloadReport = ({ projectId }) => {
  const { translate } = useTranslate()
  const { openModal, contentTypes } = useModalStore()
  const content = useModalIntlContent(contentTypes.warning)

  const onError = async (err) => {
    const errJson = await err.response?.data.text()
    const errorMessage = JSON.parse(errJson)?.detail ?? convertError(err)
    openModal(MODAL_TYPES.COMMON_CONFIRM, { ...content, description: errorMessage })
  }

  const { mutateAsync: handleDownloadLastReport, isLoading: isLastReportLoading } =
    useMutation({
      mutationFn: async () => {
        return projectApiService.downloadLastReport(projectId)
      },
      onSuccess: (lastReport) => {
        FileSaver.saveAs(
          lastReport,
          translate({
            id: 'latestReport',
            defaultMessage: 'Последние отчеты'
          })
        )
      },
      onError
    })

  const { mutateAsync: handleDownloadAllReports, isLoading: isAllReportLoading } =
    useMutation({
      mutationFn: async () => {
        return projectApiService.downloadAllReports(projectId)
      },
      onSuccess: (allReports) => {
        FileSaver.saveAs(
          allReports,
          translate({
            id: 'allReports',
            defaultMessage: 'Все отчеты'
          })
        )
      },
      onError
    })

  const downloadAllReportsProps = {
    onClick: (e) => {
      stopProp(e)
      handleDownloadAllReports()
    },
    disabled: isAllReportLoading
  }

  const downloadLastReportProps = {
    onClick: (e) => {
      stopProp(e)
      handleDownloadLastReport()
    },
    disabled: isLastReportLoading
  }

  return (
    <DownloadReportView
      downloadAllReportsProps={downloadAllReportsProps}
      downloadLastReportProps={downloadLastReportProps}
    />
  )
}

export default DownloadReport
