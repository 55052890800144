import React from 'react'
import { TITLE_TYPE, TITLE_VARIANT } from '../../const'
import PropTypes from 'prop-types'
import { suitability2 } from '../../images'
import Title from '../Title'
import styles from './styles/index.module.scss'

const SuitabilityCard = ({ title, description, image, type }) => {
  const View = type
  return (
    <View className={styles.card}>
      <img
        height={290}
        width={355}
        src={image || suitability2}
        alt={title}
        className={styles.card_image}
        loading='lazy'
      />
      <Title
        type={TITLE_TYPE.H3}
        className={styles.card_title}
        variant={TITLE_VARIANT.SUITABILITY}
      >
        {title && title}
      </Title>
      {description && <p className={styles.card_description}>{description}</p>}
    </View>
  )
}

SuitabilityCard.defaultProps = {
  type: 'div'
}

SuitabilityCard.propTypes = {
  type: PropTypes.oneOf(['div', 'li'])
}

export default SuitabilityCard
