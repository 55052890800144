import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import classNames from 'classnames'
import styles from './styles/index.module.scss'

const Icon = ({
  theme = 'square',
  variant = '',
  className,
  ...restProps
}) => {
  return (
    <GxIcon
      className={classNames(styles[theme], { [styles[variant]]: !!variant }, { [className]: !!className })}
      {...restProps}
    />
  )
}

export default Icon
