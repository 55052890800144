/* eslint-disable camelcase */
import React, { useRef, useEffect } from 'react'
import Media from 'react-media'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { macAVIF, macJPG, macWEBP } from '../../../images/landing/mac5'
import { BUTTON_THEME, LANDING_ID } from '../../../const'
import InnerHtml from '../../../components/InnerHtml'
import cn from 'classnames'
import ActionButton from '../ActionButton'

import styles from './styles/index.module.scss'

const Welcome = ({ handleOpenModal, content, count }) => {
  const {
    text_title,
    text,
    video,
    action_button,
    action_button2,
    theme,
    image,
    top_banner_elements,
    withLaptop
  } = content
  const styleWelcome = theme && {
    '--background-color': theme.background_color,
    '--subtitle-color': theme.text_subtitle_color,
    '--title-color': theme.text_title_color,
    '--text-color': theme.text_color,
    '--background-color-2': theme.background_card_color,
    '--font-custom': theme.font
  }
  // !!hac, custom founts only gazprom (now)
  const isGazprom = !!theme?.font

  const videoRef = useRef(null)

  useEffect(() => {
    videoRef && videoRef.current && videoRef.current.play()
  }, [videoRef])
  return (
    <section
      data-count={count}
      className={cn({
        [styles.welcome]: true,
        [styles['welcome--bg']]: isGazprom
      })}
      id={LANDING_ID.WELCOME} style={styleWelcome}
    >
      <div className={cn({
        [styles.container]: true,
        [styles.container__custom]: !withLaptop
      })}
      >
        <div className={styles['left-column']}>
          {text_title && <h1 data-laptop={withLaptop} data-gaz={isGazprom} className={styles['welcome-heading']}>{text_title}</h1>}
          {text && <InnerHtml data-gaz={isGazprom} html={text} className={styles['welcome-text']} />}
          {(action_button || action_button2) && (
            <div className={styles['welcome-btns']}>
              {action_button && (
                <ActionButton
                  actionButton={action_button}
                  theme={BUTTON_THEME.FILL_ICON.LANDING}
                  className={styles['welcome-btn']}
                  handleOpenModal={handleOpenModal}
                />
              )}
              {action_button2 && (
                <ActionButton
                  actionButton={action_button2}
                  theme={BUTTON_THEME.FILL_ICON.LANDING}
                  className={styles['welcome-btn']}
                  handleOpenModal={handleOpenModal}
                />
              )}
            </div>
          )}
          <div data-laptop={withLaptop} className={styles.welcome__wrap}>
            {top_banner_elements &&
              top_banner_elements.length > 0 &&
              top_banner_elements.map(({ id, image, text }) => (
                <div data-laptop={withLaptop} className={styles['welcome-import']} key={id}>
                  {
                    image &&
                      <div className={styles['welcome-import__icon']}>
                        <GxIcon src={image} />
                      </div>
                  }
                  {text}
                </div>
              ))}
          </div>
        </div>
        <div className={styles['right-column']}>
          <div className={styles['welcome-screen']}>
            {video && withLaptop && (
              <Media
                queries={{
                  small: '(max-width: 568px)'
                }}
              >
                {(matches) => {
                  return (
                    <>
                      {!matches.small
                        ? (
                          <video
                            className={styles['welcome-screen__video']}
                            ref={videoRef}
                            loop
                            muted
                          >
                            {video && <source src={video} type='video/mp4' />}
                          </video>
                          )
                        : (
                          <video
                            className={styles['welcome-screen__video']}
                            loop
                            muted
                          >
                            {video && <source src={video} type='video/mp4' />}
                          </video>
                          )}
                    </>
                  )
                }}
              </Media>
            )}
            {withLaptop
              ? (
                <picture>
                  <source srcSet={macAVIF} type='image/avif' />
                  <source srcSet={macWEBP} type='image/webp' />
                  <img
                    width='100'
                    height='100'
                    src={macJPG}
                    alt='project image'
                    className={styles['welcome-screen__img']}
                  />
                </picture>
                )
              : (
                <img
                  src={image}
                  alt='banner'
                  loading='lazy'
                  width='1000'
                  height='600'
                  className={styles['welcome-screen__img-lap']}
                />

                )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Welcome
