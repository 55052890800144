import { cloneDeep } from '../../../utils'
import { CALCULATION_STATUS, DefaultProject } from '../../../const'
import { calculationApiService, projectApiService } from '../../../api/services'
import { projectSerializer } from '../../../api/serializers'

const projectModule = (store) => {
  store.on('@init', () => ({ project: cloneDeep(DefaultProject) }))

  store.on('project/save', (store, project) => {
    return {
      project,
      currentWarehouseId:
        project.roadMaps && project.roadMaps.length > 0
          ? project.roadMaps[0].id
          : '0',
    }
  })

  store.on('project/change/id&status', ({ project }, { id, status }) => {
    return {
      project: { ...project, calculation_id: id, calculation_status: status },
    }
  })

  store.on(
    'afterSceneAction',
    (
      { project },
      { cargoNewInfo = {}, handleError = () => {}, handleSucsess = () => {} }
    ) => {
      if (project.calculation_status === CALCULATION_STATUS.SUCCESS) {
        calculationApiService
          .move_create({
            id_calculation: project.calculation_id,
            params: cargoNewInfo,
          })
          .then((res) => {
            store.dispatch('project/change/id&status', {
              id: res.id,
              status: res.status,
            })
            handleSucsess(res.id)
          })
          .catch((e) => {
            handleError(e)
          })
      } else {
        calculationApiService
          .move_update({
            id_calculation: project.calculation_id,
            params: cargoNewInfo,
          })
          .then((res) => {
            handleSucsess(project.calculation_id)
          })
          .catch((e) => {
            handleError(e)
          })
      }
    }
  )

  store.on('project/set', ({ project }, payload) => {
    return {
      project: { ...project, ...payload },
    }
  })

  store.on('project/clean', () => {
    return {
      project: cloneDeep(DefaultProject),
    }
  })

  store.on('project/calc/id/save', ({ project }, id) => {
    if (id !== project.calculation_id) {
      return {
        project: { ...project, calculation_id: id },
      }
    }
  })

  store.on('project/calc/status/save', ({ project }, status) => {
    if (status !== project.calculation_status) {
      return {
        project: { ...project, calculation_status: status },
      }
    }
  })

  store.on('project/cloneCargoSpace', ({ project }, is_clone_cargo_space) => {
    return {
      project: { ...project, is_clone_cargo_space },
    }
  })

  store.on('project/axisLimitEnabled/set', ({ project }, is_axis_limit_enabled) => {
    return {
      project: { ...project, is_axis_limit_enabled },
    }
  })

  store.on('project/calc/save', ({ project }, calc) => {
    return {
      ...store,
      project: { ...project, calculation: { ...calc } },
    }
  })

  store.on('project/set/title', (store, title) => {
    return { ...store, project: { ...store.project, title } }
  })
  store.on('project/set/last_calc', (store, calc) => {
    return {
      ...store,
      project: {
        ...store.project,
        lastCalc: { ...store.project.lastCalc, ...calc },
      },
    }
  })

  store.on('project/systemId/change', ({ project }, { id }) => {
    return {
      project: { ...cloneDeep(project), currentSystemId: id },
    }
  })

  store.on('project/visualData/change', ({ project }, { data }) => {
    return {
      project: { ...cloneDeep(project), first_visual: data },
    }
  })

  store.on(
    'project/get',
    async ({ project: { cargoList } }, { id, params, success, error }) => {
      //!! достаем cargoList, тк теперь в он берется из запроса на group/id_...
      try {
        const projectRes = await projectApiService.getProject({ id, params })
        const serialProject = {
          ...projectSerializer(projectRes),
          cargoList: cargoList,
        }
        store.dispatch('project/save', serialProject)
        success()
      } catch (e) {
        const message = e.message
        error(message)
      }
    }
  )

  store.on('project/cargoSpace/add', ({ project }, newSpaces) => {
    return { project: { ...project, cargoSpaces: newSpaces } }
  })

  store.on('project/cargoList/group/add', ({ project }, { newGroups }) => ({
    project: { ...project, cargoList: newGroups },
  }))

  store.on('project/change/userSort', ({ project }, userSort) => ({
    project: { ...project, userSort },
  }))

  store.on('project/change/calculation_grade', ({ project }, calculation_grade) => ({
    project: { ...project, calculation_grade },
  }))
}

export default projectModule
