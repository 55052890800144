import { CARGO_TYPE_ENUM } from '@/const'

export const INITIAL_CARGO = {
  info: { material: '' },
  type: CARGO_TYPE_ENUM.BOX,
  color: '#a6001a',
  title: '',
  article: '',
  cargo_id: '',
  length: '',
  width: '',
  height: '',
  mass: '',
  stacking: true,
  is_rotate_y: false,
  stacking_limit: '',
  turnover: true,
  margin_length: 0,
  margin_width: 0,
  count: 1,
  diameter: '',
  inner_diameter: ''
}

export const INITIAL_MP_FIELDS = {
  layer: '',
  tiering: '',
  type: '',
  RTU_priority: ''
}

export const INITIAL_CARGO_GROUP = {
  group_id: '',
  title: '',
  pallet: null,
  cargoes: []
}

export const CARGO_ITEM_FIELDS = [
  {
    id: 0,
    name: 'article',
    label: {
      id: 'article',
      defaultMessage: 'Артикул'
    },
    placeholder: '',
    required: null,
    component: 'input'
  },
  {
    id: 1,
    name: 'length',
    label: {
      id: 'space.field.length',
      defaultMessage: 'Длина (мм)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 2,
    name: 'width',
    label: {
      id: 'space.field.width',
      defaultMessage: 'Ширина (мм)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 3,
    name: 'height',
    label: {
      id: 'space.field.height',
      defaultMessage: 'Высота (мм)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 4,
    name: 'mass',
    label: {
      id: 'space.field.mass',
      defaultMessage: 'Масса (кг)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  }
]

export const CARGO_ITEM_FIELDS_DIAMETERS = [
  {
    id: 0,
    name: 'article',
    label: {
      id: 'article',
      defaultMessage: 'Артикул'
    },
    placeholder: '',
    required: null,
    component: 'input'
  },
  {
    id: 1,
    name: 'diameter',
    label: {
      id: 'space.field.diameter',
      defaultMessage: 'Внешний диаметр (мм)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 2,
    name: 'height',
    label: {
      id: 'space.field.height',
      defaultMessage: 'Высота (мм)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 3,
    name: 'mass',
    label: {
      id: 'space.field.mass',
      defaultMessage: 'Масса (кг)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  }
]

export const CARGO_ITEM_FIELDS_TUBE = [
  {
    id: 0,
    name: 'article',
    label: {
      id: 'article',
      defaultMessage: 'Артикул'
    },
    placeholder: '',
    required: null,
    component: 'input'
  },
  {
    id: 1,
    name: 'diameter',
    label: {
      id: 'space.field.diameter',
      defaultMessage: 'Внешний диаметр (мм)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 2,
    name: 'inner_diameter',
    label: {
      id: 'space.field.innerDiameter',
      defaultMessage: 'Внутренний диаметр (мм)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  },
  // {
  //   id: 2,
  //   name: 'inner_diameter',
  //   label: {
  //     id: 'space.field.innerDiameter',
  //     defaultMessage: 'Внутренний диаметр (мм)',
  //   },
  //   placeholder: '0',
  //   defaultValue: 0,
  //   required: true,
  //   component: 'inputNumbers',
  // },
  {
    id: 3,
    name: 'height',
    label: {
      id: 'space.field.height',
      defaultMessage: 'Высота (мм)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  },
  {
    id: 4,
    name: 'mass',
    label: {
      id: 'space.field.mass',
      defaultMessage: 'Масса (кг)'
    },
    placeholder: '0',
    defaultValue: 0,
    required: true,
    component: 'inputNumbers'
  }
]

export const CARGO_ITEM_CHECKBOXS = [
  {
    id: 0,
    name: 'turnover',
    tooltipContent: {
      id: 'cant',
      defaultMessage: 'Кантовать'
    }
  },
  {
    id: 1,
    name: 'stacking',
    tooltipContent: {
      id: 'stack',
      defaultMessage: 'Штабелировать'
    }
  },
  {
    id: 2,
    name: 'is_rotate_y',
    tooltipContent: {
      id: 'limitVerticalRotation',
      defaultMessage: 'Поворот вокруг вертикальной оси'
    }
  }
]

export const CARGO_ITEM_TUBE_CHECKBOXES = [
  ...CARGO_ITEM_CHECKBOXS,
  {
    id: 3,
    name: 'is_telescoping',
    tooltipContent: {
      id: 'limitVerticalRotation',
      defaultMessage: 'Поворот вокруг вертикальной оси'
    }
  }
]
/**
 * Енам сущностей проекта с которыми можно взаимодействовать
 * @readonly
 * @enum {string}
 */
export const NEW_CALC_ELEMENTS = {
  CARGO: 'cargo',
  CARGO_SPACE: 'cargo_space',
  GROUPS: 'groups',
  SETTINGS: 'settings'
}
/**
 * Енам действий для взаимодействиями с сущностями проекта
 * @readonly
 * @enum {string}
 */
export const NEW_CALC_ACTIONS = {
  ADD: 'add',
  CHANGE: 'change',
  DELETE: 'delete'
}
