import React from 'react'
import styles from '../styles/index.module.scss'
import { logo } from '../../../../images'

const ModalIconLogo = () => {
  return (
    <div className={styles.icon_logo}>
      <img src={logo} />
    </div>
  )
}

export default ModalIconLogo
