import React from 'react'
import style from './style/index.module.scss'

const LogoGrid = ({ content }) => {
  const { logo_net_elements: companyInfo } = content
  return (
    <ul className={style.logo_grid}>
      {companyInfo.map(({ id, image, short_description: desc }) => {
        return (
          <li
            className={style.logo_grid__card}
            key={id}
          >
            <div className={style.logo_grid__card_wrap}>
              <img
                className={style.logo_grid__card_img}
                width={320}
                height={200}
                src={image}
              />
            </div>
            {desc && (
              <div className={style.logo_grid__card_info}>
                <div className={style.logo_grid__card_scroll}>
                  <p className={style.logo_grid__card_desc}>{desc}</p>
                </div>
              </div>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default LogoGrid
