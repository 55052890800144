import React, { useEffect, useMemo } from 'react'
import { useSocket } from '../../hooks'
import { runtimeConfig } from '../../../config'
import api from '../../../api'

const MAIN_URL_WSS = runtimeConfig.API_URL_WSS
const withSocket = (Component) => (props) => {
  const { socket, init, update } = useSocket()
  const token = useMemo(() => api.getAuthToken(), [api])

  useEffect(() => {
    if (socket === null && token) {
      const initSocket = init(MAIN_URL_WSS, token)
      update(initSocket)
    }
  }, [socket, token])
  return <Component {...props} />
}

export default withSocket
