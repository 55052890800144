export const INITIAL_REGISTRATION_KEYS = {
  PASSWORD: 'password',
  REPEAT_PASSWORD: 'repeatPassword',
  PHONE: 'phone',
  TAX_IDENTIFY: 'tax_identify',
  EMAIL: 'email',
  PROMOCODE: 'promocode',
  EMAIL_CONF_CODE: 'email_confirmation_code'
}

export const PASSWORD_VAL_FUNC_KEYS = {
  min_length: 'min',
  min_digits: 'minDigits',
  min_chars: 'minChar',
  min_uppercase: 'minUppercase',
  min_special_symbols: 'minSpecialSymbols'
}

export const PASSWORD_VAL_FUNC_ARR = Object.values(PASSWORD_VAL_FUNC_KEYS)
