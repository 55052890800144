import * as yup from 'yup'
import titleSchema from './TitleSchema'
import { getValidateText } from '../ValidateText'
import { ENTITY_TYPES, ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '../../const'

const RenameSchema = yup.lazy(({ type }) => {
  switch (type) {
    case ENTITY_TYPES.CARGO:
      return yup.object().shape({
        title: yup
          .string()
          .trim()
          .max(50, getValidateText('title', 'max', 50))
          .min(ERROR_NUMBERS.MIN_STRING_NUMBER, getValidateText('title', 'min', ERROR_NUMBERS.MIN_STRING_NUMBER))
          .required(INTL_ERROR_MESSAGE.REQUIRED)
      })
    default:
      return yup.object().shape({
        ...titleSchema
      })
  }
})

export default RenameSchema
