import React from 'react'
import { youtubeImage } from '../../../../images'
import styles from '../styles/index.module.scss'
import { TITLE_TYPE, TITLE_VARIANT } from '../../../../const'
import Title from '../../../Title'
import { useIntl } from 'react-intl'

const HomeVideo = ({ handleClose, videoLink }) => {
  const intl = useIntl()
  return (
    <div className={styles.home_video__container}>
      <Title type={TITLE_TYPE.H2} variant={TITLE_VARIANT.LARGE_MODAL}>{intl.formatMessage({
        id: 'smartLoadingSystem',
        defaultMessage: 'Система умной погрузки'
      })}
      </Title>
      <p className={styles.home_video__subtitle}>
        {
          intl.formatMessage({
            id: 'watchMore',
            defaultMessage: 'Смотрите больше видео на нашем канале'
          })
        }
      </p>
      <a onClick={handleClose} target='_blank' href={videoLink} className={styles.home_video__banner} rel='noreferrer'>
        <img
          className={styles.home_video__image}
          width='90px'
          height='90px'
          alt='Youtube'
          src={youtubeImage}
        />
        <div className={styles.home_video__banner_title}>{intl.formatMessage({
          id: 'youtubeChanel',
          defaultMessage: 'Youtube канал GLS'
        })}
        </div>
        <p className={styles.home_video__text}>
          {
            intl.formatMessage({
              id: 'youTubeDescription',
              defaultMessage: 'Обучающие видео и другая полезная информация'
            })
          }
        </p>
      </a>
    </div>
  )
}

export default HomeVideo
