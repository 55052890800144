import { getValidateText } from '../../../utils/ValidateText'
import { INITIAL_REGISTRATION_KEYS, PASSWORD_VAL_FUNC_KEYS } from '../consts'
/**
 * * метод для создания правил по созданию пароля
 * @function getPasswordRules
 * @param {obj} passwordConfig - конфиг с правилами для создания пароля
 */
export const getPasswordRules = (passwordConfig) => {
  const passwordRules = []
  Object.entries(passwordConfig).forEach(([key, value], i) => {
    if (value > 0 && value) {
      const rule = getValidateText(
        INITIAL_REGISTRATION_KEYS.PASSWORD,
        PASSWORD_VAL_FUNC_KEYS[key],
        value
      )
      passwordRules.push({
        rule,
        keyRule: PASSWORD_VAL_FUNC_KEYS[key]
      })
    }
  })

  return passwordRules
}
