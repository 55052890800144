import React, { useState, useEffect } from 'react'

const AnimateNumber = ({ className = '', number, isVisible, speed = 20 }) => {
  const [animate, setAnimate] = useState(0)

  useEffect(() => {
    if (isVisible) {
      const timeOut = setTimeout(() => {
        setAnimate((prev) => prev + 1)
      }, speed)
      if (animate === number) {
        clearTimeout(timeOut)
      }
    }
  }, [animate, isVisible])

  return <span className={className}>{animate}%</span>
}

export default AnimateNumber
