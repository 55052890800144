import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import Media from 'react-media'
import Button from '../Button'
import Tariff from './Tariff'
import UserCurrentTariff from '../../components/ProfileComponent/UserCurrentTariff'
import ToPreservedHtml from '../ToPreservedHtml/ToPreservedHtml'
import {
  crown,
  dollar,
  historyIcon,
  okIcon,
  promocodes
} from '../../images'
import Title from '../Title'
import { TITLE_VARIANT } from '../../const'
import style from './styles/index.module.scss'
import { formattingDate } from '../../utils'

const Tariffs = ({
  tariffs,
  pressTariff,
  buyTariff,
  onPressTariff,
  currentTariff,
  setCurrentTariff
}) => {
  return (
    <>
      <div className={style.tariffs}>
        <Title variant={TITLE_VARIANT.LIST_PAGES}>Тарифы</Title>
        <div className={style.tariffs__wrapper}>
          <div className={style['tariffs_left-block']}>
            <div className={style.tariffs_block}>
              {tariffs.map((tariff) => (
                <div key={tariff.id} className={style.tariffs__item}>
                  <Tariff
                    tariff={tariff}
                    pressId={pressTariff?.id}
                    onClick={onPressTariff}
                    active={pressTariff?.id === tariff?.id}
                  />
                  <Media
                    queries={{
                      small: '(max-width: 573px)'
                    }}
                  >
                    {(matches) => (
                      <>
                        {matches.small
                          ? pressTariff?.id === tariff.id && (
                            <>
                              <div className={style.tariffs_desc}>
                                <Title
                                  variant={TITLE_VARIANT.LIST_PAGES}
                                  className={style.tariffs_desc__title}
                                >
                                  Описание
                                </Title>
                                <ToPreservedHtml
                                  className={style.tariffs_desc__text}
                                  html={pressTariff.description}
                                />
                              </div>
                              {pressTariff?.promocode_info && (
                                <div
                                  className={
                                      style['tariffs_desc-promotion']
                                    }
                                >
                                  <Title
                                    variant={TITLE_VARIANT.LIST_PAGES}
                                    className={
                                        style[
                                          'tariffs_desc-promotion__title'
                                        ]
                                      }
                                  >
                                    <span>
                                      <GxIcon
                                        src={okIcon}
                                        className={
                                            style[
                                              'tariffs_desc-promotion__icon'
                                            ]
                                          }
                                      />
                                    </span>
                                    Применён промокод
                                  </Title>
                                  {!pressTariff?.promocode_info
                                    ?.description_promotion && (
                                      <ToPreservedHtml
                                        className={
                                          style.tariffs_desc__text
                                        }
                                        html={
                                          pressTariff.promocode_info
                                            .description_promotion
                                        }
                                      />
                                  )}
                                  {pressTariff?.promocode_info
                                    ?.promocode_finish_at && (
                                      <div
                                        className={
                                          style[
                                            'tariffs_desc-promotion__finished'
                                          ]
                                        }
                                      >
                                        <Title
                                          variant={
                                            TITLE_VARIANT.LIST_PAGES
                                          }
                                          className={
                                            style[
                                              'tariffs_desc-promotion__dateTo_title'
                                            ]
                                          }
                                        >
                                          <GxIcon
                                            src={historyIcon}
                                            className={
                                              style[
                                                'tariffs_desc-promotion__dateTo_icon'
                                              ]
                                            }
                                          />
                                          Действителен до:
                                          <span
                                            className={
                                              style[
                                                'tariffs_desc-promotion__dateTo_date'
                                              ]
                                            }
                                          >
                                            {formattingDate(
                                              pressTariff.promocode_info
                                                .promocode_finish_at
                                            )}
                                          </span>
                                        </Title>
                                      </div>
                                  )}
                                </div>
                              )}
                              <div
                                className={style.tariffs__button_center}
                              >
                                <Button
                                  onClick={buyTariff}
                                  disabled={pressTariff?.is_free}
                                >
                                  Перейти к оплате
                                </Button>
                              </div>
                            </>
                            )
                          : null}
                      </>
                    )}
                  </Media>
                </div>
              ))}
            </div>
            <Media
              queries={{
                small: '(max-width: 573px)'
              }}
            >
              {(matches) => (
                <>
                  {!matches.small
                    ? pressTariff && (
                      <>
                        <div className={style.tariffs_desc}>
                          <Title
                            variant={TITLE_VARIANT.LIST_PAGES}
                            className={style.tariffs_desc__title}
                          >
                            Описание
                          </Title>
                          <ToPreservedHtml
                            className={style.tariffs_desc__text}
                            html={pressTariff.description}
                          />
                        </div>
                        {pressTariff?.promocode_info && (
                          <div
                            className={style['tariffs_desc-promotion']}
                          >
                            <Title
                              variant={TITLE_VARIANT.LIST_PAGES}
                              className={
                                  style['tariffs_desc-promotion__title']
                                }
                            >
                              <GxIcon
                                src={promocodes}
                                className={
                                    style['tariffs_desc-promotion__icon']
                                  }
                              />
                              Применён промокод
                            </Title>
                            {pressTariff?.promocode_info
                              ?.description_promotion && (
                                <ToPreservedHtml
                                  className={style.tariffs_desc__text}
                                  html={
                                    pressTariff.promocode_info
                                      .description_promotion
                                  }
                                />
                            )}
                            {pressTariff?.promocode_info
                              ?.promocode_finish_at && (
                                <div
                                  className={
                                    style[
                                      'tariffs_desc-promotion__finished'
                                    ]
                                  }
                                >
                                  <Title
                                    variant={TITLE_VARIANT.LIST_PAGES}
                                    className={
                                      style[
                                        'tariffs_desc-promotion__dateTo_title'
                                      ]
                                    }
                                  >
                                    <GxIcon
                                      src={historyIcon}
                                      className={
                                        style[
                                          'tariffs_desc-promotion__dateTo_icon'
                                        ]
                                      }
                                    />
                                    Действителен до:
                                    <span
                                      className={
                                        style[
                                          'tariffs_desc-promotion__dateTo_date'
                                        ]
                                      }
                                    >
                                      {formattingDate(
                                        pressTariff.promocode_info
                                          .promocode_finish_at
                                      )}
                                    </span>
                                  </Title>
                                </div>
                            )}
                          </div>
                        )}
                        <div className={style.tariffs__buttons}>
                          <div className={style.tariffs__press_tariff}>
                            <p
                              className={
                                  style.tariffs__press_tariff__title
                                }
                            >
                              <GxIcon
                                slot='icon-left'
                                src={crown}
                                className={style.icons}
                              />
                              Тариф:{' '}
                              <span
                                className={
                                    style.tariffs__press_tariff__value
                                  }
                              >
                                {pressTariff.title}
                              </span>
                            </p>
                            <p
                              className={
                                  style.tariffs__press_tariff__title
                                }
                            >
                              <GxIcon
                                slot='icon-left'
                                src={dollar}
                                className={style.icons}
                              />
                              Стоимость:{' '}
                              <span
                                className={
                                    style.tariffs__press_tariff__value
                                  }
                              >
                                {+pressTariff.price} ₽
                              </span>
                            </p>
                            {pressTariff?.promocode_info
                              ?.promocode_title && (
                                <p
                                  className={
                                    style.tariffs__press_tariff__title
                                  }
                                >
                                  <GxIcon
                                    slot='icon-left'
                                    src={promocodes}
                                    className={style.icons}
                                  />
                                  Промокод:{' '}
                                  <span
                                    className={
                                      style.tariffs__press_tariff__value
                                    }
                                  >
                                    {
                                      pressTariff.promocode_info
                                        .promocode_title
                                    }
                                  </span>
                                </p>
                            )}
                          </div>
                          {pressTariff?.is_free
                            ? null
                            : (
                              <div className={style.tariffs__button}>
                                <Button
                                  onClick={buyTariff}
                                  disabled={pressTariff?.is_free}
                                >
                                  Перейти к оплате
                                </Button>
                              </div>
                              )}
                        </div>
                      </>
                      )
                    : null}
                </>
              )}
            </Media>
          </div>
          <div className={style.tariffs__current_tariff}>
            <UserCurrentTariff
              currentTariff={currentTariff}
              setCurrentTariff={setCurrentTariff}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Tariffs
