const modalModule = (store) => {
  store.on('@init', () => ({
    showModal: null,
    isUnclosable: false,
    modalProps: {},
  }))
  store.on('modalType/toggle', ({ isUnclosable }, { modal, modalProps }) => {
    return {
      isUnclosable,
      showModal: modal,
      modalProps,
    }
  })
  store.on(
    'modalType/setUnclosable',
    ({ showModal, modalProps }, { isUnclosable }) => {
      return {
        showModal,
        modalProps,
        isUnclosable,
      }
    }
  )
  store.on('modalType/close', () => ({
    showModal: null,
    modalProps: {},
    isUnclosable: false
  }))
}

export default modalModule
