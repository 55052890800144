import ProfileNavigation from './ProfileNavigation'
import CountrySelect from './CountrySelect'
import HelpContainer from './HelpContainer'
import Profile from './Profile'
import ProfileInfo from './ProfileInfo'
import Tariffs from './Tariffs'
import UserCurrentTariff from './UserCurrentTariff'
import UserType from './UserType'

export default {
  CountrySelect,
  HelpContainer,
  Profile,
  ProfileInfo,
  Tariffs,
  UserCurrentTariff,
  UserType,
  ProfileNavigation
}
