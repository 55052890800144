import { useEffect } from 'react'
import api from './api'

export const WebToken = ({ children }) => {
  useEffect(() => {
    if (!window.gls) {
      window.gls = {}
    }

    window.gls.setToken = (token) => api.setAuthToken(token)
  }, [])

  return children
}
