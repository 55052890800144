import {Api} from '../../index.js'
import { runtimeConfig } from '../../../config'


class TruckApiService extends Api {

    getTruck = async ({ id }) => {
        const res = await this.get(`/cargo/truck/${id}/`);
        return res.data;
    };

    getTrucks = async (params) => {
        const res = await this.get('/cargo/truck/', params);
        return res.data;
    };

    createTruck = async (params) => {
        const res = await this.post('/cargo/truck/', params);
        return res.data;
    };

    deleteTruck = async ({ id }) => {
        const res = await this.delete(`/cargo/truck/${id}/`);
        return res.data;
    };

    updateTruck = async ({ id, ...params }) => {
        const res = await this.put(`/cargo/truck/${id}/`, { ...params });
        return res.data;
    };

    copyTruck = async ({ id }) => {
        const res = await this.post(`/cargo/truck/${id}/copy/`);
        return res.data;
    };

    renameTruck = async ({ id, title }) => {
        const res = await this.patch(`/cargo/truck/${id}/`, { title });
        return res.data;
    };
}

export default new TruckApiService(runtimeConfig.API_URL)