import React, { memo } from 'react'
import { helpEmpty } from '../../images'
import classNames from 'classnames'
import styles from './styles/empty.module.scss'
import Title from '../Title'
import { useIntl } from 'react-intl'
import { INTL_TITLE } from '../../const'

const QuestionEmpty = ({ hasQuestions = false, theme = '' }) => {
  const intl = useIntl()
  return (
    <div
      className={classNames(
        styles.empty,
        theme && styles[`empty__${theme}`]
      )}
    >
      <img
        className={classNames(
          styles.empty__img,
          theme && styles[`empty__img-${theme}`]
        )}
        src={helpEmpty}
        height={200}
        width={200}
      />
      <Title
        variant={`empty_${theme}`}
        type='h3'
      >
        {
          hasQuestions ? intl.formatMessage(INTL_TITLE.SEARCH) : intl.formatMessage(INTL_TITLE.SEARCH)
        }
      </Title>
    </div>
  )
}
export default memo(QuestionEmpty)
