import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useStoreon } from 'storeon/react'
import { useMutation } from 'react-query'
import { useLocation } from 'react-router-dom'
import usePage from '../../../../hooks/usePage'
import { userApiService } from '../../../../api/services'
import { convertError } from '../../../../utils'
import Checkbox from '../../../../views/Checkbox'
import Button from '../../../../views/Button'

import styles from './styles/index.module.scss'

/**
 * Незакрываемая модалка политики конфеденциальности
 * @return {JSX.Element|null}
 * @constructor
 */
const PrivacyPolicyModal = () => {
  const intl = useIntl()
  const location = useLocation()
  const { currentUser } = useStoreon('currentUser')
  const { baseSlug } = usePage()
  const [isProcessingPersonalData, setProcessingPersonalData] = useState(currentUser?.is_processing_personal_data)
  const [checked, setChecked] = useState(false)
  const [error, setError] = useState('')
  const isPrivacyPolicyPage = location.pathname === baseSlug.pagePrivacyPolicy
  const handleChange = (e) => {
    setChecked(e.target.checked)
  }

  const { mutate, isLoading } = useMutation({
    mutationKey: ['updatePersonalData'],
    mutationFn: () => userApiService.updatePersonalData(currentUser?.id),
    onError: (error) => {
      setError(convertError(error))
    },
    onSuccess: () => {
      setProcessingPersonalData(true)
      document.querySelector('body').classList.remove('gx-scroll-lock')
    }
  })
  const handleSubmit = () => {
    mutate()
  }
  useEffect(() => {
    if (isProcessingPersonalData === false && !isPrivacyPolicyPage) {
      document.querySelector('body').classList.add('gx-scroll-lock')
    }
  }, [isProcessingPersonalData])

  useEffect(() => {
    if (currentUser) {
      setProcessingPersonalData(currentUser.is_processing_personal_data)
    }
  }, [currentUser])

  if (!currentUser || isProcessingPersonalData === undefined || isProcessingPersonalData || isPrivacyPolicyPage) {
    return null
  }

  return (
    <div className={styles.privacy__root}>
      <div className={styles.privacy__modal}>
        <h3 className={styles.privacy__head}>
          {
            intl.formatMessage({
              id: 'privacyPolicyTitle',
              defaultMessage: 'Подтвердите свое согласие на обработку персональных данных'
            })
          }
        </h3>
        <Checkbox className={styles.privacy__checkbox} onGx-change={handleChange}>
          {intl.formatMessage({
            id: 'privacyPolicyMessage',
            defaultMessage: 'Классный сервис! Я, конечно, согласен с <a> политикой в отношении обработки персональных данных</a>'
          }, {
            a: (chunks) => (
              <a className={styles.privacy__link} target='_blank' rel='noreferrer' href={baseSlug.pagePrivacyPolicy}>
                {chunks}
              </a>
            )
          })}
        </Checkbox>
        {
          !!error && (
            <p className={styles.privacy__error}>error</p>
          )
        }
        <div className={styles.privacy__button}>
          <Button disabled={checked === false || isLoading} onClick={handleSubmit} isSpinner={isLoading}>
            {intl.formatMessage({ id: 'continue', defaultMessage: 'Продолжить' })}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicyModal
