import React from 'react'
import classNames from 'classnames'

import { BUTTON_THEME } from '../../const'

import ContactsInfo from './ContactsInfo'
import FooterSecondPart from './FooterSecondPart'
import ContactForm from './ContactForm'
import ActionButton from '../LandingView/ActionButton'
import style from './styles/index.module.scss'

/**
 *
 * @view
 *
 * * Вью Футера на лендинге
 *
 * @param {string}        errorRecaptcha
 * @param {string}        msgStatus       статус отправленнго сообщеня
 * @param {Object}        baseSlug        ссылки на страницы
 * @param {Array<Object>} menus           массив ссылок на стр нижнего футера
 * @param {string}        garpixUrl
 * @param {boolean}       raiseFooter
 * @func  handleOpenModal                 метод открытия модалки
 * @func  sendFeedback                    метод для отправки вопроса от юзера
 *
 */

const FooterLanding = ({
  errorRecaptcha,
  sendFeedback,
  msgStatus,
  baseSlug,
  menus,
  components,
  handleOpenModal,
  garpixUrl,
  raiseFooter
}) => {
  const footerComponent = React.useMemo(
    () =>
      components?.find(
        (component) => component.component_model === 'FooterComponent'
      ),
    []
  )

  const actionButton = footerComponent?.object?.action_button
  const theme = footerComponent?.object?.theme
  const styleFooter = theme && {
    '--bc-color': theme.background_color,
    '--bc-bottom-color': theme.background_card_color,
    '--titles-color': theme.text_title_color,
    '--accent-color': theme.accent_color_1,
    '--bc-form-color': theme.accent_color_2,
    '--font-custom': theme.font
  }
  return (
    <footer
      className={classNames({
        [style.footer]: true,
        [style.footer__raise]: raiseFooter
      })}
      style={styleFooter}
    >
      {footerComponent && (
        <div className={style['first-part']}>
          <div className={style.container}>
            <div className={style['first-part__wrap']}>
              <ContactsInfo actionButton={actionButton} footerComponent={footerComponent} />
              {actionButton && (
                <div className={style['first-part__right-col']}>
                  <ContactForm
                    errorRecaptcha={errorRecaptcha}
                    sendFeedback={sendFeedback}
                    msgStatus={msgStatus}
                    baseSlug={baseSlug}
                    sendBtn={actionButton}
                  />
                </div>
              )}
            </div>
          </div>
          {footerComponent?.object?.action_button2 && (
            <div className={style['footer-btns']}>
              <ActionButton
                actionButton={footerComponent?.object?.action_button2}
                theme={BUTTON_THEME.FILL_ICON.ORANGE}
                handleOpenModal={handleOpenModal}
                className={style['footer_action-btn']}
              />
            </div>
          )}
        </div>
      )}
      <FooterSecondPart menus={menus} garpixUrl={garpixUrl} />
    </footer>
  )
}

export default FooterLanding
