import { useMutation, useQueryClient } from 'react-query'
import { companyApiService } from '../../../../api/services'
import useModalStore from '../../../../hooks/Modals/useModalStore'
import { useModalIntlContent } from '../../../../hooks/Modals'
import { convertError } from '../../../../utils'
import { COMPANY_QUERY } from '../../const'
import useTranslate from '../../../../hooks/useTranslate'

const useChangeOwner = () => {
  const queryClient = useQueryClient()
  const { contentTypes, openModal } = useModalStore()
  const { translate } = useTranslate()
  const modalWarningContent = useModalIntlContent(contentTypes.warning)
  const modalSuccessContent = useModalIntlContent(contentTypes.successChangeOwner)
  const mutation = useMutation({
    mutationFn: companyApiService.changeOwner,
    onSuccess: () => {
      openModal('commonConfirm', modalSuccessContent)
    },
    onError: (e) => {
      const error = translate(convertError(e))
      openModal('commonConfirm', { ...modalWarningContent, description: error })
    },
    onSettled: () => {
      queryClient.invalidateQueries([COMPANY_QUERY.STAFF_LIST])
      queryClient.invalidateQueries('currentUser')
    }
  })
  return mutation
}

export default useChangeOwner
