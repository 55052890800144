import * as yup from 'yup'
import { ERROR_NUMBERS, INTL_ERROR_MESSAGE } from '@/const'
import { CS_TRUCK_FIELDS_KEYS } from '@/apps/appCargoSpace/const'
import { getValidateText } from '../../ValidateText'
import indentationSchema from '../indentationSchema'
import cargoSpaceSchema from '../cargoSpaceSchema'
import titleSchema from '../TitleSchema'

const TruckSchema = yup.object().shape({
  ...titleSchema,
  ...indentationSchema,
  ...cargoSpaceSchema,
  tilt_distance:
    yup.number()
      .integer(INTL_ERROR_MESSAGE.INTEGER)
      .min(ERROR_NUMBERS.TRUCK.MIN_TILT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.TRUCK.MIN_TILT_NUMBER))
      .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
      .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
      .when(
        CS_TRUCK_FIELDS_KEYS.IS_TILT, {
          is: true,
          then: (scheme) =>
            scheme
              .required(INTL_ERROR_MESSAGE.REQUIRED),
          otherwise: (scheme) => scheme.optional()
        }
      ),
  axles: yup.array().of(
    yup.object().shape({
      distance: yup
        .number()
        .integer(INTL_ERROR_MESSAGE.INTEGER)
        .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
        .min(ERROR_NUMBERS.TRACTOR.MIN_DISTANCE_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.TRACTOR.MIN_DISTANCE_NUMBER))
        .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
        .test(
          'axles_distance',
          {
            id: 'schemes.truck.axlesDistance',
            defaultMessage: 'Дистанция осей не может быть больше длины'
          },
          (value, ctx) => {
            const length = ctx.from[1].value.length
            const distance = ctx.from[1].value.axles.reduce((acc, axl) => acc + axl.distance, 0) || 0
            return distance < length
          }
        )
        .required(INTL_ERROR_MESSAGE.REQUIRED),
      default_load: yup
        .number()
        .integer(INTL_ERROR_MESSAGE.INTEGER)
        .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
        .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
        .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
        .test(
          'axles_load',
          {
            id: 'schemes.truck.axlesLoad',
            defaultMessage: 'Сумма нагрузки пустого полуприцепа на сцепку и нагрузок на оси пустого полуприцепа должна равняться массе полуприцепа'
          },
          (value, ctx) => {
            const mass = +ctx.from[1].value.mass
            const load = ctx.from[1].value.axles.reduce((acc, axl) => acc + axl.default_load, 0) || 0
            const hitchLoad = +ctx.from[1].value.hitch_load
            return load + hitchLoad === mass
          }
        )
        .required(INTL_ERROR_MESSAGE.REQUIRED),
      max_load: yup
        .number()
        .integer(INTL_ERROR_MESSAGE.INTEGER)
        .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
        .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
        .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
        .test(
          'max_load',
          INTL_ERROR_MESSAGE.MAX_LOAD,
          (value, ctx) => value > ctx.parent.default_load
        )
        .required(INTL_ERROR_MESSAGE.REQUIRED)
    })
  ),
  hitch_distance: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.TRUCK.MIN_HITCH_DISTANCE_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.TRUCK.MIN_HITCH_DISTANCE_NUMBER))
    .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
    .test(
      'strict',
      {
        id: 'schemes.truck.hitchDistance',
        defaultMessage: 'Данное поле не может быть больше разности "Длины" на "Расстояние осей Полуприцепа."'
      },
      (value, ctx) => {
        const distances = ctx.parent.axles.reduce((acc, axl) => acc + axl.distance, 0) || 0
        return value <= (ctx.parent.length - distances || 0)
      }
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  hitch_load: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
    .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
    .test(
      'strict',
      {
        id: 'schemes.truck.hitchLoad',
        defaultMessage: 'Нагрузка пустого полуприцепа на сцепку не может быть больше допустимой нагрузки на сцепку.'
      },
      (value, ctx) => value <= ctx.parent.hitch_max_load
    )
    .test(
      'axles_load',
      {
        id: 'schemes.truck.axlesLoad',
        defaultMessage: 'Сумма нагрузки пустого полуприцепа на сцепку и нагрузок на оси пустого полуприцепа должна равняться массе полуприцепа'
      },
      (value, ctx) => {
        const mass = +ctx.parent.mass
        const load = ctx.parent.axles.reduce((acc, axl) => acc + axl.default_load, 0) || 0
        const hitchLoad = +ctx.parent.hitch_load
        return load + hitchLoad === mass
      }
    )
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  hitch_max_load: yup
    .number()
    .integer(INTL_ERROR_MESSAGE.INTEGER)
    .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
    .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
    .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
    .required(INTL_ERROR_MESSAGE.REQUIRED),
  tractor: yup.object().shape({
    ...titleSchema,
    length: yup
      .number()
      .integer(INTL_ERROR_MESSAGE.INTEGER)
      .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
      .min(ERROR_NUMBERS.MIN_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_NUMBER))
      .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
      .required(INTL_ERROR_MESSAGE.REQUIRED),
    hitch_distance: yup
      .number()
      .integer(INTL_ERROR_MESSAGE.INTEGER)
      .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
      .min(ERROR_NUMBERS.TRACTOR.MIN_HITCH_DISTANCE_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.TRACTOR.MIN_HITCH_DISTANCE_NUMBER))
      .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
      .test(
        'hitch_distance',
        {
          id: 'schemes.truck.tractor.hitchDistance',
          defaultMessage: 'Расстояние от задней оси до сцепки не может быть больше полной длины'
        },
        (value, ctx) => value < ctx.parent.length
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED),
    mass: yup
      .number()
      .integer(INTL_ERROR_MESSAGE.INTEGER)
      .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
      .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
      .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
      .required(INTL_ERROR_MESSAGE.REQUIRED),
    axles: yup.array().of(
      yup.object().shape({
        distance: yup
          .number()
          .integer(INTL_ERROR_MESSAGE.INTEGER)
          .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
          .min(ERROR_NUMBERS.TRACTOR.MIN_DISTANCE_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.TRACTOR.MIN_DISTANCE_NUMBER))
          .max(ERROR_NUMBERS.MAX_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_NUMBER))
          .test(
            'axles_distance',
            {
              id: 'schemes.truck.axlesDistance',
              defaultMessage: 'Дистанция осей не может быть больше длины'
            },
            (value, ctx) => {
              const length = ctx.from[1].value.length
              const distance = ctx.from[1].value.axles.reduce((acc, axl) => acc + axl.distance, 0) || 0
              return distance < length
            }
          )
          .required(INTL_ERROR_MESSAGE.REQUIRED),
        default_load: yup
          .number()
          .integer(INTL_ERROR_MESSAGE.INTEGER)
          .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
          .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
          .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
          .test(
            'axles_load',
            {
              id: 'schemes.truck.tractor.axlesLoad',
              defaultMessage: 'Сумма общей нагрузки на оси должна быть больше массы'
            },
            (value, ctx) => {
              const mass = +ctx.from[1].value.mass
              const load = ctx.from[1].value.axles.reduce((acc, axl) => acc + axl.default_load, 0) || 0
              return load > mass
            }
          )
          .required(INTL_ERROR_MESSAGE.REQUIRED),
        max_load: yup
          .number()
          .integer(INTL_ERROR_MESSAGE.INTEGER)
          .typeError(INTL_ERROR_MESSAGE.TYPE_ERROR)
          .min(ERROR_NUMBERS.MIN_WEIGHT_NUMBER, getValidateText('number', 'min', ERROR_NUMBERS.MIN_WEIGHT_NUMBER))
          .max(ERROR_NUMBERS.MAX_WEIGHT_NUMBER, getValidateText('number', 'max', ERROR_NUMBERS.MAX_WEIGHT_NUMBER))
          .test(
            'max_load',
            INTL_ERROR_MESSAGE.MAX_LOAD,
            (value, ctx) => value > ctx.parent.default_load
          )
          .required(INTL_ERROR_MESSAGE.REQUIRED)
      }))
  }),
  truckMassAndSumAxlesHitch: yup
    .number()
    .test(
      'all_mass_and_axles',
      {
        id: 'schemes.truck.truckMassAndSumAxlesHitch',
        defaultMessage: 'Сумма массы тягача и полуприцепа должна быть равна сумме нагрузки на все оси'
      },
      (value, ctx) => {
        const massTruck = +ctx.parent.mass
        const massTractor = +ctx.parent.tractor.mass
        const massTruckAndTractor = massTruck + massTractor
        const loadTractor = ctx.parent.tractor.axles.reduce((acc, axl) => acc + axl.default_load, 0) || 0
        const loadTruck = ctx.parent.axles.reduce((acc, axl) => acc + axl.default_load, 0) || 0
        const loadTractorAndTruck = loadTractor + loadTruck
        return massTruckAndTractor === loadTractorAndTruck
      }
    )
})

export default TruckSchema
