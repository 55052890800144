import React from 'react'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { logoText } from '.././../images'
import styles from './styles/index.module.scss'

const LogoView = () => {
  return (
    <div className={styles.root}>
      <GxIcon src={logoText} />
    </div>
  )
}

export default LogoView
