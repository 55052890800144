import React from 'react'
import cn from 'classnames'

import styles from './styles/index.module.scss'
/**
 * * Вьюха строки правила (с подцветкой ошибки)
 * @view
 * @param {string} keyRule
 * @param {string} errorKey
 * @param {string} rule
 * @param {number} serialNumber
 * @returns
 */
const PasswordRule = ({ keyRule, errorKey, rule, serialNumber }) => {
  const enteredPasswordHasThisError = keyRule === errorKey
  return (
    <li
      className={cn(styles.list__item, {
        [styles.list__item_error]: enteredPasswordHasThisError
      })}
    >
      {serialNumber}: {rule}
    </li>
  )
}

export default PasswordRule
