import { useState } from 'react'
import { useMutation } from 'react-query'
import { companyApiService } from '../../../../api/services'
import { useStoreon } from 'storeon/react'
import { avatar24 } from '../../../../images'

const useSelectUser = () => {
  const { currentUser } = useStoreon('currentUser')
  const [value, setValue] = useState({
    id: currentUser?.company?.user_company_id,
    label: currentUser?.email,
    img: currentUser?.thumbnail || avatar24
  })
  const mutation = useMutation({
    mutationFn: companyApiService.getUsers
  })

  const loadOptions = async (q, prevOptions, { page }) => {
    const params = {
      page,
      companyID: currentUser?.company?.id,
      q,
      page_size: 10,
      is_blocked: false
    }
    const { results, next } = await mutation.mutateAsync(params)
    const options = results.map((item) => ({
      id: item.id,
      label: item.user.email,
      img: item.user.thumbnail || avatar24
    }))
    return {
      options,
      hasMore: !!next,
      additional: {
        page: page + 1
      }
    }
  }
  return { isLoading: mutation.isLoading, loadOptions, value, setValue }
}

export default useSelectUser
