import React from 'react'
import { doneIcon } from '../../images'
import { GxIcon } from '@garpix/garpix-web-components-react'
import style from './style/index.module.scss'

const AssessmentTableField = () => {
  return (
    <div className={style.assessment_icon}>
      <GxIcon src={doneIcon} />
    </div>
  )
}

export default AssessmentTableField
