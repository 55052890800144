/* eslint-disable camelcase */
import { useStoreon } from 'storeon/react'
import { useEffect, useState } from 'react'
import { useDebouncedCallback } from '@/apps/hooks'
import { NEW_CALC_ACTIONS, NEW_CALC_ELEMENTS } from '@/apps/appProject/const'
import { useFormikContext } from 'formik'
import { formattedCargoForBackendSave } from '@/utils'

export const useUpdateProjectSettings = (fetcher, wait = 1000) => {
  const { project } = useStoreon('project')
  const { userSort, is_axis_limit_enabled, is_clone_cargo_space } = project

  return useDebouncedCallback((args) => {
    fetcher({
      element: NEW_CALC_ELEMENTS.SETTINGS,
      action: NEW_CALC_ACTIONS.CHANGE,
      body: {
        userSort: args?.userSort ?? userSort,
        is_clone_cargo_space: args?.is_clone_cargo_space ?? is_clone_cargo_space,
        is_axis_limit_enabled: args?.is_axis_limit_enabled ?? is_axis_limit_enabled
      }
    })
  }, wait)
}

export const useUpdateCargo = ({
  fetcher,
  cargoIndex,
  groupIndex
}) => {
  const [isUserChange, setIsUserChange] = useState(false)
  const { handleChange, values, validateForm, setFieldValue } = useFormikContext()
  const cargo = values.groups[groupIndex].cargoes[cargoIndex]
  const groupId = values.groups[groupIndex].group_id

  const debouncedFetch = useDebouncedCallback(async () => {
    const errors = await validateForm()
    const errorInCurrentGroup = errors?.groups?.at(groupIndex)?.cargoes?.at(cargoIndex)
    if (errorInCurrentGroup) return
    fetcher({
      element: NEW_CALC_ELEMENTS.CARGO,
      action: NEW_CALC_ACTIONS.CHANGE,
      body: {
        cargo: formattedCargoForBackendSave(cargo),
        group_id: cargo?.group_id ?? groupId
      }
    })
  }, 3000)
  /**
   * @function handleChangeCargoFields
   * @param {React.ChangeEvent<HTMLElement>} event
   */
  const handleChangeCargoFields = (event) => {
    setIsUserChange(true)
    handleChange(event)
  }

  /**
   * @function handleChangeCargoFieldCustom
   * @param {string | React.ChangeEvent<HTMLElement>} event
   * @param {number | string | undefined} value
   */
  const handleChangeCargoFieldCustom = (event, value) => {
    setIsUserChange(true)
    const name = typeof event === 'string' ? event : event.target.name
    const userInput = value ?? event.target.checked

    setFieldValue(name, userInput)
  }

  useEffect(() => {
    if (isUserChange) {
      debouncedFetch(cargo)
    }
  }, [cargo, isUserChange])

  return { handleChangeCargoFields, handleChangeCargoFieldCustom }
}
