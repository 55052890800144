import styles from './styles/index.module.scss'
import useTranslate from '../../../../hooks/useTranslate'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { crown, historyIcon } from '../../../../images'
import Divider from '../../../components/views/Divider'
import TariffLabel from '../TariffLabelView'
import Button from '../../../../views/Button'
import React from 'react'
import { DIVIDER_THEME } from '../../../../const'
import { decOfMonth, formatPrice } from '../../../../utils'
import { formInfinityVal } from '../../helpers'

const TariffDetails = ({ tariff, activeTab, currentUser, regLink, handleBuyTariff, advantages, hasPromotionType }) => {
  const { formatMonth } = useTranslate()
  const {
    price,
    price_per_period: monthPrice,
    base_type: baseType,
    promotion_price: promotionActionPrice,
    promocode_info: promocodeInfo
  } = tariff
  const {
    price_promotion: pricePromotion
  } = promocodeInfo || {}
  const amountTime = activeTab.amount
  const timeUnit = activeTab.time_units
  const tariffLabelText = hasPromotionType ? `${formatPrice(price)} через ${amountTime} ${decOfMonth(amountTime)}` : `${formatPrice(monthPrice)} в месяц`

  //* приоритета у цены нет
  const currentPrice = promotionActionPrice || pricePromotion || price
  return (
    <div className={styles.tariff_details__root}>
      <div className={styles.tariff_details__wrapper}>
        <ul className={styles.tariff_details__advantages_list}>
          <li className={styles.tariff_details__advantages_item}>
            <GxIcon slot='icon-left' src={baseType.icon || crown} />
            Тариф:<span>{baseType.title}</span>
          </li>
          <li className={styles.tariff_details__advantages_item}>
            <GxIcon slot='icon-left' src={historyIcon} />
            Период:<span>{formatMonth(amountTime, timeUnit)}</span>
          </li>
          {advantages.map(el => {
            return (
              el.title
                ? (
                  <li key={el.id} className={styles.tariff_details__advantages_item}>
                    <GxIcon slot='icon-left' src={el.icon || historyIcon} />
                    {el.title}:<span>{formInfinityVal(el.value)}</span>
                  </li>
                  )
                : null
            )
          })}
        </ul>
        <Divider theme={DIVIDER_THEME.M20} />
        <div>
          {(hasPromotionType || amountTime !== 1) && (
            <div className={styles.tariff_details__price_label}>
              <TariffLabel>{tariffLabelText}</TariffLabel>
            </div>
          )}
          <div className={styles.tariff_details__price}>
            {formatPrice(currentPrice)}
          </div>
        </div>
      </div>
      <Button
        className={styles.tariff_details__btn}
        onClick={() => handleBuyTariff(tariff)}
      >
        Купить
      </Button>
    </div>
  )
}

export default TariffDetails
