import React from 'react'
import DropdownViews from '../../../../views/DropdownViews'
import Button from '../../../../views/Button'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { downIcon } from '../../../../images'
import styles from './styles/index.module.scss'
import useLocale from '../../../../hooks/useLocale'
import { LOCALE } from '../../../../const'

const languages = [LOCALE.RU, LOCALE.EN]

const LocaleMenu = () => {
  const { locale, setLocale } = useLocale()
  const handleClick = (lang) => {
    setLocale(lang)
  }
  return (
    <DropdownViews.Dropdown hoist skidding={-10}>
      <Button
        slot='trigger'
        caret
        variant='text'
        theme='language_dropdown'
      >
        <GxIcon
          slot='icon-right'
          src={downIcon}
          className={styles.locale__icon}
        />
        {locale}
      </Button>
      <DropdownViews.Menu>
        {
          languages.map(lang => {
            return (
              <DropdownViews.Item
                key={lang}
                onClick={() => handleClick(lang)}
              >
                {lang}
              </DropdownViews.Item>
            )
          })
        }
      </DropdownViews.Menu>
    </DropdownViews.Dropdown>
  )
}

export default LocaleMenu
