import React from 'react'
import WatchUsView from '../../../views/LandingView/WatchUs'
import { useModalStore } from '../../../hooks/Modals'
import { CARDS } from '../../../const'

const WatchUs = ({ handleOpenModal, ...props }) => {
  const {
    content: { video_link: videoLink }
  } = props
  const { openModal } = useModalStore()

  const handleOpenYouTubeModals = () => {
    openModal('homeVideo', { theme: 'large', videoLink })
  }
  return (
    <WatchUsView
      {...props}
      id={CARDS.WATCH_US.id}
      handleOpenYouTubeModals={handleOpenYouTubeModals}
      handleOpenModal={handleOpenModal}
    />
  )
}

export default WatchUs
