const visualData = (store) => {
  store.on('@init', () => ({ visualData: {} }))
  store.on('set/visualData', (_, vd) => {
    return {
      visualData: vd,
    }
  })
}

export default visualData
