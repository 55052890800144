/* eslint-disable camelcase */
import React from 'react'
import SectionHomePage from '.'
import ActionButton from '../ActionButton'
import { BUTTON_THEME } from '../../../const'
import styles from './styles/index.module.scss'

const SectionHomePageWithButtons = ({ children, handleOpenModal, content, ...props }) => {
  const { action_button, action_button2 } = content

  return (
    <SectionHomePage {...props}>
      {children}
      {(action_button || action_button2) && (
        <div className={styles.section_btns}>
          {action_button && (
            <ActionButton
              actionButton={action_button}
              theme={BUTTON_THEME.FILL_ICON.ORANGE}
              handleOpenModal={handleOpenModal}
              className={styles.section_btn}
            />
          )}
          {action_button2 && (
            <ActionButton
              actionButton={action_button2}
              theme={BUTTON_THEME.FILL_ICON.ORANGE}
              handleOpenModal={handleOpenModal}
              className={styles.section_btn}
            />
          )}
        </div>
      )}
    </SectionHomePage>
  )
}

export default SectionHomePageWithButtons
