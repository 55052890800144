import { useMutation } from 'react-query'
import { useStoreon } from 'storeon/react'

import { useModalStore, useModalIntlContent } from '@/hooks/Modals'
import { calculationApiService } from '@/api/services'
import { convertError } from '@/utils'
// import { CALCULATION_STATUS } from '@/const'

/**
 * @description
 * ! замыкаем calcId чтобы не вызывать перезапрос групп с грузами
 */
let calculationId = null

/**
 * @typedef { import("@/api/types/calculation-models").NewCalculationModel } NewCalculationModel
 */

const useUpdateCalc = () => {
  const { project } = useStoreon('project')
  const { openModal, contentTypes } = useModalStore()
  const content = useModalIntlContent(contentTypes.warning)
  const updateCalc = useMutation({
    mutationFn: async (changedVal) =>
      await calculationApiService.changeProjectData(
        project.id,
        changedVal,
        calculationId ?? project.calculation_id
      ),
    onSuccess: ({ calculation_id: calcId }) => {
      setCalcId(calcId)
      /**
       * @description
       * ! Dont touch - комм пока автосейвы не ворк
       */
      // dispatch('project/calc/id/save', calcId)
      // dispatch('project/calc/status/save', CALCULATION_STATUS.DESIGNED)
    },
    onError: (err) => {
      const errorMessage = convertError(err)
      openModal('commonConfirm', { ...content, description: errorMessage })
    }
  })
  /**
   *
   * @param {number | null} value
   */
  function setCalcId (value) {
    calculationId = value
  }

  return { ...updateCalc, calculationId, setCalcId }
}

export default useUpdateCalc
