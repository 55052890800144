/* eslint-disable camelcase */
import React, { useRef } from 'react'
import PartnersCard from '../../PartnersCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper/core'
import styles from './styles/index.module.scss'
import classNames from 'classnames'
import { GxIcon } from '@garpix/garpix-web-components-react'
import { arrowIcon } from '../../../images'

SwiperCore.use([Navigation])

const Partners = ({ content }) => {
  const { partners_elements } = content
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  return (
    <div className={styles.slider__wrapper}>
      <Swiper
        className={classNames(
          styles.slider__swiper,
          partners_elements.length < 2 && styles.slider__list
        )}
        slidesPerView={1}
        navigation={{
          nextEl: 'slider__button--next',
          prevEl: 'slider__button--prev'
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current
          swiper.params.navigation.nextEl = nextRef.current
        }}
        breakpoints={{
          1024: {
            slidesPerView: 2
          }
        }}
      >
        {partners_elements &&
          partners_elements.length > 0 &&
          partners_elements.map((el) => {
            return (
              <SwiperSlide key={el.id}>
                <PartnersCard data={el} />
              </SwiperSlide>
            )
          })}
        <div
          ref={prevRef}
          className={classNames(
            styles.slider__button,
            styles['slider__button--prev']
          )}
        >
          <GxIcon src={arrowIcon} />
        </div>
        <div
          ref={nextRef}
          className={classNames(
            styles.slider__button,
            styles['slider__button--next']
          )}
        >
          <GxIcon src={arrowIcon} />
        </div>
      </Swiper>
    </div>
  )
}

export default Partners
