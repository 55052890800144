import * as yup from 'yup'
import { INTL_ERROR_MESSAGE } from '../../const'

const GradeModalSchema = yup.object().shape({
  comment:
    yup
      .string()
      .max(500, INTL_ERROR_MESSAGE.MAX_LENGTH_MESSAGE)
})

export default GradeModalSchema
