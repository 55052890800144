import * as yup from 'yup'
import { INPUT_REGEXP, INTL_ERROR_MESSAGE } from '../../const'
import { PASSWORD_VAL_FUNC_KEYS } from '../../apps/appRegistration/consts'

const checkYourValWithRegExp = (val, regExp, count) => {
  if (!val || !count || count <= 0) return true
  const findedValue = val.match(regExp)
  return findedValue?.length >= count
}
/**
 * * Метод возвращающий кастомную валидацию вашего пароля (газпром)
 * @func getPasswordValidateGaz
 * @param passwordConfig конфинг с требованиями к паролю
 * @param fieldsName названия валидируемых полей (если деф не подходят)
 * @returns
 */
export const getPasswordValidateGaz = (passwordConfig, fieldsName = {
  password: 'password',
  repeatPassword: 'repeatPassword'
}) => {
  if (!passwordConfig) {
    return {
      [fieldsName.password]: yup.string().trim().required(INTL_ERROR_MESSAGE.REQUIRED),
      [fieldsName.repeatPassword]: yup
        .string()
        .trim()
        .oneOf([yup.ref(fieldsName.password)], INTL_ERROR_MESSAGE.MATCH_PASS)
        .required(INTL_ERROR_MESSAGE.REQUIRED)
    }
  }

  return {
    [fieldsName.password]: yup
      .string()
      .trim()
      .min(passwordConfig.min_length, PASSWORD_VAL_FUNC_KEYS.min_length)
      .test('checkUppercaseChars', PASSWORD_VAL_FUNC_KEYS.min_uppercase, (val) =>
        checkYourValWithRegExp(
          val,
          INPUT_REGEXP.UNICODE_UPPERCASE_CHARS,
          passwordConfig?.min_uppercase
        )
      )
      .test('checkLowercaseChars', PASSWORD_VAL_FUNC_KEYS.min_chars, (val) =>
        checkYourValWithRegExp(
          val,
          INPUT_REGEXP.UNICODE_CHARS,
          passwordConfig?.min_chars
        )
      )
      .test('checkMinDigits', PASSWORD_VAL_FUNC_KEYS.min_digits, (val) =>
        checkYourValWithRegExp(
          val,
          INPUT_REGEXP.DIGITS_SYMBOL,
          passwordConfig?.min_digits
        )
      )
      .test(
        'checkMinSpecialSymbols',
        PASSWORD_VAL_FUNC_KEYS.min_special_symbols,
        (val) =>
          checkYourValWithRegExp(
            val,
            INPUT_REGEXP.SPECIAL_SYMBOLS,
            passwordConfig?.min_special_symbols
          )
      )
      .required(INTL_ERROR_MESSAGE.REQUIRED),
    [fieldsName.repeatPassword]: yup
      .string()
      .trim()
      .oneOf([yup.ref(fieldsName.password)], INTL_ERROR_MESSAGE.MATCH_PASS)
      .required(INTL_ERROR_MESSAGE.REQUIRED)
  }
}
