import { GxCheckbox } from '@garpix/garpix-web-components-react'
import React from 'react'
import style from './styles/index.module.scss'
import classNames from 'classnames'

const Checkbox = ({
  children,
  theme = 'checkbox_default',
  className,
  ...props
}) => {
  return (
    <GxCheckbox className={classNames(style[theme], className)} {...props}>
      {children}
    </GxCheckbox>
  )
}

export default Checkbox
