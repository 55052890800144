import React from 'react'
import { Helmet } from 'react-helmet-async'
import usePage from '../../../hooks/usePage'

import { APP_ENVIRONMENT, ALLOWED_PAGE_MODELS } from '../../../const'
import { runtimeConfig } from '../../../config'

const vkPixi = `
  !function(){var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?169',t.onload=function()
  {VK.Retargeting.Init("VK-RTRG-1710756-b37J4"),VK.Retargeting.Hit()}
  ,document.head.appendChild(t)}();
  `

const yandexMetric = `(function (m, e, t, r, i, k, a) {
        m[i] =
          m[i] ||
          function () {
            (m[i].a = m[i].a || []).push(arguments);
          };
        m[i].l = 1 * new Date();
        (k = e.createElement(t)),
          (a = e.getElementsByTagName(t)[0]),
          (k.async = 1),
          (k.src = r),
          a.parentNode.insertBefore(k, a);
      })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      ym(86465851, "init", {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      });`

/**
 * @hoc
 * * Hoc с метриками, которые работают на определенных стр
 */
const withMetrics = Component => props => {
  const { page } = usePage()
  const isProd = runtimeConfig.RAZZLE_APP_ENVIRONMENT === APP_ENVIRONMENT.PRODUCTION

  if (ALLOWED_PAGE_MODELS.includes(page.page_model) && isProd) {
    return (
      <>
        <Helmet>
          <meta name='google-site-verification' content='iae8aGKUFKA2cbSnNdTv50B23pD0-By8FgAeaOG_B_0' />
          <script type='text/javascript'>{yandexMetric}</script>
          <script type='text/javascript'>{vkPixi}</script>
        </Helmet>
        <Component {...props} />
      </>
    )
  }

  return <Component {...props} />
}

export default withMetrics
