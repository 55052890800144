import React, { memo } from 'react'
import { GxIcon, GxSpinner } from '@garpix/garpix-web-components-react'

import { downIcon } from '../../images'
import style from './select.module.scss'

export const DropdownIndicator = memo(() => (
  <GxIcon src={downIcon} className={style.button_icon} data-cy='select-indicator' />
))

export const LoadingIndicator = () => <GxSpinner />
