import React from 'react'
import ModalView from '../../../ModalView'
import styles from '../styles/index.module.scss'
import { useIntl } from 'react-intl'

const SaveCargo = (props) => {
  const { cargo, handleClose, handleBack } = props
  const onBackHandler = () => {
    handleBack()
    handleClose()
  }
  const intl = useIntl()
  return (
    <div data-cy='save-cargo-modal'>
      <ModalView.Icon>
        <ModalView.Icon.Success />
      </ModalView.Icon>
      <ModalView.Title>
        {
          intl.formatMessage({
            id: 'modals.saveCargo.title',
            defaultMessage: 'Груз <underline>{cargo}</underline> успешно сохранен'
          }, {
            cargo,
            underline: (chunks) => <span className={styles['text--underline']}>{chunks}</span>
          })
        }
      </ModalView.Title>
      <ModalView.Buttons>
        <ModalView.Buttons.Button data-cy='modal-cancel-btn' theme='stroke_icon--small' onClick={onBackHandler}>
          {
            intl.formatMessage({
              id: 'modals.saveCargo.accept',
              defaultMessage: 'Вернуться к грузам'
            })
          }
        </ModalView.Buttons.Button>
        <ModalView.Buttons.Button data-cy='modal-accept-btn' theme='fill_icon--small' onClick={handleClose}>
          {
            intl.formatMessage({
              id: 'continue',
              defaultMessage: 'Продолжить'
            })
          }
        </ModalView.Buttons.Button>
      </ModalView.Buttons>
    </div>
  )
}

export default SaveCargo
