import React from 'react'
import { GxTextarea } from '@garpix/garpix-web-components-react'
import style from './styles/index.module.scss'

const Textarea = ({
  children,
  theme = 'textarea_default',
  placeholder,
  label,
  errorText = '',
  required,
  error,
  ...props
}) => {
  return (
    <>
      <GxTextarea
        className={style[theme]}
        placeholder={placeholder}
        label={label}
        required={required}
        error={error}
        {...props}
      >
        {children}
        <div className={style.error_text} slot='help-text'>
          {errorText}
        </div>
      </GxTextarea>
    </>
  )
}

export default Textarea
