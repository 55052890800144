import React from 'react'
import Layout from '.'
import HeaderComponent from '../../components/HeaderComponent'
import styles from './styles/index.module.scss'
import ProfileNavigation from '../../components/ProfileComponent/ProfileNavigation'
import Section from '../Section'
import { SECTION_VARIANT } from '../../const'

const PersonalArea = ({
  children,
  header = {},
  dataAttr = '',
  noBanner
}) => {
  return (
    <Layout.Seo>
      <HeaderComponent showOnMobile={false} header={header} noBanner={noBanner} />
      <main data-cy={dataAttr} className={styles['main--default']}>
        <ProfileNavigation />
        <Section variant={SECTION_VARIANT.PERSONAL_AREA_PAGES} noBaner>
          {children}
        </Section>
      </main>
    </Layout.Seo>
  )
}
export default PersonalArea
