import React from 'react'
import { useFormik } from 'formik'
import { RenameSchema } from '../../../utils/ValidateSchemes'
import { Create } from '../../../views/BaseModal/ModalContent/'
import { useIntl } from 'react-intl'

const RenameModal = (props) => {
  const { handleClose, title, initialTitle, handleAccept } = props
  const intl = useIntl()

  const formik = useFormik({
    initialValues: {
      title: initialTitle
    },
    validationSchema: RenameSchema,
    onSubmit: handleAccept
  })

  const inputLabel = intl.formatMessage({
    id: 'modals.rename.inputLabel',
    defaultMessage: 'Новое название'
  })

  const buttonLabel = intl.formatMessage({
    id: 'rename',
    defaultMessage: 'Переименовать'
  })

  return (
    <Create
      formik={formik}
      title={title}
      inputLabel={inputLabel}
      buttonLabel={buttonLabel}
      closeModal={handleClose}
    />
  )
}

export default RenameModal
