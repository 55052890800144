import React from 'react'
import { useStoreon } from 'storeon/react'
import ProfileViews from '../../views/ProfileViews'

const CountrySelect = ({ onChange, country }) => {
  const { countries } = useStoreon('countries')

  return (
    <ProfileViews.CountrySelect
      countries={countries}
      onChange={onChange}
      country={country}
    />
  )
}

export default CountrySelect
