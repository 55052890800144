import React from 'react'
import Loader from '../Loader'
import styles from './styles/index.module.scss'
import classNames from 'classnames'
import { PAGE_LOADER_THEME } from '../../const'

const PageLoader = ({ className = '', theme = PAGE_LOADER_THEME.DEFAULT }) => {
  return (
    <div className={classNames(styles[theme], className)}>
      <Loader />
    </div>
  )
}

export default PageLoader
