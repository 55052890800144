import api from '../../../api'

const LocaleModule = (store) => {
    store.on('@init', () => ({
        locale: api.getLanguage(),
    }))
    store.on('set/locale', (_, lang) => {
        return {
            locale: lang
        }
    })
}

export default LocaleModule

