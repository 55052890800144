import React from 'react'
import { downIcon } from '../../../../images'
import DirectLink from './../../../../components/DirectLink'
import { GxIcon } from '@garpix/garpix-web-components-react'
import styles from '../styles/index.module.scss'
import { BUTTON_VARIANT, BUTTON_THEME } from '../../../../const'
import HeaderLandingLink from '../../../HeaderLanding/HeaderLandingLink'
import DropdownViews from '../../../DropdownViews'
import Media from 'react-media'
import ActionButton from '../../../LandingView/ActionButton'

import Button from '../../../Button'
import { FormattedMessage } from 'react-intl'

const HomeMenu = ({
  closeModal,
  baseSlug,
  page,
  content
}) => {
  const { menu, image: logo, action_button: actionButton, action_button2: actionButton2 } = content
  return (
    <div className={styles.home_menu__modal}>
      <div style={{ width: '100%' }}>
        <header className={styles.home_menu__header}>
          <DirectLink handleClick={closeModal} link={baseSlug.pageHome}>
            <img src={logo} alt='Logo' className={styles.home_menu__image} />
          </DirectLink>
        </header>
        <div className={styles.home_menu__nav}>
          {page?.page_model && (
            <div className={styles.home_menu__links}>
              {menu.map(({ id, url, title, target_blank: targetBlank, children }) =>
                <React.Fragment key={id}>
                  {children && children.length > 0
                    ? (
                      <Media
                        queries={{
                          small: '(max-width: 567.98px)',
                          medium: '(max-width: 1200px)'
                        }}
                      >
                        {(media) => {
                          const positionDropdown = media.small ? 'bottom-start' : 'bottom'

                          return (
                            <DropdownViews.Dropdown
                              distance='10'
                              className={styles.home_menu__dropdown}
                              placement={positionDropdown}
                            >
                              <Button
                                slot='trigger'
                                caret
                                variant={BUTTON_VARIANT.TEXT}
                                className={styles.home_menu__dropdown_btn}
                                theme={BUTTON_THEME.PROFILE_DROPDOWN}
                              >
                                {title}
                                <GxIcon className={styles.home_menu__dropdown_img} src={downIcon} />
                              </Button>
                              <DropdownViews.Menu>
                                {children.map(
                                  ({ url, id, title, target_blank: targetBlank }) => (
                                    <DropdownViews.Item
                                      key={id}
                                      className={styles.home_menu__dropdown_item}
                                    >
                                      <HeaderLandingLink
                                        className={styles.home_menu__dropdown_link}
                                        title={title}
                                        url={url}
                                        targetBlank={targetBlank}
                                        baseSlug={baseSlug}
                                        handleClick={closeModal}
                                      />
                                    </DropdownViews.Item>
                                  )
                                )}
                              </DropdownViews.Menu>
                            </DropdownViews.Dropdown>
                          )
                        }}
                      </Media>
                      )
                    : (
                      <HeaderLandingLink
                        title={title}
                        url={url}
                        targetBlank={targetBlank}
                        baseSlug={baseSlug}
                        className={styles.home_menu__link}
                        handleClick={closeModal}
                      />
                      )}
                </React.Fragment>
              )}
            </div>
          )}
          <div className={styles['home_menu__link-buttons']}>
            {
              actionButton && (
                <ActionButton
                  actionButton={actionButton}
                  callBack={closeModal}
                  theme={BUTTON_THEME.FILL_ICON.ORANGE}
                  data-cy='header-menu-auth-link'
                />
              )
            }
            {
              actionButton2 && (
                <ActionButton
                  actionButton={actionButton2}
                  callBack={closeModal}
                  data-cy='header-menu-registration-link'
                  theme={BUTTON_THEME.FILL_ICON.ORANGE}
                />
              )
            }
          </div>
        </div>
      </div>
      <footer className={styles.home_menu__footer}>
        <a
          data-cy='header-menu-privacy-policy-link'
          href={baseSlug.pagePrivacyPolicy}
          target='_blank'
          className={styles.home_menu__footer_link} rel='noreferrer'
        >
          <FormattedMessage
            id='privacyPolicy'
            defaultMessage='Политика конфиденциальности'
          />
        </a>
        <a
          data-cy='header-menu-contract-offer-link'
          href={baseSlug.pageContractOffer}
          target='_blank'
          className={styles.home_menu__footer_link} rel='noreferrer'
        >
          <FormattedMessage
            id='contractOffer'
            defaultMessage='Договор оферты'
          />
        </a>
      </footer>
    </div>
  )
}

export default HomeMenu
